<div class="m-3">
  <h1 mat-dialog-title>Comments</h1>
  <mat-divider></mat-divider>
  <div mat-dialog-content *ngFor="let reviewComment of data.reviewComments">
    <div class="dialog-body-message">
      <div class="title">
        {{ reviewComment.user.firstName }} {{ reviewComment.user.lastName }} -
        {{ reviewComment.created | date: "yyyy-MM-dd HH:mm:ss" }}
      </div>
      <div class="comment">{{ reviewComment.comments }}</div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button
      class="
        mat-raised-button mat-button-base
        mt-3
        bg-green-500
        text-contrast-white
      "
      mat-raised-button
      type="button"
      (click)="onOkClick()"
    >
      Ok
    </button>
  </div>
</div>
