<div fxLayout="row" class="clearfix">
  <mat-form-field fxFlex="100%">
    <mat-label>Comments {{ approve ? "(Optional)" : "(Required)" }}</mat-label>
    <textarea
      matInput
      cdkTextareaAutosize
      [formControl]="comment"
      #autosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="5"
      style="overflow: hidden"
      maxlength="commentsFieldLimit"
      placeholder="Comments"
      aria-label="Comments"
    ></textarea>

    <span style="float: right"
      >{{ comment && commentsFieldLimit - comment.value?.length }} chars
      left</span
    >
  </mat-form-field>
</div>
<div
  fxLayout="row"
  fxLayoutAlign="end bottom"
  fxLayoutGap="8px"
  class="clearfix"
>
  <button
    class="mat-raised-button mat-button-base mt-3 bg-gray-500 text-contrast-black"
    mat-raised-button
    type="button"
    (click)="cancel()"
  >
    {{ "cancel" | translate }}
  </button>
  <button
    [ngClass]="{
      'mat-raised-button mat-button-base mt-3 text-contrast-white': true,
      'bg-green-500': approve,
      'bg-red-500': !approve
    }"
    mat-raised-button
    type="button"
    [disabled]="commentsDisabled"
    (click)="save()"
  >
    <span *ngIf="isSubmitting$ | async">
      <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
    </span>
    {{ approve ? "Approve" : "Reject" }}
  </button>
</div>
