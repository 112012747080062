import { Injectable } from '@angular/core';
import { Observable, of, throwError, Subject } from 'rxjs';
import { AuthorizedHttp } from './authorized-http.service';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NonOnliner } from '../data-models/NonOnliner';

@Injectable()
export class AlternateOnlinerMaintenanceService {
  private _apiEndpoint = `${environment.apiEndpoint}/api/NonOnliner`;
  private _nonOnliners: NonOnliner[];
  private _nonOnliner: NonOnliner;
  private _isDeleted: boolean;

  constructor(private http: AuthorizedHttp) {
    const headers: any = {
        'Content-Type': 'application/json; charset=utf-8', /*or whatever type is relevant */
        'Accept': 'application/json'
    }
  }

  public getNonOnlinersAsync(): Observable<NonOnliner[]> {
    return this.http
      .get(`${this._apiEndpoint}/`)
      .pipe(
        map(result => {
          this._nonOnliners = result;
          return this._nonOnliners;
        }),
        catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
      );
  }

    public getByUserId(userId: string): Observable<NonOnliner> {
      return this.http
        .get(`${this._apiEndpoint}/getByUserId/`+ userId)
        .pipe(
          map(result => {
            this._nonOnliner = result;
            return this._nonOnliner;
          }),
          catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
        );
    }

    public createNewNonOnlinerAsync(nonOnliner: NonOnliner): Observable<NonOnliner> {
      return this.http
        .post(`${this._apiEndpoint}/`, JSON.stringify(nonOnliner))
        .pipe(
          map(result => {
            this._nonOnliner = result;
            return this._nonOnliner;
          }),
          catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
        );
    }

    public search(userName: string): Observable<NonOnliner[]> {
      return this.http
        .get(`${this._apiEndpoint}/search/` + userName)
        .pipe(
          map(result => {
            this._nonOnliners = result;
            return this._nonOnliners;
          }),
          catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
        );
    }  
    
    public updateNonOnlinerAsync(nonOnliner: NonOnliner): Observable<NonOnliner> {
      return this.http
        .patch(`${this._apiEndpoint}`, JSON.stringify(nonOnliner))
        .pipe(
          map(result => {
            this._nonOnliner = result;
            return this._nonOnliner;
          }),
          catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
        );
    }    
}
