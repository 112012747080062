import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { AuthorizedHttp } from './authorized-http.service';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ProfileSummary } from '../data-models/ProfileSummary';
import { CommonService } from "./common.service";

@Injectable()
export class ProfileSummaryNOService {
  private _apiEndpoint = `${environment.apiEndpoint}/api/profilesummaryNO`;
  private _profileSummaries: ProfileSummary[];
  private _profileSummary: ProfileSummary;
  private _isDeleted: Boolean;

  constructor(private http: AuthorizedHttp, private commonService: CommonService) { }

  public getProfileSummariesNO(userId: string): Observable<ProfileSummary[]> {
    userId = this.commonService.encrypt(userId);
    return this.http
      .get(`${this._apiEndpoint}/myProfileSummariesNO/` + userId)
      .pipe(
        map(result => {
          this._profileSummaries = result;
          return this._profileSummaries;
        }),
        catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
      );
  }

  public postNewProfileSummaryNO(profileSummary: ProfileSummary, userId: string): Observable<ProfileSummary> {
    userId = this.commonService.encrypt(userId);
    return this.http
      .post(`${this._apiEndpoint}/addNewProfileSummaryNO/` + userId, JSON.stringify(profileSummary))
      .pipe(
        map(result => {
          this._profileSummary = result;
          return this._profileSummary;
        }),
        catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
      );
  }

  public updateProfileSummaryNO(profileSummary: ProfileSummary, userId: string): Observable<ProfileSummary> {
    userId = this.commonService.encrypt(userId);
    return this.http
      .patch(`${this._apiEndpoint}/updateProfileSummaryNO/` + userId, JSON.stringify(profileSummary))
      .pipe(
        map(result => {
          this._profileSummary = result;
          return this._profileSummary;
        }),
        catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
      );
  }

    public deleteProfileSummary(profileSummary: ProfileSummary, userId: string): Observable<ProfileSummary> {
      userId = this.commonService.encrypt(userId);
        return this.http
            .patch(`${this._apiEndpoint}/deleteProfileSummaryNO/` + userId, JSON.stringify(profileSummary))
            .pipe(
                map(result => {
                    this._profileSummary = result;
                    return this._profileSummary;
                }),
                catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
            );
    }
}