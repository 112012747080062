import { DatePipe } from "@angular/common";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MomentDateModule } from "@angular/material-moment-adapter";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Routes } from "@angular/router";
// Translation
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { MsAdalAngular6Module } from "microsoft-adal-angular6";
import { SidenavComponent } from "src/@vex/layout/sidenav/sidenav.component";
import { AuthGuard } from "src/app/common/services/auth-guard.service";
import { AuthService } from "src/app/common/services/auth.service";
import { AuthorizedHttp } from "src/app/common/services/authorized-http.service";
import { CommonService } from "src/app/common/services/common.service";
import { DataApprovalService } from "src/app/common/services/dataApproval.service";
import { OnlinerService } from "src/app/common/services/onliner.service";
import { PendingChangesGuard } from "src/app/common/services/pending-changes-guard.service";
import { ProfileSummaryService } from "src/app/common/services/profileSummary.service";
import { SnackBarService } from "src/app/common/services/snackbar.service";
import { UserService } from "src/app/common/services/user.service";
import { environment } from "src/environments/environment";
import { VexModule } from "../@vex/vex.module";
import { AlternateOnlinerMaintenanceModule } from "./alternateOnlinerMaintenance/alternateOnlinerMaintenance.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AttributeApprovalsModule } from "./attribute-approvals/attribute-approvals.module";
// import { BuildResumeModule } from "./build-resume/build-resume.module";
import { BuildResumeCommonModule } from "./build-resume-common/build-resume-common.module";
import { AlternateOnlinerMaintenanceService } from "./common/services/alternateOnlinerMaintenance.service";
import { AssociationsService } from "./common/services/association.service";
import { AssociationsNOService } from "./common/services/associationNO.service";
import { AttributeService } from "./common/services/attribute.service";
import { AutoSuggestService } from "./common/services/auto-suggest.service";
import { EducationService } from "./common/services/education.service";
import { EducationNOService } from "./common/services/educationNO.service";
import { EducationApprovalsResolverService } from "./common/services/educationApprovals-resolver.service";
import { ExcelService } from "./common/services/excel.service";
import { OnlinerSearchUtilService } from "./common/services/onliner-search-util.service";
import { OnlinersResolverService } from "./common/services/onliners-resolver.service";
import { ProfileSummaryNOService } from "./common/services/profileSummaryNO.service";
import { ProjectService } from "./common/services/project.service";
import { ProjectNOService } from "./common/services/projectNO.service";
import { ResumeSearchUtilService } from "./common/services/resume-search-util.service";
import { ResumeService } from "./common/services/resume.service";
import { ResumeNOService } from "./common/services/resumeNO.service";
import { SkillsService } from "./common/services/skill.service";
import { SkillsNOService } from "./common/services/skillNO.service";
import { SkillSummaryService } from "./common/services/skillSummary.service";
import { SkillSummaryNOService } from "./common/services/skillSummaryNO.service";
import { UtilityService } from "./common/services/utility.service";
import { WorkflowService } from "./common/services/workflow.service";
import { CustomLayoutModule } from "./custom-layout/custom-layout.module";
import { OnlinerSearchModule } from "./onliner-search/onliner-search-module";
import { ResumePreviewModule } from "./resume-preview/resume-preview.module";
import { ResumeReviewModule } from "./resume-review/resume-review.module";
import { ReviewCommentsModalModule } from "./review-comments-modal/review-comments-modal.module";
import { SkillDescriptionModalModule } from "./skill-description-modal/skill-description-modal.module";
import { SkillCompetencyAndCategoryService } from "src/app/common/services/skill-competency-and-category.service";
import { ResumesSearchModule } from "./searchResumes/resumes-search-module";
import { ResumesSearchInputModule } from "./searchResumes/search-input/search-input-module";
import { VarDirective } from "./shared/app-var.directive";
import { ForbiddenComponent } from "./shared/forbidden/forbidden.component";
import { IfRoleDirective } from "./shared/if-role/if-role.directive";
import { MatIconModule } from "@angular/material/icon";
import { LogoutModule } from "./logout/logout.module";
import { AuthGuardLogout } from "./common/services/auth-guard-logout.service";
import {
  MsalModule,
  MsalRedirectComponent,
  MsalGuard,
  MsalInterceptor,
} from "@azure/msal-angular";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { LocalLoader } from "./common/utilities/localLoader";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects"
import { CompetencyService } from "./common/services/competency.service";
import { CompetencyMaintenanceService } from "./common/services/competency-maintenance.service";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, `assets/i18n/`, ".json");
}
// end translation
const appRoutes: Routes = [
  { path: "forbidden", component: ForbiddenComponent, pathMatch: "full" },
];

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;
const MSALConfig = {
  clientId: environment.webApiClientId,
  authority: environment.cloudInstanceId + environment.tenant,
  redirectUri: environment.postLogoutRedirect,
};

@NgModule({
  declarations: [
    AppComponent,
    ForbiddenComponent,
    VarDirective,
    IfRoleDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatProgressSpinnerModule,
    // Msal Config
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: MSALConfig,
        cache: {
          cacheLocation: "sessionStorage",
          storeAuthStateInCookie: isIE,
        },
      }),
      {
        interactionType: InteractionType.Redirect, // MSAL Guard Configuration
        authRequest: {
          scopes: ["User.Read"],
        },
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map<string, string[]>([
          ["https://graph.microsoft.com/v1.0/me", ["User.Read"]],
        ]),
      }
    ),
    BrowserAnimationsModule,
    HttpClientModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MomentDateModule,
    ResumePreviewModule,
    ReviewCommentsModalModule,
    SkillDescriptionModalModule,
    BuildResumeCommonModule,
    ResumeReviewModule,
    ResumeReviewModule,
    AttributeApprovalsModule,
    FormsModule,
    ReactiveFormsModule,
    ResumesSearchModule,
    OnlinerSearchModule,
    AlternateOnlinerMaintenanceModule,
    VexModule,
    CustomLayoutModule,
    MatDialogModule,
    MatSnackBarModule,
    MatIconModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: LocalLoader
      },
    }),
    ResumesSearchInputModule,
    LogoutModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([])
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    AuthorizedHttp,
    AuthService,
    AuthGuard,
    MsalGuard,
    ProfileSummaryService,
    ProfileSummaryNOService,
    EducationApprovalsResolverService,
    OnlinersResolverService,
    DataApprovalService,
    EducationService,
    EducationNOService,
    PendingChangesGuard,
    OnlinerService,
    SnackBarService,
    DatePipe,
    UserService,
    UtilityService,
    CommonService,
    SkillSummaryService,
    SkillSummaryNOService,
    AssociationsService,
    AssociationsNOService,
    ResumeService,
    ResumeNOService,
    WorkflowService,
    SkillsService,
    SkillsNOService,
    SkillCompetencyAndCategoryService,
    ProjectService,
    ProjectNOService,
    AutoSuggestService,
    AttributeService,
    ResumeSearchUtilService,
    OnlinerSearchUtilService,
    AlternateOnlinerMaintenanceService,
    ExcelService,
    SidenavComponent,
    AuthGuardLogout,
    CompetencyService,
    CompetencyMaintenanceService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  exports: [TranslateModule],
})
export class AppModule {}
