<div class="text-center mb-3" style="margin-top: -60px">
  <obs-resume-preview-action-buttons
    [disabled]="disablePreview"
    (onOpen)="generatePdf('open')"
    (onDownload)="generatePdf('download')"
    (onPrint)="generatePdf('print')"
    [resume]="resume"
  >
  </obs-resume-preview-action-buttons>
</div>
<div @fadeInUp class="card p-16 resume-output">
  <div class="row">
    <div class="col-12">
      <img class="h-auto" [src]="wordmarkLogo" />
    </div>
  </div>

  <div class="row" *ngIf="!isLoading">
    <div class="col-12">
      <div class="text-right body-1">
        <h1 class="mb-3 mt-5 text-bold">
          {{ employee }}
        </h1>
        <h3 class="mb-3">
          {{ getAccreditation() }}
        </h3>
      </div>
    </div>
  </div>
  <div class="px-4">
    <div class="row">
      <div class="col-4">
        <h4 class="uppercase mb-1">{{ "profile" | translate }}</h4>
        <p class="render-new-line">
          {{ getProfileSummary() }}
        </p>
      </div>
      <div class="col-8">
        <h4 class="uppercase mb-1">{{ "skillSummary" | translate }}</h4>
        <div
          *ngFor="let skillSummary of resume.skillSummaries | getSkillSummaries"
        >
          <h4 class="mb-1">{{ skillSummary.name  }}</h4>
          <p class="mb-2 render-new-line">
            {{ skillSummary.summary  }}
          </p>
        </div>
      </div>
    </div>
    <div class="mt-4" fxLayout="row">
      <div class="" fxFlex="auto" fxFlex.lt-lg="auto">
        <h4 class="uppercase mb-1">
          {{
            (resume.isProfessionalResume ? "PROFESSIONAL" : "TECHNICAL") +
              " EXPERTISE"
          }}
        </h4>
        <div fxLayout="row" *ngIf="isOldSnapshotResume === true">
          <div fxFlex="25"
            *ngFor="let expertise of resume.skills | filterSkillExpertiseUnique"
          >
            {{ expertise }}

            <ul class="list-disc list-inside">
              <li
                *ngFor="
                  let skill of resume.skills | filterSkillByExpertise: expertise
                "
              >
                {{ skill.skill.name }}
              </li>
            </ul>
          </div>
        </div>
        <div fxLayout="row" *ngIf="isOldSnapshotResume === false">
          <div fxFlex="25"
            *ngFor="let expertise of resume.resumeExpertiseGroups | filterResumeExpertiseGroupUnique"
          >
            {{ expertise }}

            <ul class="list-disc list-inside">
              <li
                *ngFor="
                  let item of resume.resumeExpertiseGroups | filterResumeExpertiseGroupByExpertise: expertise
                "
              >
                {{ item.skill.skill.name }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-6">
        <h4 class="uppercase mb-1">{{ "education" | translate }}</h4>
        <ul class="list-disc list-inside">
          <li
            *ngFor="
              let education of resume.educations
                | filterEducationByType: 'Degree'
            "
          >
            <span
              *ngIf="
                education.institutionCity === '' ||
                  education.institutionCity === null;
                else elseBlock
              "
            >
              {{ education.education.name  }}
            </span>
            <ng-template #elseBlock>
              {{ education.education.name  }} -
              {{ education.institutionCity  }}
            </ng-template>
          </li>
        </ul>

        <p
          class="mb-1 text-bold"
          *ngIf="
            (resume.educations | filterEducationByType: 'Certification/Course')
              .length > 0
          "
        >
          {{ "relevantCourses" | translate }}
        </p>
        <ul class="list-disc list-inside">
          <li
            *ngFor="
              let education of resume.educations
                | filterEducationByType: 'Certification/Course'
            "
          >
            {{ education.education.name  }}
          </li>
        </ul>
      </div>
      <div class="col-6">
        <h4 class="uppercase mb-1">{{ "industryExperience" | translate }}</h4>
        <ul class="list-disc list-inside">
          <li
            *ngFor="
              let industry of resume.projects | getProjectIndustriesUnique
            "
          >
            {{ industry  }}
          </li>
        </ul>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12">
        <h4 class="uppercase mb-1">
          {{ isOnliner() ? "PROJECT EXPERIENCE" : "WORK EXPERIENCE" }}
        </h4>
        <div *ngFor="let projectGroup of resume.projects | getProjects">
          <div
            *ngIf="projectGroup.projects?.length > 1 && !resume.isClientHidden"
          >
            <h5 *ngIf="resume.isClientHidden === false" class="mb-1">
              {{ projectGroup.company?.name  }}
            </h5>

            <p class="mb-2" *ngIf="!resume.isClientHidden">
              {{
                getProjectExperienceDateInterval(
                  projectGroup.startDate,
                  projectGroup.endDate
                )
              }}
            </p>
          </div>

          <div *ngFor="let project of projectGroup.projects">
            <div
              [ngClass]="{
                indent:
                  projectGroup.projects?.length > 1 && !resume.isClientHidden
              }"
            >
              <div>
                <h4 class="mb-1">
                  {{ getProjectName(project,projectGroup.projects)  }}
                  {{ getProjectJobTitle(project)  }}
                </h4>
                <h5
                  *ngIf="
                    !resume.isClientHidden && projectGroup.projects?.length == 1
                  "
                  class="mb-1"
                >
                  {{ projectGroup.company?.name }}
                </h5>
                <p class="mb-2">
                  {{
                    getProjectExperienceDateInterval(
                      project.startDate,
                      project.endDate
                    )
                  }}
                </p>
              </div>
              <p class="mb-2 italic render-new-line">
                {{ project.projectDescription  }}
              </p>

              <p class="mb-2" *ngIf="project.responsibilities.length > 0">
                {{ getProjectResponsibilitiesIncludedStatement() }}
              </p>

              <div class="mb-3">
                <ul
                  class="list-disc list-inside"
                  *ngIf="project.responsibilities.length > 0"
                >
                  <li *ngFor="let responsibility of project.responsibilities">
                    {{ responsibility.responsibility  }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4" *ngIf="resume.associations.length > 0">
      <div class="col-12">
        <h4 class="uppercase mb-1">Associations</h4>
        <div *ngFor="let association of resume.associations | getAssociations">
          <h4 class="mb-1">{{ association.assoc.name  }}</h4>
        </div>
      </div>
    </div>

    <div class="row resume-footer mt-4 italic">
      <div class="col-4">www.obsglobal.com</div>
      <div class="col-4 text-center"></div>
      <div class="col-4 text-right">
        <img [src]="taglineLogo" />
      </div>
    </div>
  </div>
</div>

<div class="text-center mt-3">
  <obs-resume-preview-action-buttons
    [disabled]="disablePreview"
    (onOpen)="generatePdf('open')"
    (onDownload)="generatePdf('download')"
    (onPrint)="generatePdf('print')"
    [resume]="resume"
  >
  </obs-resume-preview-action-buttons>
</div>
