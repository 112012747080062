import { DateTime } from "luxon";
import { RAttribute } from "./commonDataModels";

export class RNonOnlinerSkill {
  public id: number;
  public skill: RAttribute;
  public skillId: number;
  public description: string;
  public dateModified: Date;
  public expertiseType: RAttribute;
  public expertiseTypeId: number;
  public expertiseLevelId: number;
  public userId: string;
  public isStarred: boolean;
  public isUsed: boolean;

  constructor(
    id: number,
    skillId: number,
    skill: RAttribute,
    description: string,
    userId: string,
    dateModified: Date,
    expertiseType: RAttribute,
    expertiseTypeId: number,
    expertiseLevelId: number,
    isStarred: boolean,
    isUsed: boolean
  ) {
    this.id = id;
    this.dateModified = dateModified;
    this.description = description;
    this.userId = userId;
    this.skillId = skillId;
    this.skill = skill;
    this.expertiseType = expertiseType;
    this.expertiseTypeId = expertiseTypeId;
    this.expertiseLevelId = expertiseLevelId;
    this.isStarred = isStarred;
    this.isUsed = isUsed;
  }
}
