export class RNonOnlinerSkillSummary {
  public id: number;
  public name: string;
  public summary: string;
  public userId: string;
  public dateModified: Date;
  public isUsed: boolean;

  constructor(id: number, name: string, summary: string, userId: string, dateModified: Date, isUsed: boolean) {
      this.id = id;
      this.name = name;
      this.summary = summary;
      this.userId = userId;
      this.dateModified = dateModified;
      this.isUsed = isUsed
  }

}
