import { environment } from "../../../environments/environment";
import { Observable, of, throwError, Subject } from "rxjs";
import { AuthorizedHttp } from "./authorized-http.service";
import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { RDataApproval } from "../data-models/RDataApproval";

@Injectable()
export class DataApprovalService {
  private _apiEndpoint = `${environment.apiEndpoint}/api/dataapproval`;
  private _dataApproval: RDataApproval;

  constructor(private http: AuthorizedHttp) {
    let headers: any = {
      "Content-Type":
        "application/json; charset=utf-8" /*or whatever type is relevant */,
      Accept: "application/json",
    };
  }

  public getDataApproval(): Observable<any> {
    return this.http.get(`${this._apiEndpoint}`).pipe(
      map((result) => {
        this._dataApproval = result;
        return this._dataApproval;
      }),
      catchError((errorResponse: any) =>
        throwError(errorResponse.error.exceptionMessage || "Server error")
      )
    );
  }

  public postNewDataApproval(
    dataApproval: RDataApproval
  ): Observable<RDataApproval> {
    return this.http
      .post(`${this._apiEndpoint}/addNewRequest`, JSON.stringify(dataApproval))
      .pipe(
        map((result) => {
          this._dataApproval = result;
          return this._dataApproval;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }
}
