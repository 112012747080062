import { AlternateOnlinerMaintenanceService } from './../../../app/common/services/alternateOnlinerMaintenance.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PopoverService } from '../popover/popover.service';
import { ToolbarUserDropdownComponent } from './toolbar-user-dropdown/toolbar-user-dropdown.component';
import icPerson from '@iconify-icons/ic/twotone-person';
import theme from '../../utils/tailwindcss';
import { AuthService } from 'src/app/common/services/auth.service';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/common/services/user.service';
import { forkJoin, Observable } from 'rxjs';
import { NavigationService } from '../../services/navigation.service';
import { SnackBarService } from '../../../app/common/services/snackbar.service';
import { OnlinerProfile } from '../../../app/common/data-models/commonDataModels';

@Component({
  selector: 'vex-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  styleUrls: ['./toolbar-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserComponent implements OnInit {
  public isLoading = true;
  public userProfile: any;
  dropdownOpen: boolean;
  icPerson = icPerson;
  public profilePhotoData: any;
  public isImageLoading: boolean;
  userRoles: string;
  public isSlideChecked: boolean = false;
  public defaultOnliner: string;
  pronouns$: Observable<string>

  theme = theme;

  constructor(
    private authService: AuthService,
    private snackBarService: SnackBarService,
    private navigationService: NavigationService,
    private popover: PopoverService,
    private cd: ChangeDetectorRef,
    private userService: UserService,
    private nonOnlinerService: AlternateOnlinerMaintenanceService,
    private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.isImageLoading = true;
    if(this.authService.isAuthenticated()) {
      this.userProfile = this.authService.getUserProfile();
      this.isLoading = false;
      this.userService.getOnlinerProfileDefault(this.authService.getUserId()).subscribe(
            (onlinerProfile) => {
              if (onlinerProfile == null) {
                  onlinerProfile = new OnlinerProfile(this.authService.getUserId());
              }
              this.userService.setProfile(onlinerProfile);
              this.pronouns$ = this.userService.getPronouns();
            },
            (error) => {
              this.snackBarService.message(error);
            },
      );

      this.userService.getMyProfilePhoto()
        .subscribe(data => {
          if (data !== null) {
            this.profilePhotoData = `data:image/png;base64,${data}`;
          } else {
            this.profilePhotoData = null;
          }
          this.isImageLoading = false;
          this.changeDetectorRef.markForCheck();
        }, error => {
          this.isImageLoading = false;
        });

      this.userRoles = this.roles();

    } else {
      this.userProfile = null;
      this.isLoading = false;
    }
  }

  roles(): string {

    let retRoles = '';
    let userProfile = this.authService.getUserProfile();
    let userRoles = this.authService.getUserRoles();

    if (this.authService.isAuthenticated()) {
      if (userProfile) {
        if (userProfile) {
          const allRoles: Array<string> = userRoles;

          if(allRoles.includes(environment.roles.ResumesUser)) {
            retRoles += 'User'
        }

        if(allRoles.includes(environment.roles.ResumesCM)) {
          retRoles += ', Career Mentor';
        }

        if(allRoles.includes(environment.roles.ResumesAdmin)) {
          retRoles += ', Admin';
        }

          if (allRoles.includes(environment.roles.ResumesMarketing)) {
            retRoles += ', Marketing';
          }

          if (allRoles.includes(environment.roles.ResumesRMT)) {
            retRoles += ', Resume Management Team';
          }

          if (allRoles.includes(environment.roles.ResumesPMT)) {
            retRoles += ', Professional Management Team';
          }

          if (allRoles.includes(environment.roles.ResumesSales)) {
            retRoles += ', Sales';
          }
        }
      } else {
        this.authService.logout();
      }
    } else {
      this.authService.logout();
    }

    return 'Roles: ' + retRoles;
  }

  setIsSlideChecked($event) {
    this.isSlideChecked = $event;
  }

  showPopover(originRef: HTMLElement) {
      let userRoles = this.authService.getUserRoles();

      this.dropdownOpen = true;
      this.cd.markForCheck();

      if (userRoles && userRoles.includes(environment.roles.ResumesRMT)) {
      forkJoin([
        this.userService.getAltOnlinerDefault()
      ]).subscribe(
        ([altOnlinerDefault]) => {
          if (altOnlinerDefault) {
            this.defaultOnliner = altOnlinerDefault.altOnlinerUserFirstName + ' ' + altOnlinerDefault.altOnlinerUserLastName;
            this.navigationService.setAltOnlinerDefault(altOnlinerDefault);
          } 
          else this.defaultOnliner = 'N/A';

          const popoverRef = this.popover.open({
            content: ToolbarUserDropdownComponent,
            origin: originRef,
            offsetY: 12,
            position: [
              {
                originX: 'center',
                originY: 'top',
                overlayX: 'center',
                overlayY: 'bottom'
              },
              {
                originX: 'end',
                originY: 'bottom',
                overlayX: 'end',
                overlayY: 'top',
              },
            ],
            data: { isSlideChecked: this.isSlideChecked, defaultOnliner: this.defaultOnliner }
          });

          popoverRef.afterClosed$.subscribe(result => {
            this.dropdownOpen = false;
            this.cd.markForCheck();
            if (typeof result.data === 'boolean') {
              this.isSlideChecked = result.data;
            }
          });
        });
    } else {
      const popoverRef = this.popover.open({
        content: ToolbarUserDropdownComponent,
        origin: originRef,
        offsetY: 12,
        position: [
          {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom'
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
        ],
        data: { isSlideChecked: false, defaultOnliner: 'N/A' }
      });

      popoverRef.afterClosed$.subscribe(result => {
        this.dropdownOpen = false;
        this.cd.markForCheck();
      });
    }
  }
}
