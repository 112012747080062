<mat-card fxFlex="100%" width="100%">
  <mat-card-header>
    <mat-card-title class="card-title" >{{ title }}</mat-card-title>
  </mat-card-header>
  <div
    fxLayout="row"
    fxLayout.lt-sm="row"
    fxLayoutGap="8px"
    fxLayoutGap.lt-sm="4px"
    *ngIf="!isReadOnly"
  >
    <div row class="col-md-9 add-link">
      <p
        style="cursor: pointer"
        class="text-obs-blue text-center pt-1"
        (click)="add()"
        [ngClass]="maxSelectionReached() ? 'disable' : ''"
      >
        <i class="fas fa-plus"></i> Add {{ fieldLabel }}
        {{ maxItems ? "(Max " + maxItems + " items)" : "" }}
      </p>
    </div>
  </div>

  <div
    [formGroup]="parentForm"
    cdkDropList
    class="list"
    (cdkDropListDropped)="drop($event)"
  >
    <div [formArrayName]="formArrayName">
      <div *ngFor="let control of formArray.controls; let i = index">
        <div>
          <div class="drag-box" cdkDrag [cdkDragDisabled]="isReadOnly">
            <div
              class="drag-and-drop-custom-placeholder"
              *cdkDragPlaceholder
            ></div>

            <div fxLayout="column" fxFlex="100%">
              <div
                fxLayout="row"
                fxLayout.lt-sm="row"
                fxLayoutGap="8px"
                fxLayoutGap.lt-sm="4px"
                class="center component-row"
              >
                <button
                  matTooltip="Drag to sort"
                  class="btn move"
                  cdkDragHandle
                  *ngIf="!isReadOnly"
                >
                  <i class="fas fa-sort"></i>
                </button>

                <mat-form-field
                  class="no-padding-bottom"
                  fxFlex="100%"
                  *ngIf="control.value"
                >
                  <mat-label>{{ fieldLabel }} </mat-label>
                  <mat-select
                    class="select-style"
                    [formControlName]="i"
                    placeholder="Select a {{ fieldLabel }}"
                    (selectionChange)="select(i, control)"
                  >
                    <mat-option
                      *ngFor="let item of objectDropDown"
                      [value]="item.value"
                    >
                      {{ item.viewValue }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <button *ngIf="!isReadOnly" (click)="remove(i)">
                  <i class="fas fa-trash"></i>
                </button>
              </div>
              <mat-error
                class="mat-error"
                *ngIf="requiredIsEmpty(control)"
                >{{ fieldLabel }} required.</mat-error
              >
              
              <mat-error
                class="mat-error"
                *ngIf="control.hasError('duplicateSelectionError')"
                >{{ fieldLabel }} already selected.</mat-error
              >

              <mat-error
                class="mat-error"
                *ngIf="control.hasError('maxGroupSelectionError')"
                >You can only have a max of 4 expertise.</mat-error
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>
