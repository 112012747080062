import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { AuthorizedHttp } from "./authorized-http.service";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { omit } from "lodash";
import { RCompetencySkillCategory } from "../data-models/commonDataModels";

@Injectable()
export class SkillCompetencyAndCategoryService {
  private _apiEndpoint = `${environment.apiEndpoint}/api/skill-competency-and-category`;

  constructor(private http: AuthorizedHttp) {}

  public getSkillCompetenciesAndCategories(
    skillId: number
  ): Observable<RCompetencySkillCategory[]> {
    return this.http.get(`${this._apiEndpoint}/${skillId}`).pipe(
      map((result) => {
        return result;
      }),
      catchError((errorResponse: any) =>
        throwError(errorResponse.error.exceptionMessage || "Server error")
      )
    );
  }

  public upsertSkillCompetenciesAndCategories(
    skillId: number,
    rCompetencySkillCategoriesToSave: RCompetencySkillCategory[],
  ): Observable<RCompetencySkillCategory[]> {
    return this.http.put(
        `${this._apiEndpoint}/${skillId}`, 
        rCompetencySkillCategoriesToSave.map(x => omit(x, ['skill', 'competency', 'category'])
      )
    ).pipe(
      map((result) => {
        return result;
      }),
      catchError((errorResponse: any) =>
        throwError(errorResponse.error.exceptionMessage || "Server error")
      )
    );
  }
  
  public getCompetencySkillCategorySkills(): Observable<RCompetencySkillCategory[]> {
    return this.http
      .get(`${this._apiEndpoint}/allCompetencySkillCategorySkills/`)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }  

  public getCompetencySkillCategorySkillsFromUser(): Observable<RCompetencySkillCategory[]> {
    return this.http
      .get(`${this._apiEndpoint}/allCompetencySkillCategorySkillsFromUser/`)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }
}
