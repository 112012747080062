import { Component, OnInit, Input } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Resume } from "../../common/data-models/Resume";
import { ResumePdfBuilderService } from "../../common/services/resume-pdf-builder.service";
import { iconsFA } from "src/static-data/icons-fa";
import { iconsIC } from "src/static-data/icons-ic";
import * as pdf from "pdfjs-dist";
import { ResumeStatusTypes } from "../../common/services/resume.service";

@Component({
  selector: "obs-resume-pdf-preview",
  templateUrl: "./pdf-preview.component.html",
  styleUrls: ["./pdf-preview.component.scss"],
})
export class ResumePdfPreviewComponent implements OnInit {
  @Input("resume") resume: Resume;
  @Input("scale") scale: number;
  @Input("originalResume") originalResume: Resume;
  isLoading: boolean;
  isLoading$: BehaviorSubject<boolean>;

  renderError: boolean;
  renderError$: BehaviorSubject<boolean>;

  public faIcons = iconsFA;
  public icIcons = iconsIC;

  public constructor(
    private resumePdfBuilderService: ResumePdfBuilderService,
  ) { }

  ngOnInit(): void {
    this.isLoading$ = new BehaviorSubject<boolean>(this.isLoading);
    this.renderError$ = new BehaviorSubject<boolean>(this.renderError);
    this.isLoading$.next((this.isLoading = true));
    this.renderError$.next((this.renderError = false));

    if (this.resume) {
      this.loadPreview(this.resume, this.originalResume);
    }
  }

 public generatePdf(action = "open") {
  if (this.resume.statusType.name === ResumeStatusTypes.APPROVED) {
    switch (action) {
      case "open":
        this.resumePdfBuilderService.open(this.originalResume);
        break;
      case "print":
        this.resumePdfBuilderService.print(this.originalResume);
        break;
      case "download":
        this.resumePdfBuilderService.download(this.originalResume);
        break;
      default:
        this.resumePdfBuilderService.open(this.originalResume);
        break;
    }
  } else {
    // Handle other cases when resume status is not approved
    switch (action) {
      case "open":
        this.resumePdfBuilderService.open(this.resume);
        break;
      case "print":
        this.resumePdfBuilderService.print(this.resume);
        break;
      case "download":
        this.resumePdfBuilderService.download(this.resume);
        break;
      default:
        this.resumePdfBuilderService.open(this.resume);
        break;
    }
  }
}

  async loadPreview(resume: Resume, originalResume: Resume) {
    try {
        if (resume.statusType.name === ResumeStatusTypes.APPROVED) {
            const base64 = await this.resumePdfBuilderService.getBase64Compare(originalResume, originalResume);
            await this.render(base64);
        }
        else {
            const base64 = await this.resumePdfBuilderService.getBase64Compare(resume, originalResume);
            await this.render(base64);
        }       
        this.isLoading$.next((this.isLoading = false));
    }catch (err) {
        this.isLoading$.next((this.isLoading = false));
        this.renderError$.next((this.renderError = true));
        throw err;
    }
  }

  async render(base64: string) {
    const scale = this.scale ?? 1.5;

    pdf.GlobalWorkerOptions.workerSrc = "pdf.worker.min.js";

    var canvasContainer = <HTMLCanvasElement>(
      document.getElementById("render-container")
    );

    // Using DocumentInitParameters object to load binary data.
    var loadingTask = pdf.getDocument({ data: atob(base64) });

    loadingTask.promise.then(
      async (pdf) => {
        var spinContainer = document.createElement("div");
        spinContainer.style.fontWeight = "600";
        spinContainer.style.fontSize = "18px";
        spinContainer.style.alignSelf = "center";
        spinContainer.textContent = this.resume.spins.name;
        canvasContainer.appendChild(spinContainer);

        await renderPages(pdf);

        function renderPage(page): Promise<boolean> {
          return new Promise((resolve) => {
            var viewport = page.getViewport({ scale: scale });
            var wrapper = document.createElement("div");
            wrapper.style.marginBottom = "16px";
            wrapper.style.backgroundColor = "#eee";
            wrapper.style.alignSelf = "center";
            var canvas = document.createElement("canvas");
            var ctx = canvas.getContext("2d");
            var renderContext = {
              canvasContext: ctx,
              viewport: viewport,
            };

            canvas.height = viewport.height;
            canvas.width = viewport.width;
            canvas.style.width = "95%";
            canvas.style.margin = "0 auto";
            wrapper.appendChild(canvas);

            canvasContainer.appendChild(wrapper);

            page.render(renderContext);

            resolve(true);
          });
        }

        async function renderPages(pdfDoc) {
          for (var num = 1; num <= pdfDoc.numPages; num++)
            await pdfDoc
              .getPage(num)
              .then(async (page) => await renderPage(page));
        }
      },
      (err) => {
        // PDF loading error
        console.error(err);
      }
    );
  }
}
