import { DateTime } from "luxon";
import { RAttribute } from "./commonDataModels";

export class RAssociation {
  public id: number;
  public assoc: RAttribute;
  public assocId: number;
  public dateJoined: Date;
  public comments: string;
  public userId: string;
  public isUsed: boolean;

  constructor(
    id: number,
    assoc: RAttribute,
    assocId: number,
    dateJoined: Date,
    comments: string,
    userId: string,
    isUsed: boolean
  ) {
    this.id = id;
    this.dateJoined = dateJoined;
    this.comments = comments;
    this.userId = userId;
    this.assocId = assocId;
    this.assoc = assoc;
    this.isUsed = isUsed;
  }
}
