import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatIconModule } from "@angular/material/icon";
import { MatExpansionModule } from "@angular/material/expansion";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IconModule } from "@visurel/iconify-angular";
import { SecondaryToolbarModule } from "src/@vex/components/secondary-toolbar/secondary-toolbar.module";
import { BreadcrumbsModule } from "src/@vex/components/breadcrumbs/breadcrumbs.module";
import { MatButtonModule } from "@angular/material/button";
import { PageLayoutModule } from "src/@vex/components/page-layout/page-layout.module";
import { ContainerModule } from "src/@vex/directives/container/container.module";
import { TranslateModule } from "@ngx-translate/core";
import { MatInputModule } from "@angular/material/input";
import { MatDialogModule } from "@angular/material/dialog";
import { CommentsFormModal } from "./comments-form-modal/comments-form-modal";
import { ReviewCommentsInput } from "./comments-input/comments-input.component";
import {
  ResumePdfBuilderService
} from "../common/services/resume-pdf-builder.service";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ResumeReviewComponent } from "./resume-review.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatListModule } from "@angular/material/list";
import { MatCardModule } from "@angular/material/card";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ResumeReviewErrorComponent } from "./error/error.component";
import { ResumePreviewModule } from "../resume-preview/resume-preview.module";
import { ResumePreviewComponent } from "../resume-preview/resume-preview.component";
import { ReviewCommentsModalComponent } from "../review-comments-modal/review-comments-modal.component";

@NgModule({
    declarations: [
        ResumeReviewComponent,
        CommentsFormModal,
        ResumeReviewErrorComponent,
        ReviewCommentsInput,
    ],
    imports: [
        MatAutocompleteModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        FlexLayoutModule,
        MatDialogModule,
        MatIconModule,
        MatExpansionModule,
        MatInputModule,
        IconModule,
        SecondaryToolbarModule,
        BreadcrumbsModule,
        MatButtonModule,
        PageLayoutModule,
        ContainerModule,
        TranslateModule,
        MatProgressSpinnerModule,
        MatDatepickerModule,
        MatTooltipModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatListModule,
        MatCardModule,
        DragDropModule,
        ResumePreviewModule,
    ],
    exports: [
        ResumeReviewComponent,
        ResumeReviewErrorComponent,
        ReviewCommentsInput,
    ],
    providers: [ResumePdfBuilderService]
})
export class ResumeReviewModule {}
