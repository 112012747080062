import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
} from "@angular/forms";
import { DataApproval } from "../data-models/DataApproval";

class AttributeFilterCriteria {
  id: string;
  attributeTypeId: string;
  attributeType: string;
  aliasId: string;
  createdDate: string;
  isActive: string;
  isOther: string;
  modifiedDate: string;
  name: string;
  value: string;
  isAttached: string;
}

@Injectable()
export class AttributeFilterService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private datePipe: DatePipe,
  ) {}

  _filterCriteriaForm = new UntypedFormGroup({
    name: new UntypedFormControl(),
    dataType: new UntypedFormControl(),
    createdDate: new UntypedFormControl(),
    modifiedDate: new UntypedFormControl(),
    isAttached: new UntypedFormControl(),
  });

  resetForm() {
    this._filterCriteriaForm.reset({
      name: "",
      dataType: "",
      createdDate: "",
      modifiedDate: "",
      isAttached: "",
    });
  }

  getFilterCriteriaForm(): UntypedFormGroup {
    return this._filterCriteriaForm;
  }

  getFilterCriteriaModel(): AttributeFilterCriteria {
    const filterCriteria = new AttributeFilterCriteria();
    filterCriteria.name =
      this._filterCriteriaForm.controls.name.value || undefined;
    filterCriteria.attributeType =
      this._filterCriteriaForm.controls.dataType.value || undefined;
    filterCriteria.createdDate =
      this._filterCriteriaForm.controls.createdDate.value || undefined;
    filterCriteria.modifiedDate =
      this._filterCriteriaForm.controls.modifiedDate.value || undefined;
    filterCriteria.isAttached =
      this._filterCriteriaForm.controls.isAttached.value || undefined;

    return filterCriteria;
  }

  filterName(
    attribute: DataApproval,
    filterCriteria: AttributeFilterCriteria
  ): boolean {
    return filterCriteria.name === undefined
      ? true
      : attribute.name.includes(filterCriteria.name);
  }

  filterAttributeType(
    attribute: DataApproval,
    filterCriteria: AttributeFilterCriteria
  ): boolean {
    return filterCriteria.attributeType === undefined
      ? true
      : attribute.attributeType.includes(filterCriteria.attributeType);
  }

  getFormatedDate(date: Date) {
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  }

  filterCreatedDate(
    attribute: DataApproval,
    filterCriteria: AttributeFilterCriteria
  ): boolean {
    const filterDate = new Date(filterCriteria.createdDate);
    const attrDate = new Date(attribute.createdDate);
    return filterCriteria.createdDate === undefined
      ? true
      : this.getFormatedDate(filterDate) === this.getFormatedDate(attrDate);
  }

  filterModifiedDate(
    attribute: DataApproval,
    filterCriteria: AttributeFilterCriteria
  ): boolean {
    const filterDate = new Date(filterCriteria.modifiedDate);
    const attrDate = new Date(attribute.modifiedDate);
    return filterCriteria.modifiedDate === undefined
      ? true
      : this.getFormatedDate(filterDate) === this.getFormatedDate(attrDate);
  }

  filterIsAttached(
    attribute: DataApproval,
    filterCriteria: AttributeFilterCriteria
  ): boolean {
    if (!filterCriteria.isAttached) return true;
    const criteria = filterCriteria.isAttached === "No" ? false : true;
    return !!attribute.isAttached === criteria;
  }
}
