import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { UntypedFormGroup, FormBuilder } from "@angular/forms";
import { defaultOnliner } from "src/app/common/services/onliner.service";
import * as formValidator from "src/app/shared/form-validator";

import { FormControl } from "@angular/forms";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { MatDialog } from "@angular/material/dialog";
import { SessionExpiredDialogComponent } from "src/app/shared/session-expired-dialog/session-expired-dialog.component";
import * as _moment from "moment";
import { default as _rollupMoment, Moment } from "moment";

const moment = _rollupMoment || _moment;

@Injectable()
export class UtilityService {
  public _filterCriteriaForm: UntypedFormGroup;

  public firstDayOfTheYear = new Date(new Date().getFullYear(), 0, 1);

  private dates = new Map<string, Moment>();

  public sessionExpiredDialogIsOpened: boolean = false;

  public constructor(private datePipe: DatePipe, private dialog: MatDialog) {}

  public chosenYearHandler(normalizedYear: Moment, key: string = "default") {
    let date = this.dates.get(key) || moment().set("date", 1);
    const ctrlValue = date;
    ctrlValue.year(normalizedYear.year());
    this.dates.set(key, ctrlValue);
  }

  public chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>,
    key: string = "default"
  ) {
    let date = this.dates.get(key) || moment().set("date", 1);
    const ctrlValue = date;
    ctrlValue.month(normalizedMonth.month());

    this.dates.set(key, ctrlValue);
    datepicker.close();
  }

  public getDate(key: string = "default"): Moment {
    return this.dates.get(key);
  }

  public setDate(value: Moment,key: string = "default"): void {
    this.dates.set(key, value);
  }

  public showSessionExpiredDialog() {
    if (!this.sessionExpiredDialogIsOpened) {
      this.sessionExpiredDialogIsOpened = true;
      let dialogRef = this.dialog.open(SessionExpiredDialogComponent, {
        width: "400px",
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.sessionExpiredDialogIsOpened = false;
      });
    }
  }
}
