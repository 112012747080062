<div class="p-6" *ngIf="isLoading">
  <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="24px">
    <mat-spinner></mat-spinner>
  </div>
</div>

<section *ngIf="!isLoading && searchResumesDisplayData.data.length > 0">
  <header>
    <div
      fxLayout="column"
      fxLayout.lt-sm="column"
      fxLayoutGap="16px"
      fxLayoutGap.lt-sm="0"
    >
      <div class="page-title">Available Resumes</div>
    </div>
  </header>

  <mat-table [dataSource]="searchResumesDisplayData"
             matSort
             class="mat-elevation-z8 attribute-row"
             (matSortChange)="onSortData($event)"
             [hidden]="isLoading">
      <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Resume</mat-header-cell>
          <mat-cell *matCellDef="let value" class="leftAlignCell">
              {{ value.spins.name }}

          </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
          <mat-cell *matCellDef="let value" [ngClass]="value.statusType.name != 'Approved' ? 'un-approved' : 'leftAlignCell'">
                 {{ value.statusType.name }}
          </mat-cell>
      </ng-container>

      <ng-container matColumnDef="modifiedDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header>ApprovalDate</mat-header-cell>
          <mat-cell *matCellDef="let value" class="leftAlignCell">
              <div *ngIf="value.statusType.name == 'Approved'; else naBlock">
                  {{ value.dateApproved | date: dateFormat }}
              </div>
              <ng-template #naBlock>
                  N/A
              </ng-template>
          </mat-cell>
      </ng-container>

      <ng-container matColumnDef="careerManager">
          <mat-header-cell *matHeaderCellDef>Career Mentor</mat-header-cell>
          <mat-cell *matCellDef="let value" class="leftAlignCell">
              {{ careerManager }}
          </mat-cell>
      </ng-container>

      <ng-container matColumnDef="attachment">
          <mat-header-cell *matHeaderCellDef class="centerAlignCell">Attachment</mat-header-cell>
          <mat-cell *matCellDef="let value; let i = index"
                    class="attachment centerAlignCell">
              <i class="fas fa-file-pdf mr-1"
                 (click)="
                onCreateDocumentClicked(value.id, DOWNLOADABLE_DOC_TYPE.PDF);
                $event.stopPropagation()
              "></i>
              <i class="fas fa-file-word"
                 (click)="
                onCreateDocumentClicked(value.id, DOWNLOADABLE_DOC_TYPE.WORD);
                $event.stopPropagation()
              "></i>
              <span style="margin-left: 5px"
                    *ngIf="i == downloadingElementRowIndex && isGeneratingDocument">
                  <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
              </span>
          </mat-cell>
      </ng-container>

      <ng-container matColumnDef="clientInfo">
          <mat-header-cell class="centerAlignCell" *matHeaderCellDef mat-sort-header>Client Info</mat-header-cell>
          <mat-cell *matCellDef="let value" class="centerAlignCell">
              {{
            value.isClientHidden ? "Hidden" : "Displayed"
              }}
          </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

      <mat-row *matRowDef="let row; columns: displayedColumns"
               (click)="onPreviewDocumentClicked(row)"></mat-row>
  </mat-table>
    </section>
    <section *ngIf="!isLoading && nothingFound">
      <header>
        <div
          fxLayout="column"
          fxLayout.lt-sm="column"
          fxLayoutGap="16px"
          fxLayoutGap.lt-sm="0"
        >
          <h4>No Resume(s) found</h4>
        </div>
      </header>
    </section>
