import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/common/services/auth.service';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.css']
})
export class ForbiddenComponent implements OnInit {
  timedOut: boolean;

  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.timedOut = !this.authService.isAuthenticated();
  }

  logout() {
    this.authService.logout();
  }
}
