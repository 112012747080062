import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';


@Injectable()
export class SnackBarService {

  constructor(private snackBar: MatSnackBar) {}

  message(message: string | any[]) {
    if ( message instanceof Array) {
      message.forEach( (message, index) => {
        setTimeout(() => {
          alert("adf");
          this.snackBar.open(message, 'Dismiss', {
            duration: 5000,
          });
        }, index * (5500));
      });
    }
    else {
      this.snackBar.open(message, 'Dismiss', {
        duration: 5000,
      });
    }
  }

  error(message: string){
    this.snackBar.open(`Error: ${message}`, 'Dismiss', {
      duration: 5000,
    });
  }
}
