export class GlobalConstants {
  public static FULL_DATE_FORMAT: string = "yyyy-MM-dd";
  public static DATE_FORMAT: string = "MM/yyyy";
  public static DATEPICKER_DATE_FORMAT = {
    parse: {
      dateInput: "MM/YYYY",
    },
    display: {
      dateInput: "MM/YYYY",
      monthYearLabel: "MMM YYYY",
      monthYearA11yLabel: "MMMM YYYY",
    },
  };
  public static DATEPICKER_FULLDATE_FORMAT = {
    parse: {
      dateInput: "YYYY-MM-DD",
    },
    display: {
      dateInput: "YYYY-MM-DD",
      monthYearLabel: "MMM YYYY",
    },
  };
}
