import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'app-resume-preview-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ResumePreviewErrorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    
  }

  logout() {
    
  }
}
