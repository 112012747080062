import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { OnlinerService } from './onliner.service';

@Injectable({
  providedIn: "root"
})
export class OnlinersResolverService implements Resolve<any> {
  constructor(public onlinerService: OnlinerService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.onlinerService.getOnliners();
  }
}