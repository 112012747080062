import { environment } from "../../../environments/environment";
import { Observable, of, throwError, Subject } from "rxjs";
import { AuthorizedHttp } from "./authorized-http.service";
import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { CertificationStatus } from "../data-models/CertificationStatus";
import { EducationName } from "../data-models/commonDataModels";
import { EducationModel } from "../data-models/EducationModel";
import { RNonOnlinerEducation } from "../data-models/RNonOnlinerEducation";
import { CommonService } from "./common.service";

@Injectable()
export class EducationNOService {
    private _apiEndpoint = `${environment.apiEndpoint}/api/educationNO`;
    private _educationRecords: EducationModel[];
    private _certificationStatus: CertificationStatus[];
    private _educationNames: EducationName[];
    private _educationRecord: EducationModel;
    private _isDeleted: boolean;

    constructor(private http: AuthorizedHttp, private commonService: CommonService) {
        let headers: any = {
            "Content-Type":
                "application/json; charset=utf-8" /*or whatever type is relevant */,
            Accept: "application/json",
        };
    }
    public getAllEducationRecords(approvalType: string): Observable<EducationModel[]> {
        return this.http.get(`${this._apiEndpoint}/allRecordsNO/` + approvalType).pipe(
            map((result) => {
                this._educationRecords = result;
                return this._educationRecords;
            }),
            catchError((errorResponse: any) =>
                throwError(errorResponse.error.exceptionMessage || "Server error")
            )
        );
    }
    public getEducationNames(): Observable<EducationName[]> {
        return this.http.get(`${this._apiEndpoint}/getEducationNamesNO`).pipe(
            map((result) => {
                this._educationNames = result;
                return this._educationNames;
            }),
            catchError((errorResponse: any) =>
                throwError(errorResponse.error.exceptionMessage || "Server error")
            )
        );
    }

    public getMyEducationRecords(userId: string): Observable<EducationModel[]> {
        userId = this.commonService.encrypt(userId);
        return this.http
            .get(`${this._apiEndpoint}/myEducationRecordsNO/` + userId)
            .pipe(
                map((result) => {
                    this._educationRecords = result;
                    return this._educationRecords;
                }),
                catchError((errorResponse: any) =>
                    throwError(errorResponse.error.exceptionMessage || "Server error")
                )
            );
    }

    public getMyCertificationsAndCourses(
        userId: string
    ): Observable<EducationModel[]> {
        userId = this.commonService.encrypt(userId);
        return this.http
            .get(`${this._apiEndpoint}/myCertificationsAndCoursesNO/` + userId)
            .pipe(
                map((result) => {
                    this._educationRecords = result;
                    return this._educationRecords;
                }),
                catchError((errorResponse: any) =>
                    throwError(errorResponse.error.exceptionMessage || "Server error")
                )
            );
    }
    public postNewEducationRecord(
        educationModel: EducationModel,
        userId: string,
        sendApprovalEmail: boolean
    ): Observable<EducationModel> {
        userId = this.commonService.encrypt(userId);
        return this.http
            .post(
                `${this._apiEndpoint}/addNewEducationRecordNO/` +
                userId +
                `/` +
                sendApprovalEmail,
                JSON.stringify(educationModel)
            )
            .pipe(
                map((result) => {
                    this._educationRecord = result;
                    return this._educationRecord;
                }),
                catchError((errorResponse: any) =>
                    throwError(errorResponse.error.exceptionMessage || "Server error")
                )
            );
    }
    public updateEducationRecord(
        educationModel: EducationModel,
        userId: string,
        sendApprovalEmail: boolean
    ): Observable<EducationModel> {
        userId = this.commonService.encrypt(userId);
        return this.http
            .patch(
                `${this._apiEndpoint}/updateCertCoursesNO/` +
                userId +
                `/` +
                sendApprovalEmail,
                JSON.stringify(educationModel)
            )
            .pipe(
                map((result) => {
                    this._educationRecord = result;
                    return this._educationRecord;
                }),
                catchError((errorResponse: any) =>
                    throwError(errorResponse.error.exceptionMessage || "Server error")
                )
            );
    }

    public deleteREducationNO(educationModel: RNonOnlinerEducation): Observable<RNonOnlinerEducation> {
      return this.http
          .patch(`${this._apiEndpoint}/deleteEducationNO`, JSON.stringify(educationModel))
          .pipe(
              map(result => {
                  this._educationRecord = result;
                  return this._educationRecord;
              }),
              catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
          );
    }
}
