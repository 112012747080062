import { Component, OnInit, OnDestroy, ViewChild, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { Subscription } from "rxjs";
import { Resume } from "src/app/common/data-models/Resume";
import { OnlinerSearchUtilService } from "src/app/common/services/onliner-search-util.service";
import { ResumeDocxBuilderService } from "src/app/common/services/resume-docx-builder.service";
import { ResumePdfBuilderService } from "src/app/common/services/resume-pdf-builder.service";
import { ResumeSearchUtilService } from "src/app/common/services/resume-search-util.service";
import { ResumeService } from "src/app/common/services/resume.service";
import {
  ConfirmationDialogComponent,
  ConfirmDialog,
} from "src/app/shared/confirmation-dialog/confirmation-dialog.component";
import { OnlinerService } from "./../../common/services/onliner.service";
import { Employee } from "src/app/common/data-models/Employee";
import { ExcelService } from "./../../common/services/excel.service";
import { GlobalConstants } from "src/app/shared/global-constants";
import { OnlinerResultModalComponent } from "../onliner-result-modal/onliner-result-modal.component";
import { UserService } from "../../common/services/user.service";

enum DOWNLOADABLE_DOC_TYPE {
  PDF,
  WORD,
}

@Component({
  selector: "obs-preset-search-results",
  templateUrl: "search-results.component.html",
  styleUrls: ["search-results.component.scss"],
  providers: [ResumeSearchUtilService],
})
export class PresetSearchResults implements OnInit, OnDestroy {
  private _resultSubscription: Subscription;
  DOWNLOADABLE_DOC_TYPE = DOWNLOADABLE_DOC_TYPE;
  displayedColumns = [
    "Onliner",
    "Resumes",
    "Status",
    "ApprovalDate",
    "CM",
    "Attachment",
    "ClientInfo",
  ];
  private userId: string;
  public resume: Resume;
  readonly APPROVED_RESUME = "Approved";

  private onliners = [];
  public matchingOnlinersDisplayData = new MatTableDataSource<Employee>();
  public isLoading = false;
  public nothingFound = false;
  private selectedDocType: DOWNLOADABLE_DOC_TYPE;
  public numMatched: number;
  public dateFormat = GlobalConstants.FULL_DATE_FORMAT;

  query: string;

  constructor(
    private _onlinerSearchUtil: OnlinerSearchUtilService,
    private _dialog: MatDialog,
    private _resumePdfBuilderService: ResumePdfBuilderService,
    private _resumeDocxBuilderService: ResumeDocxBuilderService,
    private _resumeService: ResumeService,
    private _excelService: ExcelService,
    private _onlinerService: OnlinerService,
    private _userService: UserService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this._onlinerSearchUtil.searchOnliner().subscribe((data) => {
      this.onliners = [];

      if (this.query != undefined) {
        this.onliners.push({ Name: `Query Used: ${this.query}` });
        data.employees.forEach((onliner) => {
          onliner.resumes = onliner.resumes.filter(
            (r) => r.dateApproved == r.maxApprovedSpinDate
          );
          this.onliners.push({
            Name: `${onliner.lastName}, ${onliner.firstName}`,
          });
        });
        this.numMatched = data.employees.length;
      }

      this.matchingOnlinersDisplayData.data = data.employees;
      if (this.matchingOnlinersDisplayData.data.length == 0) {
        this.nothingFound = true;
      } else {
        this.nothingFound = false;
      }
    });

    this._onlinerSearchUtil.isSearching().subscribe((searching) => {
      this.isLoading = searching;
    });

    this._onlinerSearchUtil.sharedQuery.subscribe(
      (message) => (this.query = message)
    );
  }

  onCreateDocumentClicked(resumeId: number, docType: DOWNLOADABLE_DOC_TYPE) {
    this.downloadFile(resumeId, docType);
  }

  private async downloadFile(resumeId: number, docType: DOWNLOADABLE_DOC_TYPE) {
      this.resume = await this.loadResume(resumeId);

    switch (docType) {
      case DOWNLOADABLE_DOC_TYPE.WORD:
        this._resumeDocxBuilderService.download(this.resume);
        break;
      case DOWNLOADABLE_DOC_TYPE.PDF:
        this._resumePdfBuilderService.download(this.resume);
        break;
      default:
        // none
        break;
    }
  }

  private async loadResume(resumeId: number): Promise<Resume> {
    return new Promise(async (resolve) => {
      this._resumeService
        .getSnapshotResume(this.userId, resumeId)
        .subscribe(async (resume: Resume) => {
          resolve(resume);
        });
    });
  }

  onExportExcelClicked(): void {
    const confirmDialog = new ConfirmDialog();

    confirmDialog.title = "Export Results";
    confirmDialog.message = `Export search results for matched Onliners. Continue?`;
    confirmDialog.cancelButtonTitle = "No";
    confirmDialog.okButtonTitle = "Yes";

    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: "500px",
      data: confirmDialog,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result: string) => {
      if (result == "ok") {
        this._excelService.exportAsExcelFile(
          this.onliners,
          "Search_Onliner_Results"
        );
      }
    });
  }

  getIcon(isClientHidden: boolean): string {
    return isClientHidden ? "visibility_off" : "visibility";
  }

  public getOnlinerData(userId, $event): void {
    $event.preventDefault();
    var displaySummary = true;
    if (this.query.includes("[")) displaySummary = false;
    var startWith = false;
    if (this.query.includes("\\b")) startWith = true;
    this._onlinerService.getOnliner(userId).subscribe((onliner: Employee) => {
      this._dialog.open(OnlinerResultModalComponent, {
        width: "90%",
        height: "90%",
        data: {
          onliner: onliner,
          value: this.query,
          displaySummary: displaySummary,
          startWith: startWith,
        },
      });
    });
  }

  ngOnDestroy() {
    this._resultSubscription?.unsubscribe();
  }
}
