<div class="dialog-wrap" [ngClass]="{'dialog-header': confirmDialog.theme==='success', 'dialog-header-danger': confirmDialog.theme==='danger'}">
  <span class="dialog-title d-inline">{{confirmDialog.title}}</span>
  <button mat-dialog-close="cancel" type="button" class="btn btn-link close pull-right text-white no-underline" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="dialog-body">
  <div *ngIf="confirmDialog.message" class="text-center dialog-body-message">
    <span [innerHTML]="confirmDialog.message"></span>
  </div>
  <div class="row no-gutters justify-content-center pt-1">
      <div class="col-auto" *ngIf="!confirmDialog.flipButtonColor">
        <button *ngIf="confirmDialog.cancelButtonTitle" type="button" class="btn btn-action-dialog cancel-button mr-1" mat-dialog-close="cancel">{{confirmDialog.cancelButtonTitle}}</button>
        <button *ngIf="confirmDialog.okButtonTitle" type="button" class="btn btn-action-dialog success-button ml-1" [ngClass]="{'success-button': confirmDialog.theme==='success', 'danger-button': confirmDialog.theme==='danger'}"  mat-dialog-close="ok">
          {{confirmDialog.okButtonTitle}}
        </button>
        <button *ngIf="confirmDialog.secondOkButton" type="button" class="btn btn-action-dialog success-button ml-1" [ngClass]="{'success-button': confirmDialog.theme==='success', 'danger-button': confirmDialog.theme==='danger'}"  mat-dialog-close="okother">
          {{confirmDialog.secondOkButton}}
        </button>
        <button *ngIf="confirmDialog?.actionOkButton" type="button" class="btn btn-action-dialog success-button ml-1" [ngClass]="{'success-button': confirmDialog.theme==='success', 'danger-button': confirmDialog.theme==='danger'}"  mat-dialog-close="{{confirmDialog.actionOkButton.action}}">
          {{confirmDialog.actionOkButton.title}}
        </button>
      </div>
      <div class="col-auto" *ngIf="confirmDialog.flipButtonColor">
        <button *ngIf="confirmDialog.cancelButtonTitle" type="button" class="btn btn-action-dialog success-button mr-1"  [ngClass]="{'success-button': confirmDialog.theme==='success', 'danger-button': confirmDialog.theme==='danger'}" mat-dialog-close="cancel">{{confirmDialog.cancelButtonTitle}}</button>
        <button *ngIf="confirmDialog.okButtonTitle" type="button" class="btn btn-action-dialog cancel-button ml-1" mat-dialog-close="ok">
          {{confirmDialog.okButtonTitle}}
        </button>
        <button *ngIf="confirmDialog.secondOkButton" type="button" class="btn btn-action-dialog cancel-button ml-1" mat-dialog-close="okother">
          {{confirmDialog.secondOkButton}}
        </button>
        <button *ngIf="confirmDialog?.actionOkButton" type="button" class="btn btn-action-dialog cancel-button ml-1" mat-dialog-close="{{confirmDialog.actionOkButton.action}}">
          {{confirmDialog.actionOkButton.title}}
        </button>
      </div>
  </div>
</div>

<!-- <div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
  <div>{{confirmDialog.title}}</div>
  <button type="button" class="float-right" mat-icon-button (click)="close('No answer')" tabindex="-1">
    <mat-icon [icIcon]="icClose"></mat-icon>
  </button>
</div>

<mat-dialog-content>
  <div *ngIf="confirmDialog.message" class="text-center">
    <span [innerHTML]="confirmDialog.message"></span>
  </div>
</mat-dialog-content>


<mat-dialog-actions align="end">
  <button mat-button *ngIf="confirmDialog.cancelButtonTitle"  mat-dialog-close="cancel" color="warn">{{data.cancelButtonTitle}}</button>
  <button mat-button *ngIf="confirmDialog.okButtonTitle" [ngClass]="{'success-button': confirmDialog.theme==='success', 'danger-button': confirmDialog.theme==='danger'}"  mat-dialog-close="ok">{{confirmDialog.okButtonTitle}}</button>
</mat-dialog-actions> -->