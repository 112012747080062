export enum RAttributeGroupNames {
    Skill = "Skill",
    Expertise = "Expertise",
    Association = "Association",
    Industry = "Industry",
    Company = "Company",
    JobTitle = "JobTitle",
    Spins = "Spins",
    Degree = "Degree",
    Certification = "Certification"
}