import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { IconModule } from '@visurel/iconify-angular';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { OnlinerSearchComponent } from './onliner-search.component';
import { NgModule } from "@angular/core";
import { PageLayoutModule } from 'src/@vex/components/page-layout/page-layout.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PresetSearchResults } from './search-results/search-results.component';
import { PresetSearchInputComponent } from './search-input/search-input.component';
import { OnlinerResultModalComponent } from './onliner-result-modal/onliner-result-modal.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';


@NgModule({
    declarations: [
        PresetSearchInputComponent,
        PresetSearchResults,
        OnlinerSearchComponent,
        OnlinerResultModalComponent
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        FlexLayoutModule,
        MatIconModule,
        MatProgressSpinnerModule,
        PageLayoutModule,
        MatInputModule,
        IconModule,
        ScrollingModule,
        BreadcrumbsModule,
        SecondaryToolbarModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatFormFieldModule,
        MatTooltipModule,
        MatTableModule,
        MatExpansionModule,
        TranslateModule,
        MatSlideToggleModule
    ],
    exports: [
        OnlinerSearchComponent
    ],
})
export class OnlinerSearchModule {

}