import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { CommonService } from './common.service';

@Injectable({
  providedIn: "root"
})
export class EducationApprovalsResolverService implements Resolve<any> {
  constructor(public commonService: CommonService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.commonService.getCertificationApprovalStatuses();
  }
}