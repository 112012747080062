import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { AuthorizedHttp } from "./authorized-http.service";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { omit } from "lodash";
import { CompetencyLead, CompetencyProfileData } from "../data-models/commonDataModels";

@Injectable()
export class CompetencyMaintenanceService {
    private _apiEndpoint = `${environment.apiEndpoint}/api/competency-maintenance`;

    constructor(private http: AuthorizedHttp) { }

    public getCompetencyProfileByCompetency ( competencyId: number ): Observable<CompetencyProfileData[]> {
        return this.http.get(`${this._apiEndpoint}/${competencyId}`).pipe(
            map((result) => {
                return result;
            }),
            catchError((errorResponse: any) =>
                throwError(errorResponse.error.exceptionMessage || "Server error")
            )
        );
    }

    public getCompetencyLeadsByCompetency(competencyId: number): Observable<CompetencyLead[]> {
        return this.http.get(`${this._apiEndpoint}/getCompetencyLead/${competencyId}`).pipe(
            map((result) => {
                return result;
            }),
            catchError((errorResponse: any) =>
                throwError(errorResponse.error.exceptionMessage || "Server error")
            )
        );
    }

    public upsertCompetencyProfile(competencyId: number, competencyProfilesToSave: CompetencyProfileData[],): Observable<CompetencyProfileData[]> {
        return this.http.put(
            `${this._apiEndpoint}/${competencyId}`,
            competencyProfilesToSave.map(x => omit(x, ['competency', 'role', 'level'])
            )
        ).pipe(
            map((result) => {
                return result;
            }),
            catchError((errorResponse: any) =>
                throwError(errorResponse.error.exceptionMessage || "Server error")
            )
        );
    }

    public upsertCompetencyLeads(competencyId: number, competencyLeadsToSave: CompetencyLead[],): Observable<CompetencyLead[]> {
        return this.http.put(
            `${this._apiEndpoint}/upsertCompetencyLead/${competencyId}`,
            competencyLeadsToSave.map(x => omit(x, ['leadUser'])
            )
        ).pipe(
            map((result) => {
                return result;
            }),
            catchError((errorResponse: any) =>
                throwError(errorResponse.error.exceptionMessage || "Server error")
            )
        );
    }

    public getCompetencyProfile(): Observable<CompetencyProfileData[]> {
        return this.http
            .get(`${this._apiEndpoint}/allCompetencyProfiles/`)
            .pipe(
                map((result) => {
                    return result;
                }),
                catchError((errorResponse: any) =>
                    throwError(errorResponse.error.exceptionMessage || "Server error")
                )
            );
    }
}
