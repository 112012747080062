import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { AuthorizedHttp } from "./authorized-http.service";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { RProject } from "../data-models/project";
import { CommonService } from "./common.service";

@Injectable()
export class ProjectService {
  private _apiEndpoint = `${environment.apiEndpoint}/api/projects`;
  private _projects: RProject[];
  private _project: RProject;
  private _isDeleted: boolean;

    constructor(private http: AuthorizedHttp, private commonService: CommonService) {
    const headers: any = {
      "Content-Type":
        "application/json; charset=utf-8" /*or whatever type is relevant */,
      Accept: "application/json",
    };
  }

  public getProjects(userId: string): Observable<RProject[]> {
    userId = this.commonService.encrypt(userId);
    return this.http.get(`${this._apiEndpoint}/myProjects/` + userId).pipe(
      map((result) => {
          this._projects = result;

        this._projects.forEach(f => {
          f.responsibilities = f.responsibilities?.sort((a, b) => a.sortOrder - b.sortOrder);
          f.responsibilities.forEach(e => {
            e.responsibility = e.responsibility;
          });
        })

        return this._projects;
      }),
      catchError((errorResponse: any) => {
        console.log("errorResponse", errorResponse);
        return throwError(
          errorResponse.error.exceptionMessage || "Server error"
        );
      })
    );
  }

  public getUserProjects(userId: string): Observable<RProject[]> {
    userId = this.commonService.encrypt(userId);
    return this.http.get(`${this._apiEndpoint}/userProjects/` + userId).pipe(
      map((result) => {
          this._projects = result;

        this._projects.forEach(f => {
          f.responsibilities = f.responsibilities?.sort((a, b) => a.sortOrder - b.sortOrder);
          f.responsibilities.forEach(e => {
            e.responsibility = e.responsibility;
          });
        })

        return this._projects;
      }),
      catchError((errorResponse: any) => {
        console.log("errorResponse", errorResponse);
        return throwError(
          errorResponse.error.exceptionMessage || "Server error"
        );
      })
    );
  }

  public postNewProject(
    projectModel: RProject,
    userId: string
  ): Observable<RProject> {
    userId = this.commonService.encrypt(userId);
    return this.http
      .post(
        `${this._apiEndpoint}/myProjects/` + userId,
        JSON.stringify(projectModel)
      )
      .pipe(
        map((result) => {
          this._project = result;
          return this._project;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }

  public updateProject(
    projectModel: RProject,
    userId: string
  ): Observable<RProject> {
    userId = this.commonService.encrypt(userId);
    return this.http
      .patch(
        `${this._apiEndpoint}/myProjects/` + userId,
        JSON.stringify(projectModel)
      )
      .pipe(
        map((result) => {
          this._project = result;
          return this._project;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }

  public deleteProject(project: RProject, userId: string): Observable<RProject> {
    userId = this.commonService.encrypt(userId);
    return this.http
        .patch(`${this._apiEndpoint}/deleteProject/` + userId, JSON.stringify(project))
        .pipe(
            map(result => {
                this._project = result;
                return this._project;
            }),
            catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
        );
  }
}
