<div class="p-3 dialog-header-danger text-center">
  <span class="dialog-title d-inline">Session About to Expire</span>
</div>

<div class="dialog-body">
  <div class="row no-gutters justify-content-center pt-1">
      <div class="col-auto">
        <button type="button" (click)="logout()" [disabled]="isLoggingOut || isRenewingSession" class="btn btn-action-dialog cancel-button mr-1">
          <span *ngIf="isLoggingOut"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span> Logout</button>
        <button type="button" (click)="renewSession()" [disabled]="isRenewingSession || isLoggingOut" class="btn btn-action-dialog success-button ml-1 success-button">
          <span *ngIf="isRenewingSession"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span> Extend Session</button>
      </div>
  </div>
  <div *ngIf="errorMessage">
    <span>{{errorMessage}}</span>
  </div>
</div>

