<div class="p-6" *ngIf="isLoading$ | async">
  <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="24px">
    <mat-spinner></mat-spinner>
  </div>
</div>

<div *ngIf="resume">
  <obs-resume-preview
    [resume]="resume"
    [scale]="1.2"
    [originalResume]="originalResume"
    previewType="pdf"
  ></obs-resume-preview>
</div>
