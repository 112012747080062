import { Component, Inject, EventEmitter } from "@angular/core";

import * as _moment from "moment";
import { default as _rollupMoment } from "moment";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface DialogData {
  commentsFieldLimit: number;
  approve: boolean;
}

@Component({
  selector: "obs-review-comments-form-modal",
  templateUrl: "comments-form-modal.html",
  styleUrls: ["comments-form-modal.scss"],
})
export class CommentsFormModal {
  constructor(
    public dialogRef: MatDialogRef<CommentsFormModal>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onSave: EventEmitter<{
    comment: string,
    approve: boolean,
    callback: any
  }> = new EventEmitter();

  cancel($event): void {
    this.dialogRef.close();
  }

  public save({comment, approve, callback}:{comment: string, approve:boolean, callback: any}) {
    this.onSave.emit({
      comment: comment,
      approve: approve,
      callback: callback
    });
  }
  
}
