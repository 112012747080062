import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource, MatTable } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatDialog } from "@angular/material/dialog";
import { DataApproval } from "src/app/common/data-models/DataApproval";
import {
  ConfirmDialog,
  ConfirmationDialogComponent,
} from "src/app/shared/confirmation-dialog/confirmation-dialog.component";
import { Router } from "@angular/router";
import { AttributeService } from "src/app/common/services/attribute.service";
import { RAttribute } from "src/app/common/data-models/commonDataModels";
import { GlobalConstants } from "src/app/shared/global-constants";

@Component({
  selector: "obs-attributes-list",
  templateUrl: "./attributes-list.component.html",
  styleUrls: ["./attributes-list.component.scss"],
})
export class AttributesListComponent implements OnInit {
  isLoading = true;
  selected = [];
  dataSource: any;
  displayedColumns: string[] = [
    "name",
    "attributeType",
    "createdDate",
    "modifiedDate",
    "isAttached",
    "onliner",
  ];

  public dateFormat = GlobalConstants.FULL_DATE_FORMAT;

  @Input() set attributes(attributeData: DataApproval[]) {
    if (attributeData) {
      this.isLoading = false;
      this.dataSource.data = attributeData;
    }
  }

  @Input() refreshPage: Function;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild(MatTable, { static: false }) table: MatTable<DataApproval>;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private attributeService: AttributeService
  ) {
    this.dataSource = new MatTableDataSource<DataApproval>();
  }

  openDialog(data: ConfirmDialog, row: DataApproval) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === "ok") {
        row.name = row.name;
        const updatedData: RAttribute = row;

        this.attributeService.updateAttribute(updatedData).subscribe();

        this.dataSource._renderData._value.splice(
          this.dataSource._renderData._value.findIndex((x) => x.id === row.id),
          1
        );
        this.table.renderRows();
      }
    });
  }

  removeAttritbute(row: DataApproval): void {
    const notAssociatedAttributeDialog: ConfirmDialog = {
      title: `Unused Attribute`,
      message: `Deactivate ${row.attributeType} '${
        row.name
      }'?`,
      okButtonTitle: "Yes",
      cancelButtonTitle: "No",
      secondOkButton: "",
      theme: "",
      flipButtonColor: false,
    };
    row.isActive = false;

    this.openDialog(notAssociatedAttributeDialog, row);
  }

  moveToMainList(row: DataApproval): void {
    const associatedAttributeDialog: ConfirmDialog = {
      title: `Convert ${row.attributeType} Attribute`,
      message: `Add '${
        row.name
      }' to the main ${row.attributeType} list?`,
      okButtonTitle: "Yes",
      cancelButtonTitle: "Cancel",
      secondOkButton: "",
      theme: "",
      flipButtonColor: false,
    };
    row.isOther = false;
    row.userId = null;

    this.openDialog(associatedAttributeDialog, row);
  }

  onRowClicked(row): void {
    if (!row.isAttached) {
      this.removeAttritbute(row);
      return;
    }

    this.moveToMainList(row);
  }

  ngOnInit() {}

  ngOnChanges() {
    this.dataSource.sort = this.sort;
  }
}
