import { AfterViewInit, Component, HostListener, OnInit } from "@angular/core";
import { ResumeSearchUtilService } from "../common/services/resume-search-util.service";

@Component({
  selector: "obs-resumes-search",
  templateUrl: "resumes-search.component.html",
  styleUrls: ["resumes-search.component.scss"],
})
export class ResumesSearchComponent implements OnInit, AfterViewInit {
  public isLoading: boolean = true;
  public isSelected: boolean = false;

  constructor(private _resumeSearchUtil: ResumeSearchUtilService) {}

  ngOnInit() {
    this.isLoading = false;
  }

  selectOnliner($event) {
    this._resumeSearchUtil.getSearchResumes($event);
    this.isSelected = true;
  }

  ngAfterViewInit() {}
}
