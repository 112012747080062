import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'obs-onliner-search',
  templateUrl: './onliner-search.component.html',
  styleUrls: ['./onliner-search.component.scss']
})
export class OnlinerSearchComponent implements OnInit {
  public isLoading: boolean = true;
  public isSelected: boolean = false;
  public queryData: Event;

  constructor() { }

  ngOnInit(): void {
    this.isLoading = false;
  }

  selectOnliner($event) {
    this.isSelected = true;
  }

  hideResult() {
    this.isSelected = false;
  }
}
