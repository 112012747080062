import { SnackBarService } from 'src/app/common/services/snackbar.service';
import { AuthService } from 'src/app/common/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-session-expired-dialog',
  templateUrl: './session-expired-dialog.component.html',
  styleUrls: ['./session-expired-dialog.component.css']
})
export class SessionExpiredDialogComponent implements OnInit {

  errorMessage: string;
  isRenewingSession: boolean;
  isLoggingOut: boolean;

  constructor(
    private authService: AuthService,
    public dialogRef: MatDialogRef<SessionExpiredDialogComponent>,
    private snackBarService: SnackBarService)   { }

  ngOnInit() {
  }

  renewSession(){
    this.isRenewingSession = true;
    this.authService.acquireToken().subscribe(result => {
      this.errorMessage = null;
      this.isRenewingSession = false;
      if(!this.authService.tokenHasExpired()){
        this.dialogRef.close();
        this.snackBarService.message("Session succesfully extended.");
      } else {
        this.snackBarService.error("Unable to extend session.");
      }
    },
    error => {
      this.errorMessage = error;
      this.isRenewingSession = false;
      this.snackBarService.error("Unable to extend session.");
    });
  }

  logout(){
    this.isLoggingOut = true;
    this.authService.logout();
  }

}
