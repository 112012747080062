import { ProjectResponsibility } from "src/app/common/data-models/project-responsibility";
import { RAttribute } from "./commonDataModels";
import { ProjectIndustry } from "./ProjectIndustry";
import { ProjectJobTitle } from "./ProjectJobTitle";

export class RProject {
  public id: number;
  public company: RAttribute;
  public companyId: number;
  public startDate: Date;
  public endDate: Date;
  public projectDescription: string;
  public userId: number;
  public responsibilities: ProjectResponsibility[];
  public dateModified: Date;
  public isUsed: boolean;
  public jobTitles: ProjectJobTitle[];
  // retain for snapshot object
  public jobTitleObj: RAttribute;
  // retain for snapshot object
  public industry: RAttribute;
  public industries: ProjectIndustry[];
  public projectName: string;
}
