import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
} from "@angular/core";
import { LayoutService } from "../../services/layout.service";
import icBookmarks from "@iconify-icons/ic/twotone-bookmarks";
import emojioneDE from "@iconify-icons/emojione/flag-for-flag-germany";
import emojioneFR from "@iconify-icons/emojione/flag-for-flag-france";
import emojioneCA from "@iconify-icons/emojione/flag-for-flag-canada";
import emojionePT from "@iconify-icons/emojione/flag-for-flag-portugal";
import emojioneES from "@iconify-icons/emojione/flag-for-flag-spain";
import icMenu from "@iconify-icons/ic/twotone-menu";
import { ConfigService } from "../../services/config.service";
import { map } from "rxjs/operators";
import icPersonAdd from "@iconify-icons/ic/twotone-person-add";
import icAssignmentTurnedIn from "@iconify-icons/ic/twotone-assignment-turned-in";
import icBallot from "@iconify-icons/ic/twotone-ballot";
import icDescription from "@iconify-icons/ic/twotone-description";
import icAssignment from "@iconify-icons/ic/twotone-assignment";
import icReceipt from "@iconify-icons/ic/twotone-receipt";
import icDoneAll from "@iconify-icons/ic/twotone-done-all";
import { NavigationService } from "../../services/navigation.service";
import icArrowDropDown from "@iconify-icons/ic/twotone-arrow-drop-down";
import { PopoverService } from "../../components/popover/popover.service";
import { MegaMenuComponent } from "../../components/mega-menu/mega-menu.component";
import icSearch from "@iconify-icons/ic/twotone-search";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../../../environments/environment";

@Component({
  selector: "vex-toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.scss"],
})
export class ToolbarComponent implements OnInit {
  @Input() mobileQuery: boolean;

  @Input()
  @HostBinding("class.shadow-b")
  public hasShadow: boolean;

  public navigationItems = this.navigationService.items;

  public isHorizontalLayout$ = this.configService.config$.pipe(
    map((config) => config.layout === "horizontal")
  );
  public isVerticalLayout$ = this.configService.config$.pipe(
    map((config) => config.layout === "vertical")
  );
  public isNavbarInToolbar$ = this.configService.config$.pipe(
    map((config) => config.navbar.position === "in-toolbar")
  );
  public isNavbarBelowToolbar$ = this.configService.config$.pipe(
    map((config) => config.navbar.position === "below-toolbar")
  );

  public icSearch = icSearch;
  public icBookmarks = icBookmarks;
  // emojioneUS = emojioneUS;
  // emojioneDE = emojioneDE;

  public emojioneCA = emojioneCA;
  public emojioneFR = emojioneFR;
  public emojioneDE = emojioneDE;
  public emojioneES = emojioneES;
  public emojionePT = emojionePT;
  public icMenu = icMenu;
  public icPersonAdd = icPersonAdd;
  public icAssignmentTurnedIn = icAssignmentTurnedIn;
  public icBallot = icBallot;
  public icDescription = icDescription;
  public icAssignment = icAssignment;
  public icReceipt = icReceipt;
  public icDoneAll = icDoneAll;
  public icArrowDropDown = icArrowDropDown;

  public obsLogoUrl = `assets/img/logo/online-business-systems-logo-w.png`;

  public ResumeAppLogoUrl = `assets/img/logo/rProf-Logo-120x50.png`


  public supportedLangs: any;

  constructor(
    public translate: TranslateService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private navigationService: NavigationService,
    private popoverService: PopoverService
  ) { }

  public ngOnInit() {
  }

  public openQuickpanel() {
    this.layoutService.openQuickpanel();
  }

  public openSidenav() {
    this.layoutService.openSidenav();
  }

  public openMegaMenu(origin: ElementRef | HTMLElement) {
    this.popoverService.open({
      content: MegaMenuComponent,
      origin,
      position: [
        {
          originX: "start",
          originY: "bottom",
          overlayX: "start",
          overlayY: "top",
        },
        {
          originX: "end",
          originY: "bottom",
          overlayX: "end",
          overlayY: "top",
        },
      ],
    });
  }

  public openSearch() {
    this.layoutService.openSearch();
  }

  public checkCurrentLang() {
    let currentLang: any;

    switch (this.translate.currentLang) {
      case "de":
        currentLang = emojioneDE;
        break;
      case "en":
        currentLang = emojioneCA;
        break;
      case "es":
        currentLang = emojioneES;
        break;
      case "fr":
        currentLang = emojioneFR;
        break;
      case "pt":
        currentLang = emojionePT;
        break;
      default:
        break;
    }
    return currentLang;
  }

  public changeLanguage(language: string) {
    this.translate.use(language);
  }
}
