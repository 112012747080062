import { RAttribute } from "./commonDataModels";
import { RProject } from "./project";

export class RProjectGroup {
  public company: RAttribute;
  public companyId: number;
  public startDate: Date;
  public endDate: Date;
  public projects: RProject[];

  constructor(project: RProject) {
    this.company = project.company;
    this.companyId = project.companyId;
    this.startDate = project.startDate;
    this.endDate = project.endDate;
    this.projects = [project];
  }
}
