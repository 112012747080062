<mat-table matTable
           [dataSource]="dataSource"
           matSort
           matSortChange="sortData($event)"
           class="mat-elevation-z8 overflow-auto"
           [hidden]="isLoading || !(dataSource.data.length > 0)">
    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
        <mat-cell *matCellDef="let value" class="leftAlignCell">
            {{ value.name }}
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="attributeType">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Data Type</mat-header-cell>
        <mat-cell *matCellDef="let value" class="leftAlignCell">
            {{ value.attributeType }}
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="createdDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Date Created</mat-header-cell>
        <mat-cell *matCellDef="let value" class="leftAlignCell">
            {{ value.createdDate | date: dateFormat }}
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="modifiedDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Date Modified</mat-header-cell>
        <mat-cell *matCellDef="let value" class="leftAlignCell">
            {{ value.modifiedDate | date: dateFormat }}
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="isAttached">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Is Attached to Onliner</mat-header-cell>
        <mat-cell *matCellDef="let value" class="leftAlignCell">
            {{ value.isAttached ? "Yes" : "No" }}
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="onliner">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Onliner</mat-header-cell>
        <mat-cell *matCellDef="let value" class="leftAlignCell">
            {{ value.onliner }}
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"
             (click)="onRowClicked(row)"></mat-row>
</mat-table>
