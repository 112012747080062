<div class="component-container">
  <div class="top-margin panel panel-default">
    <div class="panel-body">
        <div class="row">
          <div class="col-md-12"><p class="header text-center">Authorization Error</p></div>
        </div>
        <div class="row">&nbsp;</div>
        <div class="row">
           <div class="col-md-12 information">
             <p class="text-center">You are not authorized to access this page.</p>
             <p class="text-center">Please contact your system administrator.</p>
           </div>
        </div>
    </div>
  </div>
</div>
  