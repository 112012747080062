<form [formGroup]="filterCriteriaForm" novalidate [style.fontSize.px]="18">
  <div class="row form-group">
    <mat-form-field class="mr-3 col-md-3 search">
      <mat-label>Attribute name</mat-label>
      <input
        matInput
        name="attribute-name"
        id="attribute-name"
        placeholder="Attribute Name"
        formControlName="name"
        (ngModelChange)="handleChange($event)"
        [matAutocomplete]="autoName"
      />
      <i class="material-icons" matSuffix>keyboard_arrow_down</i>

      <mat-autocomplete
        #autoName="matAutocomplete"
        autoActiveFirstOption
        (optionSelected)="filterAttributes($event)"
        panelWidth="280px"
      >
        <mat-option
          *ngFor="let option of attributeNames"
          [value]="option "
        >
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="mr-3 col-md-3 search">
      <mat-label>Data type</mat-label>
      <input
        matInput
        name="attribute-type"
        id="attribute-type"
        placeholder="Data Type"
        formControlName="dataType"
        (ngModelChange)="handleChange($event)"
        [matAutocomplete]="autoType"
      />
      <i class="material-icons" matSuffix>keyboard_arrow_down</i>

      <mat-autocomplete
        #autoType="matAutocomplete"
        autoActiveFirstOption
        (optionSelected)="filterAttributes($event)"
        panelWidth="280px"
      >
        <mat-option *ngFor="let option of dataTypesList" [value]="option">
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div class="row form-group">
    <mat-form-field class="mr-3 col-md-2 search">
      <mat-label for="client">Created Date</mat-label>
      <input
        matInput
        formControlName="createdDate"
        placeholder="YYYY-MM-DD"
        name="dateStart"
        [matDatepicker]="createdDatePicker"
        [max]="endDate"
        (click)="createdDatePicker.open()"
        (focus)="createdDatePicker.open()"
        (dateChange)="filterAttributes($event)"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="createdDatePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #createdDatePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="mr-3 col-md-2 search">
      <mat-label for="client">Modified Date</mat-label>
      <input
        matInput
        formControlName="modifiedDate"
        placeholder="YYYY-MM-DD"
        name="dateStart"
        [max]="endDate"
        [matDatepicker]="modifiedDatePicker"
        (click)="modifiedDatePicker.open()"
        (focus)="modifiedDatePicker.open()"
        (dateChange)="filterAttributes($event)"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="modifiedDatePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #modifiedDatePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="mr-3 col-md-3 search">
      <mat-label>Is Attached to Onliner</mat-label>
      <input
        matInput
        name="attribute-is-attached"
        id="attribute-is-attached"
        placeholder="Is Attached to Onliner"
        formControlName="isAttached"
        (ngModelChange)="handleChange($event)"
        [matAutocomplete]="autoAttachedTo"
      />
      <i class="material-icons" matSuffix>keyboard_arrow_down</i>

      <mat-autocomplete
        #autoAttachedTo="matAutocomplete"
        autoActiveFirstOption
        (optionSelected)="filterAttributes($event)"
        panelWidth="280px"
      >
        <mat-option [value]="'Yes'">Yes</mat-option>
        <mat-option [value]="'No'">No</mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div class="row form-group">
    <div class="col-md-3 padding-left-15">
      <button
        type="button"
        class="btn btn-action-dialog danger-button ml-1"
        (click)="resetForm()"
      >
        RESET
      </button>
    </div>
  </div>
</form>
