import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PageLayoutModule } from "src/@vex/components/page-layout/page-layout.module";

import { SecondaryToolbarModule } from "src/@vex/components/secondary-toolbar/secondary-toolbar.module";
import { BreadcrumbsModule } from "src/@vex/components/breadcrumbs/breadcrumbs.module";
import { ContainerModule } from "src/@vex/directives/container/container.module";
import { AttributeApprovalsComponent } from "./attribute-approvals.component";
import { AttributesListComponent } from "./attributes-list/attributes-list.component";
import { AttributeListFilterCriteriaComponent } from "./attribute-list-filter-criteria/attribute-list-filter-criteria.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// Material
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatIconModule } from "@angular/material/icon";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatSelectModule } from "@angular/material/select";
import { MatDialogModule } from "@angular/material/dialog";

import { DataApprovalService } from "../common/services/dataApproval.service";
import { DataApproval } from "../common/data-models/DataApproval";
import { AttributeFilterService } from "../common/services/attribute-filter.service";
import { AttributeApprovalsRoutingModule } from "./attribute-approvals-routing.module";

@NgModule({
  declarations: [
    AttributeApprovalsComponent,
    AttributesListComponent,
    AttributeListFilterCriteriaComponent,
  ],
  imports: [
    CommonModule,
    SecondaryToolbarModule,
    BreadcrumbsModule,
    PageLayoutModule,
    ContainerModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    MatIconModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatSelectModule,
    MatDialogModule,
    AttributeApprovalsRoutingModule,
  ],
  providers: [
    DataApprovalService,
    AttributeFilterService,
  ],
  exports: [AttributeApprovalsComponent, AttributesListComponent],
})
export class AttributeApprovalsModule {}
