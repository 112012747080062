import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatDialog } from "@angular/material/dialog";
import icInfoCircle from "@iconify-icons/uil/info-circle";
import { forkJoin, Observable } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
} from "rxjs/operators";
import { fadeInUp400ms } from "src/@vex/animations/fade-in-up.animation";
import { stagger60ms } from "src/@vex/animations/stagger.animation";
import {
  CompetencyProfile,
  RAttribute,
  SavedQuery,
} from "src/app/common/data-models/commonDataModels";
import { Preset } from "src/app/common/data-models/Preset";
import { AuthService } from "src/app/common/services/auth.service";
import { CommonService } from "src/app/common/services/common.service";
import { OnlinerService } from "src/app/common/services/onliner.service";
import { Employee } from "src/app/common/data-models/Employee";
import { SnackBarService } from "src/app/common/services/snackbar.service";
import { Stack } from "./../../common/data-models/Stack";
import { OnlinerSearchUtilService } from "./../../common/services/onliner-search-util.service";
import {
  ConfirmationDialogComponent,
  ConfirmDialog,
} from "./../../shared/confirmation-dialog/confirmation-dialog.component";
import { CompetencyService } from "../../common/services/competency.service";

enum LastInserted {
  Nothing = "Nothing",
  Operator = "Operator",
  Preset = "Preset",
}

enum LastOperatorInserted {
  Nothing = "Nothing",
  And = "AND",
  Or = "OR",
}

@Component({
  selector: "obs-preset-search-input",
  templateUrl: "search-input.component.html",
  styleUrls: ["search-input.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: [stagger60ms, fadeInUp400ms],
})
export class PresetSearchInputComponent implements OnInit {
  private _lastInserted: LastInserted;
  private _lastOperatorInserted: LastOperatorInserted;
  private _queryToParse: Stack<string>;
  private experienceNumberOfYearsEnd: number;
  private experienceNumberOfYearsStart: number;
  private validSeparator = " ";
  private educationGroupNames = ["Degree", "Certification"];
  private skillGroupNames: string[] = ["Skill"];

  public isLoading = false;
  public isLoadingAll = true;
  public isSubmitting: boolean;
  public isDirty: boolean;
  public onlyApprovedResume: boolean = false;
  public startWith: boolean = false;
  public presets: Preset[];
  public filteredPreset: Observable<Preset[]>;
  public operators: string[] = ["AND", "OR"];
  public nameOptions: Observable<string[]>;
  public savedQueries: SavedQuery[];
  public filteredSavedQueries: Observable<string[]>;

  disableAnimation = true;
  public searchInputOpenState = false;
  public newQuery = false;

  public associations: RAttribute[];
  public projects: RAttribute[];
  public skills: RAttribute[];
  public educations: RAttribute[];
  public industries: RAttribute[];
  public employers: RAttribute[];
  public onliners: Employee[];
  public competencies: CompetencyProfile[];

  public options: string[];
  public caretWord: string;
  public caretPos: number;
  public posInsert: number;
  public textSaving: string;
  public insertedPreset: string;
  public mapPresetToArrays: { [key: string]: RAttribute[] } = {};

  presetSearch: UntypedFormControl;
  presetOperator: UntypedFormControl;
  query: UntypedFormControl;
  selectSavedQuery: UntypedFormControl;
  newQueryName: UntypedFormControl;
  wildCardSearch: UntypedFormControl;

  icInfoCircle = icInfoCircle;

  @Output() selectedOnliner: EventEmitter<string> = new EventEmitter<string>();
  @Output() hideResult: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private _commonService: CommonService,
    private _authService: AuthService,
    private _onlinerService: OnlinerService,
    private _onlinerSearchUtil: OnlinerSearchUtilService,
    private _snackBarService: SnackBarService,
    private _competencyService: CompetencyService,
    private _dialog: MatDialog
  ) {}

  async ngOnInit() {
    this._onlinerSearchUtil.isSearching().subscribe((searching) => {
      this.isLoading = searching;
    });
    this.presetSearch = new UntypedFormControl();
    this.presetOperator = new UntypedFormControl();
    this.query = new UntypedFormControl();
    this.selectSavedQuery = new UntypedFormControl();
    this.newQueryName = new UntypedFormControl();
    this.wildCardSearch = new UntypedFormControl();

    await this.initValues();

    this._commonService.getPresets().subscribe((searchResponse) => {
      this.presets = searchResponse.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
      this.setPresetFilter();
    });

    this.isLoadingAll = false;

    this.nameOptions = this.query.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      startWith(""),
      map((val) => (val.includes("[") ? this.filterName(val) : []))
    );

    this.filteredPreset = this.presetSearch.valueChanges.pipe(
      startWith(null as Preset),
      map((value) => this.presetsFilter(value, this.presets))
    );

    this.filteredSavedQueries = this.selectSavedQuery.valueChanges.pipe(
      startWith(""),
      map((value) => this.savedQueriesFilter(value))
    );

    this._lastInserted = LastInserted.Nothing;
    this._lastOperatorInserted = LastOperatorInserted.Nothing;

    this._queryToParse = new Stack<string>();

    this.isSubmitting = false;
    this.isDirty = false;
  }

  async initValues() {
      const [
          associations,
          projects,
          industries,
          onliners,
          employers,
          educations,
          skills,
          competencies,
          savedQueries
      ]: [
          RAttribute[],
          RAttribute[],
          RAttribute[],
          Employee[],
          RAttribute[],
          RAttribute[],
          RAttribute[],
          CompetencyProfile[],
          SavedQuery[]
      ] = await forkJoin([
          this._commonService.getAssociations(),
          this._commonService.getJobTitles(),
          this._commonService.getIndustries(),
          this._onlinerService.getOnliners(),
          this._commonService.getEmployers(),
          this._commonService.getSkills(this.educationGroupNames),
          this._commonService.getSkills(this.skillGroupNames),
          this._competencyService.getCompetencies(2),
          this._onlinerService.getSavedQueries(this._authService.getUserId())
      ] as const).toPromise();

      this.associations = associations;
      this.projects = projects;
      this.industries = industries;
      this.onliners = onliners;
      this.employers = employers;
      this.educations = educations;
      this.skills = skills;
      this.competencies = competencies;
      this.savedQueries = savedQueries;

      this.mapPresetToArrays["associations["] = this.associations;
      this.mapPresetToArrays["project["] = this.projects;
      this.mapPresetToArrays["clientemployer["] = this.employers;
      this.mapPresetToArrays["education["] = this.educations;
      this.mapPresetToArrays["industry["] = this.industries;
      this.mapPresetToArrays["skill["] = this.skills;
      this.mapPresetToArrays["corecompetency["] = [];
      this.mapPresetToArrays["name["] = [];
  }

  async getSavedQueriesList() {
    this.hideResult.emit();
    this.isLoadingAll = true;
    this.query.setValue("");
    this.selectSavedQuery.setValue("");
    this.newQueryName.setValue("");
    this.query.markAsPristine();
    this.selectSavedQuery.markAsPristine();
    this.newQueryName.markAsPristine();
    this.savedQueries = await this._onlinerService
      .getSavedQueries(this._authService.getUserId())
      .toPromise();
    this.isLoadingAll = false;
  }

  onInfoClicked() {
    const confirmDialog = new ConfirmDialog();
    confirmDialog.title = "Examples";
    confirmDialog.message =
      "<p>Choose a Preset and update the search parameters<br>" +
      "Or paste a query into the Query field.</p>" +
      "<p class='text-left'>Examples:</p>" +
      "<ul class='list-disc text-left'><li>Skill[.NET] AND Experience[5][10]</li>" +
      "<li>Skill[.NET] AND Skill[C#] AND Experience[5]</li>" +
      "<li>Client[Wawanesa]</li></ul>";
    confirmDialog.cancelButtonTitle = null;
    confirmDialog.okButtonTitle = "Ok";

    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: "500px",
      data: confirmDialog,
      disableClose: true,
    });
  }

  clickAndKeyup($event) {
      if (this.query.value !== null) {
          var endPos = $event.target.selectionStart;
          if (endPos == -1) endPos = this.query.value.length;
          this.caretPos = endPos;
          var result = '';
          var i = endPos - 1;
          while (i >= 0) {
              //if we run into a ] before running into a [ return null
              if (this.query.value[i] === ']') {
                  result = null;
                  break;
              }
              else if (this.query.value[i] === '[') {
                  if (i > 0 && this.query.value[i - 1] !== ']') {
                      // Continue traversal until space is encountered
                      while (i >= 0 && this.query.value[i] !== ' ') {
                          result = this.query.value[i] + result;
                          i--;
                      }
                      break;
                  }
                  // add ][ to the front of our result and then continue as normal 
                  else {
                      result = this.query.value[i] + result;
                      i--;
                      if (this.query.value[i]) {
                          result = this.query.value[i] + result;
                      }
                  }
              }
              else
              {
                  result = this.query.value[i] + result;
              }
              i--;
          }
          this.caretWord = result;
      }
      else this.posInsert = null;
  }
  
  onNewButtonClick(): void {
    this.newQuery = !this.newQuery;
  }

  filterName(val: string): string[] {
    if (this.caretWord != null) {
      if (this.mapPresetToArrays.hasOwnProperty(this.caretWord.toLowerCase())) {
        this.insertedPreset = this.caretWord.toLowerCase();
        this.posInsert = this.caretPos;
      }
      if (
        this.caretWord.toLowerCase().includes(this.insertedPreset) &&
        this.mapPresetToArrays.hasOwnProperty(this.insertedPreset)
      ) {
        if (this.insertedPreset === "name[")
          this.options = [
            ...this.onliners.map((a) => a.firstName.trim()),
            ...this.onliners.map((a) => a.lastName.trim()),
          ];
        else if (this.insertedPreset === "corecompetency[")
          this.options = this.competencies.map((a) =>
            a.name.trim()
          );
        else {
          var listItem = this.mapPresetToArrays[this.insertedPreset];
          this.options = listItem
            .filter((a) => !a.isOther)
            .map((a) => a.name.trim());
        }
        return this.getOptions(val);
      } else {
        this.insertedPreset = null;
        this.posInsert = this.caretPos;
      }
    }
  }

  getOptions(val: string) {
    this.textSaving = this.query.value;
    var wordTyping = val.slice(this.posInsert, this.caretPos);
    return this.options
      .filter((option) =>
        option.toLowerCase().includes(wordTyping.toLowerCase())
      )
      .sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));
  }

  setPresetFilter() {
    this.filteredPreset = this.presetSearch.valueChanges.pipe(
      startWith(null as Preset),
      map((value) => this.presetsFilter(value, this.presets))
    );
  }

  private presetsFilter(val: string, presets: Preset[]): Preset[] {
    if (!val) {
      return presets;
    }
    type AttributeCompare = { name: string };
    const filterFn = (rAttribute: AttributeCompare) =>
      `${rAttribute.name.toLowerCase()}`.includes(val.toLowerCase());
    return (presets as AttributeCompare[]).filter(filterFn) as typeof presets;
  }

  savedQueriesFilter(val: string): string[] {
    this.options = this.savedQueries.map((a) =>
      a.name.trim()
    );
    return this.options
      .filter((option) => option.toLowerCase().includes(val.toLowerCase()))
      .sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));
  }

  searchPresetDisplay(option: Preset) {
    if (!option) return;
    return ` ${option.name}`;
  }

  savedQuerySelected(event: MatAutocompleteSelectedEvent) {
    var chosenSavedQuery = this.savedQueries.find(
      (a) => a.name === event.option.value
    );
    this.query.setValue(chosenSavedQuery.searchQuery);
  }

  querySelected(event: MatAutocompleteSelectedEvent) {
    this.textSaving =
      this.textSaving.slice(0, this.posInsert) +
      this.textSaving.slice(this.caretPos);
    if (this.textSaving[this.posInsert] === "]")
      this.query.setValue(
        [
          this.textSaving.slice(0, this.posInsert),
          event.option.value,
          this.textSaving.slice(this.posInsert),
        ].join("")
      );
    else
      this.query.setValue(
        [
          this.textSaving.slice(0, this.posInsert),
          event.option.value,
          this.textSaving.slice(this.posInsert),
          "]",
        ].join("")
      );
    this.caretWord = null;
    this.posInsert = null;
    this.caretPos = null;
  }

  presetSelected(event: MatAutocompleteSelectedEvent) {
    this.setupQueryViaPreset(event.option.value);
    this.presetSearch.setValue("");
  }

  operatorSelected(event: MatAutocompleteSelectedEvent) {
    this.setupQueryViaOperator(event.option.value);
    this.presetOperator.setValue("");
  }

  setupQueryViaOperator(operator: string) {
    let query: string = this.query.value;
    if (operator) {
      if (!query) {
        return;
      } else if (
        query.toUpperCase() !== "OR" &&
        query.toUpperCase() !== "AND"
      ) {
        this._lastInserted = LastInserted.Preset;
      }

      if (this._lastInserted === LastInserted.Operator) {
        if (this._lastOperatorInserted !== operator) {
          operator =
            this._lastOperatorInserted === LastOperatorInserted.And
              ? LastOperatorInserted.Or
              : LastOperatorInserted.And;
          this.rebuildQueryAndInsertLastOperator(operator);
        }
      } else if (this._lastInserted === LastInserted.Preset) {
        this.insertIntoStack(operator);

        query = `${query}${this.validSeparator}${operator}`;
        this.setQueryValue(query);
      }

      this._lastOperatorInserted =
        LastOperatorInserted.And === operator
          ? LastOperatorInserted.And
          : LastOperatorInserted.Or;
    }

    this.setLastInserted(LastInserted.Operator);
  }

  setupQueryViaPreset(preset: string) {
    var query = this.query.value;
    if (!query) {
      query = preset;
    } else {
      query = query + " " + preset;
    }
    if (preset) {
      this.setQueryValue(query);
    }

    this.setLastInserted(LastInserted.Preset);
  }

  private resetQueryIfCurrentPresetEquals(preset: string) {
    const currentPreset = this._queryToParse.peek();
    if (currentPreset !== preset) {
      this._queryToParse.replaceLastValue(preset);
      this.resetQueryString();
    }
  }

  private rebuildQueryAndInsertLastOperator(operator: string) {
    this._queryToParse.replaceLastValue(operator);
    this.resetQueryString();
  }

  private resetQueryString() {
    let newQuery = "";
    for (const item of this._queryToParse.items()) {
      newQuery = `${newQuery}${this.validSeparator}${item}`;
    }
    this.query.setValue(newQuery);
  }

  private insertIntoStack(value: string) {
    this._queryToParse.push(value);
  }

  private canInsertPresetInQuery() {
    return (
      this._lastInserted === LastInserted.Operator ||
      this._lastInserted === LastInserted.Nothing
    );
  }

  private setQueryValue(query: string) {
    this.query.setValue(`${query}`);
  }

  private setLastInserted(toInsert: LastInserted) {
    this._lastInserted = toInsert;
  }

  public validateAndSearch(event) {
    const finalQuery: string = this.query.value;

    if (finalQuery === "" || finalQuery === undefined || finalQuery === null) {
      return this.invalidQuery("Your query cannot be empty.");
    }

    if (!this.hasValidOpeningAndClosingBrackets(finalQuery)) {
      return this.invalidQuery(
        "Your query does not have valid opening or closing square brackets."
      );
    }

    let updatedQuery =
      this.replaceUnderscoresBetweenSquareBracketsWithSpaces(finalQuery);

    updatedQuery = updatedQuery.replace("]", "] ");
    updatedQuery = updatedQuery.replace(/\s+/g, " ");
    updatedQuery = updatedQuery.replace(/] AND |] AND/gi, "] AND ");
    updatedQuery = updatedQuery.replace(/] OR |] OR/gi, "] OR ");
    updatedQuery = updatedQuery.trim();

    this.query.setValue(updatedQuery);

    var tempQuery = updatedQuery.replace(/] AND |] AND/gi, "]] AND ");
    var tempQuery = tempQuery.replace(/] OR |] OR/gi, "]] OR ");
    const queryToArray = tempQuery.split(/] AND |] OR /i);
    const presetMatch = new RegExp(/[\w]+\[[\w ,!@#$%^&+.*()/-]+]$/);
    const presetMatchForExperience = new RegExp(/[\w]+\[[0-9]*]+\[[0-9]*]$/);

    for (const item of queryToArray) {
      if (item === "") {
        return this.invalidQuery(
          "Query cannot begin or end with an operator. See info button for query example."
        );
      }
      if (!this.isOperator(item)) {
        if (!item.match(presetMatch) && !item.match(presetMatchForExperience)) {
          return this.invalidQuery(
            "Please check " + item.trim() + " for an invalid query."
          );
        }

        const positionOfFirstBracket = item.indexOf("[");
        const positionOfLastBracket = item.indexOf("]");
        if (positionOfFirstBracket === -1 || positionOfLastBracket === -1) {
          return this.invalidQuery(
            `One of your square brackets is missing for ${item}`
          );
        }
        const presetToCheck = item
          .substring(0, positionOfFirstBracket)
          .toUpperCase();

        switch (presetToCheck) {
          case "EXPERIENCE":
            if (
              !this.validateExperience(
                item,
                positionOfFirstBracket,
                positionOfLastBracket
              )
            ) {
              return this.invalidQuery(
                "Invalid Experience preset query. Please fix the Experience preset"
              );
            } else if (
              !queryToArray.find((preset) =>
                preset.toUpperCase().includes("SKILL")
              ) &&
              !queryToArray.find((preset) =>
                preset.toUpperCase().includes("KNOWLEDGE")
              )
            ) {
              return this.invalidQuery(
                "Invalid Experience preset query. Please add Experience preset after Skill preset."
              );
            } else if (
              this.experienceNumberOfYearsStart >
              this.experienceNumberOfYearsEnd
            ) {
              if (this.experienceNumberOfYearsEnd !== 0) {
                return this.invalidQuery(
                  "Invalid years of experience. The Number of years start cannot be greater than the number of years end."
                );
              }
            }
            break;
        }
      }
    }

    // validate the last inserted item
    const lastItemOfQuery = queryToArray[queryToArray.length - 1].toUpperCase();

    if (lastItemOfQuery === "AND" || lastItemOfQuery === "OR") {
      return this.invalidQuery(
        "The last word in your query needs to be a preset and not an operator."
      );
    }

    this.search(this.query.value);
    this.selectedOnliner.emit(event);
    this._onlinerSearchUtil.sendQuery(this.query.value);
  }

  private replaceUnderscoresBetweenSquareBracketsWithSpaces(
    finalQuery: string
  ): string {
    let newQuery: string = finalQuery;

    let firstEncounter = 0;
    let secondEncounter = 0;
    let lastEncounter = newQuery.length;
    let firstHalf = "";
    let secondHalf = "";

    while (secondEncounter < lastEncounter) {
      lastEncounter = newQuery.lastIndexOf("]");

      firstEncounter = newQuery.indexOf("[", secondEncounter);
      if (firstEncounter <= 0) {
        return newQuery;
      }

      secondEncounter = newQuery.indexOf("]", firstEncounter);
      if (secondEncounter <= 0) {
        return newQuery;
      }

      const stringBetweenBrackets = newQuery.substr(
        firstEncounter + 1,
        secondEncounter - firstEncounter - 1
      );

      if (stringBetweenBrackets.indexOf("_") > 0) {
        firstHalf = newQuery.substr(0, secondEncounter + 1);
        secondHalf = newQuery.substr(secondEncounter + 1, newQuery.length);

        firstHalf = firstHalf.replace(
          stringBetweenBrackets,
          stringBetweenBrackets.replace("_", " ")
        );
        newQuery = firstHalf + secondHalf;
      }
    }

    return newQuery;
  }

  private hasValidOpeningAndClosingBrackets(finalQuery: string): boolean {
    const stackWithClosingBrackets: Stack<string> = new Stack<string>();

    for (const item of finalQuery) {
      if (item === "[") {
        stackWithClosingBrackets.push("]");
      } else if (item === "]") {
        if (stackWithClosingBrackets.count() === 0) return false;

        const poppedBracket = stackWithClosingBrackets.pop();
        if (poppedBracket !== "]") {
          return false;
        }
      }
    }

    return stackWithClosingBrackets.count() === 0;
  }

  private isOperator(item: string) {
    return item.toUpperCase() === "AND" || item.toUpperCase() === "OR";
  }

  private validateExperience(
    item: string,
    indexOfFirstBracket,
    indexOfSecondBracket
  ): boolean {
    const numberOfYearsStart: number = this.retrieveNumberOfYearsStart(
      item,
      indexOfFirstBracket,
      indexOfSecondBracket
    );
    if (!Number.isInteger(numberOfYearsStart)) {
      return false;
    }

    const numberOfYearsEnd: number = this.retrieveNumberOfYearsEnd(
      item,
      indexOfSecondBracket
    );

    if (!Number.isInteger(numberOfYearsEnd)) {
      return false;
    }

    if (numberOfYearsEnd < 0 || numberOfYearsStart < 0) {
      return false;
    }

    this.experienceNumberOfYearsEnd = numberOfYearsEnd;
    this.experienceNumberOfYearsStart = numberOfYearsStart;

    return true;
  }

  private retrieveNumberOfYearsEnd(
    item: string,
    indexOfSecondBracket: any
  ): number {
    if (item.toUpperCase().includes("(OPTIONAL)")) {
      const stringWithOptional = item.substring(
        indexOfSecondBracket + 2,
        item.length - 1
      );
      const replaceString = /(Optional)/gi;
      const numberOfYearEndString = stringWithOptional
        .toUpperCase()
        .replace(replaceString, "")
        .replace("(", "")
        .replace(")", "");
      return parseInt(numberOfYearEndString, 10);
    } else if (!item.toUpperCase().includes("(OPTIONAL)")) {
      if (item.substring(indexOfSecondBracket + 2, item.length) === "") {
        return 0;
      }
      return parseInt(
        item.substring(indexOfSecondBracket + 2, item.length - 1),
        10
      );
    } else {
      return 0;
    }
  }

  private retrieveNumberOfYearsStart(
    item: string,
    indexOfFirstBracket: any,
    indexOfSecondBracket: any
  ): number {
    return parseInt(
      item.substring(indexOfFirstBracket + 1, indexOfSecondBracket),
      10
    );
  }

  private invalidQuery(message: string) {
    let dialog = new ConfirmDialog();
    dialog.title = "Invalid Query";
    dialog.message = `<p>${message}</p>`;
    dialog.okButtonTitle = "Ok";

    let dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: "500px",
      data: dialog,
      disableClose: true,
    });
  }

  private search(query: string) {
    this._onlinerSearchUtil.getUsersViaPreset(query);
  }

  public searchWildCard() {
    if (
      this.wildCardSearch.value === "" ||
      this.wildCardSearch.value === undefined ||
      this.wildCardSearch.value === null
    ) {
      return this.invalidQuery("Your query cannot be empty.");
    }
    this._onlinerSearchUtil.getUsersViaWildCard(
      this.wildCardSearch.value,
      this.startWith,
      this.onlyApprovedResume
    );
    this.selectedOnliner.emit();
    if (this.startWith)
      this._onlinerSearchUtil.sendQuery(
        "\\b" + this.wildCardSearch.value + "\\b"
      );
    else this._onlinerSearchUtil.sendQuery(this.wildCardSearch.value);
  }

  public resetQuery() {
    this.query.setValue("");
    this.selectSavedQuery.setValue("");
    this.newQueryName.setValue("");
    this.wildCardSearch.setValue("");
    this.wildCardSearch.markAsPristine();
    this.query.markAsPristine();
    this.selectSavedQuery.markAsPristine();
    this.newQueryName.markAsPristine();
    this.setLastInserted(LastInserted.Nothing);
  }

  save(newQueryName: any, query: any): void {
    if (
      this.savedQueries.find(
        (query) =>
          query.name.toLowerCase() ===
          newQueryName.value.toLowerCase()
      )
    )
      this.errorMessage(
        "This name is already used. Please choose a different name."
      );
    else {
      this.isSubmitting = true;

      newQueryName.disable();
      query.disable();
      var newQuery = new SavedQuery(
        0,
        newQueryName.value,
        query.value,
        this._authService.getUserId(),
        new Date()
      );

      this._onlinerService
        .postNewQuery(newQuery, this._authService.getUserId())
        .subscribe(
          (ra) => {
            newQueryName.enable();
            query.enable();
            this.newQuery = !this.newQuery;
            this.getSavedQueriesList();
            this._snackBarService.message("Query saved successfully!");
            this.isSubmitting = false;
          },
          (error) => {
            this.isSubmitting = false;
            newQueryName.enable();
            query.enable();
            this.newQuery = !this.newQuery;
            this._snackBarService.message(error);
          }
        );
    }
  }

  delete(selectSavedQuery: any): void {
    var queryToDel = this.savedQueries.find(
      (a) => a.name === selectSavedQuery.value
    );
    if (queryToDel == null)
      this.errorMessage("Please choose an existed query name to delete.");
    else {
      const confirmDialog = new ConfirmDialog();
      this.isSubmitting = true;
      selectSavedQuery.disable();

      confirmDialog.title = "Delete Saved Query";
      confirmDialog.message = "Delete " + this.selectSavedQuery.value + "?";
      confirmDialog.okButtonTitle = "Yes";
      confirmDialog.cancelButtonTitle = "No";

      const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
        width: "500px",
        data: confirmDialog,
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result === "ok") {
          this._onlinerService
            .deleteSavedQuery(this._authService.getUserId(), queryToDel)
            .subscribe(
              (ra) => {
                selectSavedQuery.enable();
                this.getSavedQueriesList();
                this._snackBarService.message("Query deleted successfully!");
                this.isSubmitting = false;
              },
              (error) => {
                this.isSubmitting = false;
                selectSavedQuery.enable();
                this._snackBarService.message(error);
              }
            );
        }
        if (result === "cancel") {
          this.isSubmitting = false;
          selectSavedQuery.enable();
        }
      });
    }
  }

  update(selectSavedQuery: any, query: any): void {
    var updatedQuery = this.savedQueries.find(
      (a) => a.name === selectSavedQuery.value
    );
    if (updatedQuery == null)
      this.errorMessage("Please choose an existed query name to update.");
    else {
      this.isSubmitting = true;
      selectSavedQuery.disable();
      query.disable();
      updatedQuery.searchQuery = query.value;
      this._onlinerService
        .updateSavedQuery(updatedQuery, this._authService.getUserId())
        .subscribe(
          (ra) => {
            selectSavedQuery.enable();
            query.enable();
            this.getSavedQueriesList();
            this._snackBarService.message("Query updated successfully!");
            this.isSubmitting = false;
          },
          (error) => {
            this.isSubmitting = false;
            selectSavedQuery.enable();
            query.enable();
            this._snackBarService.message(error);
          }
        );
    }
  }

  private errorMessage(message: string) {
    let dialog = new ConfirmDialog();
    dialog.title = "Error Action";
    dialog.message = `<p>${message}</p>`;
    dialog.okButtonTitle = "Ok";

    let dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: "500px",
      data: dialog,
      disableClose: true,
    });
  }

  searchDataChange() {
    this.onlyApprovedResume = !this.onlyApprovedResume;
  }

  startWithChange() {
    this.startWith = !this.startWith;
  }
}
