import { createReducer, createFeature, on } from '@ngrx/store'
import {
  RAttribute,
} from "../../common/data-models/commonDataModels";
import { findIndex, remove } from "lodash";
import { Skill } from "../../build-resume/build-resume-card/models/Skill";
import { DropdownSelectedItem } from "../../build-resume/build-resume-card/models/DropdownSelectedItem";
import { OnlinerSkill } from "../../build-resume/build-resume-card/models/OnlinerSkill";
import * as UserSkillsActions from "./multi-select-skills-card.actions";

export const featureName = "userSkills";

export interface State {
  userId: string;
  originalOnlinerSkills: OnlinerSkill[];
  onlinerSkills: OnlinerSkill[];
  filter: {
    skillName: string,
    expertiseTypeName: string,
    resetSkillFilterResult: boolean,
    resetExpertiseTypeName: boolean,
  };
  skills: Skill[];
  expertiseTypes: RAttribute[];
  backendDataIsLoadedStatus: BackendDataIsLoadedStatus;
  isSaving: boolean;
  isBackendDataLoading: boolean;
}

const initialState: State = {
  userId: null,
  originalOnlinerSkills: null,
  onlinerSkills: null,
  filter: {
    skillName: '',
    expertiseTypeName: '',
    resetSkillFilterResult: false,
    resetExpertiseTypeName: false,
  },
  skills: null, // for dropdown data
  expertiseTypes: null,
  backendDataIsLoadedStatus: {
    onlinerSkills: null,
    skills: null,
    expertiseTypes: null,
  },
  isSaving: false,
  isBackendDataLoading: false,
}

interface BackendDataIsLoadedStatus {
  onlinerSkills: boolean,
  skills: boolean,
  expertiseTypes: boolean,
}

const isAllBackendDataLoading = (backendDataIsLoadedStatus: BackendDataIsLoadedStatus) => {
  if (
    backendDataIsLoadedStatus.onlinerSkills != null &&
    backendDataIsLoadedStatus.skills != null &&
    backendDataIsLoadedStatus.expertiseTypes != null
  ) {
    return false;
  }

  return true;
}

export const userSkillsFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(UserSkillsActions.loadExpertiseTypeData, (state, { userId }) => ({
      ...state,
      isBackendDataLoading: true,
      userId
    })),
    on(UserSkillsActions.setInitialOnlinerSkills, (state, { onlinerSkills }) => ({
      ...state,
      onlinerSkills: [...onlinerSkills]
        .map((s) => ({
          ...s,
          // competency: {
          //     ...s.competency,
          //     name: s.competency != null ? s.competency.name : ""
          // },
          // category: {
          //     ...s.category,
          //     name: s.category != null ? s.category.name : ""
          // },
          skill: {
            ...s.skill,
            name: s.skill != null ? s.skill.name : ""
          },
          expertiseType: {
            ...s.expertiseType,
            name: s.expertiseType != null ? s.expertiseType.name : ""
          },
          // expertiseLevel: {
          //   ...s.expertiseLevel,
          //   name: s.expertiseLevel != null ? s.expertiseLevel.name : ""
          // }
        }))
        .sort((a, b) => {
          if (a.skill.name.toLowerCase() == b.skill.name.toLowerCase()) {
            if (a.expertiseType.name == b.expertiseType.name) {
              return a.id < b.id ? -1 : 1;
            }

            return a.expertiseType.name < b.expertiseType.name ? -1 : 1;
          }

          return a.skill.name.toLowerCase() < b.skill.name.toLowerCase() ? -1 : 1;
        }),
      originalOnlinerSkills: [...onlinerSkills]
        .map((s) => ({
          ...s,
          // competency: {
          //     ...s.competency,
          //     name: s.competency != null ? s.competency.name : ""
          // },
          // category: {
          //     ...s.category,
          //     name: s.category != null ? s.category.name : ""
          // },
          skill: {
            ...s.skill,
            name: s.skill != null ? s.skill.name : ""
          },
          expertiseType: {
            ...s.expertiseType,
            name: s.expertiseType != null ? s.expertiseType.name : ""
          },
          // expertiseLevel: {
          //   ...s.expertiseLevel,
          //   name: s.expertiseLevel != null ? s.expertiseLevel.name : ""
          // }
        }))
        .sort((a, b) => {
          if (a.skill.name.toLowerCase() == b.skill.name.toLowerCase()) {
            if (a.expertiseType.name == b.expertiseType.name) {
              return a.id < b.id ? -1 : 1;
            }

            return a.expertiseType.name < b.expertiseType.name ? -1 : 1;
          }

          return a.skill.name.toLowerCase() < b.skill.name.toLowerCase() ? -1 : 1;
        }),
      backendDataIsLoadedStatus: { ...state.backendDataIsLoadedStatus, onlinerSkills: true },
      isBackendDataLoading: isAllBackendDataLoading({...state.backendDataIsLoadedStatus, onlinerSkills: true })
    })),
    on(UserSkillsActions.setSkills, (state, { skills }) => ({
      ...state,
      skills: [...skills].map((s) => ({
          ...s,
          name: s.name != null ? s.name : "",
      })),
      backendDataIsLoadedStatus: { ...state.backendDataIsLoadedStatus, skills: true },
      isBackendDataLoading: isAllBackendDataLoading({...state.backendDataIsLoadedStatus, skills: true })
    })),
    on(UserSkillsActions.setUserSkillsExpertiseTypes, (state, { expertiseTypes }) => ({
      ...state,
      expertiseTypes: [...expertiseTypes].map((s) => ({
          ...s,
          name: s.name != null ? s.name : "",
      })),
      backendDataIsLoadedStatus: { ...state.backendDataIsLoadedStatus, expertiseTypes: true },
      isBackendDataLoading: isAllBackendDataLoading({...state.backendDataIsLoadedStatus, expertiseTypes: true })
    })),
    on(UserSkillsActions.addUserSkillExpertiseType, (state, { expertiseType }) => ({
      ...state,
      expertiseTypes: [...state.expertiseTypes, <RAttribute>{
        id: expertiseType.id,
        name: expertiseType.name,
        aliasId: 0,
        value: null,
        attributeTypeId: 9,
        isActive: true
      }]
    })),
    on(UserSkillsActions.addOnlinerSkill, (state, { userId, competencySkillCategory }) => {
      let defaultExpertiseLevelId = -1;

      let updatedSkills = state.onlinerSkills != null ? [...state.onlinerSkills] : [];
      const newSkills = updatedSkills.filter(i => i.id === -1);
      updatedSkills.push(
        {
          ...new OnlinerSkill(
            -1,
            competencySkillCategory.skill,            
            competencySkillCategory.skillId,
            "",
            userId,
            new Date(),
            false,
            false,            
            -1,
            null,
            -1,
            defaultExpertiseLevelId,
            competencySkillCategory.competency,
            competencySkillCategory.competencyId,
            competencySkillCategory.category,
            competencySkillCategory.categoryId
          ),
          tempId: newSkills.length + 1
      }
      );

      return {
        ...state,
        onlinerSkills: updatedSkills
      }
    }),
    on(UserSkillsActions.removeOnlinerSkill, (state, { onlinerSkillToDelete }) => {
      let updatedOnlinerSkills = [...state.onlinerSkills];
      let indexToUpdate = findIndex(updatedOnlinerSkills, (i) =>
        i.skillId == onlinerSkillToDelete.skillId &&
        i.competencyId == onlinerSkillToDelete.competencyId &&
        i.categoryId == onlinerSkillToDelete.categoryId &&
        i.id != -1 && i.id == onlinerSkillToDelete.id
      );

      if (indexToUpdate > -1) {
        updatedOnlinerSkills[indexToUpdate] = {
          ...updatedOnlinerSkills[indexToUpdate],
          isDelete: true
        };
      }

      updatedOnlinerSkills = updatedOnlinerSkills.filter(i => {
        if (i.skillId == onlinerSkillToDelete.skillId &&
          i.competencyId == onlinerSkillToDelete.competencyId &&
          i.categoryId == onlinerSkillToDelete.categoryId &&
          i.id == -1 && i.tempId == onlinerSkillToDelete.tempId
        ) {
          return null;
        }

        return i;
      });

      return {
        ...state,
        onlinerSkills: updatedOnlinerSkills
      }
    }),
    on(UserSkillsActions.updateExpertiseType, (state, { onlinerSkillToUpdate, newExpertiseType }) => {
      let updatedOnlinerSkills = [...state.onlinerSkills];
      let indexToUpdate = findIndex(updatedOnlinerSkills, (i) =>
        i.skillId == onlinerSkillToUpdate.skillId &&
        i.competencyId == onlinerSkillToUpdate.competencyId &&
        i.categoryId == onlinerSkillToUpdate.categoryId &&
        (
          (i.id != -1 && i.id == onlinerSkillToUpdate.id) ||
          (i.id == -1 && i.tempId == onlinerSkillToUpdate.tempId)
        )
      );

      if (indexToUpdate > -1) {
        updatedOnlinerSkills[indexToUpdate] = {
          ...updatedOnlinerSkills[indexToUpdate],
          expertiseTypeId: newExpertiseType.id,
          expertiseType: newExpertiseType
        };
      }

      return {
        ...state,
        onlinerSkills: updatedOnlinerSkills
      }
    }),
    on(UserSkillsActions.saveOtherExpertiseTypeDone, (state, { onlinerSkillToUpdate, newExpertiseType }) => {
      let updatedExpertiseTypes = [...state.expertiseTypes, newExpertiseType];
      let updatedOnlinerSkills = [...state.onlinerSkills];
      let indexToUpdate = findIndex(updatedOnlinerSkills, (i) =>
        i.skillId == onlinerSkillToUpdate.skillId &&
        i.competencyId == onlinerSkillToUpdate.competencyId &&
        i.categoryId == onlinerSkillToUpdate.categoryId &&
        (
          (i.id != -1 && i.id == onlinerSkillToUpdate.id) ||
          (i.id == -1 && i.tempId == onlinerSkillToUpdate.tempId)
        )
      );

      if (indexToUpdate > -1) {
        updatedOnlinerSkills[indexToUpdate] = {
          ...updatedOnlinerSkills[indexToUpdate],
          expertiseTypeId: newExpertiseType.id,
          expertiseType: newExpertiseType
        };
      }

      return {
        ...state,
        onlinerSkills: updatedOnlinerSkills,
        expertiseTypes: updatedExpertiseTypes
      }
    }),
    on(UserSkillsActions.saveGroupExpertiseTypeDone, (state, { newExpertiseType }) => {
      let updatedExpertiseTypes = [...state.expertiseTypes, newExpertiseType];

      return {
        ...state,
        expertiseTypes: updatedExpertiseTypes
      }
    }),
    on(UserSkillsActions.setBackendDataIsLoadedStatusToFailed, (state, { dataName }) => ({
      ...state,
      backendDataIsLoadedStatus: {
        ...state.backendDataIsLoadedStatus,
        [dataName]: false
      }
    })),
    on(UserSkillsActions.cancelOnlinerSkillChanges, (state) => ({
              ...state,
              onlinerSkills: state.originalOnlinerSkills
    })),
    on(UserSkillsActions.saveSkills, (state) => ({
      ...state,
      isSaving: true
    })),
    on(UserSkillsActions.saveSkillsDone, (state, { onlinerSkills }) => ({
      ...state,
        onlinerSkills: [...onlinerSkills]
            .map((s) => ({
                ...s,
                skill: {
                    ...s.skill,
                    name: s.skill != null ? s.skill.name : ""
                },
                competency: {
                    ...s.competency,
                    name: s.competency != null ? s.competency.name : ""
                },
                category: {
                    ...s.category,
                    name: s.category != null ? s.category.name : ""
                },
                expertiseType: {
                    ...s.expertiseType,
                    name: s.expertiseType != null ? s.expertiseType.name : ""
                },
                expertiseLevel: {
                    ...s.expertiseLevel,
                    name: s.expertiseLevel != null ? s.expertiseLevel.name : ""
                }
            }))
            .sort((a, b) => {
                if (a.skill.name.toLowerCase() == b.skill.name.toLowerCase()) {
                    if (a.expertiseType.name == b.expertiseType.name) {
                        return a.id < b.id ? -1 : 1;
                    }

                    return a.expertiseType.name < b.expertiseType.name ? -1 : 1;
                }

                return a.skill.name.toLowerCase() < b.skill.name.toLowerCase() ? -1 : 1;
            }),
        originalOnlinerSkills: [...onlinerSkills]
            .map((s) => ({
                ...s,
                skill: {
                    ...s.skill,
                    name: s.skill != null ? s.skill.name : ""
                },
                competency: {
                    ...s.competency,
                    name: s.competency != null ? s.competency.name : ""
                },
                category: {
                    ...s.category,
                    name: s.category != null ? s.category.name : ""
                },
                expertiseType: {
                    ...s.expertiseType,
                    name: s.expertiseType != null ? s.expertiseType.name : ""
                },
                expertiseLevel: {
                    ...s.expertiseLevel,
                    name: s.expertiseLevel != null ? s.expertiseLevel.name : ""
                }
            }))
            .sort((a, b) => {
                if (a.skill.name.toLowerCase() == b.skill.name.toLowerCase()) {
                    if (a.expertiseType.name == b.expertiseType.name) {
                        return a.id < b.id ? -1 : 1;
                    }

                    return a.expertiseType.name < b.expertiseType.name ? -1 : 1;
                }

                return a.skill.name.toLowerCase() < b.skill.name.toLowerCase() ? -1 : 1;
            }),
      isSaving: false
    })),
    on(UserSkillsActions.setIsSaving, (state, { value }) => ({
      ...state,
      isSaving: value
    })),
    // START FILTER-RELATED REDUCER
    on(UserSkillsActions.setSkillNameFilterValue, (state, { value, resetFilterResult = false }) => ({
      ...state,
      filter: {
        ...state.filter,
        skillName: value,
        resetSkillFilterResult: resetFilterResult
      }
    })),
    on(UserSkillsActions.setExpertiseTypeFilterValue, (state, { value, resetFilterResult = false }) => ({
      ...state,
      filter: {
        ...state.filter,
        expertiseTypeName: value,
        resetExpertiseTypeName: resetFilterResult
      },
    })),
    on(UserSkillsActions.resetExpertiseTypeFilterValue, (state) => ({
      ...state,
      filter: initialState.filter
    })),
    on(UserSkillsActions.resetSkillFilterValue, (state) => ({
      ...state,
      filter: initialState.filter
    })),
    // END FILTER-RELATED REDUCER
    on(UserSkillsActions.resetStore, (state) => ({
      ...state,
      ...initialState
    }))
  )
});