import { AltOnlinerDefault } from './../data-models/AltOnlinerDefault';
import {throwError, of , Observable, BehaviorSubject} from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';

import {AuthorizedHttp} from './authorized-http.service';
import { AccountingEmployee, DirectManager, EmployeeHome, OnlinerLanguage, OnlinerProfile, RegionDirector } from '../data-models/commonDataModels';
import { CommonService } from "./common.service";


@Injectable()
export class UserService {

  private apiEndpoint: string = `${environment.apiEndpoint}/api/user`;
  private altOnlinerDefault: AltOnlinerDefault;
  private _accountingEmployees: AccountingEmployee[];
  private _profile: OnlinerProfile;
  private _pronouns: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(private http: AuthorizedHttp, private commonService: CommonService) {
    const headers: any = {
        'Content-Type': 'application/json; charset=utf-8', /*or whatever type is relevant */
        Accept: 'application/json'
    }
  }

  public getMyProfilePhoto(): Observable<any> {
    return this.http
      .getText(`${this.apiEndpoint}/myphoto`)
      .pipe(
        catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
      );
    }

  public getOnlinerHomeLocation(userId: string): Observable<EmployeeHome> {
    userId = this.commonService.encrypt(userId);
    return this.http
      .get(`${this.apiEndpoint}/getOnlinerHomeLocation/${userId}`)
      .pipe(
        map(result => {          
          return result;
        }),
          catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error')) 
        );
  }

  public getOnlinerProfileDefault(userId: string): Observable<OnlinerProfile> {
    userId = this.commonService.encrypt(userId);
    return this.http
      .get(`${this.apiEndpoint}/getOnlinerProfileDefault/${userId}`)
      .pipe(
        map(result => {          
          return result;
        }),
          catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error')) 
        );
  }

  public getOnlinerSpokenLangDefault(userId: string): Observable<OnlinerLanguage[]> {
    userId = this.commonService.encrypt(userId);
    return this.http
      .get(`${this.apiEndpoint}/getOnlinerSpokenLangDefault/${userId}`)
      .pipe(
        map(result => {          
          return result;
        }),
          catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error')) 
        );
  }

  public getOnlinerWrittenLangDefault(userId: string): Observable<OnlinerLanguage[]> {
    userId = this.commonService.encrypt(userId);
    return this.http
      .get(`${this.apiEndpoint}/getOnlinerWrittenLangDefault/${userId}`)
      .pipe(
        map(result => {          
          return result;
        }),
          catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error')) 
        );
  }

   public getDirectors(): Observable<RegionDirector[]> {
        return this.http.get(`${this.apiEndpoint}/DirectorList`).pipe(
            map((result) => {
                return result;
            }),
            catchError((errorResponse: any) =>
                throwError(errorResponse.error.exceptionMessage || "Server error")
            )
        );
   }

   public getDirectManager(): Observable<DirectManager[]> {
    return this.http.get(`${this.apiEndpoint}/DirectManagerList`).pipe(
        map((result) => {
            return result;
        }),
        catchError((errorResponse: any) =>
            throwError(errorResponse.error.exceptionMessage || "Server error")
        )
    );
}

   public getAccountingEmployees(): Observable<AccountingEmployee[]> {
        return this.http.get(`${this.apiEndpoint}/AccountingEmployees`).pipe(
            map((result) => {
                this._accountingEmployees = result;
                return result;
            }),
            catchError((errorResponse: any) =>
                throwError(errorResponse.error.exceptionMessage || "Server error")
            )
        );
   }

   public useAccountingEmployees(): AccountingEmployee[] {
       return this._accountingEmployees;
   }

   public getPronouns(): Observable<string> {
       return this._pronouns.asObservable();
   }
    
   public setProfile(profile: OnlinerProfile): void {
       this._profile = profile;
       const newPronouns = this._profile != null && this._profile.pronoun != null && this._profile.pronoun.name !== '' ? "(" + this._profile.pronoun.name + ")" : "";
       this._pronouns.next(newPronouns);
   }

  public upsertOnlinerProfileDefault(profileModel: OnlinerProfile): Observable<OnlinerProfile> {
     return this.http
      .post(`${this.apiEndpoint}/upsertOnlinerProfileDefault`, JSON.stringify(profileModel))
      .pipe(
        map(result => { 
            if (profileModel.userId.toLowerCase() == this._profile.userId.toLowerCase()) {
                this.setProfile(profileModel);
            }
            return result;
        }),
          catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error')) 
        );
  }

  public upsertOnlinerSpokenLangDefault(languageModel: OnlinerLanguage[]): Observable<OnlinerLanguage[]> {
    return this.http
      .post(`${this.apiEndpoint}/upsertOnlinerSpokenLangDefault`, JSON.stringify(languageModel))
      .pipe(
        map(result => {          
          return result;
        }),
          catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error')) 
        );
  }

  public upsertOnlinerWrittenLangDefault(languageModel: OnlinerLanguage[]): Observable<OnlinerLanguage[]> {
    return this.http
      .post(`${this.apiEndpoint}/upsertOnlinerWrittenLangDefault`, JSON.stringify(languageModel))
      .pipe(
        map(result => {          
          return result;
        }),
          catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error')) 
        );
  }

  public getAltOnlinerDefault(): Observable<AltOnlinerDefault> {
    return this.http
      .get(`${this.apiEndpoint}/getAltOnlinerDefault`)
      .pipe(
        map(result => {          
          return result;
        }),
        catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))        
      );
  }

  public postAltOnlinerDefault(altOnlinerDefault: AltOnlinerDefault): Observable<AltOnlinerDefault> {
    return this.http
        .post(`${this.apiEndpoint}/addAltOnlinerDefault`, JSON.stringify(altOnlinerDefault))
        .pipe(
          map(result => {
            this.altOnlinerDefault = result;
            return this.altOnlinerDefault;
          }),
          catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
        );
  }

  public updateAltOnlinerDefault(altOnlinerDefault: AltOnlinerDefault): Observable<AltOnlinerDefault> {
    return this.http
        .patch(`${this.apiEndpoint}/updateAltOnlinerDefault`, JSON.stringify(altOnlinerDefault))
        .pipe(
          map(result => {
            this.altOnlinerDefault = result;
            return this.altOnlinerDefault;
          }),
          catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
        );
  }
}
