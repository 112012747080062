<div @fadeInUp class="card mb-3">
  <div class="px-6 py-4" [@.disabled]="disableAnimation">
    <mat-expansion-panel [disabled]="true"  [expanded]="panelOpenState" class="no-pad" (closed)="closePanel(firstName, lastName, comments)" (opened)="panelOpenState = true">
      <mat-expansion-panel-header (click)="togglePanel(firstName, lastName)" class="p-0" *ngIf="!panelOpenState">
        <mat-panel-title>
          <p>
            <span class="font-bold" *ngIf="nonOnliner.userId">{{ nonOnliner.lastName + ', ' + nonOnliner.firstName}} </span>
            <br>
            <span *ngIf="nonOnliner.modifiedDate">{{ getLastModified(nonOnliner.modifiedDate) }} </span>
          </p>
          <span *ngIf="nonOnliner.userId == altOnlinerDefault" fxFlexAlign="center"><i class="fas fa-user fa-3"></i></span>
          <button *ngIf="!nonOnliner.isOnliner" matTooltip="Delete Non Onliner" (click)="deleteNonOnliner($event)">
            <i class="fas fa-trash fa-3"></i>
          </button>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div *ngIf="panelOpenState" fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
          <mat-form-field fxFlex="auto">
              <mat-label>First Name</mat-label>
              <input type="text"
                     matInput
                     placeholder="Enter First Name"
                     [formControl]="firstName"
                     (ngModelChange)="onFirstNameChange($event, firstName)"
                     name="nonOnliner.firstName"
                     required>
              <mat-error>Non Onliner First Name is required</mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="auto">
              <mat-label>Last Name</mat-label>
              <input matInput
                     [formControl]="lastName"
                     placeholder="Enter Last Name"
                     (ngModelChange)="onLastNameChange($event, lastName)"
                     name="nonOnliner.lastName"
                     required>
              <mat-error>Non Onliner Last Name is required</mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="auto">
              <mat-label>Accounting User Id</mat-label>
              <input type="text"
                     matInput
                     [formControl]="accountingUserId"
                     [matAutocomplete]="auto"
                     placeholder="Select Accounting User Id"
                     name="nonOnliner.firstName + ' ' + nonOnliner.lastName + ' - ' + nonOnliner.accountingUserId">
              <i class="material-icons" matSuffix>keyboard_arrow_down</i>
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onAccountingUserIdChange($event)">
                  <mat-option *ngFor="let empl of filteredAccountingEmployees" [value]="empl" [disabled]="isEmployeeUsed(empl)">
                      {{ empl.accountingUserId }}
                  </mat-option>
              </mat-autocomplete>
          </mat-form-field>
      </div>
      <div *ngIf="panelOpenState" fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
        <mat-form-field fxFlex="auto">
          <mat-label>Comments</mat-label>            
          <textarea matInput cdkTextareaAutosize
                    [formControl]="comments"
                    (ngModelChange)="onCommentsChange($event, comments)"
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="5"
                    maxlength={{nonOnlinerCommentsFieldLimit}}
                    style="overflow:hidden" name="nonOnliner.comments" ></textarea>
          <span style='float:right' >{{nonOnlinerCommentsFieldLimit - nonOnliner.comments.length}} chars left</span>
        </mat-form-field>
      </div>
      <mat-action-row *ngIf="panelOpenState">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" class="clearfix">
          <button *ngIf="nonOnliner.userId" class="mat-raised-button mat-button-base mt-3 bg-green-500 text-contrast-white" mat-raised-button
          type="button" [disabled]="nonOnliner.userId == '' || nonOnliner.userId == altOnlinerDefault"  (click)="makeDefault()">
          <span *ngIf="isSubmitting"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span>{{ 'Make Default' | translate }}</button>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px" class="clearfix">
            <button class="mat-raised-button mat-button-base mt-3 bg-gray-500 text-contrast-black" mat-raised-button
            type="button" (click)="cancelButton(firstName, lastName, comments)">{{ 'cancel' | translate }}</button>
            <button class="mat-raised-button mat-button-base mt-3 bg-green-500 text-contrast-white" mat-raised-button
            type="button" [disabled]="(!firstName.dirty && !lastName.dirty && !comments.dirty && !accountingUserId.dirty) || (nonOnlinerCommentsFieldLimit - nonOnliner.comments.length < 0) || 
            nonOnliner.firstName.length  === 0 || nonOnliner.lastName.length  === 0"  (click)="save(firstName, lastName, comments)">
            <span *ngIf="isSubmitting"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span>{{ 'save' | translate }}</button>            
        </div>
      </mat-action-row>
    </mat-expansion-panel>
  </div>
</div>