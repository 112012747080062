import { Component, Inject, LOCALE_ID, Renderer2, ChangeDetectorRef, OnDestroy, OnInit, HostListener } from '@angular/core';
import { ConfigService } from '../@vex/services/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@vex/services/navigation.service';
import { LayoutService } from '../@vex/services/layout.service';
import { ActivatedRoute } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { Style, StyleService } from '../@vex/services/style.service';
import { ConfigName } from '../@vex/interfaces/config-name.model';

import { Observable, Subject, Subscription, interval } from 'rxjs';
import { AuthService } from './common/services/auth.service';
import { MediaMatcher } from '@angular/cdk/layout';

import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { menuItems } from 'src/static-data/menu-items';
import { NavigationItem } from 'src/@vex/interfaces/navigation-item.interface';
import { UtilityService } from './common/services/utility.service';

import { Router } from '@angular/router';
import { environment } from "src/environments/environment";
import { MatDialog } from '@angular/material/dialog';

import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';

import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Online Business Systems';
  subscription: Subscription;
  public translatedText: string;
  loading: boolean = true;
  public supportedLanguages = ['en', 'fr', 'de', 'es','pt'];
  isAuthenticated: boolean = false;

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  showMainNav: boolean = false;

  sub: Subscription;

  private readonly unsubscribe = new Subject<void>();

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public media: MediaMatcher,
    public translate: TranslateService,
    private authService: AuthService,
    private msalService: MsalService,
    private broadcastService: MsalBroadcastService,
    private configService: ConfigService,
    private styleService: StyleService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService,
    private utilityService: UtilityService,
    private router: Router,
    private dialog: MatDialog) {
    Settings.defaultLocale = this.localeId;

    /*
    * Language Service 
    */

    this.translate.addLangs(this.supportedLanguages);
    this.translate.setDefaultLang('en');
    this.translate.use('en');

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    showConfigButton: false,
     *    footer: {
     *      visible: false
     *    }
     *  });
     */

    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('rtl') && coerceBooleanProperty(queryParamMap.get('rtl')))
    ).subscribe(queryParamMap => {
      this.document.body.dir = 'rtl';
      this.configService.updateConfig({
        rtl: true
      });
    });

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('layout'))
    ).subscribe(queryParamMap => this.configService.setConfig(queryParamMap.get('layout') as ConfigName));

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('style'))
    ).subscribe(queryParamMap => this.styleService.setStyle(queryParamMap.get('style') as Style));


    /**
     * Add your own routes here
     */
    this.navigationService.items = menuItems as NavigationItem[];
  }

  @HostListener('document:keydown', ['$event'])
  canDeactivate(event: KeyboardEvent): boolean {
    if (event.key === 'F5' || (event.key === 'r' && (event.ctrlKey || event.metaKey))) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        window.location.reload();
      });
      return false;
    }
    return true;
  }

  public async ngOnInit(): Promise<void> {
    this.authService.initAuth();
    this.isAuthenticated = this.authService.isAuthenticated();
    
    this.broadcastService.msalSubject$
        .pipe(
            filter((message: EventMessage) => message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
            takeUntil(this.unsubscribe)
        )
        .subscribe(async (message: EventMessage) => {
            const authResult = message.payload as AuthenticationResult;
            this.msalService.instance.setActiveAccount(authResult.account);
            let token = await this.authService.getToken();
            if(token) {
              this.isAuthenticated = this.authService.isAuthenticated();
              this.loading = false;
            }
        });

        this.sub = interval(30000).subscribe((val) => {
        if (this.authService.isSessionExpired) {
          this.authService.logout();
        }

        //if 55min then show dialog to extend session
        if (this.authService.showPopUp()) {
          this.utilityService.showSessionExpiredDialog();
        }
      });
  }

  public ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.sub.unsubscribe();
  }

}
