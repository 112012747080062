import { ResumeDocxBuilderService } from "../../common/services/resume-docx-builder.service";
import { Resume } from "../../common/data-models/Resume";
import { Component, OnInit, Input, Output, ViewChild, EventEmitter, ElementRef } from "@angular/core";
import { iconsFA } from "src/static-data/icons-fa";
import { iconsIC } from "src/static-data/icons-ic";
import { ResumeStatusTypes } from "../../common/services/resume.service";

@Component({
  selector: "obs-resume-preview-action-buttons",
  templateUrl: "./action-buttons.component.html",
  styleUrls: ["./action-buttons.component.scss"]
})
export class ResumePreviewActionButtonsComponent implements OnInit {
  @Input("resume") resume: Resume;
  @Input("originalResume") originalResume: Resume;
  @Input() public disabled: boolean = false;
  @Output() onDownload: EventEmitter<any> = new EventEmitter<any>();
  @Output() onOpen: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPrint: EventEmitter<any> = new EventEmitter<any>();

  public faIcons = iconsFA;
  public icIcons = iconsIC;

  public constructor(
    private _resumeDocxBuilderService: ResumeDocxBuilderService
  ) {}

  ngOnInit(): void {
    
  }

  open() {
    this.onOpen.emit();
  }

  print() {
    this.onPrint.emit();
  }

  downloadPDF() {
    this.onDownload.emit();
  }

  downloadWord() {
      if (this.resume.statusType.name === ResumeStatusTypes.APPROVED) {
          this._resumeDocxBuilderService.download(this.originalResume);
      }
      else {
          this._resumeDocxBuilderService.download(this.resume, this.originalResume);
      }  
  }
}