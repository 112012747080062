import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { environment } from "src/environments/environment";
import { VexRoutes } from "../@vex/interfaces/vex-route.interface";
import { BuildResumeComponent } from "./build-resume/build-resume.component";
import { AuthGuard } from "./common/services/auth-guard.service";
import { PendingChangesGuard } from "./common/services/pending-changes-guard.service";
import { CustomLayoutComponent } from "./custom-layout/custom-layout.component";
import { OnlinerSearchComponent } from "./onliner-search/onliner-search.component";
import { ResumeReviewComponent } from "./resume-review/resume-review.component";
import { ResumesSearchComponent } from "./searchResumes/resumes-search.component";
import { ForbiddenComponent } from "./shared/forbidden/forbidden.component";
import { BuildResumeNOComponent } from "./build-resumeNO/build-resumeNO.component";
import { LogoutComponent } from "./logout/logout.component";
import { AuthGuardLogout } from "./common/services/auth-guard-logout.service";
import { MsalGuard } from "@azure/msal-angular";

const childrenRoutes: VexRoutes = [
  {
    path: "forbidden",
    canActivate: [MsalGuard],
    pathMatch: "full",
    component: ForbiddenComponent,
  },
  {
    path: "",
    redirectTo: "dashboards/analytics",
    pathMatch: "full",
  },
  {
    path: "dashboards/analytics",
    canActivate: [MsalGuard],
    data: { roles: [environment.roles.ResumesUser] },
    loadChildren: () =>
      import(
        "./pages/dashboards/dashboard-analytics/dashboard-analytics.module"
      ).then((m) => m.DashboardAnalyticsModule),
  },
  {
    path: "app/skillSummary",
    canActivate: [AuthGuard, MsalGuard],
    data: { roles: [environment.roles.ResumesUser] },
    loadChildren: () =>
      import("./skillSummary/skillSummary.module").then(
        (m) => m.SkillSummaryModule
      ),
  },
  {
    path: "app/skillSummaryNO",
    canActivate: [AuthGuard, MsalGuard],
    data: { roles: [environment.roles.ResumesRMT] },
    loadChildren: () =>
      import("./skillSummaryNO/skillSummaryNO.module").then(
        (m) => m.SkillSummaryNOModule
      ),
  },
  {
    path: "app/profileSummary",
    canActivate: [AuthGuard, MsalGuard],
    data: { roles: [environment.roles.ResumesUser] },
    loadChildren: () =>
      import("./profileSummary/profileSummary.module").then(
        (m) => m.ProfileSummaryModule
      ),
  },
  {
    path: "app/profileSummaryNO",
    canActivate: [AuthGuard, MsalGuard],
    data: { roles: [environment.roles.ResumesRMT] },
    loadChildren: () =>
      import("./profileSummaryNO/profileSummaryNO.module").then(
        (m) => m.ProfileSummaryNoModule
      ),
  },
  {
    path: "app/associations",
    canActivate: [AuthGuard, MsalGuard],
    data: { roles: [environment.roles.ResumesUser] },
    loadChildren: () =>
      import("./associations/associations.module").then(
        (m) => m.AssociationsModule
      ),
  },
  {
    path: "app/associationsNO",
    canActivate: [AuthGuard, MsalGuard],
    data: { roles: [environment.roles.ResumesRMT] },
    loadChildren: () =>
      import("./associationsNO/associationsNO.module").then(
        (m) => m.AssociationsNOModule
      ),
  },
  {
    path: "app/build-resume",
    component: BuildResumeComponent,
    canDeactivate: [PendingChangesGuard],
    canActivate: [AuthGuard, MsalGuard],
    data: { roles: [environment.roles.ResumesUser] },
    children: [
      { path: ":id", component: BuildResumeComponent, pathMatch: "full" },
    ],
  },
  {
    path: "app/build-resumeNO",
    component: BuildResumeNOComponent,
    canDeactivate: [PendingChangesGuard],
    canActivate: [AuthGuard, MsalGuard],
    data: { roles: [environment.roles.ResumesRMT] },
    children: [
      { path: ":id", component: BuildResumeNOComponent, pathMatch: "full" },
    ],
  },
  {
    path: "app/projects",
    canActivate: [AuthGuard, MsalGuard],
    data: { roles: [environment.roles.ResumesUser] },
    loadChildren: () =>
      import("./projects/projects.module").then((m) => m.ProjectsModule),
  },
  {
    path: "app/projectsNO",
    canActivate: [AuthGuard, MsalGuard],
    data: { roles: [environment.roles.ResumesRMT] },
    loadChildren: () =>
      import("./projectsNO/projectsNO.module").then((m) => m.ProjectsNOModule),
  },
  {
    path: "app/certificationApprovalsOnliner",
    canActivate: [AuthGuard, MsalGuard],
    data: { roles: [environment.roles.ResumesUser] },
    loadChildren: () =>
      import("./educationApprovals/educationApprovals.module").then(
        (m) => m.EducationApprovalsModule
      ),
  },
  {
    path: "app/certificationApprovals",
    canActivate: [AuthGuard, MsalGuard],
    data: { roles: [environment.roles.ResumesPMT] },
    loadChildren: () =>
      import("./educationApprovals/educationApprovals.module").then(
        (m) => m.EducationApprovalsModule
      ),
  },
  {
    path: "app/education",
    canActivate: [AuthGuard, MsalGuard],
    data: { roles: [environment.roles.ResumesUser] },
    loadChildren: () =>
      import("./userEducation/userEducation.module").then(
        (m) => m.UserEducationModule
      ),
  },
  {
    path: "app/educationNO",
    canActivate: [AuthGuard, MsalGuard],
    data: { roles: [environment.roles.ResumesUser] },
    loadChildren: () =>
      import("./userEducationNO/userEducationNO.module").then(
        (m) => m.UserEducationNOModule
      ),
  },
  {
    path: "app/skillsNO",
    canActivate: [AuthGuard, MsalGuard],
    data: { roles: [environment.roles.ResumesRMT] },
    loadChildren: () =>
      import("./skillsNO/skillsNO.module").then((m) => m.SkillsNOModule),
  },
  {
    path: "app/skills",
    canActivate: [AuthGuard, MsalGuard],
    data: { roles: [environment.roles.ResumesUser] },
    loadChildren: () =>
      import("./skills-new/skills.module").then((m) => m.SkillsModule),
  },
  {
    path: "app/attributeApprovals",
    canActivate: [AuthGuard, MsalGuard],
    data: { roles: [environment.roles.ResumesAdmin] },
    loadChildren: () =>
      import("./attribute-approvals/attribute-approvals.module").then(
        (m) => m.AttributeApprovalsModule
      ),
  },
  {
    path: "app/competencyMaintenance",
    canActivate: [AuthGuard, MsalGuard],
    data: {
      roles: [environment.roles.ResumesRMT, environment.roles.ResumesAdmin],
    },
    loadChildren: () =>
      import("./competency-maintenance/competency-maintenance.module").then(
        (m) => m.CompetencyMaintenanceModule
      ),
  },
  {
    path: "app/skillsMaintenance",
    canActivate: [AuthGuard, MsalGuard],
    data: {
      roles: [environment.roles.ResumesRMT, environment.roles.ResumesAdmin],
    },
    loadChildren: () =>
      import("./skills-maintenance/skills-maintenance.module").then(
        (m) => m.SkillsMaintenanceModule
      ),
  },
  {
    path: "app/requestReview",
    canActivate: [AuthGuard, MsalGuard],
    data: { roles: [environment.roles.ResumesUser] },
    loadChildren: () =>
      import("./request-review/request-review.module").then(
        (m) => m.RequestReviewModule
      ),
  },
  {
    path: "app/settings",
    canActivate: [AuthGuard, MsalGuard],
    data: { roles: [environment.roles.ResumesUser] },
    loadChildren: () =>
      import("./user-dropdown-options/user-dropdown-options.module").then(
        (m) => m.UserDropdownOptionsModule
      ),
  },
  {
    path: "app/workflows",
    canActivate: [AuthGuard, MsalGuard],
    data: {
      roles: [environment.roles.ResumesCM, environment.roles.ResumesRMT],
    },
    loadChildren: () =>
      import("./workflows/workflows.module").then((m) => m.WorkflowsModule),
  },
  {
    path: "app/attributeMaintenance",
    canActivate: [AuthGuard, MsalGuard],
    data: { roles: [environment.roles.ResumesAdmin] },
    loadChildren: () =>
      import("./attribute-maintenance/attribute-maintenance.module").then(
        (m) => m.AttributeMaintenanceModule
      ),
  },
  {
    path: "app/resume-review/:id",
    component: ResumeReviewComponent,
    canDeactivate: [PendingChangesGuard],
    canActivate: [AuthGuard, MsalGuard],
    data: {
      roles: [environment.roles.ResumesCM, environment.roles.ResumesRMT],
    },
  },
  {
    path: "app/searchResumes",
    component: ResumesSearchComponent,
    // canDeactivate:[PendingChangesGuard], TODO might not need this
    canActivate: [AuthGuard, MsalGuard],
    data: {
      roles: [
        environment.roles.ResumesMarketing,
        environment.roles.ResumesCM,
        environment.roles.ResumesSales,
        environment.roles.ResumesAdmin,
        environment.roles.ResumesRMT,
      ],
    },
  },
  {
    path: "app/searchOnliners",
    component: OnlinerSearchComponent,
    canActivate: [AuthGuard, MsalGuard],
    data: {
      roles: [
        environment.roles.ResumesMarketing,
        environment.roles.ResumesCM,
        environment.roles.ResumesSales,
        environment.roles.ResumesAdmin,
        environment.roles.ResumesRMT,
      ],
    },
  },
  {
    path: "app/alternateOnlinerMaintenance",
    canActivate: [AuthGuard, MsalGuard],
    data: { roles: [environment.roles.ResumesUser] },
    loadChildren: () =>
      import(
        "./alternateOnlinerMaintenance/alternateOnlinerMaintenance.module"
      ).then((m) => m.AlternateOnlinerMaintenanceModule),
  },
  {
    path: "app/logout",
    canActivate: [AuthGuardLogout],
    component: LogoutComponent,
  },
  {
    path: "**",
    loadChildren: () =>
      import("./pages/pages/errors/error-404/error-404.module").then(
        (m) => m.Error404Module
      ),
  },
];

const routes: Routes = [
  {
    path: "",
    redirectTo: "dashboards/analytics",
    pathMatch: "full",
  },
  {
    path: "",
    component: CustomLayoutComponent,
    children: childrenRoutes,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "corrected",
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
