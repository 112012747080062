import { Injectable } from '@angular/core';
import { Observable, of, throwError, Subject } from 'rxjs';
import { AuthorizedHttp } from './authorized-http.service';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { RSkill } from '../data-models/RSkill';
import { RAttribute } from '../data-models/commonDataModels';
import { CommonService } from "./common.service";

@Injectable()
export class SkillsNOService {
  private _apiEndpoint = `${environment.apiEndpoint}/api/skillsNO`;
  private _skills: RSkill[];
  private _skill: RSkill;
  private _isDeleted: boolean;

  constructor(private http: AuthorizedHttp, private commonService: CommonService) {
    const headers: any = {
        'Content-Type': 'application/json; charset=utf-8', /*or whatever type is relevant */
        Accept: 'application/json'
    }
  }

  public getRSkills(userId: string): Observable<RSkill[]> {
    userId = this.commonService.encrypt(userId);
    return this.http
        .get(`${this._apiEndpoint}/mySkillsNO/` + userId)
        .pipe(
          map(result => {
            this._skills = result;
            return this._skills;
          }),
          catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
        );
    }

    public postNewRSkill(skillModel: RSkill, userId: string): Observable<RSkill> {
      userId = this.commonService.encrypt(userId);
      return this.http
          .post(`${this._apiEndpoint}/addNewSkillNO/`+ userId, JSON.stringify(skillModel))
          .pipe(
            map(result => {
              this._skill = result;
              return this._skill;
            }),
            catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
          );
    }

    public updateRSkill(skillModel: RSkill, userId: string): Observable<RSkill> {
      userId = this.commonService.encrypt(userId);
      return this.http
          .patch(`${this._apiEndpoint}/updateSkillNO/` + userId, JSON.stringify(skillModel))
          .pipe(
            map(result => {
              this._skill = result;
              return this._skill;
            }),
            catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
          );
    }

    public deleteRSkill(skillModel: RSkill, userId: string): Observable<RSkill> {
      userId = this.commonService.encrypt(userId);
        return this.http
            .patch(`${this._apiEndpoint}/deleteSkillNO/` + userId, JSON.stringify(skillModel))
            .pipe(
                map(result => {
                    this._skill = result;
                    return this._skill;
                }),
                catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
            );
    }

    public getSkills(): Observable<any> {
      return this.http
        .get(this._apiEndpoint)
        .pipe(
          map(result => {
            return result;
          }),
          catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
        );
    }

    public insertAndDeleteSkills(
      userId: string,
      skillsToSave: any[],
    ): Observable<any[]> {
      userId = this.commonService.encrypt(userId);
      return this.http.put(
        `${this._apiEndpoint}/insertAndDeleteSkills/${userId}`, 
        skillsToSave
      ).pipe(
        map((result) => {
          return result;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
    }

    public addNewSkill(
      userId: string,
      skill: RAttribute,
    ): Observable<RAttribute> {
      userId = this.commonService.encrypt(userId);
      return this.http.post(
        `${this._apiEndpoint}/newSkill/${userId}`, 
        JSON.stringify(skill)
      ).pipe(
        map((result) => {
          return result;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
    }

    public getSkillByName(value: string): Observable<RAttribute> {
      return this.http.get(
        `${this._apiEndpoint}/skillByName/${value}`
      ).pipe(
        map((result) => {
          return result;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
    }
}
