import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatIconModule } from "@angular/material/icon";
import { MatExpansionModule } from "@angular/material/expansion";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IconModule } from "@visurel/iconify-angular";
import { SecondaryToolbarModule } from "src/@vex/components/secondary-toolbar/secondary-toolbar.module";
import { BreadcrumbsModule } from "src/@vex/components/breadcrumbs/breadcrumbs.module";
import { MatButtonModule } from "@angular/material/button";
import { PageLayoutModule } from "src/@vex/components/page-layout/page-layout.module";
import { ContainerModule } from "src/@vex/directives/container/container.module";
import { TranslateModule } from "@ngx-translate/core";
import { MatInputModule } from "@angular/material/input";
import { MatDialogModule } from "@angular/material/dialog";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatListModule } from "@angular/material/list";
import { MatCardModule } from "@angular/material/card";
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HtmlDecodeModule } from 'src/@vex/pipes/html-decode/html-decode.module';
import { UriDecodeModule } from 'src/@vex/pipes/uri-decode/uri-decode.module';
import { SkillDescriptionModal } from './skill-description-modal.component';
import { UriDecodePipe } from 'src/@vex/pipes/uri-decode/uri-decode.pipe';

@NgModule({
  declarations: [
    SkillDescriptionModal
  ],
  imports: [
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    FlexLayoutModule,
    MatDialogModule,
    MatIconModule,
    MatExpansionModule,
    MatInputModule,
    IconModule,
    SecondaryToolbarModule,
    BreadcrumbsModule,
    MatButtonModule,
    PageLayoutModule,
    ContainerModule,
    TranslateModule,    
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatListModule,
    MatCardModule,
    HtmlDecodeModule,
    UriDecodeModule,
  ],
  exports: [SkillDescriptionModal],
  providers: [
    UriDecodePipe,
  ],
})
export class SkillDescriptionModalModule {}

