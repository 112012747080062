import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { MenuItem } from '../interfaces/menu-item.interface';
import { trackById } from '../../../utils/track-by';
import icPerson from '@iconify-icons/ic/twotone-person';
import icSettings from '@iconify-icons/ic/twotone-settings';
import icAccountCircle from '@iconify-icons/ic/twotone-account-circle';
import icMoveToInbox from '@iconify-icons/ic/twotone-move-to-inbox';
import icListAlt from '@iconify-icons/ic/twotone-list-alt';
import icTableChart from '@iconify-icons/ic/twotone-table-chart';
import icCheckCircle from '@iconify-icons/ic/twotone-check-circle';
import icAccessTime from '@iconify-icons/ic/twotone-access-time';
import icDoNotDisturb from '@iconify-icons/ic/twotone-do-not-disturb';
import icOfflineBolt from '@iconify-icons/ic/twotone-offline-bolt';
import icChevronRight from '@iconify-icons/ic/twotone-chevron-right';
import icArrowDropDown from '@iconify-icons/ic/twotone-arrow-drop-down';
import icBusiness from '@iconify-icons/ic/twotone-business';
import icVerifiedUser from '@iconify-icons/ic/twotone-verified-user';
import icLock from '@iconify-icons/ic/twotone-lock';
import icNotificationsOff from '@iconify-icons/ic/twotone-notifications-off';
import { Icon } from '@visurel/iconify-angular';
import { PopoverRef } from '../../popover/popover-ref';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/common/services/auth.service';
import { environment } from 'src/environments/environment';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { NavigationService } from 'src/@vex/services/navigation.service';
import { Router } from "@angular/router";

export interface OnlineStatus {
  id: 'online' | 'away' | 'dnd' | 'offline';
  label: string;
  icon: Icon;
  colorClass: string;
}

@Component({
  selector: 'vex-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserDropdownComponent implements OnInit {
  @Input() isSlideChecked: boolean;

  items: MenuItem[] = [
    {
      id: '1',
      icon: icAccountCircle,
      label: this.translate.instant('myProfile'),
      description: this.translate.instant('personalInformation'),
      colorClass: 'text-teal-500',
      route: '/pages/profile'
    },
    {
      id: '2',
      icon: icMoveToInbox,
      label: this.translate.instant('myInbox'),
      description: this.translate.instant('messageAndLatestNews'),
      colorClass: 'text-primary-500',
      route: '/apps/chat'
    },
    {
      id: '3',
      icon: icListAlt,
      label: this.translate.instant('myFeedback'),
      description: this.translate.instant('myFeedbackSubtitle'),
      colorClass: 'text-amber-500',
      route: '/apps/feedback'
    },
    {
      id: '4',
      icon: icTableChart,
      label: this.translate.instant('myResume'),
      description: this.translate.instant('myResumeSubtitle'),
      colorClass: 'text-purple-500',
      route: '/apps/resume'
    }
  ];

  statuses: OnlineStatus[] = [
    {
      id: 'online',
      label: 'Online',
      icon: icCheckCircle,
      colorClass: 'text-green-500'
    },
    {
      id: 'away',
      label: 'Away',
      icon: icAccessTime,
      colorClass: 'text-orange-500'
    },
    {
      id: 'dnd',
      label: 'Do not disturb',
      icon: icDoNotDisturb,
      colorClass: 'text-red-500'
    },
    {
      id: 'offline',
      label: 'Offline',
      icon: icOfflineBolt,
      colorClass: 'text-gray-500'
    }
  ];

  activeStatus: OnlineStatus = this.statuses[0];

  trackById = trackById;
  icPerson = icPerson;
  icSettings = icSettings;
  icChevronRight = icChevronRight;
  icArrowDropDown = icArrowDropDown;
  icBusiness = icBusiness;
  icVerifiedUser = icVerifiedUser;
  icLock = icLock;
  icNotificationsOff = icNotificationsOff;
  public isRMTRole = false;
  public nonOnlinerToolTip: string;
  public collapsed: boolean = false;
  defaultOnliner: string;

  constructor(public translate: TranslateService,
    private authService: AuthService,
    private cd: ChangeDetectorRef,
    private popoverRef: PopoverRef<Boolean>,
    private router: Router,
    private navigationService: NavigationService) { }

  public ngOnInit() {
    if (this.authService.getUserRoles()) {
        if (this.authService.getUserRoles().includes(environment.roles.ResumesRMT)) {
            this.isRMTRole = true;
        }
    }
    
    if (typeof this.popoverRef.data["isSlideChecked"] === 'boolean') {
      this.isSlideChecked = this.popoverRef.data["isSlideChecked"];
    }
    this.defaultOnliner = this.popoverRef.data["defaultOnliner"];
    this.nonOnlinerToolTip = "Enable Impersonation of " + this.defaultOnliner
    this.changeTooltip();
    this.toggleMenu(this.isSlideChecked);
  }

  public setStatus(status: OnlineStatus) {
    this.activeStatus = status;
    this.cd.markForCheck();
  }

  public logout() {
    this.router.navigateByUrl("/app/logout");
  }

  public toggleResourceType($event: MatSlideToggleChange) {
    this.toggleMenu($event.checked);
    this.popoverRef.close($event.checked);
    // redirect users to the homepage whenever they enable/disable the toggle
    this.router.navigateByUrl("/dashboards/analytics");
  }

  public changeTooltip() {
    this.isSlideChecked
      ? this.nonOnlinerToolTip = "Disable Impersonation of " + this.defaultOnliner
      : this.nonOnlinerToolTip = "Enable Impersonation of " + this.defaultOnliner;
  }

  toggleMenu(val: boolean) {
    this.navigationService.setIsSlideChecked(val);
  }

  public closeProfileMenu() {
    this.popoverRef.close();
  }
}
