<mat-card fxFlex="100%" width="100%">
  <mat-card-header>
    <mat-card-title class="card-title">{{ title }}</mat-card-title>
  </mat-card-header>
  <div
    fxLayout="row"
    fxLayout.lt-sm="row"
    fxLayoutGap="8px"
    fxLayoutGap.lt-sm="4px"
  >
    <!--Multi-select list goes here.-->
    <div class="col-lg-12">
      <div class="row">
        <mat-error class="mat-error" *ngIf="errorMessage">
          {{ errorMessage }}
        </mat-error>
      </div>
      <div class="row">
        <form class="col-lg-6" *ngIf="!isReadOnly">
          <mat-card>
            <div
              class="ml-2 left-card-heading d-flex flex-row"
              fxLayout="row"
              *ngIf="!isReadOnly"
            >
              <div fxLayout="row" class="mr-3 list-heading">
                {{ leftTitle }}
              </div>
              <div class="ml-auto">
                <button
                  *ngIf="isEmpty && !isReadOnly"
                  class="add-button"
                  matTooltip="Add all available items to resume"
                  (click)="addAllItems()"
                >
                  <p>Add all</p>
                </button>
              </div>
            </div>
            <div class="mt-2">
              <ul class="obs-multi-select-box" size="10">
                <li
                  *ngFor="let item of leftSide; index as i"
                  class="obs-option"
                  (click)="!isReadOnly && leftOptionClick(i, item)"
                  title=""
                >
                  <div>
                    {{ item.viewValue }}
                  </div>
                  <div class="obs-option-icon">
                    <mat-icon
                      *ngIf="item.icon"
                      aria-hidden="false"
                      aria-label="option icon"
                      class="obs-icon"
                      >{{ item.icon }}</mat-icon
                    >
                  </div>
                </li>
              </ul>
            </div>
          </mat-card>
        </form>
        <form [ngClass]="isReadOnly ? 'col-lg-12' : 'col-lg-6'">
          <mat-card [ngClass]="{ disabled: isReadOnly }">
            <div
              class="ml-2 left-card-heading d-flex flex-row"
              fxLayout="row"
              *ngIf="!isReadOnly"
            >
              <div fxLayout="row" class="mr-3 list-heading">
                {{ rightTitle }}
              </div>
              <div class="ml-auto">
                <button
                  *ngIf="isPopulated && !isReadOnly"
                  class="clear-button"
                  matTooltip="Clear all items included in the Resume"
                  (click)="clearSelectedItems()"
                >
                  <p>Clear all</p>
                </button>
              </div>
            </div>
            <div class="mt-2">
              <ul
                [ngClass]="
                  isReadOnly
                    ? 'obs-multi-select-box-disabled'
                    : 'obs-multi-select-box'
                "
                size="10"
              >
                <li
                  *ngFor="let item of rightSide; index as i"
                  class="obs-option"
                  (click)="!isReadOnly && rightOptionClick(i, item)"
                  title=""
                >
                  <div>
                    {{ item.viewValue }}
                  </div>
                  <div class="obs-option-icon">
                    <mat-icon
                      *ngIf="item.icon"
                      aria-hidden="false"
                      aria-label="option icon"
                      class="obs-icon"
                      >{{ item.icon }}</mat-icon
                    >
                  </div>
                </li>
              </ul>
            </div>
          </mat-card>
        </form>
      </div>
    </div>
  </div>
</mat-card>
