import { Pipe, PipeTransform } from '@angular/core';
import { isString } from 'util';

@Pipe({
    name: 'uriDecode'
})
export class UriDecodePipe implements PipeTransform {

    transform(value: any, args?: any): string {
        return decodeURIComponent(value);
    }

}