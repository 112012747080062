import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Resume } from "../data-models/Resume";
import { Employee } from "../data-models/Employee";
import { ResumeService } from "./resume.service";

@Injectable()
export class ResumeSearchUtilService {
  public userId: number;
  public _getUserResumes: BehaviorSubject<{
    resumes: Resume[];
    careerManager: string;
  }> = new BehaviorSubject({ resumes: [], careerManager: null });
  public _isSearching: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private _resumeService: ResumeService) {}

  getUserApprovedResumes(user: Employee) {
    this._isSearching.next(true);
    this._resumeService.getApprovedResumes(user.userId).subscribe(
      (resumes) => {
        this._getUserResumes.next({
          resumes: resumes,
          careerManager: user.careerManager,
        });
      },
      (error) => this._isSearching.next(false),
      () => this._isSearching.next(false)
    );
  }

  getSearchResumes(user: Employee) {
    this._isSearching.next(true);
    this._resumeService.getSearchResumes(user.userId).subscribe(
      (resumes) => {
        this._getUserResumes.next({
          resumes: resumes,
          careerManager: user.careerManager,
        });
      },
      (error) => this._isSearching.next(false),
      () => this._isSearching.next(false)
    );
  }


  searchResumeResults(): Observable<{
    resumes: Resume[];
    careerManager: string;
  }> {
    return this._getUserResumes.asObservable();
  }

  isSearching(): Observable<boolean> {
    return this._isSearching.asObservable();
  }
}
