<header>
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div class="page-title">{{ onliner.firstName }} {{ onliner.lastName }} ({{ onliner.email }})</div>
    </div>
</header>
<div class="p-6" *ngIf="isLoading" >
    <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="24px">
      <mat-spinner></mat-spinner>
    </div>
</div>
<div @fadeInUp class="card cardResult mb-3" *ngIf="!isLoading && displaySummary">
    <div class="px-6 py-4" [@.disabled]="disableAnimation">
        <p class="querySummary">
            <span class="font-bold"
                >Query Summary
            </span>
        </p><br>
        <p class="query">
            <span class="italic"
                >"{{displayQuery}}" was found in {{foundString.join(", ")}}
            </span>
        </p>
    </div>
</div>

<div @fadeInUp class="card cardResult mb-3" *ngIf="!isLoading">
    <div class="px-6 py-4" [@.disabled]="disableAnimation">
        <mat-expansion-panel
            [expanded] = "panelSkillOpenState"
            class="no-pad"
            (closed)="panelSkillOpenState = false"
            (opened)="panelSkillOpenState = true"
        >
            <mat-expansion-panel-header
            class="p-0"
            >
            <mat-panel-title>
                <p class="cardDetail">
                <span class="font-bold"
                    >Skills
                </span>
                </p>
                
            </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="overflow-table" *ngIf="panelSkillOpenState">
                <mat-table
                [dataSource]="matchingSkillsDisplayData"
                class="mat-elevation-z8 attribute-row"
                >
                    <ng-container matColumnDef="Skill">
                        <mat-header-cell *matHeaderCellDef class="skillHeader"
                        >Name</mat-header-cell
                        >
                        <mat-cell *matCellDef="let element" class="topLeftAlignCell"
                        >{{ element.skill.name }}</mat-cell
                        >
                    </ng-container>

                    <ng-container matColumnDef="Expertise">
                        <mat-header-cell *matHeaderCellDef class="expertiseHeader"
                        >Expertise Level 
                        <mat-icon [icIcon]="icInfoCircle"
                            class="mt-3 iconInfo"
                            (click) = "onInfoClicked()"
                            [matTooltip] = "expertiseInfo"
                            matTooltipClass = "tooltipClass"
                            size="25px">
                        </mat-icon>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="topLeftAlignCell"
                        >{{ element.expertiseLevel }}</mat-cell
                        >
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumnsSkill; sticky: true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsSkill"></mat-row>
                </mat-table>
            </div>
        </mat-expansion-panel>
    </div>
</div>

<div @fadeInUp class="card cardResult mb-3" *ngIf="!isLoading">
    <div class="px-6 py-4" [@.disabled]="disableAnimation">
        <mat-expansion-panel
            [expanded] = "panelEducationOpenState"
            class="no-pad"
            (closed)="panelEducationOpenState = false"
            (opened)="panelEducationOpenState = true"
        >
            <mat-expansion-panel-header
            class="p-0"
            >
            <mat-panel-title>
                <p class="cardDetail">
                <span class="font-bold"
                    >Certifications/Degrees
                </span>
                </p>
                
            </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="overflow-table" *ngIf="panelEducationOpenState">
                <mat-table
                [dataSource]="matchingEducationsDisplayData"
                class="mat-elevation-z8 attribute-row"
                >
                    <ng-container matColumnDef="Education">
                        <mat-header-cell *matHeaderCellDef class="educationnHeader"
                        >Name</mat-header-cell
                        >
                        <mat-cell *matCellDef="let element" class="topLeftAlignCell"
                        >{{ element.education.name }}</mat-cell
                        >
                    </ng-container>

                    <ng-container matColumnDef="Date Achieved">
                        <mat-header-cell *matHeaderCellDef class="dateAchievedHeader"
                        >Date Achieved</mat-header-cell
                        >
                        <mat-cell *matCellDef="let element" class="topLeftAlignCell"
                        >{{ (element.endDate | date:'yyyy-MM-dd') || "In Progress"}}</mat-cell
                        >
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumnsEducation; sticky: true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsEducation"></mat-row>
                </mat-table> 
            </div>
        </mat-expansion-panel>
    </div>
</div>

<div @fadeInUp class="card cardResult mb-3" *ngIf="!isLoading">
    <div class="px-6 py-4" [@.disabled]="disableAnimation">
        <mat-expansion-panel
            [expanded] = "panelProjectOpenState"
            class="no-pad"
            (closed)="panelProjectOpenState = false"
            (opened)="panelProjectOpenState = true"
        >
            <mat-expansion-panel-header
            class="p-0"
            >
            <mat-panel-title>
                <p class="cardDetail">
                <span class="font-bold"
                    >Projects
                </span>
                </p>
                
            </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="overflow-table" *ngIf="panelProjectOpenState">
                <mat-table
                [dataSource]="matchingProjectsDisplayData"
                class="mat-elevation-z8 attribute-row"
                >
                    <ng-container matColumnDef="Project">
                        <mat-header-cell *matHeaderCellDef class="projectHeader"
                        >Job Title</mat-header-cell
                        >
                        <mat-cell *matCellDef="let element" style="flex-direction: column">
                            <mat-cell *ngFor="let title of element.jobTitles" class="jobTitle"
                            >{{ title.jobTitleObj.name }}</mat-cell
                            >
                        </mat-cell>
                    </ng-container>
            
                    <ng-container matColumnDef="Employer">
                        <mat-header-cell *matHeaderCellDef class="employerHeader"
                        >Employer</mat-header-cell
                        >
                        <mat-cell *matCellDef="let element" class="topLeftAlignCell"
                        >{{ element.company.name }}</mat-cell
                        >
                    </ng-container>

                    <ng-container matColumnDef="Duration">
                        <mat-header-cell *matHeaderCellDef class="durationHeader"
                        >Duration</mat-header-cell
                        >
                        <mat-cell *matCellDef="let element" class="topLeftAlignCell"
                        >{{ element.startDate | date:'MM/yyyy' }} - {{ (element.endDate | date:'MM/yyyy') || "present" }}</mat-cell
                        >
                    </ng-container>

                    <ng-container matColumnDef="Industry">
                        <mat-header-cell *matHeaderCellDef class="industryHeader"
                        >Industry</mat-header-cell
                        >
                        <mat-cell *matCellDef="let element" style="flex-direction: column">
                            <mat-cell *ngFor="let title of element.industries" class="jobTitle"
                            >{{ title.industryObj.name }}</mat-cell
                            >
                        </mat-cell>
                    </ng-container>
            
                    <mat-header-row *matHeaderRowDef="displayedColumnsProject; sticky: true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsProject"></mat-row>
                </mat-table>
            </div>
        </mat-expansion-panel>
    </div>
</div>

<div @fadeInUp class="card cardResult mb-3" *ngIf="!isLoading">
    <div class="px-6 py-4" [@.disabled]="disableAnimation">
        <mat-expansion-panel
            [expanded] = "panelAssociationOpenState"
            class="no-pad"
            (closed)="panelAssociationOpenState = false"
            (opened)="panelAssociationOpenState = true"
        >
            <mat-expansion-panel-header
            class="p-0"
            >
            <mat-panel-title>
                <p class="cardDetail">
                <span class="font-bold"
                    >Associations
                </span>
                </p>
                
            </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="overflow-table" *ngIf="panelAssociationOpenState">
                <mat-table
                [dataSource]="matchingAssociationsDisplayData"
                class="mat-elevation-z8 attribute-row"
                >
                    <ng-container matColumnDef="Association">
                        <mat-header-cell *matHeaderCellDef class="associationHeader"
                        >Name</mat-header-cell
                        >
                        <mat-cell *matCellDef="let element" class="topLeftAlignCell"
                        >{{ element.assoc.name }}</mat-cell
                        >
                    </ng-container>
            
                    <ng-container matColumnDef="Date Joined">
                        <mat-header-cell *matHeaderCellDef class="dateJoinedHeader"
                        >Date Joined</mat-header-cell
                        >
                        <mat-cell *matCellDef="let element" class="topLeftAlignCell"
                        >{{ element.dateJoined | date:'MM/yyyy'}}</mat-cell
                        >
                    </ng-container>
            
                    <mat-header-row *matHeaderRowDef="displayedColumnsAssociation; sticky: true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsAssociation"></mat-row>
                </mat-table>
            </div>
        </mat-expansion-panel>
    </div>
</div>