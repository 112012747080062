import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AlternateOnlinerMaintenanceComponent } from './alternateOnlinerMaintenance.component';
import { AlternateOnlinerMaintenanceCardComponent } from './alternateOnlinerMaintenance-card/alternateOnlinerMaintenance-card.component';
import { PendingChangesGuard } from '../common/services/pending-changes-guard.service';

const AlternateOnlinerMaintenanceRoutes: Routes = [
  {
    path: '',
    canDeactivate: [PendingChangesGuard],
    component: AlternateOnlinerMaintenanceComponent,
    children: [
      { path: ':id', component: AlternateOnlinerMaintenanceCardComponent, pathMatch: 'full' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(AlternateOnlinerMaintenanceRoutes)],
  exports: [RouterModule],
})
export class AlternateOnlinerMaintenanceRoutingModule { }
