import { Preset } from './../data-models/Preset';
import { RSkillType, RAttribute, RAttributeType, AnalyticsValue, RAttributeMaintenance, CertInfoDto, Competency, RegionDirector, SkillCompetency } from './../data-models/commonDataModels';
import { throwError, of, Observable } from 'rxjs';

import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

import { AuthorizedHttp } from "./authorized-http.service";
import { RComplexityRating } from "../data-models/commonDataModels";
import { formatDate } from '@angular/common';
import { OnlineTeam } from '../data-models/OnlineTeam';

export interface DropDown<T> {
  id: number;
  value: number;
  viewValue: string;
  dateModified: Date;
  element: T;
  icon?: string;
}

@Injectable()
export class CommonService {
  private apiEndpoint = `${environment.apiEndpoint}/api/common`;
  public _associations: RAttribute[];
  private _skills: RAttribute[];
  private _certificationStatus: RAttribute[];
  private _certificationApprovalStatus: RAttribute[];

  private _complexityRatings: RComplexityRating[];
  private _expertiseLevels: RAttribute[];
  private _rSkillTypes: RSkillType[];
  private rAttribute: RAttribute;
  public _rAttributeTypes: RAttributeType[];
  public _analyticsValues: AnalyticsValue[];
  public _educationGroups: RAttribute[];
  public _presets: RAttribute[];
  public _rAttributeList: RAttribute[];
  private _skillCategories: RAttribute[];
  private _onlineTeams: OnlineTeam[];
  constructor(private http: AuthorizedHttp) { }

  public encrypt(value: string): string {
    let encoded = btoa(value);
    encoded = encoded.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    return encoded;
  }

  public getAttributeTypes(): Observable<RAttributeType[]> {
    return this._rAttributeTypes
      ? of(this._rAttributeTypes)
      : this.http.get(`${this.apiEndpoint}/rAttributeType`).pipe(
        map((result) => {
          this._rAttributeTypes = result;
          return result;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }

  public addRAttribute(rAttributeModel: RAttribute): Observable<RAttribute> {
    return this.http
      .post(
        `${this.apiEndpoint}/addRAttribute/`, 
        JSON.stringify(rAttributeModel)
      )
      .pipe(
        map((result) => {
          this.rAttribute = result;
          return this.rAttribute;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }

  public updateRAttribute(rAttributeModel: RAttribute): Observable<RAttribute> {
    return this.http
      .patch(
        `${this.apiEndpoint}/updateRAttribute/`,
        JSON.stringify(rAttributeModel)
      )
      .pipe(
        map((result) => {
          this.rAttribute = result;
          return this.rAttribute;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }

  public deleteRAttribute(rAttributeId: number): Observable<Boolean> {
    return this.http
      .delete(`${this.apiEndpoint}/deleteRAttribute/` + rAttributeId)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }

  public addRAttributeNoEmail(rAttributeModel: RAttribute): Observable<RAttribute> {
    return this.http
      .post(
        `${this.apiEndpoint}/addRAttributeNoEmail/`,
        JSON.stringify(rAttributeModel)
      )
      .pipe(
        map((result) => {
          this.rAttribute = result;
          return this.rAttribute;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }

  public getAttributesByGroupName(groupName: string | string[]): Observable<RAttribute[]> {
    return this.http
      .get(`${this.apiEndpoint}/rAttributeGroupActiveAndApprovedByUserId/${groupName}`)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }

  public getJobTitles(): Observable<RAttribute[]> {
    return this.http.get(`${this.apiEndpoint}/rAttributeGroup/JobTitle`).pipe(
      map((result) => result),
      catchError((errorResponse: any) =>
        throwError(errorResponse.error.exceptionMessage || "Server error")
      )
    );
  }

  public getIndustries(): Observable<RAttribute[]> {
    return this.http.get(`${this.apiEndpoint}/rAttributeGroup/Industry`).pipe(
      map((result) => result),
      catchError((errorResponse: any) =>
        throwError(errorResponse.error.excptionMessage || "Server Error")
      )
    );
  }

  public getEmployers(): Observable<RAttribute[]> {
    return this.http.get(`${this.apiEndpoint}/rAttributeGroup/Company`).pipe(
      map((result) => result),
      catchError((errorResponse: any) =>
        throwError(errorResponse.error.exeptionMessage || "Server Error")
      )
    );
  }

  public getTextLimit(fieldName: string): Observable<number> {
    return this.http.get(`${this.apiEndpoint}/rAttribute/TextCharLimit`).pipe(
      map((result) => {
        return result.find((tcl) => tcl.name === fieldName).value;
      }),
      catchError((errorResponse: any) =>
        throwError(errorResponse.error.exceptionMessage || "Server error")
      )
    );
  }

  public getPronouns(): Observable<RAttribute[]> {
    return this.http.get(`${this.apiEndpoint}/rAttributeGroup/Pronoun`).pipe(
      map((result) => {
        return result;
      }),
      catchError((errorResponse: any) =>
        throwError(errorResponse.error.exceptionMessage || "Server error")
      )
    );
  }

  public getLanguages(): Observable<RAttribute[]> {
    return this.http.get(`${this.apiEndpoint}/rAttributeGroup/Language`).pipe(
      map((result) => {
        return result;
      }),
      catchError((errorResponse: any) =>
        throwError(errorResponse.error.exceptionMessage || "Server error")
      )
    );
  }

  public getCostLevels(): Observable < RAttribute[] > {
    return this.http.get(`${this.apiEndpoint}/rAttributeGroup/CostLevel`).pipe(
        map((result) => {       
            return result;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
    );
  }

  public getProficiencyLevels(): Observable < RAttribute[] > {
    return this.http.get(`${this.apiEndpoint}/rAttributeGroup/ProficiencyLevel`).pipe(
        map((result) => {
            return result;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
    );
  }


  public getAssociations(): Observable<RAttribute[]> {
    return this.http.get(`${this.apiEndpoint}/rAttributeGroup/Association`).pipe(
      map((result) => {
        // this._associations = result;
        return result;
      }),
      catchError((errorResponse: any) =>
        throwError(errorResponse.error.exceptionMessage || "Server error")
      )
    );
  }


  public getCertifications(): Observable<RAttribute[]> {
    return this.http.get(`${this.apiEndpoint}/rAttribute/Certification`).pipe(
      map((result) => {
        this._associations = result;
        return result;
      }),
      catchError((errorResponse: any) =>
        throwError(errorResponse.error.exceptionMessage || "Server error")
      )
    );
  }
  public getCertificationStatuses(): Observable<RAttribute[]> {
    return this.http
      .get(`${this.apiEndpoint}/rAttribute/CertificationStatus`)
      .pipe(
        map((result) => {
          this._certificationStatus = result;
          return result;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }
  public getCertificationApprovalStatuses(): Observable<RAttribute[]> {
    return this.http
      .get(`${this.apiEndpoint}/rAttribute/CertificationApprovalStatus`)
      .pipe(
        map((result) => {
          this._certificationApprovalStatus = result;
          return result;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }
  public getSkillGroups(): Observable<RAttribute[]> {
    return this.http.get(`${this.apiEndpoint}/rAttribute/SkillGroup`).pipe(
      map((result) => {
        return result;
      }),
      catchError((errorResponse: any) =>
        throwError(errorResponse.error.exceptionMessage || "Server error")
      )
    );
  }

  public getSkills(skillGroupNames: string[]): Observable<RAttribute[]> {
    return this.http
      .get(`${this.apiEndpoint}/rAttributeGroup/${skillGroupNames}`)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }

  public getComplexityRatings(): Observable<RComplexityRating[]> {
    return this._complexityRatings
      ? of(this._complexityRatings)
      : this.http.get(`${this.apiEndpoint}/cmplxtyrtngs`).pipe(
        map((result) => {
          this._complexityRatings = result;
          return result;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }

  public getRSkillTypes(): Observable<RSkillType[]> {
    return this._rSkillTypes
      ? of(this._rSkillTypes)
      : this.http.get(`${this.apiEndpoint}/rskilltypes`).pipe(
        map((result) => {
          this._rSkillTypes = result;
          return result;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }

  public getExpertiseTypes(): Observable<RAttribute[]> {
    return this.http.get(`${this.apiEndpoint}/rAttributeGroup/Expertise`).pipe(
      map((result) => {
        return result;
      }),
      catchError((errorResponse: any) =>
        throwError(errorResponse.error.exceptionMessage || "Server error")
      )
    );
  }

  public getExpertiseLevels(): Observable<[RAttribute]> {
    return this._expertiseLevels
      ? of(this._expertiseLevels)
      : this.http.get(`${this.apiEndpoint}/rAttribute/ExpertiseLevel`).pipe(
        map((result) => {
          this._expertiseLevels = result.sort((a, b) => a.id < b.id ? -1 : 1);;
          return result;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }

  public getLengthString(value: number): string {
    if (value < 1)
      return (12 * value) + " months";
    else if (value === 1)
      return "1 year";
    else return value + " years";

  }

  public getExperienceLength(expertiseLevel: RAttribute, expertiseList: RAttribute[]): string {
    var pos = expertiseList.indexOf(expertiseLevel);
    if (pos === 0) {
      return "0 - " + this.getLengthString(Number(expertiseLevel.value));
    }
    else if (pos === expertiseList.length - 1) {
      return ">" + this.getLengthString(Number(expertiseList[pos - 1].value));
    }
    else {
      return this.getLengthString(Number(expertiseList[pos - 1].value)) + " - " + this.getLengthString(Number(expertiseLevel.value));
    }
  }

  public getSpins(): Observable<RAttribute[]> {
    return this.http.get(`${this.apiEndpoint}/rAttributeGroup/Spins`).pipe(
      map((result) => {
        return result;
      }),
      catchError((errorResponse: any) =>
        throwError(errorResponse.error.exceptionMessage || "Server error")
      )
    );
  }

  public getAnalyticsValues(userId: string): Observable<AnalyticsValue[]> {
    return this.http
      .get(`${this.apiEndpoint}/analytics/` + userId)
      .pipe(
        map(result => {
          this._analyticsValues = result;
          return this._analyticsValues;
        }),
        catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
      );
  }


  public getEducationGroups(): Observable<[RAttribute]> {
    // 
    return this.http.get(`${this.apiEndpoint}/rAttribute/EducationGroup`)
      .pipe(
        map(result => {
          this._educationGroups = result;
          return result;
        }),
        catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
      );
  }

  public getPresets(): Observable<Preset[]> {
    return this.http.get(`${this.apiEndpoint}/rAttribute/SearchPreset`)
      .pipe(
        map(result => {
          let filterActiveResults = result.filter(searchPreset => {
            return searchPreset.isActive
          })
          this._presets = filterActiveResults;
          return filterActiveResults;
        }),
        catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
      );
  }

  public getRAttributeList(AttributeTypeGroupName: string): Observable<[RAttribute]> {
    // 
    return this.http.get(`${this.apiEndpoint}/rAttribute/${AttributeTypeGroupName}`)
      .pipe(
        map(result => {
          this._rAttributeList = result;
          return result;
        }),
        catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
      );
  }

  public getRAttributeMaintenanceList(AttributeTypeGroupName: string): Observable<[RAttributeMaintenance]> {
    // 
    return this.http.get(`${this.apiEndpoint}/rAttributeMaintenance/${AttributeTypeGroupName}`)
      .pipe(
        map(result => {
          this._rAttributeList = result;
          return result;
        }),
        catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
      );
  }

  public getAllCertificationInfo(): Observable<[CertInfoDto]> {
    return this.http.get(`${this.apiEndpoint}/allCertificationInfo`)
      .pipe(
        map(result => {
          return result;
        }),
        catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
      );
  }

  public updateCertVendor(certId: number, vendorId: number): Observable<CertInfoDto> {
    return this.http.get(
      `${this.apiEndpoint}/updateCertVendor/${certId}/${vendorId}`
    ).pipe(
      map(result => {
        return result;
      }),
      catchError((errorResponse: any) =>
        throwError(errorResponse.error.exceptionMessage || "Server error")
      )
    );
  }

  public updateCertSkillsArea(certId: number, skillsAreaId: number): Observable<CertInfoDto> {
    return this.http.get(
      `${this.apiEndpoint}/updateCertSkillsArea/${certId}/${skillsAreaId}`
    ).pipe(
      map(result => {
        return result;
      }),
      catchError((errorResponse: any) =>
        throwError(errorResponse.error.exceptionMessage || "Server error")
      )
    );
  }

  public getAllCompetencies(): Observable<[Competency]> {
    return this.http.get(`${this.apiEndpoint}/allCoreCompetencies`)
      .pipe(
        map(result => {
          return result;
        }),
        catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
      );
  }

  public loadSkillSummariesDropDown(data: any): DropDown<any>[] {
    var skillSummaries = new Array();
    data.forEach((element) => {
      skillSummaries.push(
        this.createDropDownItem({
          id: element.id,
          value: element.id,
          viewValue: element.name,
          dateModified: element.dateModified,
          element: element,
        })
      );
    });
    return skillSummaries;
  }

  public loadSkillsDropDown(data: any): DropDown<any>[] {
    var skills = new Array();
    data.forEach((element) => {
      skills.push(
        this.createDropDownItem({
          id: element.skillId,
          value: element.id,
          viewValue: `${element.expertiseType?.name} - ${element.skill.name}`,
          dateModified: element.dateModified,
          element: element,
          icon: element.isStarred ? "star" : null,
        })
      );
    });
    return skills;
  }

  public loadUserSkillsDropDown(data: any): DropDown<any>[] {
    var skills = new Array();
    data.forEach((element) => {
      skills.push(
        this.createDropDownItem({
          id: element.skillId,
          value: element.id,
          viewValue: `${element.skill.name}`,
          dateModified: element.dateModified,
          element: element,
          icon: element.isStarred ? "star" : null,
        })
      );
    });
    return skills;
  }

  public loadAssociationsDropDown(data: any): DropDown<any>[] {
    var associations = new Array();
    data.forEach((element) => {
      associations.push(
        this.createDropDownItem({
          id: element.id,
          value: element.assocId,
          viewValue: element.assoc.name,
          dateModified: element.dateModified,
          element: element,
        })
      );
    });
    return associations;
  }

  public loadEducationsDropDown(data: any): DropDown<any>[] {
    var educations = new Array();
    data.forEach((element) => {
      educations.push(
        this.createDropDownItem({
          id: element.id,
          value: element.educationId,
          viewValue: element.education.name,
          dateModified: element.dateModified,
          element: element,
        })
      );
    });
    return educations;
  }

  public loadProjectsDropDown(data: any): DropDown<any>[] {
    var projects = new Array();
    data.forEach((element) => {
      projects.push(
        this.createDropDownItem({
          id: element.id,
          value: element.id,
          viewValue: `${formatDate(element.startDate, "MM/yyyy", "en-CA")} - ${element.endDate
            ? formatDate(element.endDate, "MM/yyyy", "en-CA")
            : "Present"
            } - ${element.jobTitles ?
              element.jobTitles.map((x) => x.jobTitleObj?.name)
                .join(", ") : element.jobTitleObj?.name} at ${element.company?.name} ${element.projectName ? " - " + element.projectName : ''}`,
          dateModified: element.dateModified,
          element: element,
        })
      );
    });
    return projects;
  }

  public loadProfileSummariesDropDown(data: any): DropDown<any>[] {
    var profileSummaries = new Array();
    data.forEach((element) => {
      profileSummaries.push(
        this.createDropDownItem({
          id: element.id,
          value: element.id,
          viewValue: element.name,
          dateModified: element.dateModified,
          element: element,
        })
      );
    });
    return profileSummaries;
  }

  createDropDownItem(dropDown: DropDown<any>): DropDown<any> {
    return {
      id: dropDown.id,
      value: dropDown.value,
      viewValue: dropDown.viewValue,
      dateModified: dropDown.dateModified,
      element: dropDown.element,
      icon: dropDown.icon,
    };
  }

 public getAllSkillCompetencies(): Observable<[SkillCompetency]> {
    return this.http.get(`${this.apiEndpoint}/allCompetencies`).pipe(
      map((result) => {
        return result;
      }),
      catchError((errorResponse: any) =>
        throwError(errorResponse.error.exceptionMessage || "Server error")
      )
    );
  }

  public getSkillCategories(): Observable<[RAttribute]> {
    return this._skillCategories
      ? of(this._skillCategories)
      : this.http.get(`${this.apiEndpoint}/rAttribute/SkillCategory`).pipe(
        map((result) => {
          this._skillCategories = result.sort((a, b) => a.id < b.id ? -1 : 1);;
          return result;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }

  public getOnlineTeams(): Observable<OnlineTeam[]> {
    return this._onlineTeams
      ? of(this._onlineTeams)
      : this.http.get(`${this.apiEndpoint}/onlineTeams`).pipe(
        map((result) => result),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }

 
}