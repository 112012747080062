import { RNonOnlinerProjectResponsibility } from "src/app/common/data-models/RNonOnlinerProjectResponsibility";
import { RAttribute } from "./commonDataModels";
import { RNonOnlinerProjectIndustry } from "./RNonOnlinerProjectIndustry";
import { RNonOnlinerProjectJobTitle } from "./RNonOnlinerProjectJobTitle";

export class RNonOnlinerProject {
  public id: number;
  public company: RAttribute;
  public companyId: number;
  public startDate: Date;
  public endDate: Date;
  public projectDescription: string;
  public userId: number;
  public responsibilities: RNonOnlinerProjectResponsibility[];
  public dateModified: Date;
  public isUsed: boolean;
  public jobTitles: RNonOnlinerProjectJobTitle[];
  // retain for snapshot object
  public jobTitleObj: RAttribute;
  // retain for snapshot object
  public industry: RAttribute;
  public industries: RNonOnlinerProjectIndustry[];
  public projectName: string;
}
