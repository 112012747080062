<div #originRef (click)="showPopover(originRef)" [class.bg-hover]="dropdownOpen"
  class="flex items-center rounded cursor-pointer relative trans-ease-out select-none py-1 pr-1 pl-3 hover:bg-hover" matRipple 
    *ngIf="!isLoading" [matTooltip]="userRoles" [matTooltipPosition]="'right'">
    <div class="body-1 font-medium leading-snug ltr:mr-3 rtl:ml-3" fxHide.xs>
        <ng-container *ngIf="userProfile && userProfile.name; else noProfile">
            {{ (pronouns$ | async) ? (userProfile.name + ' ' + (pronouns$ | async)) : userProfile.name }}
        </ng-container>
        <ng-template #noProfile>
            No Profile
        </ng-template>
    </div>
  <div [style.background-color]="theme.colors.primary['500'] | colorFade:0.9" [style.color]="theme.colors.primary['500']" class="rounded-full h-9 w-9 flex items-center justify-center">
    <img [src]="profilePhotoData"  class="img-fluid profilePhotoData" *ngIf="!isImageLoading && profilePhotoData; else noImageFound" alt="Signed in as {{ userProfile.given_name }} {{ userProfile.family_name }}" />
    <ng-template #noImageFound>
      <mat-icon [icIcon]="icPerson"></mat-icon>
    </ng-template>
  </div>
</div>