import { SnackBarService } from "./../common/services/snackbar.service";
import { MatDialog } from "@angular/material/dialog";
import {
  Component,
  OnInit,
  HostListener
} from "@angular/core";
import { Observable, forkJoin, BehaviorSubject } from "rxjs";
import { CommonService } from "src/app/common/services/common.service";
import { WorkflowService } from "../common/services/workflow.service";
import { AuthService } from "../common/services/auth.service";
import icMoreVert from "@iconify-icons/ic/twotone-more-vert";
import { Resume } from "../common/data-models/Resume";
import { ActivatedRoute, Router } from "@angular/router";
import { CommentsFormModal } from "./comments-form-modal/comments-form-modal";
import { ReviewCommentsModalComponent } from "../review-comments-modal/review-comments-modal.component";
import { environment } from "src/environments/environment";
import { ResumeStatusTypes } from "../common/services/resume.service";

@Component({
  selector: "obs-resume-review",
  templateUrl: "./resume-review.component.html",
  styleUrls: ["./resume-review.component.scss"],

})
export class ResumeReviewComponent implements OnInit {
  public resume: Resume;
  private userId: string;
  public resumeId: number;
  public formIsDirty = false;
  icMoreVert = icMoreVert;
  private redirectUrl: string;
  public isError: boolean = false;
  public commentsFieldLimit: number;
  public originalResume: Resume;
  public actionAllow = false;

  isLoading: boolean;
  isLoading$: BehaviorSubject<boolean>;

  public constructor(
    public commonService: CommonService,
    private workflowService: WorkflowService,
    private authService: AuthService,
    public snackBarService: SnackBarService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.resumeId = +this.route.snapshot.paramMap.get("id");
  }

  @HostListener("window:beforeunload")
  canDeactivate(): boolean | Observable<boolean> {
    if(!this.authService.isAuthenticated()) return true;
    return !this.formIsDirty;
  }

  public ngOnInit(): void {
    this.userId = this.authService.getUserId();

    this.isLoading$ = new BehaviorSubject<boolean>(this.isLoading);

    this.redirectUrl =
      this.route.snapshot.queryParams["redirectUrl"] ?? "workflows";

    this.isLoading$.next((this.isLoading = true));

    forkJoin([
      this.commonService.getTextLimit("reviewComment"),
      this.workflowService.getPendingReviewResume(this.userId, this.resumeId),
      this.workflowService.getPendingReviewOriginalResume(this.userId, this.resumeId),
    ]).subscribe(
      async ([textLimit, resume, originalResume]) => {
        this.commentsFieldLimit = +textLimit;
        resume.spins.name = resume.spins.name;
        resume.accreditation = resume.accreditation;
        originalResume.spins.name = originalResume.spins.name;
        originalResume.accreditation = originalResume.accreditation;
        this.resume = resume;
        this.resume.useOriginal = true;
        this.originalResume = originalResume;
        this.actionBasedOnRole();
        this.isLoading$.next((this.isLoading = false));
      },
      (err) => {
        console.error(err);
        this.displayError();
        this.isLoading$.next((this.isLoading = false));
      }
    );
  }

  displayError(): void {
    this.isError = true;
  }

  actionBasedOnRole(): void {
    if (this.authService.getUserRoles().includes(environment.roles.ResumesRMT)) {
      if (this.resume.statusType.name === ResumeStatusTypes.PENDING_RMT_REVIEW)
        this.actionAllow = true;
    }
    if (this.authService.getUserRoles().includes(environment.roles.ResumesCM)) {
      if (this.resume.statusType.name === ResumeStatusTypes.PENDING_CM_REVIEW)
        this.actionAllow = true;
    }
  }

  openReviewCommentsFormDialog(approve: boolean): void {
    const dialogRef = this.dialog.open(CommentsFormModal, {
      width: "400px",
      maxHeight: "300px",
      disableClose: true,
      data: { commentsFieldLimit: this.commentsFieldLimit, approve },
    });

    dialogRef.componentInstance.onSave.subscribe(
      async ({ comment, approve, callback }) => {

        const result = await this.submit(comment, approve);

        const approvedText = approve ? "Approved" : "Rejected";
        const approvingText = approve ? "approving" : "rejecting";

        if (callback) {
          callback(); //let the caller knows that the request is over
        }

        if (result) {
          this.snackBarService.message(`${approvedText} successfully!`);
          dialogRef.close();
          this.redirectBack();
        } else {
          this.snackBarService.message(`Error while ${approvingText} resume`);
        }
      }
    );
  }

  async submit(comment: string, approve: boolean): Promise<boolean> {
    if (approve) {
      return <boolean>await this.approve(comment);
    } else {
      return <boolean>await this.reject(comment);
    }
  }

  public openApproveModal() {
    this.openReviewCommentsFormDialog(true);
  }

  public approve(comment: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.workflowService
        .approveResumeReview(this.userId, this.resumeId, comment)
        .subscribe((result: boolean) => {
          resolve(result);
        },
          error => resolve(false)
        );
    });
  }

  public openRejectModal() {
    this.openReviewCommentsFormDialog(false);
  }

  public reject(comment: string) {
    return new Promise((resolve) => {
      this.workflowService
        .rejectResumeReview(this.userId, this.resumeId, comment)
        .subscribe((result: boolean) => {
          resolve(result);
        },
          error => resolve(false)
        );
    });
  }

  public back() {
    this.redirectBack();
  }

  redirectBack() {
    this.router.navigate([`/app/${this.redirectUrl}`]);
  }

  public markDirty(): void {
    this.formIsDirty = true;
  }

  public markClean(): void {
    this.formIsDirty = false;
  }

  openReviewCommentsDialog($event): void {
    $event.preventDefault();

    this.dialog.open(ReviewCommentsModalComponent, {
      width: "500px",
      maxHeight: "500px",
      data: { reviewComments: this.resume.reviewComments },
      panelClass: ["no-padding-dialog", "resume-review-comments"],
    });
  }
}
