import { Injectable } from '@angular/core';
import { Observable, of, throwError, Subject } from 'rxjs';
import { AuthorizedHttp } from './authorized-http.service';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { RNonOnlinerSkillSummary } from '../data-models/RNonOnlinerSkillSummary';
import { CommonService } from "./common.service";

@Injectable()
export class SkillSummaryNOService {
  private _apiEndpoint = `${environment.apiEndpoint}/api/skillsummaryNO`;
  private _skillSummaries: RNonOnlinerSkillSummary[];
  private _skillSummary: RNonOnlinerSkillSummary;
  private _isDeleted: boolean;

  constructor(private http: AuthorizedHttp, private commonService: CommonService) {
    const headers: any = {
        'Content-Type': 'application/json; charset=utf-8', /*or whatever type is relevant */
        'Accept': 'application/json'
    }
  }

  public getSkillSummaries(userId: string): Observable<RNonOnlinerSkillSummary[]> {
    userId = this.commonService.encrypt(userId);
    return this.http
        .get(`${this._apiEndpoint}/mySkillSummariesNO/` + userId)
        .pipe(
          map(result => {
            this._skillSummaries = result;
            return this._skillSummaries;
          }),
          catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
        );
    }

    public postNewSkillSummary(skillSummary: RNonOnlinerSkillSummary, userId: string): Observable<RNonOnlinerSkillSummary> {
      userId = this.commonService.encrypt(userId);
      return this.http
          .post(`${this._apiEndpoint}/addNewSkillSummaryNO/`+ userId, JSON.stringify(skillSummary))
          .pipe(
            map(result => {
              this._skillSummary = result;
              return this._skillSummary;
            }),
            catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
          );
    }

    public updateSkillSummary(skillSummary: RNonOnlinerSkillSummary, userId: string): Observable<RNonOnlinerSkillSummary> {
      userId = this.commonService.encrypt(userId);
      return this.http
          .patch(`${this._apiEndpoint}/updateSkillSummaryNO/` + userId, JSON.stringify(skillSummary))
          .pipe(
            map(result => {
              this._skillSummary = result;
              return this._skillSummary;
            }),
            catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
          );
    }

    public deleteSkillSummaryNO(skillSummary: RNonOnlinerSkillSummary): Observable<RNonOnlinerSkillSummary> {
        return this.http
            .patch(`${this._apiEndpoint}/deleteSkillSummaryNO/`,  JSON.stringify(skillSummary))
            .pipe(
                map(result => {
                    this._skillSummary = result;
                    return this._skillSummary;
                }),
                catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
            );
    }
}
