<div style="height: 100%; max-width: 100vw; overflow: auto">
  <div style="padding: 16px 0 16px 0; background-color: #eee">
    <div *ngIf="previewType === 'pdf'">
      <obs-resume-pdf-preview
        [resume]="resume"
        [scale]="scale"
        [originalResume]="originalResume"
      ></obs-resume-pdf-preview>
    </div>
  </div>
</div>
