import { DateTime } from "luxon";
import { RAttribute, SkillCompetency } from "./commonDataModels";

export class RSkill {
  public id: number;
  public skill: RAttribute;
  public skillId: number;
  public description: string;
  public userId: string;
  public dateModified: Date;
  public isStarred: boolean;
  public isUsed: boolean;
  public skillTypeId: number;
  public expertiseType: RAttribute;
  public expertiseTypeId: number;
  public expertiseLevelId: number;
  public competency: SkillCompetency;
  public competencyId: number;
  public category: RAttribute;
  public categoryId: number;

  constructor(
    id: number,
    skill: RAttribute,
    skillId: number,
    description: string,
    userId: string,
    dateModified: Date,
    isStarred: boolean,
    isUsed: boolean,
    skillTypeId: number,
    expertiseType: RAttribute,
    expertiseTypeId: number,
    expertiseLevelId: number,
    competency: SkillCompetency,
    competencyId: number,
    category: RAttribute,
    categoryId: number
  ) {
    this.id = id;
    this.skill = skill;    
    this.skillId = skillId;
    this.description = description;
    this.userId = userId;
    this.dateModified = dateModified;
    this.isStarred = isStarred;
    this.isUsed = isUsed;
    this.skillTypeId = skillTypeId;
    this.expertiseType = expertiseType;
    this.expertiseTypeId = expertiseTypeId;
    this.expertiseLevelId = expertiseLevelId;
    this.competency = competency;
    this.competencyId = competencyId;
    this.category = category;
    this.categoryId = categoryId;
  }
}
