import { DateTime } from "luxon";
import { CertificateAttachment } from "./CertificateAttachment";
import { RAttribute } from "./commonDataModels";

export class EducationModel {
  id: number;
  educationTypeId: number;
  educationType: RAttribute;
  education: RAttribute;
  educationId: number;
  startDate: Date;
  endDate: Date;
  institutionCity: string;
  userId: string;
  statusId: number;
  vendorId: number;
  comments: string;
  unitsReceived: number;
  isLocked: boolean;
  approvalStatusId: number;
  note: string;
  bonus: number;
  dateModified: Date;
  certificateAttachment: CertificateAttachment;
  expirationDate: Date;
  isUsed: boolean;
}