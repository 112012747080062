import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

export class ConfirmDialog {
  title: string = "Unsaved Changes";
  message: string = `There are currently unsaved changes that have not 
                      been committed. Are you sure you want to continue?`;
  okButtonTitle: string = 'Continue';
  secondOkButton: string;
  cancelButtonTitle: string = 'Cancel';
  flipButtonColor: boolean = false;
  theme: string;
  actionOkButton?: { title: string, action: string | number } | null; // an actionable state to conclude on
}

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  confirmDialog: ConfirmDialog;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialog) {
    this.confirmDialog = data;
    if (!this.confirmDialog.theme || this.confirmDialog.theme === '') {
      this.confirmDialog.theme = "success";
    }
  }

  ngOnInit() {
  }
}

