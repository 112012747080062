import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatIconModule } from "@angular/material/icon";
import { MatExpansionModule } from "@angular/material/expansion";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IconModule } from "@visurel/iconify-angular";
import { SecondaryToolbarModule } from "src/@vex/components/secondary-toolbar/secondary-toolbar.module";
import { BreadcrumbsModule } from "src/@vex/components/breadcrumbs/breadcrumbs.module";
import { MatButtonModule } from "@angular/material/button";
import { PageLayoutModule } from "src/@vex/components/page-layout/page-layout.module";
import { ContainerModule } from "src/@vex/directives/container/container.module";
import { TranslateModule } from "@ngx-translate/core";
import { MatInputModule } from "@angular/material/input";
import { MatDialogModule } from "@angular/material/dialog";
import { BuildResumeCardComponent } from "../build-resume/build-resume-card/build-resume-card.component";
import { BuildResumeNOCardComponent } from "../build-resumeNO/build-resumeNO-card/build-resumeNO-card.component";


import { ResumePreviewModule } from "../resume-preview/resume-preview.module";
import { MultiDropdownCardComponent } from "../build-resume/build-resume-card/multi-dropdown-card/multi-dropdown-card.component";
import { MultiSelectCardComponent } from "../build-resume/build-resume-card/multi-select-card/multi-select-card.component";
// import { ReviewCommentsModal } from "../review-comments-modal/review-comments-modal";
import { MultiSelectSkillsCardComponent } from "../build-resume/build-resume-card/multi-select-skills-card/multi-select-skills-card.component";

import { ResumeOutputModal } from "../build-resume/resume-output-modal/resume-output-modal";
// import { ResumeOutputComponent } from "./resume-output/resume-output.component";
import { 
    FilterSkillByExpertisePipe, 
    FilterSkillExpertiseUniquePipe, 
    FilterResumeExpertiseGroupUniquePipe,
    FilterResumeExpertiseGroupByExpertisePipe,
    FilterEducationByTypePipe, 
    GetProjectIndustriesUniquePipe, 
    GetAssociationsPipe, 
    GetProjectsPipe, 
    GetSkillSummariesPipe } from "../common/services/resume-builder.service";
import { ResumePdfBuilderService } from "../common/services/resume-pdf-builder.service";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { BuildResumeComponent } from "../build-resume/build-resume.component";
import { BuildResumeNOComponent } from "../build-resumeNO/build-resumeNO.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatListModule } from "@angular/material/list";
import { MatCardModule } from "@angular/material/card";
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ResumePreviewComponent } from "../resume-preview/resume-preview.component";
import { ReviewCommentsModalComponent } from "../review-comments-modal/review-comments-modal.component";
import { BuildResumeFormUtilService } from '../build-resume/build-resume-form-state.service';
import { BuildResumeNOFormUtilService } from '../build-resumeNO/build-resumeNO-form-state.service';
import { ResumeHtmlLivePreviewComponent } from "../resume-preview/html-live-preview/html-live-preview.component";
import { BuildResumeRoutingModule } from "../build-resume/build-resume-routing.module";
import { BuildResumeNORoutingModule } from "../build-resumeNO/build-resumeNO-routing.module";
import { SharedModule } from "../shared/shared.module";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { UserSkillsEffects } from "./store/multi-select-skills-card.effects";
import { userSkillsFeature } from "./store/multi-select-skills-card.reducer";

@NgModule({
    declarations: [
        BuildResumeComponent,
        BuildResumeCardComponent,
        BuildResumeNOComponent,
        BuildResumeNOCardComponent,
        ResumeOutputModal,
        MultiDropdownCardComponent,
        MultiSelectCardComponent,
        // ResumeOutputComponent,
        MultiSelectSkillsCardComponent,
        FilterSkillByExpertisePipe,
        FilterSkillExpertiseUniquePipe,
        FilterResumeExpertiseGroupUniquePipe,
        FilterResumeExpertiseGroupByExpertisePipe,
        FilterEducationByTypePipe,
        GetProjectIndustriesUniquePipe,
        GetAssociationsPipe,
        GetProjectsPipe,
        GetSkillSummariesPipe,
        ResumeHtmlLivePreviewComponent
    ],
    imports: [
        MatAutocompleteModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        FlexLayoutModule,
        MatDialogModule,
        MatIconModule,
        MatExpansionModule,
        MatInputModule,
        IconModule,
        SecondaryToolbarModule,
        BreadcrumbsModule,
        MatButtonModule,
        PageLayoutModule,
        ContainerModule,
        TranslateModule,
        MatProgressSpinnerModule,
        MatDatepickerModule,
        MatTooltipModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatListModule,
        MatCardModule,
        DragDropModule,
        ResumePreviewModule,
        BuildResumeRoutingModule,
        BuildResumeNORoutingModule,
        SharedModule,
        StoreModule.forFeature(userSkillsFeature),
        EffectsModule.forFeature([UserSkillsEffects]),
    ],
    exports: [
        BuildResumeCardComponent, 
        BuildResumeComponent, 
        BuildResumeNOCardComponent, 
        BuildResumeNOComponent, 
        ResumeHtmlLivePreviewComponent
    ],
    providers: [
        ResumePdfBuilderService, 
        BuildResumeFormUtilService, 
        BuildResumeNOFormUtilService, 
        FilterSkillByExpertisePipe, 
        FilterSkillExpertiseUniquePipe, 
        FilterResumeExpertiseGroupUniquePipe,
        FilterResumeExpertiseGroupByExpertisePipe,
        FilterEducationByTypePipe, 
        GetProjectIndustriesUniquePipe, 
        GetAssociationsPipe, 
        GetProjectsPipe, 
        GetSkillSummariesPipe
    ]
})
export class BuildResumeCommonModule { }

