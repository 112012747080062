import { iconsIC } from "../static-data/icons-ic";
import { iconsFA } from "../static-data/icons-fa";
import Color from "color";
import theme from "../@vex/utils/tailwindcss";
import { environment } from "src/environments/environment";

export const menuItems = [
  {
    type: "link",
    label: "home",
    route: "/dashboards/analytics",
    icon: iconsIC.home,
    roles: `${environment.roles.ResumesUser}`,
    isSlideChecked: null,
  },
  {
    type: "link",
    label: "profileSummary",
    route: "/app/profileSummary",
    icon: iconsFA.user,
    roles: `${environment.roles.ResumesUser}`,
    isSlideChecked: false,
  },
  {
    type: "link",
    label: "* Profile Summary",
    route: "/app/profileSummary",
    altRoute: "/app/profileSummaryNO",
    icon: iconsFA.user,
    roles: `${environment.roles.ResumesRMT}`,
    isSlideChecked: true,
  },
  {
    type: "link",
    label: "skillSummary",
    route: "/app/skillSummary",
    icon: iconsFA["list-alt"],
    roles: `${environment.roles.ResumesUser}`,
    isSlideChecked: false,
  },
  {
    type: "link",
    label: "* Skill Summary",
    route: "/app/skillSummary",
    altRoute: "/app/skillSummaryNO",
    icon: iconsFA["list-alt"],
    roles: `${environment.roles.ResumesRMT}`,
    isSlideChecked: true,
  },
  // {
  //   type: "link",
  //   label: "Skills - Old",
  //   route: "/app/skills",
  //   icon: iconsFA["puzzle-piece"],
  //   roles: `${environment.roles.ResumesUser}`,
  //   isSlideChecked: false,
  // },
  {
    type: "link",
    label: "* Skills",
    route: "/app/skills",
    altRoute: "/app/skills",
    icon: iconsFA["puzzle-piece"],
    roles: `${environment.roles.ResumesRMT}`,
    isSlideChecked: true,
  },
  {
    type: "link",
    label: "Skills",
    route: "/app/skills",
    icon: iconsFA["puzzle-piece"],
    roles: `${environment.roles.ResumesUser}`,
    isSlideChecked: false,
  },
  {
    type: "link",
    label: "associations",
    route: "/app/associations",
    icon: iconsFA.building,
    roles: `${environment.roles.ResumesUser}`,
    isSlideChecked: false,
  },
  {
    type: "link",
    label: "* Associations",
    route: "/app/associations",
    altRoute: "/app/associationsNO",
    icon: iconsFA.building,
    roles: `${environment.roles.ResumesRMT}`,
    isSlideChecked: true,
  },
  {
    type: "link",
    label: "projects",
    route: "/app/projects",
    icon: iconsFA["project-diagram"],
    roles: `${environment.roles.ResumesUser}`,
    isSlideChecked: false,
  },
  {
    type: "link",
    label: "* Project",
    altLabel: "* Work Experience",
    route: "/app/projects",
    altRoute: "/app/projectsNO",
    icon: iconsFA["project-diagram"],
    roles: `${environment.roles.ResumesRMT}`,
    isSlideChecked: true,
  },
  {
    type: "dropdown",
    label: "education",
    icon: iconsFA["certificate"],
    roles: `${environment.roles.ResumesUser}`,
    isSlideChecked: false,
    children: [
      {
        type: "link",
        label: "education",
        route: "/app/education",
        icon: iconsFA.university,
        roles: `${environment.roles.ResumesUser}`,
        isSlideChecked: false,
      },
      {
        type: "link",
        label: "certApprovalsOnliner",
        route: "/app/certificationApprovalsOnliner",
        icon: iconsFA.home,
        roles: `${environment.roles.ResumesUser}`,
        isSlideChecked: false,
      },
    ],
  },
  {
    type: "link",
    label: "* Education",
    route: "/app/education",
    altRoute: "/app/educationNO",
    icon: iconsFA["certificate"],
    roles: `${environment.roles.ResumesRMT}`,
    isSlideChecked: true,
  },
  //{
  //  type: "link",
  //  label: "* Education",
  //  route: "/app/educationNO",
  //  icon: iconsFA.university,
  //  roles: `${environment.roles.ResumesRMT}`,
  //  isSlideChecked: true,
  //},
  {
    type: "subheading",
    label: "null",
    children: [
      {
        type: "link",
        label: "buildResume",
        route: "/app/build-resume",
        icon: iconsFA["file-alt"],
        roles: `${environment.roles.ResumesUser}`,
        isSlideChecked: false,
      },
      {
        type: "link",
        label: "* Build Resume",
        route: "/app/build-resume",
        altRoute: "/app/build-resumeNO",
        icon: iconsFA["file-alt"],
        roles: `${environment.roles.ResumesRMT}`,
        isSlideChecked: true,
      },
      {
        type: "link",
        label: "requestReview",
        route: "/app/requestReview",
        icon: iconsFA["user-edit"],
        roles: `${environment.roles.ResumesUser}`,
        isSlideChecked: null,
      },
      {
        type: "dropdown",
        label: "search",
        icon: iconsFA.search,
        roles: `${environment.roles.ResumesMarketing},${environment.roles.ResumesRMT},${environment.roles.ResumesSales},${environment.roles.ResumesCM}`,
        isSlideChecked: null,
        children: [
          {
            type: "link",
            label: "searchOnliners",
            route: "/app/searchOnliners",
            icon: iconsFA.home,
            roles: `${environment.roles.ResumesMarketing},${environment.roles.ResumesRMT},${environment.roles.ResumesSales},${environment.roles.ResumesCM}`,
            isSlideChecked: null,
          },
          {
            type: "link",
            label: "searchResumes",
            route: "/app/searchResumes",
            icon: iconsFA.home,
            roles: `${environment.roles.ResumesMarketing},${environment.roles.ResumesRMT},${environment.roles.ResumesSales},${environment.roles.ResumesCM}`,
            isSlideChecked: null,
          },
        ],
      },
      {
        type: "link",
        label: "workflows",
        route: "/app/workflows",
        icon: iconsFA["code-branch"],
        roles: `${environment.roles.ResumesCM},${environment.roles.ResumesRMT}`,
        isSlideChecked: null,
      },
      {
        type: "link",
        label: "Competency Maintenance",
        route: "/app/competencyMaintenance",
        icon: iconsFA["code-branch"],
        roles: `${environment.roles.ResumesAdmin}`,
        isSlideChecked: null,
      },
      {
        type: "link",
        label: "attributeMaintenance",
        route: "/app/attributeMaintenance",
        icon: iconsFA["code-branch"],
        roles: `${environment.roles.ResumesAdmin}`,
        isSlideChecked: null,
      },
      {
        type: "link",
        label: "certApprovals",
        route: "/app/certificationApprovals",
        icon: iconsFA["code-branch"],
        roles: `${environment.roles.ResumesPMT}`,
        isSlideChecked: null,
      },
      {
        type: "link",
        label: "dataApprovals",
        route: "/app/attributeApprovals",
        icon: iconsFA["code-branch"],
        roles: `${environment.roles.ResumesAdmin}`,
        isSlideChecked: null,
      },
      {
        type: "link",
        label: "skillsMaintenance",
        route: "/app/skillsMaintenance",
        icon: iconsFA["code-branch"], // TODO: Confirm which icon we want to use here.
        roles: `${environment.roles.ResumesRMT},${environment.roles.ResumesAdmin}`,
        isSlideChecked: null,
      },
    ],
  },

  /*{
        type: 'dropdown',
        label: 'myProfile',
        icon: iconsFA.user,
        children: [
            {
                type: 'link',
                label: 'profileSummary',
                route: '/apps/profileSummary',
                icon: iconsFA.portrait
            },
            {
                type: 'link',
                label: 'skillSummary',
                route: '/apps/skillSummary',
                icon: iconsFA.portrait
            },
        ]
    },
    {
        type: 'dropdown',
        label: 'sample',
        icon: iconsFA['file-invoice'],
        children: [
            {
                type: 'link',
                label: 'myResume',
                route: '/apps/resume',
                icon: iconsFA.home
            },
        ]
    },
    {
        type: 'subheading',
        label: 'otherApps',
        children: [
            {
                type: 'dropdown',
                label: 'onlinerDirectory',
                icon: iconsIC.contacts,
                children: [
                    {
                        type: 'link',
                        label: 'gridStyle',
                        route: '/apps/contacts/grid',
                    },
                    {
                        type: 'link',
                        label: 'tableStyle1',
                        route: '/apps/contacts/table',
                    },
                    {
                        type: 'link',
                        label: 'tableStyle2',
                        route: '/apps/directory',
                    },
                ]
            },
        ]
    }*/
];

// export const vexMenuItems = [
//     // {
//     //   type: 'link',
//     //   label: 'Dashboard',
//     //   route: '/dashboards/analytics',
//     //   icon: iconsIC.layers
//     // },
//     // {
//     //   type: 'subheading',
//     //   label: 'Feedback',
//     //   children: [
//     //     {
//     //       type: 'link',
//     //       label: 'Home',
//     //       route: '/apps/feedback/home',
//     //       icon: iconsFA.home
//     //     },
//     //     {
//     //       type: 'link',
//     //       label: 'Provide',
//     //       route: '/apps/feedback/feedback',
//     //       icon: iconsFA.edit
//     //     },
//     //     {
//     //       type: 'link',
//     //       label: 'Drafts',
//     //       route: '/apps/feedback/savedFeedback',
//     //       icon: iconsFA["file-alt"]
//     //     },
//     //     {
//     //       type: 'dropdown',
//     //       label: 'Request',
//     //       icon: iconsFA["user-plus"],
//     //       children: [
//     //         {
//     //           type: 'link',
//     //           label: 'Onliner',
//     //           route: '/apps/feedback/requestOnlinerFeedback'
//     //         },
//     //         {
//     //           type: 'link',
//     //           label: 'Client',
//     //           route: '/apps/feedback/requestClientFeedback'
//     //         }
//     //       ]
//     //     },
//     //     {
//     //       type: 'dropdown',
//     //       label: 'My Feedback',
//     //       icon: iconsFA["comment-alt"],
//     //       children: [
//     //         {
//     //           type: 'link',
//     //           label: 'Received',
//     //           route: '/apps/feedback/feedbackReceived'
//     //         },
//     //         {
//     //           type: 'link',
//     //           label: 'Provided',
//     //           route: '/apps/feedback/feedbackProvided'
//     //         }
//     //       ]
//     //     },
//     //   ]
//     // },
//     // {
//     //   type: 'subheading',
//     //   label: 'Resume',
//     //   children: [
//     //     {
//     //       type: 'link',
//     //       label: 'My Resume',
//     //       route: '/apps/resume',
//     //       icon: iconsFA.home
//     //     },
//     //   ]
//     // },
//     // {
//     //   type: 'subheading',
//     //   label: 'Competency',
//     //   children: [
//     //     {
//     //       type: 'link',
//     //       label: 'Profile',
//     //       route: '/apps/competency',
//     //       icon: iconsFA.home
//     //     },
//     //     {
//     //       type: 'link',
//     //       label: 'Notifications',
//     //       route: '/apps/competency',
//     //       icon: iconsFA.home
//     //     },
//     //   ]
//     // },
//     {
//         type: 'subheading',
//         label: 'Apps',
//         children: [
//             {
//                 type: 'link',
//                 label: 'All-In-One Table',
//                 route: '/apps/aio-table',
//                 icon: iconsIC.assignment,
//             },
//             {
//                 type: 'dropdown',
//                 label: 'Help Center',
//                 icon: iconsIC.contact_support,
//                 children: [
//                     {
//                         type: 'link',
//                         label: 'Getting Started',
//                         route: '/apps/help-center/getting-started'
//                     },
//                     {
//                         type: 'link',
//                         label: 'Pricing & Plans',
//                         route: '/apps/help-center/pricing'
//                     },
//                     {
//                         type: 'link',
//                         label: 'FAQ',
//                         route: '/apps/help-center/faq'
//                     },
//                     {
//                         type: 'link',
//                         label: 'Guides',
//                         route: '/apps/help-center/guides'
//                     }
//                 ]
//             },
//             {
//                 type: 'link',
//                 label: 'Calendar',
//                 route: '/apps/calendar',
//                 icon: iconsIC.date_range,
//                 badge: {
//                     value: '12',
//                     background: theme.colors['deep-purple']['500'],
//                     color: theme.textColor['deep-purple-contrast']['500']
//                 },
//             },
//             {
//                 type: 'link',
//                 label: 'Chat',
//                 route: '/apps/chat',
//                 icon: iconsIC.chat,
//                 badge: {
//                     value: '16',
//                     background: theme.colors.cyan['500'],
//                     color: theme.textColor['cyan-contrast']['600']
//                 },
//             },
//             {
//                 type: 'link',
//                 label: 'WYSIWYG Editor',
//                 route: '/apps/editor',
//                 icon: iconsIC.chrome_reader_mode,
//             },
//             {
//                 type: 'dropdown',
//                 label: 'Contacts',
//                 icon: iconsIC.contacts,
//                 children: [
//                     {
//                         type: 'link',
//                         label: 'List - Grid',
//                         route: '/apps/contacts/grid',
//                     },
//                     {
//                         type: 'link',
//                         label: 'List - Table',
//                         route: '/apps/contacts/table',
//                     }
//                 ]
//             },
//             {
//                 type: 'link',
//                 label: 'Scrumboard',
//                 route: '/apps/scrumboard',
//                 icon: iconsIC.assessment,
//                 badge: {
//                     value: 'NEW',
//                     background: theme.colors.primary['500'],
//                     color: theme.textColor['primary-contrast']['500']
//                 }
//             },
//         ]
//     },
//     {
//         type: 'subheading',
//         label: 'Pages',
//         children: [
//             {
//                 type: 'dropdown',
//                 label: 'Authentication',
//                 icon: iconsIC.lock,
//                 children: [
//                     {
//                         type: 'link',
//                         label: 'Login',
//                         route: '/login'
//                     },
//                     {
//                         type: 'link',
//                         label: 'Register',
//                         route: '/register'
//                     },
//                     {
//                         type: 'link',
//                         label: 'Forgot Password',
//                         route: '/forgot-password'
//                     }
//                 ]
//             },
//             {
//                 type: 'link',
//                 label: 'Coming Soon',
//                 icon: iconsIC.watch_later,
//                 route: '/coming-soon'
//             },
//             {
//                 type: 'dropdown',
//                 label: 'Errors',
//                 icon: iconsIC.error,
//                 badge: {
//                     value: '4',
//                     background: theme.colors.green['500'],
//                     color: theme.textColor['green-contrast']['600']
//                 },
//                 children: [
//                     {
//                         type: 'link',
//                         label: '404',
//                         route: '/pages/error-404'
//                     },
//                     {
//                         type: 'link',
//                         label: '500',
//                         route: '/pages/error-500'
//                     }
//                 ]
//             },
//             {
//                 type: 'link',
//                 label: 'Pricing',
//                 icon: iconsIC.attach_money,
//                 route: '/pages/pricing'
//             },
//             {
//                 type: 'link',
//                 label: 'Profile',
//                 icon: iconsIC.person_outline,
//                 route: '/pages/profile'
//             },
//             {
//                 type: 'link',
//                 label: 'Invoice',
//                 icon: iconsIC.receipt,
//                 route: '/pages/invoice'
//             },
//             {
//                 type: 'link',
//                 label: 'FAQ',
//                 icon: iconsIC.help,
//                 route: '/pages/faq'
//             },
//             {
//                 type: 'link',
//                 label: 'Guides',
//                 icon: iconsIC.book,
//                 route: '/pages/guides',
//                 badge: {
//                     value: '18',
//                     background: theme.colors.teal['500'],
//                     color: theme.textColor['teal-contrast']['500']
//                 },
//             },
//         ]
//     },
//     {
//         type: 'subheading',
//         label: 'UI Elements',
//         children: [
//             {
//                 type: 'dropdown',
//                 label: 'Components',
//                 icon: iconsIC.bubble_chart,
//                 children: [
//                     {
//                         type: 'link',
//                         label: 'Overview',
//                         route: '/ui/components/overview'
//                     },
//                     {
//                         type: 'link',
//                         label: 'Autocomplete',
//                         route: '/ui/components/autocomplete'
//                     },
//                     {
//                         type: 'link',
//                         label: 'Buttons',
//                         route: '/ui/components/buttons'
//                     },
//                     {
//                         type: 'link',
//                         label: 'Button Group',
//                         route: '/ui/components/button-group'
//                     },
//                     {
//                         type: 'link',
//                         label: 'Cards',
//                         route: '/ui/components/cards'
//                     },
//                     {
//                         type: 'link',
//                         label: 'Checkbox',
//                         route: '/ui/components/checkbox'
//                     },
//                     {
//                         type: 'link',
//                         label: 'Dialogs',
//                         route: '/ui/components/dialogs'
//                     },
//                     {
//                         type: 'link',
//                         label: 'Grid List',
//                         route: '/ui/components/grid-list'
//                     },
//                     {
//                         type: 'link',
//                         label: 'Input',
//                         route: '/ui/components/input'
//                     },
//                     {
//                         type: 'link',
//                         label: 'Lists',
//                         route: '/ui/components/lists'
//                     },
//                     {
//                         type: 'link',
//                         label: 'Menu',
//                         route: '/ui/components/menu'
//                     },
//                     {
//                         type: 'link',
//                         label: 'Progress',
//                         route: '/ui/components/progress'
//                     },
//                     {
//                         type: 'link',
//                         label: 'Progress Spinner',
//                         route: '/ui/components/progress-spinner'
//                     },
//                     {
//                         type: 'link',
//                         label: 'Radio',
//                         route: '/ui/components/radio'
//                     },
//                     {
//                         type: 'link',
//                         label: 'Slide Toggle',
//                         route: '/ui/components/slide-toggle'
//                     },
//                     {
//                         type: 'link',
//                         label: 'Slider',
//                         route: '/ui/components/slider'
//                     },
//                     {
//                         type: 'link',
//                         label: 'Snack Bar',
//                         route: '/ui/components/snack-bar'
//                     },
//                     {
//                         type: 'link',
//                         label: 'Tooltip',
//                         route: '/ui/components/tooltip'
//                     },
//                 ]
//             },
//             {
//                 type: 'dropdown',
//                 label: 'Forms',
//                 icon: iconsIC.format_color_text,
//                 children: [
//                     {
//                         type: 'link',
//                         label: 'Form Elements',
//                         route: '/ui/forms/form-elements'
//                     },
//                     {
//                         type: 'link',
//                         label: 'Form Wizard',
//                         route: '/ui/forms/form-wizard'
//                     }
//                 ]
//             },
//             {
//                 type: 'dropdown',
//                 label: 'Icons',
//                 icon: iconsIC.star,
//                 children: [
//                     {
//                         type: 'link',
//                         label: 'Material Icons',
//                         route: '/ui/icons/ic'
//                     },
//                     {
//                         type: 'link',
//                         label: 'FontAwesome Icons',
//                         route: '/ui/icons/fa'
//                     }
//                 ]
//             },
//             {
//                 type: 'dropdown',
//                 label: 'Page Layouts',
//                 icon: iconsIC.view_compact,
//                 children: [
//                     {
//                         type: 'dropdown',
//                         label: 'Card',
//                         children: [
//                             {
//                                 type: 'link',
//                                 label: 'Default',
//                                 route: '/ui/page-layouts/card',
//                                 routerLinkActive: { exact: true }
//                             },
//                             {
//                                 type: 'link',
//                                 label: 'Tabbed',
//                                 route: '/ui/page-layouts/card/tabbed',
//                             },
//                             {
//                                 type: 'link',
//                                 label: 'Large Header',
//                                 route: '/ui/page-layouts/card/large-header',
//                                 routerLinkActive: { exact: true }
//                             },
//                             {
//                                 type: 'link',
//                                 label: 'Tabbed & Large Header',
//                                 route: '/ui/page-layouts/card/large-header/tabbed'
//                             }
//                         ]
//                     },
//                     {
//                         type: 'dropdown',
//                         label: 'Simple',
//                         children: [
//                             {
//                                 type: 'link',
//                                 label: 'Default',
//                                 route: '/ui/page-layouts/simple',
//                                 routerLinkActive: { exact: true }
//                             },
//                             {
//                                 type: 'link',
//                                 label: 'Tabbed',
//                                 route: '/ui/page-layouts/simple/tabbed',
//                             },
//                             {
//                                 type: 'link',
//                                 label: 'Large Header',
//                                 route: '/ui/page-layouts/simple/large-header',
//                                 routerLinkActive: { exact: true }
//                             },
//                             {
//                                 type: 'link',
//                                 label: 'Tabbed & Large Header',
//                                 route: '/ui/page-layouts/simple/large-header/tabbed'
//                             }
//                         ]
//                     },
//                     {
//                         type: 'link',
//                         label: 'Blank',
//                         icon: iconsIC.picture_in_picture,
//                         route: '/ui/page-layouts/blank'
//                     },
//                 ]
//             },
//         ]
//     },
//     {
//         type: 'subheading',
//         label: 'Documentation',
//         children: [
//             {
//                 type: 'link',
//                 label: 'Changelog',
//                 route: '/documentation/changelog',
//                 icon: iconsIC.update,
//             },
//             {
//                 type: 'dropdown',
//                 label: 'Getting Started',
//                 icon: iconsIC.book,
//                 children: [
//                     {
//                         type: 'link',
//                         label: 'Introduction',
//                         route: '/documentation/introduction',
//                         fragment: 'introduction',
//                         routerLinkActive: { exact: true }
//                     },
//                     {
//                         type: 'link',
//                         label: 'Folder Structure',
//                         route: '/documentation/folder-structure',
//                         fragment: 'folder-structure',
//                         routerLinkActive: { exact: true }
//                     },
//                     {
//                         type: 'link',
//                         label: 'Installation',
//                         route: '/documentation/installation',
//                         fragment: 'installation',
//                         routerLinkActive: { exact: true }
//                     },
//                     {
//                         type: 'link',
//                         label: 'Development Server',
//                         route: '/documentation/start-development-server',
//                         fragment: 'start-development-server',
//                         routerLinkActive: { exact: true }
//                     },
//                     {
//                         type: 'link',
//                         label: 'Build for Production',
//                         route: '/documentation/build-for-production',
//                         fragment: 'build-for-production',
//                         routerLinkActive: { exact: true }
//                     }
//                 ]
//             },
//             {
//                 type: 'dropdown',
//                 label: 'Customization',
//                 icon: iconsIC.book,
//                 children: [
//                     {
//                         type: 'link',
//                         label: 'Configuration',
//                         route: '/documentation/configuration',
//                         fragment: 'configuration',
//                         routerLinkActive: { exact: true }
//                     },
//                     {
//                         type: 'link',
//                         label: 'Changing Styling',
//                         route: '/documentation/changing-styling-and-css-variables',
//                         fragment: 'changing-styling-and-css-variables',
//                         routerLinkActive: { exact: true }
//                     },
//                     {
//                         type: 'link',
//                         label: 'Using Custom Colors',
//                         route: '/documentation/using-custom-colors-for-the-primarysecondarywarn-palettes',
//                         fragment: 'using-custom-colors-for-the-primarysecondarywarn-palettes',
//                         routerLinkActive: { exact: true }
//                     },
//                     {
//                         type: 'link',
//                         label: 'Adding Menu Items',
//                         route: '/documentation/adding-menu-items',
//                         fragment: 'adding-menu-items',
//                         routerLinkActive: { exact: true }
//                     },
//                 ]
//             },
//             {
//                 type: 'link',
//                 label: 'Further Help',
//                 icon: iconsIC.book,
//                 route: '/documentation/further-help',
//                 fragment: 'further-help',
//                 routerLinkActive: { exact: true }
//             },
//         ]
//     },
//     {
//         type: 'subheading',
//         label: 'Customize',
//         children: []
//     },
//     {
//         type: 'link',
//         label: 'Configuration',
//         route: () => this.layoutService.openConfigpanel(),
//         icon: iconsIC.settings,
//     }
// ];
