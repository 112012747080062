<vex-secondary-toolbar current="Alternate Onliner Maintenance">
  <vex-breadcrumbs [crumbs]="['Alternate Onliner Maintenance']" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>
<div class="search-input card">
  <div class="page-title">Onliners</div>
  <obs-resumes-search-input (selectedOnliner) = "selectOnliner($event)"></obs-resumes-search-input>  
  <button class="mat-raised-button mat-button-base bg-green-500 text-contrast-white" 
          mat-raised-button
          type="button" 
          (click)="makeDefault()"
          [disabled]="!isSelected">
    <span *ngIf="isSubmitting"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span>Make Default
  </button>
</div>
<div class="p-6"*ngIf="isLoading" >
  <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="24px">
    <mat-spinner></mat-spinner>
  </div>
</div>
<div row *ngIf="!isLoading" fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" class="main-section">
  <div class="profile-section pl-10 pr-10 pb-10" fxFlex="auto" fxFlex.lt-lg="auto" vexContainer>
    <div row class="add-Non-Onliner">
      <p style="cursor: pointer;" [hidden]="!canAddNewNonOnliner" class="text-obs-blue text-center pb-8" (click)="createNewNonOnlinerAsync()"><i class="fas fa-plus"></i> Add Non Onliner</p>
    </div>
    <mat-accordion>
      <ng-container *ngFor="let nonOnliner of nonOnliners">
        <obs-alternateOnlinerMaintenance-card 
          [nonOnliner]="nonOnliner" 
          (markClean)="markClean()" 
          [formIsDirty]="formIsDirty" 
          [altOnlinerDefault]="altOnlinerDefault"
          [nonOnlinerCommentsFieldLimit]="nonOnlinerCommentsFieldLimit" 
          (refresh)="refresh()" 
          (delete)="delete()" 
          (saveNewNonOnliner)="saveNewNonOnliner()" 
          (markDirty)="markDirty()"></obs-alternateOnlinerMaintenance-card>
      </ng-container>
    </mat-accordion>
    
  </div>    
</div>
