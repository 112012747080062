import { AttributeApprovalsComponent } from './attribute-approvals.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PendingChangesGuard } from '../common/services/pending-changes-guard.service';

const AttributeApprovalsRoutes: Routes = [
  {
    path: '',
    canDeactivate: [PendingChangesGuard],
    component: AttributeApprovalsComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(AttributeApprovalsRoutes)],
  exports: [RouterModule],
})
export class AttributeApprovalsRoutingModule { }
