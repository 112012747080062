import { AltOnlinerDefault } from './../common/data-models/AltOnlinerDefault';
import { UserService } from 'src/app/common/services/user.service';
import { SnackBarService } from './../common/services/snackbar.service';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, HostListener } from '@angular/core';
import { AlternateOnlinerMaintenanceService } from '../common/services/alternateOnlinerMaintenance.service'
import { AuthService } from '../common/services/auth.service'
import { CommonService } from '../common/services/common.service';
import icMoreVert from '@iconify-icons/ic/twotone-more-vert';
import { Observable } from 'rxjs';
import { ConfirmationDialogComponent, ConfirmDialog } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { NonOnliner } from '../common/data-models/NonOnliner';
import { SidenavComponent } from 'src/@vex/layout/sidenav/sidenav.component';
import { NavigationService } from '../../@vex/services/navigation.service';
import { AccountingEmployee } from '../common/data-models/commonDataModels';

@Component({
  selector: 'obs-alternateOnlinerMaintenance',
  templateUrl: './alternateOnlinerMaintenance.component.html',
  styleUrls: ['./alternateOnlinerMaintenance.component.scss']
})
export class AlternateOnlinerMaintenanceComponent implements OnInit {
  public nonOnliners: NonOnliner[];
  public onliners: NonOnliner[];
  private userId: string;
  public nonOnlinerCommentsFieldLimit: number;
  public fieldName = 'nonOnlinerComment';
  public canAddNewNonOnliner: boolean;
  public isLoading = true;
  public formIsDirty = false;
  public altOnlinerDefault: string;
  public accountingEmployees: AccountingEmployee[];
  icMoreVert = icMoreVert;
  isSelected: boolean = false;
  isSubmitting: boolean = false;
  selectedOnliner: AltOnlinerDefault;

  public constructor(
    private alternateOnlinerMaintenanceService: AlternateOnlinerMaintenanceService,
    private authService: AuthService,
    public commonService: CommonService,
    public snackBarService: SnackBarService,
    public dialog: MatDialog,
    private userService: UserService,
    private sidenav: SidenavComponent,
    private navigationService: NavigationService
  ) { }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean | Observable<boolean> {
    if(!this.authService.isAuthenticated()) return true;
    return !this.formIsDirty;
  }

  public ngOnInit(): void {
    this.selectedOnliner = new AltOnlinerDefault('', '', new Date, true);
    this.canAddNewNonOnliner = true;
    this.commonService.getTextLimit(this.fieldName).subscribe(data => {
      this.nonOnlinerCommentsFieldLimit = data;
    },
      error => this.snackBarService.error(error));
    if (!this.nonOnliners) {
      this.nonOnliners = new Array();
    }
    this.userId = this.authService.getUserId();
    this.getNonOnliners();
    this.getAccountingEmployees();
    this.getAltOnlinerDefault();
  }

  public createNewNonOnlinerAsync(): void {
    if (this.formIsDirty) {
      this.showConfirmationDialog();
    } else {
      this.nonOnliners.unshift(new NonOnliner('', '', '', null, '', true, new Date(), false));
      this.canAddNewNonOnliner = false;
    }
  }

  public getAccountingEmployees(): void {
    this.userService.getAccountingEmployees().subscribe(
       (accountingEmployees) => {
            this.accountingEmployees = accountingEmployees;
            this.isLoading = false;
       },
       (error) => {
           this.snackBarService.message(error);          
        },        
    );
  }

  public getNonOnliners(): void {
    this.alternateOnlinerMaintenanceService.getNonOnlinersAsync().subscribe(data => {
      this.nonOnliners = new Array();
      this.onliners = new Array();
      data.forEach(e => {
        if (e.isOnliner) {
          this.onliners.push(new NonOnliner(
            e.userId,
            e.firstName,
            e.lastName,
            e.accountingUserId,
            e.comments,
            e.isActive,
            e.modifiedDate,
            e.isOnliner));
        }
        else {
          this.nonOnliners.push(new NonOnliner(
            e.userId,
            e.firstName,
            e.lastName,
            e.accountingUserId,
            e.comments,
            e.isActive,
            e.modifiedDate,
            e.isOnliner));
        }
      },
         error => this.snackBarService.error(error));     
    });
  }

  public getAltOnlinerDefault(): void {
    this.userService.getAltOnlinerDefault().subscribe(result => {
      this.navigationService.setAltOnlinerDefault(result);
      if (result) this.altOnlinerDefault = result.altOnlinerUserId;
      else this.altOnlinerDefault = null;
    });
  }

  showConfirmationDialog() {
    const confirmDialog = new ConfirmDialog();

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: confirmDialog,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        this.markClean();
        this.nonOnliners.unshift(new NonOnliner('', '', '', null, '', true, null, false));
        this.canAddNewNonOnliner = false;
      }
      if (result === 'cancel') {

      }
    });
  }

  public refresh(): void {
    this.getNonOnliners();
    this.getAltOnlinerDefault();
    this.formIsDirty = false;
  }

  public delete(): void {
    this.nonOnliners.shift();
    this.canAddNewNonOnliner = true;
  }

  public saveNewNonOnliner(): void {
    this.canAddNewNonOnliner = true;
    this.formIsDirty = false;
  }

  public markDirty(): void {
    this.formIsDirty = true;
  }

  public markClean(): void {
    this.formIsDirty = false;
  }

  selectOnliner($event) {
    this.selectedOnliner.altOnlinerUserId = $event.userId;
    this.isSelected = true;
  }

  makeDefault() {
    if (this.altOnlinerDefault) {
      this.userService.updateAltOnlinerDefault(this.selectedOnliner).subscribe(result => {
        this.navigationService.setAltOnlinerDefault(result);
        this.isSubmitting = false;
        this.snackBarService.message("Default Alternate Onliner set successfully!");
        this.refresh();
      }, (error) => {
        this.snackBarService.message(error);
      });

    } else {
      this.isSubmitting = true;
      this.userService.postAltOnlinerDefault(this.selectedOnliner).subscribe(result => {
        this.navigationService.setAltOnlinerDefault(result);
        this.isSubmitting = false;
        this.snackBarService.message("Default Alternate Onliner set successfully!");
        this.refresh();
      }, (error) => {
        this.snackBarService.message(error);
      });
    }
    this.isSelected = false;
    this.selectedOnliner = new AltOnlinerDefault('', '', new Date, true);
  }
}
