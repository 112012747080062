import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { DataApproval } from "src/app/common/data-models/DataApproval";
import { AttributeFilterService } from "src/app/common/services/attribute-filter.service";
import { Observable } from "rxjs";
import { CommonService } from "src/app/common/services/common.service";
import { isNullOrUndefined } from "@swimlane/ngx-datatable";

@Component({
  selector: "obs-attribute-list-filter-criteria",
  templateUrl: "./attribute-list-filter-criteria.component.html",
  styleUrls: ["./attribute-list-filter-criteria.component.scss"],
})
export class AttributeListFilterCriteriaComponent implements OnInit {
  @Input() filterCriteriaForm: UntypedFormGroup;
  @Input() attributes: DataApproval[];
  @Output() filterAttributesTriggered = new EventEmitter<boolean>();
  endDate: Date = new Date();
  filteredAttributes: Observable<DataApproval[]>;
  dataTypesList: string[];
  attributeNames: string[];

  constructor(
    private attributeFilterService: AttributeFilterService,
    public commonService: CommonService
  ) {}

  resetForm() {
    this.attributeFilterService.resetForm();
    this.filterAttributesTriggered.emit(true);
  }

  filterAttributes($event) {
    if (this.filterCriteriaForm.valid) {
      this.filterAttributesTriggered.emit($event);
    }
  }

  setAttributeNames(): void {
    const allAttributeNames = this.attributes.map((att) => att.name);
    this.attributeNames = [...new Set(allAttributeNames)];
  }

  setDataTypes(): void {
    this.commonService.getAttributeTypes().subscribe((response) => {
      this.dataTypesList = response
        .filter(
          (data) =>
            data.enum != "SKILLGROUP" &&
            data.enum != "SKILLSAREA" &&
            data.enum != "SKILLCATEGORY"
        )
        .map((data) => data.name);
    });
  }

  handleChange($event) {
    this.filterAttributesTriggered.emit(true);
  }

  ngOnInit() {
    this.setDataTypes();
    this.setAttributeNames();
  }
}
