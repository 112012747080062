import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from 'rxjs';
import {Resume} from "../common/data-models/Resume";

export enum BuildResumeState {
    isDirty, 
    isTouched, 
    pristine,
    refresh,
};

@Injectable()
export class BuildResumeFormUtilService {
    buildResumeFormState: BehaviorSubject<{state:BuildResumeState, resume: Resume}> = new BehaviorSubject({state: BuildResumeState.pristine, resume: null});

    setFormsState(state: BuildResumeState, resume: Resume) {
        this.buildResumeFormState.next({state, resume});
    }

    getFormsState(): Observable<{state: BuildResumeState, resume: Resume}> {
        return this.buildResumeFormState.asObservable();
    }
}