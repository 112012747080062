<vex-secondary-toolbar current="Search">
    <vex-breadcrumbs [crumbs]="['Resumes']" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>

<div class="p-6" *ngIf="isLoading" >
    <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="24px">
      <mat-spinner></mat-spinner>
    </div>
</div>
<div row *ngIf="!isLoading">
    <div class="search-input card">
        <obs-resumes-search-input (selectedOnliner) = "selectOnliner($event)"></obs-resumes-search-input>
    </div>

    <div class="search-results card" *ngIf="isSelected">
        <obs-resumes-search-results></obs-resumes-search-results>
    </div>
</div>