
import { Inject, Injectable } from '@angular/core';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { interceptAndRetry } from '../utilities/retryUtil';
import { filter, subscribeOn, switchMap, takeUntil } from 'rxjs/operators';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest, AuthenticationResult, EventMessage, EventType, AccountInfo } from '@azure/msal-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class AuthService {
    private readonly unsubscribe = new Subject<void>();
    public sessionTimeout: boolean;
    public sessionExtensionConfirmation: boolean
    public accessToken: any;
    activeAccount: AccountInfo;

    get isSessionExpired() { return this.isAuthenticated() && this.tokenHasExpired() }

    constructor(
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private broadcastService: MsalBroadcastService,
        private authService: MsalService,
        private http: HttpClient
    ) { }

    login(): void {
        if (this.msalGuardConfig.authRequest) {
            this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
        } else {
            this.authService.loginRedirect();
        }
    }

    logout(): void {
        const currentAccount = this.authService.instance.getActiveAccount();
        const url =
            environment.apiEndpoint +
            '/api/authorization/invalidate/' +
            this.getUserId();
        const handleLogout = () => {
            this.authService.logout({
                account: currentAccount,
            });
        };

        this.http
            .post(url, {}, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.accessToken
                }),
            })
            .subscribe({
                complete: handleLogout,
                error: handleLogout,
            });

    }

    initAuth(): void {
        this.authService.handleRedirectObservable();
        this.broadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this.unsubscribe)
            )
            .subscribe(() => {
                this.setAuthenticationStatus();
            })

        this.broadcastService.msalSubject$
            .pipe(
                filter((message: EventMessage) => message.eventType === EventType.LOGIN_SUCCESS),
                takeUntil(this.unsubscribe)
            )
            .subscribe((message: EventMessage) => {
                const authResult = message.payload as AuthenticationResult;
                this.authService.instance.setActiveAccount(authResult.account);
                var tokenRequest = {
                    scopes: [environment.webApiClientId + '/.default']
                };
                this.authService.acquireTokenSilent(tokenRequest).subscribe(resp => {
                    this.accessToken = resp.accessToken;
                })
            });

        const myAccounts: AccountInfo[] = this.authService.instance.getAllAccounts();
    }

    setAuthenticationStatus(): void {
        let activeAccount = this.authService.instance.getActiveAccount();

        if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
            activeAccount = this.authService.instance.getAllAccounts()[0];
            this.authService.instance.setActiveAccount(activeAccount);
        }
        this.activeAccount = activeAccount;
        if (activeAccount) {
            var tokenRequest = {
                scopes: [environment.webApiClientId + '/.default']
            };
            this.authService.acquireTokenSilent(tokenRequest).subscribe(resp => {
                this.accessToken = resp.accessToken;
            })
        }
    }

    isAuthenticated(): boolean {
        return !!this.authService.instance.getActiveAccount();
    }

    getUserId(): string {
        return this.activeAccount ? this.activeAccount.username.split('@')[0] : '';
    }

    getUserProfile(): any {
        return this.activeAccount;
    }

    getUserRoles(): any {
        return this.activeAccount?.idTokenClaims?.roles;
    }

    doesUserHaveRole(roles: string[]): boolean {
        const userRoles = this.getUserRoles();
        if (!roles || roles.length <= 0 || !userRoles || userRoles.length <= 0) {
            return false;
        }

        let userHaveRole = false;

        userRoles.forEach(userRole => {
            if (roles.includes(userRole)) {
                userHaveRole = true;
                return;
            }
        });

        return userHaveRole;
    }

    async getToken(): Promise<string> {
        var tokenRequest = {
            scopes: [environment.webApiClientId + '/.default']
        };
        return (await this.authService.instance.acquireTokenSilent(tokenRequest)).accessToken;
    }

    acquireToken(): Observable<any> {
        const renewIdTokenRequest = {
            scopes: [environment.webApiClientId + '/.default']
        };
        return interceptAndRetry(() => this.authService.acquireTokenPopup(renewIdTokenRequest), 250, 7);
    }

    showPopUp(): boolean {
        const currentTimeInSeconds = new Date().getTime() / 1000;
        const tokenExpiryTimeInSeconds = this.getUserProfile().idTokenClaims.exp - 300; //the expirery is after 60 min we want to show the popup at 55 min
        return !tokenExpiryTimeInSeconds || currentTimeInSeconds > tokenExpiryTimeInSeconds;
    }

    tokenHasExpired(): boolean {
        const currentTimeInSeconds = new Date().getTime() / 1000;
        const tokenExpiryTimeInSeconds = this.getUserProfile().idTokenClaims.exp;
        return !tokenExpiryTimeInSeconds || currentTimeInSeconds > tokenExpiryTimeInSeconds;
    }
}
