import { RSkill } from "./RSkill";
import { RAttribute } from "./commonDataModels";

export class RResumeExpertiseGroup {
    public resumeId: number;
    public expertiseType: RAttribute;
    public expertiseTypeId: number;
    public skill: RSkill;
    public skillId: number;
    public sortOrder: number
    public expertiseSortOrder: number;

    constructor(
        resumeId: number, 
        expertiseType: RAttribute, 
        expertiseTypeId: number, 
        skill: RSkill,
        skillId: number, 
        sortOrder: number, 
        expertiseSortOrder: number) {
            this.resumeId = resumeId;
            this.expertiseType = expertiseType,
            this.expertiseTypeId = expertiseTypeId;
            this.skill = skill;
            this.skillId = skillId;
            this.sortOrder = sortOrder;
            this.expertiseSortOrder = expertiseSortOrder;
    }
}