import { from, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

/**
 * Exponential backoff between retries - time between will double after each retry.
 * The first retry waits 0ms in order to make a rapid retry before it starts waiting by "initialWaitTimeMS".
 * eg 6 iterations will take a total of about 8s of waiting - ms: 0,250,500,1000,2000,4000.
 * If each retry takes time, eg WS calls timeout time, then that will apply to each iteration as well.
 * @param toObserve zero param function (which returns Observable<any>) that will be called and retried on caught error.
 * @param initialWaitTimeMS 
 * @param totalNumAttempts Must be >= 1. <=0 will throwError without any attempts.
 * @returns 
 */
export function interceptAndRetry(toObserve: () => Observable<any>, initialWaitTimeMS: number, totalNumAttempts: number): Observable<any> {
    let iteration: number = 1;

    const interceptAndRetryRecurse = (): Observable<any> => {
        if(iteration > totalNumAttempts) {
            return throwError('Failed too many times');
        }

        
        let observable: Observable<any> = toObserve();
        return observable.pipe(catchError(err => {
            return catchAndRetry(err)
        }));
    };

    /**
     * 
     * @param iteration Second iteration (the first retry) returns 0 so we make 2 rapid fire attempts
     * @returns 
     */
    const timeToWaitBackoff = (iteration: number): number => {
        return  iteration < 3 ? 0 
                : Math.pow(2, (iteration - 3)) * initialWaitTimeMS;
                //as iteration increments, return value doubles - eg 250, 500, 1000...
    }

    const catchAndRetry = (err: any): Observable<any> => {
        console.log(err);
        iteration++;
        let timeToWait = timeToWaitBackoff(iteration);
        console.log("Iteration: " + iteration  + " Time To Wait: " + timeToWait);
        let delayedTry = new Promise((resolve, reject) => {
            setTimeout(() => {
                let observable: Observable<any> = interceptAndRetryRecurse();
                observable.subscribe(result => {
                    resolve(result);
                },
                error => {
                    reject(error);
                });
            }, timeToWait);
        });

        
        return from(delayedTry);
    }

    return interceptAndRetryRecurse();

}