import { RAttribute, RExpertiseType } from "./../data-models/commonDataModels";
import { Injectable } from "@angular/core";

import {
  defaultOnliner,
  defaultRExpertiseType,
  defaultAttribute,
} from "./onliner.service";
import { Employee } from "../data-models/Employee";

import { startWith, map } from "rxjs/operators";
import { Skill } from "../data-models/commonDataModels";
import { CareerMentor } from "../data-models/CareerMentor";

@Injectable()
export class AutoSuggestService {

  rAttributeDisplay = (option?: RAttribute): string | undefined =>
    option ? `${option.name}`.trim() : undefined;

  careerMentorDisplay = (option?: CareerMentor): string | undefined =>
    option ? `${option.firstName} ${option.lastName}`.trim() : undefined;
  onlinerDisplay = (option?: Employee): string | undefined =>
    option ? `${option.firstName} ${option.lastName}`.trim() : undefined;

  setOnlinerFilter(
    onlinerControl,
    onliners: Employee[] | CareerMentor[],
    includeAll: boolean = false,
    onFilter: boolean = false
  ) {
    onliners = onliners.sort((a, b) => (a.firstName < b.firstName ? -1 : 1));

    if (!includeAll) {
      const defaultIndex = onliners.findIndex(
        (o) => o.firstName === defaultOnliner.firstName
      );
      if (defaultIndex > -1) {
        onliners.splice(defaultIndex, 1);
      }
    }

    if (onFilter) {
      onliners.unshift(defaultOnliner);
    }

    return onlinerControl.valueChanges.pipe(
      startWith<string | Employee | CareerMentor>(null),
      map((value) =>
        typeof value === typeof ""
          ? (value as string)
          : ((value as any) || {}).description
      ),
      map((description) =>
        description
          ? this.onlinerFilter(description.toString(), onliners)
          : onliners.slice()
      )
    );
  }

  private onlinerFilter(
    val: string,
    onliners: Employee[] | CareerMentor[]
  ): Employee[] | CareerMentor[] {
    type EmployeeCompare = { firstName: string; lastName: string };
    const filterFn = (onliner: EmployeeCompare) =>
      `${onliner.firstName.toLowerCase()} ${onliner.lastName.toLowerCase()}`.includes(
        val.toLowerCase()
      );
    return (onliners as EmployeeCompare[]).filter(filterFn) as typeof onliners;
  }

  setRAttributeFilter(
    attributeControl,
    filterTypeId: number,
    rAttributes: RAttribute[],
    includeAll: boolean = false,
    onFilter: boolean = false
  ) {
    if (filterTypeId) {
      rAttributes = rAttributes
        .filter((s) => s.attributeTypeId === filterTypeId)
        .sort((a, b) => (a.name < b.name ? -1 : 1));
    } else {
      rAttributes = rAttributes.sort((a, b) => (a.name < b.name ? -1 : 1));
    }

    if (!includeAll) {
      const defaultIndex = rAttributes.findIndex(
        (o) => o.name === defaultAttribute.name
      );
      if (defaultIndex > -1) {
        rAttributes.splice(defaultIndex, 1);
      }
    }

    if (onFilter) {
      rAttributes.unshift(defaultAttribute);
    }

    return attributeControl.valueChanges.pipe(
      startWith<string | RAttribute>(null),
      map((value) =>
        typeof value === typeof ""
          ? (value as string)
          : ((value as any) || {}).description
      ),
      map((description) =>
        description
          ? this.rAttributeFilter(description.toString(), rAttributes)
          : rAttributes.slice()
      )
    );
  }

  private rAttributeFilter(
    val: string,
    rAttributes: RAttribute[]
  ): RAttribute[] {
    type AttributeCompare = { name: string };
    const filterFn = (rAttribute: AttributeCompare) =>
      `${rAttribute.name.toLowerCase()}`.includes(val.toLowerCase());
    return (rAttributes as AttributeCompare[]).filter(
      filterFn
    ) as typeof rAttributes;
  }
}
