import { RSkill } from "./RSkill";
import { SkillSummary } from "./SkillSummary";
import { ProfileSummary } from "./ProfileSummary";
import { RAssociation } from "./RAssociation";
import { EducationModel } from "./EducationModel";
import { RProject } from "./project";
import { RReviewComment } from "./RReviewComment";
import { RAttribute } from "./commonDataModels";
import { Employee } from "./Employee";
import { RResumeExpertiseGroup } from "./RResumeExpertiseGroup";

export class Resume {
  public id: number;
  public user: Employee;
  public statusType: RAttribute;
  public statusTypeId: number;
  public isClientHidden: boolean;
  public isProfessionalResume: boolean;
  public dateModified: Date;
  public dateApproved: Date;
  public dateRequested: Date;
  public spins: RAttribute;
  public spinsId: number;
  public accreditation: string;
  public profileSummaryId: number;
  public profileSummaries: ProfileSummary[];
  public skillSummaries: SkillSummary[];
  public skills: RSkill[];
  public userSkills: RSkill[];
  public resumeExpertiseGroups: RResumeExpertiseGroup[];
  public associations: RAssociation[];
  public educations: EducationModel[];
  public projects: RProject[];
  public reviewComments: RReviewComment[];
  public isSnapshot: boolean;
  public maxApprovedSpinDate: Date;
  public isProjectMostRecent: boolean;
  public useOriginal: boolean;

  constructor(
    id: number,
    user: Employee,
    statusType: RAttribute,
    statusTypeId: number,
    isClientHidden: boolean,
    isProfessionalResume: boolean,
    dateModified: Date,
    dateApproved: Date,
    spins: RAttribute,
    spinsId: number,
    accreditation: string,
    profileSummaryId: number,
    profileSummaries: ProfileSummary[],
    skillSummaries: SkillSummary[],
    skills: RSkill[],
    userSkills: RSkill[],
    resumeExpertiseGroups: RResumeExpertiseGroup[],
    associations: RAssociation[],
    educations: EducationModel[],
    projects: RProject[],
    reviewComments: RReviewComment[],
    dateRequested: Date,
    isSnapshot: boolean,
    maxApprovedSpinDate: Date,
    isProjectMostRecent: boolean
  ) {
    this.id = id;
    this.user = user;
    this.statusType = statusType;
    this.statusTypeId = statusTypeId;
    this.isClientHidden = isClientHidden;
    this.isProfessionalResume = isProfessionalResume;
    this.dateModified = dateModified;
    this.dateApproved = dateApproved;
    this.spins = spins;
    this.spinsId = spinsId;
    this.accreditation = accreditation;
    this.profileSummaryId = profileSummaryId;
    this.profileSummaries = profileSummaries;
    this.skillSummaries = skillSummaries;
    this.skills = skills;
    this.userSkills = userSkills;
    this.resumeExpertiseGroups = resumeExpertiseGroups;
    this.associations = associations;
    this.educations = educations;
    this.projects = projects;
    this.reviewComments = reviewComments;
    this.dateRequested = dateRequested || null;
    this.isSnapshot = isSnapshot;
    this.maxApprovedSpinDate = maxApprovedSpinDate;
    this.isProjectMostRecent = isProjectMostRecent;
    this.useOriginal = false;
  }
}
