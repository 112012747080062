<div class="pick-list-wrap">
  <div
    class="dialog-wrap"
    [ngClass]="{
      'dialog-header': pickList.theme === 'success',
      'dialog-header-danger': pickList.theme === 'danger'
    }"
  >
    <span class="dialog-title d-inline">{{ pickList.title }}</span>
    <button
      mat-dialog-close="cancel"
      type="button"
      class="btn btn-link close pull-right text-white no-underline"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="dialog-body">
    <div class="pick-list-selected-wrapper">
      <button
        mat-raised-button
        *ngFor="let pickListItem of selectedItems"
        class="pick-list-selected"
        (click)="listItemDeselect(pickListItem)"
        [ngClass]="{ others: pickListItem.isOther }"
      >
        {{ pickListItem.name }} | x
      </button>
    </div>

    <mat-form-field class="filter-wrap">
      <mat-label>Filter</mat-label>
      <input
        matInput
        [formControl]="filterStr"
        (ngModelChange)="isFiltered($event)"
        (keyup.arrowdown)="focusResults()"
        maxlength="{{ initialStrLimit }}"
        cdkFocusInitial
        class="filterStrInput"
      >
      <span class="filterStrSpan">{{ filterStrLimit }} chars left</span>
      <button
        class="mat-raised-button mat-button-base bg-gray-500 text-contrast-black reset-button"
        (click)="resetFilter()"
      >
        Reset
      </button>
    </mat-form-field>
    <mat-error *ngIf="errorMessage" class="error">{{ errorMessage }}</mat-error>    
    <!-- <div *ngIf="pickList.message" class="text-center">
    <span [innerHTML]="pickList.message"></span>
  </div>
  <br>
  <div fxLayout="row">
    <mat-form-field fxFlex>
      <mat-label>Comments</mat-label>
      <textarea
        #comments="ngModel"
        matInput
        cdkTextareaAutosize
        [(ngModel)]="data.comments">
      </textarea>
    </mat-form-field>
  </div>
  <div fxLayout="row">
    <mat-form-field fxLayout="row">
      <mat-label>Units</mat-label>
      <input 
        #units="ngModel"
        matInput 
        type="number" 
        min="0" 
        max="999.99" 
        [(ngModel)]="data.unitsReceived"
        required>
    </mat-form-field>    
  </div> -->
    <mat-selection-list class="pickListItems" multiple #itemsSelectInput>
      <mat-spinner *ngIf="isLoading"></mat-spinner>
      <mat-list-option
        *ngFor="let pickListItem of filteredPickListData"
        (click)="listItemSelected(pickListItem)"
        [disabled] = "pickListItem.id < 0"
        class="pickListItem"
        [ngClass]="{ others: pickListItem.isOther }"
        (keyup.enter)="listItemSelected(pickListItem)"
      >
        {{ getItemName(pickListItem) }}
      
      </mat-list-option>
    </mat-selection-list>
    <div class="row no-gutters justify-end p-2" fxLayout="row" fxLayoutGap="8px">      
      <button *ngIf="pickList.cancelButtonTitle" 
        type="button" 
        class="mat-raised-button mat-button-base mt-3 bg-gray-500 text-contrast-black" 
        mat-raised-button 
        mat-dialog-close="cancel">
        {{pickList.cancelButtonTitle}}
      </button>
      <button *ngIf="pickList.okButtonTitle" 
        type="button"       
        class="mat-raised-button mat-button-base mt-3 bg-green-500 text-contrast-white" 
        mat-raised-button
        [ngClass]="{'success-button': pickList.theme==='success', 'danger-button': pickList.theme==='danger'}"  
        (click)="confirmSelection()">
        {{pickList.okButtonTitle}}
      </button>      
    </div>
  </div>
</div>
