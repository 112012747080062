<div
  class="resume-preview-section pl-10 pr-10 pb-10"
  fxFlex="auto"
  fxFlex.lt-lg="auto"
  vexContainer
  *ngIf="renderError$ | async"
>
  <app-resume-preview-error></app-resume-preview-error>
</div>

<div style="height: 100%; max-width: 100vw; overflow: auto">
  <div style="padding: 16px 0 16px 0; background-color: #eee">
    <div
      class="text-center mb-3"
      [hidden]="(isLoading$ | async) || (renderError$ | async)"
    >
      <div class="text-middle mb-3">
        <em style="font-size: 12px;">Fonts shown in preview may differ from the rendered PDF/DOCX output</em>
      </div>
      <obs-resume-preview-action-buttons
        (onOpen)="generatePdf('open')"
        (onDownload)="generatePdf('download')"
        (onPrint)="generatePdf('print')"
        [resume]="resume"
        [originalResume]="originalResume"
      >
      </obs-resume-preview-action-buttons>
    </div>

    <div class="p-6" *ngIf="isLoading$ | async">
      <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="24px">
        <mat-spinner></mat-spinner>
      </div>
    </div>
    <div id="render-container"></div>
    <div
      class="text-center mb-3"
      [hidden]="(isLoading$ | async) || (renderError$ | async)"
    >
      <obs-resume-preview-action-buttons
        (onOpen)="generatePdf('open')"
        (onDownload)="generatePdf('download')"
        (onPrint)="generatePdf('print')"
        [resume]="resume"
        [originalResume]="originalResume"
      >
      </obs-resume-preview-action-buttons>
    </div>
  </div>
</div>
