import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UriDecodePipe } from './uri-decode.pipe';


@NgModule({
  declarations: [UriDecodePipe],
  exports: [
      UriDecodePipe,
  ],
  imports: [
    CommonModule
  ],
})
export class UriDecodeModule {
}
