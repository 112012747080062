import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from "@angular/router";

@Injectable()
export class AuthGuardLogout implements CanActivate {

    constructor(
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : boolean {
        var nav = this.router.getCurrentNavigation();

        if(nav.previousNavigation && nav.previousNavigation.id != 0)
            return true

        this.router.navigateByUrl("/dashboards/analytics");
        return false;
    }
}
