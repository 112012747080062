import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType  } from '@ngrx/effects';
import { EMPTY, Observable, of } from 'rxjs';
import { map, exhaustMap, catchError, delay } from 'rxjs/operators';
import { CommonService } from "src/app/common/services/common.service";
import { SnackBarService } from 'src/app/common/services/snackbar.service';
import { AttributeService } from "src/app/common/services/attribute.service";
import { SkillsService } from "src/app/common/services/skill.service";
import * as userSkillsActions from "./multi-select-skills-card.actions";
import { OnlinerSkill } from "../../build-resume/build-resume-card/models/OnlinerSkill";
import { RAttribute } from 'src/app/common/data-models/commonDataModels';
 
@Injectable()
export class UserSkillsEffects {
  loadUserSkillsExpertiseTypes$ = createEffect(() => 
    this.actions$.pipe(
      ofType(userSkillsActions.loadExpertiseTypeData),
      exhaustMap(() => 
        // call the service
        this.commonService.getExpertiseTypes().pipe(
          map(expertiseTypes => userSkillsActions.setUserSkillsExpertiseTypes({ expertiseTypes })),
          catchError(() => {
            this.snackBarService.message("Error fetching Expertise Types data.");
            return of(userSkillsActions.setBackendDataIsLoadedStatusToFailed({ dataName: 'expertiseTypes'}))
          })
        ))
      )
    );

  saveSkills$: Observable<Action> = createEffect(() : Observable<Action> => 
    this.actions$.pipe(
      ofType(userSkillsActions.saveSkills),
      exhaustMap((action) =>
        this.skillsService.insertAndDeleteSkills(
          action.userId, 
          action.onlinerSkills
        ).pipe(
          map(( onlinerSkills: OnlinerSkill[]) => {
            this.snackBarService.message("Skills successfully saved");
            return userSkillsActions.saveSkillsDone({
              userId: action.userId,
              onlinerSkills: onlinerSkills 
            });
          }),
          catchError(() : Observable<Action> => {
            this.snackBarService.message("Error saving skills.");
            return of(userSkillsActions.setIsSaving({ value: false }));
          }),
        ))
      )
    );
  
  saveOtherExpertiseType$: Observable<Action> = createEffect(() : Observable<Action> => 
    this.actions$.pipe(
      ofType(userSkillsActions.saveOtherExpertiseType),
      exhaustMap((action) => 
        this.commonService.addRAttribute({
          ...action.newExpertiseType,
          value: null,
          userId: action.userId
        })
        .pipe(
          map(( ra: RAttribute) => {
            this.snackBarService.message("Skills successfully saved");
            return userSkillsActions.saveOtherExpertiseTypeDone({
              onlinerSkillToUpdate: action.onlinerSkillToUpdate,
              newExpertiseType: ra,
              userId: action.userId 
            });
          }),
          catchError(() : Observable<Action> => {
            this.snackBarService.message("Error saving new Expetise Type.");
            return of(userSkillsActions.setIsSaving({ value: false }));
          }),
        ))
      )
    );
  
    saveGroupExpertiseType$: Observable<Action> = createEffect(() : Observable<Action> => 
      this.actions$.pipe(
        ofType(userSkillsActions.saveGroupExpertiseType),
        exhaustMap((action) => 
          this.commonService.addRAttribute({
            ...action.newExpertiseType,
            value: null,
            userId: action.userId
          })
          .pipe(
            map(( ra: RAttribute) => {
              return userSkillsActions.saveGroupExpertiseTypeDone({
                newExpertiseType: ra,
                userId: action.userId 
              });
            }),
            catchError(() : Observable<Action> => {
              this.snackBarService.message("Error saving new Expetise Type.");
              return of(userSkillsActions.setIsSaving({ value: false }));
            }),
          ))
        )
      );

  // This will be called once the adding of new other expertise type was saved to DB
  assignNewlySaveOtherExpertiseToOnlinerSkill$: Observable<Action> = createEffect(() : Observable<Action> => 
  this.actions$.pipe(
    ofType(userSkillsActions.saveOtherExpertiseTypeDone),
    exhaustMap(({ onlinerSkillToUpdate, newExpertiseType }) : Observable<Action> => {
      return of(userSkillsActions.updateExpertiseType({ 
        onlinerSkillToUpdate,
        newExpertiseType 
      }));
    })
  ));

  // New effect to handle addExpertiseType
  addExpertiseTypeEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userSkillsActions.addUserSkillExpertiseType), // Replace with the actual action type
      exhaustMap((action) =>
        of(action.expertiseType).pipe(
          map((expertiseType) => {
            return userSkillsActions.addUserSkillExpertiseType({ expertiseType });
          })
        )
      )
    )
  );


  constructor(
    private actions$: Actions,
    private attributeService: AttributeService, 
    private commonService: CommonService,
    private skillsService: SkillsService,
    private snackBarService: SnackBarService
  ) {}
}