import { UntypedFormControl, UntypedFormArray, UntypedFormGroup } from "@angular/forms";
import { Component, EventEmitter, OnInit, Input, Output } from "@angular/core";
import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { DropDown } from "src/app/common/services/common.service";

@Component({
  selector: "obs-multi-dropdown-card",
  templateUrl: "multi-dropdown-card.component.html",
  styleUrls: ["multi-dropdown-card.component.scss"],
})
export class MultiDropdownCardComponent<T> implements OnInit {
  @Input() parentForm: UntypedFormGroup;
  @Input() formArrayName: string;
  @Input() objectDropDown: DropDown<T>[];
  @Input() title: string;
  @Input() fieldLabel: string;
  @Input() readOnlyForm: boolean;
  @Input() maxItems: number;

  @Output() onRemove: EventEmitter<number> = new EventEmitter();
  @Output() onAdd: EventEmitter<any> = new EventEmitter();
  @Output() onSelect: EventEmitter<{
    index: number;
    id: number;
  }> = new EventEmitter();

  @Output() onDrag: EventEmitter<any> = new EventEmitter();

  get formArray() {
    return this.parentForm.get(this.formArrayName) as UntypedFormArray;
  }

  get isReadOnly() {
    return this.readOnlyForm;
  }

  ngOnInit(): void {}

  public maxSelectionReached(): boolean {
    return this.formArray.length > +this.maxItems - 1;
  }

  public add(): void {
    if (this.maxSelectionReached()) return;

    this.onAdd.emit();
  }

  select(index: number, ctrl: UntypedFormControl) {
    this.onSelect.emit({ index, id: ctrl.value });
  }

  remove(index: number) {
    this.onRemove.emit(index);
  }

  drop(event: CdkDragDrop<any>) {
    const dir = event.currentIndex > event.previousIndex ? 1 : -1;

    const from = event.previousIndex;
    const to = event.currentIndex;

    const temp = this.formArray.at(from);
    for (let i = from; i * dir < to * dir; i = i + dir) {
      const current = this.formArray.at(i + dir);
      this.formArray.setControl(i, current);
    }
    this.formArray.setControl(to, temp);

    this.onDrag.emit();
  }

  public requiredIsEmpty(testValue: UntypedFormControl): boolean {
    if (
      (this.fieldLabel == "Skill Summary" ||
        this.fieldLabel == "Skill" ||
        this.fieldLabel == "Education" ||
        this.fieldLabel == "Project" ||
        this.fieldLabel == "Work Experience") &&
      testValue.value <= 0
    ) {
      return true;
    } else {
      return false;
    }
  }
}
