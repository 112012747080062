<vex-secondary-toolbar current="Attribute Approvals">
  <vex-breadcrumbs [crumbs]="['Attribute Approvals']" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>

<div class="p-6" *ngIf="isLoading" >
  <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="24px">
      <mat-spinner></mat-spinner>
  </div>
</div>

<div class="row m-0" *ngIf="!isLoading">
  <div class="attribute-approvals-wrapper">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <i class="fas fa-filter fa-lg"></i>
          <span>Filter Attributes: {{ filterTitle }}</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      
      <obs-attribute-list-filter-criteria 
        [filterCriteriaForm]="filterCriteriaForm"
        [attributes]="filteredAttributes"
        (filterAttributesTriggered)="filterAttributes($event)"
      >
      </obs-attribute-list-filter-criteria> 
           
    </mat-expansion-panel>
  <br>

    <obs-attributes-list [attributes]="filteredAttributes"></obs-attributes-list>

    <button *ngIf="!isLoading"
      class="mat-raised-button mat-button-base mt-3 bg-gray-500 text-contrast-black"
      mat-raised-button
      type="button"
      (click)="retakeSnapshots()"
      [disabled]="isTakingSnapshots">Retake Snapshots</button>

  </div>
</div>

