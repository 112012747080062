import { AutoSuggestService } from "./../../common/services/auto-suggest.service";
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { OnlinerService } from "src/app/common/services/onliner.service";
import { Employee } from "src/app/common/data-models/Employee";
import { ResumeSearchUtilService } from "src/app/common/services/resume-search-util.service";
import { Observable } from "rxjs";
import { SnackBarService } from "src/app/common/services/snackbar.service";

@Component({
  selector: "obs-resumes-search-input",
  templateUrl: "search-input.component.html",
  styleUrls: ["search-input.component.scss"],
})
export class ResumesSearchInput implements OnInit {
  public employees: Employee[];
  public filteredOnliners: Observable<Employee[]>;
  @Output() selectedOnliner: EventEmitter<Employee> =
    new EventEmitter<Employee>();
  public onlinerName: UntypedFormControl;

  constructor(
    private _onlinerService: OnlinerService,
    private _resumeSearchUtil: ResumeSearchUtilService,
    private snackBarService: SnackBarService,
    private _autoSuggestService: AutoSuggestService
  ) {}

  ngOnInit() {
    this.onlinerName = new UntypedFormControl();
    this._onlinerService.getOnlinersExcludingDefault().subscribe(
      (searchResponse) => {
        this.employees = searchResponse;
        this.setOnlinerFilter();
      },
      (error) =>
        this.snackBarService.message("Error loading Onliners. " + error)
    );
  }

  onlinerSelected(event: MatAutocompleteSelectedEvent) {
    this.selectedOnliner.emit(event.option.value);
  }

  setOnlinerFilter() {
    this.filteredOnliners = this._autoSuggestService.setOnlinerFilter(
      this.onlinerName,
      this.employees
    );
  }

  onlinerDisplay = (option?: Employee): string | undefined => {
    if (option) {
      const other = this.employees.find((a) => a.userId === option.userId);
    }
    return this._autoSuggestService.onlinerDisplay(option);
  };

  getOnlinerNameErrorMessage(onlinerField: any) {
    if (onlinerField.hasError("required")) {
      return "Onliner Name is required";
    }
  }
}
