<vex-secondary-toolbar current="Resume Review">
  <vex-breadcrumbs
    [crumbs]="['Resume Review']"
    class="flex-auto"
  ></vex-breadcrumbs>
</vex-secondary-toolbar>

<div class="flex-auto" fxLayout="column">  
  <div row class="pl-10" vexContainer>
    <button
      class="mat-raised-button mat-button-base mt-3 bg-gray-500 text-contrast-black fully-left"
      mat-raised-button
      type="button"
      (click)="back()"
    >
      Back
    </button>
  </div>
 
  <div row vexContainer>
    <div
      class="pl-10 pr-10"
      fxFlex="auto"
      fxFlex.lt-lg="auto"
      
      *ngIf="isError"
    >
      <app-resume-review-error></app-resume-review-error>
    </div>

    <div class="p-6" *ngIf="isLoading$ | async">
      <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="24px">
        <mat-spinner></mat-spinner>
      </div>
    </div>

    <div
      class="resume-review-section pl-10 pr-10"
      fxFlex="auto"
      fxFlex.lt-lg="auto"
      *ngIf="!isError && resume"
    >
      <obs-resume-preview
        [resume]="resume"
        [originalResume]="originalResume"
        previewType="pdf"
      ></obs-resume-preview>
    </div>
  </div>

  <div>
    <div
      class="resume-review-section pl-10 pr-10 pb-2"
      fxFlex="auto"
      fxFlex.lt-lg="auto"
      *ngIf="!isError && resume"
    >
      <div>
        <div class="right-group" *ngIf="actionAllow">
          <div
            class="chat-icon-container"
            *ngIf="resume?.reviewComments.length > 0"
          >
            <button (click)="openReviewCommentsDialog($event)">
              <i class="fas fa-comment fa-lg"></i>
            </button>
          </div>

          <button
            class="mat-raised-button mat-button-base mt-3 bg-red-500 text-contrast-white fully-right"
            mat-raised-button
            type="button"
            (click)="openRejectModal()"
          >
            Reject
          </button>

          <button
            class="mat-raised-button mat-button-base mt-3 bg-green-500 text-contrast-white fully-right"
            mat-raised-button
            type="button"
            (click)="openApproveModal()"
          >
            Approve
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
