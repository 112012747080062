<vex-secondary-toolbar current="Build Resume">
  <vex-breadcrumbs
    [crumbs]="['Build Resume']"
    class="flex-auto"
  ></vex-breadcrumbs>
</vex-secondary-toolbar>
<div class="p-6" *ngIf="isLoading">
  <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="24px">
    <mat-spinner></mat-spinner>
  </div>
</div>
<div row *ngIf="!isLoading">
  <div
    class="build-resume-section pl-10 pr-10 pb-10"
    vexContainer
  >
    <div row class="col-md-2 add-resume">
      <p
        style="cursor: pointer"
        [hidden]="!canAddNewResume"
        class="text-obs-blue text-center pb-8"
        (click)="addBuildResume()"
      >
        <i class="fas fa-plus"></i> Build Resume
      </p>
    </div>
    <div class="fully-left">
      <mat-slide-toggle
        class="slide-toggle"
        (change)="onResumeHidden()"
        [checked]="isHidden"
        labelPosition="before"
        >Hide Approved Resumes</mat-slide-toggle
      >
    </div>
    <mat-accordion>
      <ng-container *ngFor="let resume of resumes">
        <obs-build-resume-card
          [resume]="resume"
          [refResumeId]="refResumeId"
          [cloneInProgress]="isCloning"
          [spins]="spins"
          [profileSummaries]="profileSummaries"
          [skillSummaries]="skillSummaries"
          [skills]="skills"
          [userSkills]="userSkills"
          [resumeExpertiseGroups]="resumeExpertiseGroups"
          [associations]="associations"
          [educations]="educations"
          [projects]="projects"
          [formsListState]="formsListState"
          [fullyLoaded]="isFullyLoaded(resume.id)"
          [cloneResumeStatus]="cloneResumeStatus"
          (delete)="delete()"
          (markFullyLoaded)="markFullyLoaded($event)"
          (markCloneResume)="cloneResume($event)"
          (markNewResumeCloning)="setCloning($event)"
          (refreshList)="refreshList()" 
          (open)="loadDropdownOptions()"
          *ngIf="!isHidden || !isNonGoldApproved(resume)" 
        ></obs-build-resume-card>
      </ng-container>
    </mat-accordion>
  </div>
</div>
