import { Observable, BehaviorSubject } from "rxjs";
import { OnlinerService } from "src/app/common/services/onliner.service";
import { Employee } from "../data-models/Employee";
import { Injectable } from "@angular/core";

@Injectable()
export class OnlinerSearchUtilService {
  public _getOnlinersForQuery: BehaviorSubject<{ employees: Employee[] }> =
    new BehaviorSubject({ employees: [] });
  public _isSearching: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private query = new BehaviorSubject("");
  sharedQuery = this.query.asObservable();

  constructor(private _onlinerService: OnlinerService) {}

  getUsersViaPreset(query: string) {
    this._isSearching.next(true);
    this._onlinerService.getOnlinersUsingPresetQuery(query).subscribe(
      (onliners) => {
        this._getOnlinersForQuery.next({
          employees: onliners,
        });
      },
      (error) => this._isSearching.next(false),
      () => this._isSearching.next(false)
    );
  }

  getUsersViaWildCard(
    query: string,
    startWith: boolean,
    approvedResume: boolean
  ) {
    this._isSearching.next(true);
    this._onlinerService
      .getOnlinersUsingWildCard(query, startWith, approvedResume)
      .subscribe(
        (onliners) => {
          this._getOnlinersForQuery.next({
            employees: onliners,
          });
        },
        (error) => this._isSearching.next(false),
        () => this._isSearching.next(false)
      );
  }

  searchOnliner(): Observable<{ employees: Employee[] }> {
    return this._getOnlinersForQuery.asObservable();
  }

  isSearching(): Observable<boolean> {
    return this._isSearching.asObservable();
  }

  sendQuery(input: string) {
    this.query.next(input);
  }
}
