import { Component, Inject, OnInit } from "@angular/core";
import * as _moment from "moment";
import { default as _rollupMoment } from "moment";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Resume } from "../../common/data-models/Resume";
import { BehaviorSubject, forkJoin } from "rxjs";
import { ResumeService } from "../../common/services/resume.service";
import { ResumeNOService } from "../../common/services/resumeNO.service";
import { UserService } from "../../common/services/user.service";

export interface DialogData {
  resume: Resume;
  resumeFullyLoaded: boolean;
  userId: string;
  isNonOnliner: boolean;
}

@Component({
  selector: "resume-output-modal",
  templateUrl: "resume-output-modal.html",
  styleUrls: ["resume-output-modal.scss"],
})
export class ResumeOutputModal implements OnInit {
  public resume: Resume;
  public originalResume: Resume;

  isLoading: boolean;
  isLoading$: BehaviorSubject<boolean>;
  constructor(
    private resumeService: ResumeService,
    private resumeNOService: ResumeNOService,
    private userService: UserService,
    public dialogRef: MatDialogRef<ResumeOutputModal>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  async ngOnInit(): Promise<void> {
    this.isLoading$ = new BehaviorSubject<boolean>(this.isLoading);
    this.isLoading$.next((this.isLoading = true));

    //load resume here
    await this.loadResume();
    this.isLoading$.next((this.isLoading = false));
  }

  private async loadResume(): Promise<boolean> {

    return new Promise(async (resolve) => {
      if (this.data.resumeFullyLoaded) {
        this.resume = this.data.resume;
        this.originalResume = this.resume;
        resolve(true);
      } else {
        const specificResumeService = this.data.isNonOnliner ? this.resumeNOService : this.resumeService;
        forkJoin([
          specificResumeService.getResume(this.data.userId, this.data.resume.id),
          specificResumeService.getSnapshotResume(this.data.userId, this.data.resume.id),
        ]).subscribe(async ([resume, originalResume]) => {
          resume.spins.name = resume.spins.name;
          resume.accreditation = resume.accreditation;
          originalResume.spins.name = originalResume.spins.name;
          originalResume.accreditation = originalResume.accreditation;
          this.resume = resume;
          this.originalResume = originalResume;
          resolve(true);
        });
      }
    });
  }

  onOkClick(): void {
    this.dialogRef.close();
  }
}
