import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CompetencyLevel, CompetencyProfile, CompetencyProfileDefault, CompetencyRole, SkillCompetency } from '../data-models/commonDataModels';
import { AuthorizedHttp } from './authorized-http.service';
import { CommonService } from "./common.service";

@Injectable({
  providedIn: 'root'
})
export class CompetencyService {

    private _apiEndpoint = `${environment.apiEndpoint}/api/competency`;
    constructor(private http: AuthorizedHttp, private commonService: CommonService) { }

    public getCompetencies(competencyTypeId: number): Observable<CompetencyProfile[]> {
        return this.http
            .get(`${this._apiEndpoint}/competencyList/${competencyTypeId}`)
            .pipe(
                map((result) => {
                    return result;
                }),
                catchError((errorResponse: any) =>
                    throwError(errorResponse.error.exceptionMessage || "Server error")
                )
            );
    }

    public getCompetencyRoles(): Observable<CompetencyRole[]> {
        return this.http
            .get(`${this._apiEndpoint}/competencyRoles`)
            .pipe(
                map((result) => {
                    return result;
                }),
                catchError((errorResponse: any) =>
                    throwError(errorResponse.error.exceptionMessage || "Server error")
                )
            );
    }

    public getCompetencyLevels(): Observable<CompetencyLevel[]> {
        return this.http
            .get(`${this._apiEndpoint}/competencyLevels`)
            .pipe(
                map((result) => {
                    return result;
                }),
                catchError((errorResponse: any) =>
                    throwError(errorResponse.error.exceptionMessage || "Server error")
                )
            );
    }

    public getDefaultCompetencies(userId: string): Observable<CompetencyProfileDefault> {
        userId = this.commonService.encrypt(userId);
        return this.http
            .get(`${this._apiEndpoint}/getDefaultCompetencies/${userId}`)
            .pipe(
                map(result => {
                    return result;
                }),
                catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
            );
    }

    public upsertCompetencyProfileDefault(profileModel: CompetencyProfileDefault): Observable<CompetencyProfileDefault> {
        return this.http
            .post(`${this._apiEndpoint}/upsertCompetencyProfileDefault`, JSON.stringify(profileModel))
            .pipe(
                map(result => {
                    return result;
                }),
                catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
            );
    }

    public updateCompetency(competency: SkillCompetency): Observable<any> {
        return this.http.patch(`${this._apiEndpoint}/updateCompetency`, competency)
            .pipe(
                map(result => {
                    return result;
                }),
                catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
            );
    }

    public updateRole(role: CompetencyRole): Observable<any> {
        return this.http.patch(`${this._apiEndpoint}/updateRole`, role)
            .pipe(
                map(result => {
                    return result;
                }),
                catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
            );
    }

    public updateLevel(level: CompetencyLevel): Observable<any> {
        return this.http.patch(`${this._apiEndpoint}/updateLevel`, level)
            .pipe(
                map(result => {
                    return result;
                }),
                catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
            );
    }

}



