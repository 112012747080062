import { RNonOnlinerSkill } from "./RNonOnlinerSkill";
import { RNonOnlinerSkillSummary } from "./RNonOnlinerSkillSummary";
import { ProfileSummaryNO } from "./ProfileSummaryNO";
import { RNonOnlinerAssociation } from "./RNonOnlinerAssociation";
import { RNonOnlinerEducation } from "./RNonOnlinerEducation";
import { RNonOnlinerProject } from "./RNonOnlinerProject";
import { RReviewComment } from "./RReviewComment";
import { RAttribute } from "./commonDataModels";
import { NonOnliner } from "../data-models/NonOnliner";
import { RNonOnlinerResumeExpertiseGroup } from "./RNonOnlinerResumeExpertiseGroup";

export class NonOnlinerResume {
  public id: number;
  public user: NonOnliner;
  public statusType: RAttribute;
  public statusTypeId: number;
  public isClientHidden: boolean;
  public isProfessionalResume: boolean;
  public dateModified: Date;
  public dateApproved: Date;
  public dateRequested: Date;
  public spins: RAttribute;
  public spinsId: number;
  public accreditation: string;
  public profileSummaryId: number;
  public profileSummaries: ProfileSummaryNO[];
  public skillSummaries: RNonOnlinerSkillSummary[];
  public skills: RNonOnlinerSkill[];
  public userSkills: RNonOnlinerSkill[];
  public resumeExpertiseGroups: RNonOnlinerResumeExpertiseGroup[];
  public associations: RNonOnlinerAssociation[];
  public educations: RNonOnlinerEducation[];
  public projects: RNonOnlinerProject[];
  public reviewComments: RReviewComment[];
  public isSnapshot: boolean;
  public maxApprovedSpinDate: Date;
  public isProjectMostRecent: boolean;
  public useOriginal: boolean;

  constructor(
    id: number,
    user: NonOnliner,
    statusType: RAttribute,
    statusTypeId: number,
    isClientHidden: boolean,
    isProfessionalResume: boolean,
    dateModified: Date,
    dateApproved: Date,
    spins: RAttribute,
    spinsId: number,
    accreditation: string,
    profileSummaryId: number,
    profileSummaries: ProfileSummaryNO[],
    skillSummaries: RNonOnlinerSkillSummary[],
    skills: RNonOnlinerSkill[],
    userSkills: RNonOnlinerSkill[],
    resumeExpertiseGroups: RNonOnlinerResumeExpertiseGroup[],
    associations: RNonOnlinerAssociation[],
    educations: RNonOnlinerEducation[],
    projects: RNonOnlinerProject[],
    reviewComments: RReviewComment[],
    dateRequested: Date,
    isSnapshot: boolean,
    maxApprovedSpinDate: Date,
    isProjectMostRecent: boolean
  ) {
    this.id = id;
    this.user = user;
    this.statusType = statusType;
    this.statusTypeId = statusTypeId;
    this.isClientHidden = isClientHidden;
    this.isProfessionalResume = isProfessionalResume;
    this.dateModified = dateModified;
    this.dateApproved = dateApproved;
    this.spins = spins;
    this.spinsId = spinsId;
    this.accreditation = accreditation;
    this.profileSummaryId = profileSummaryId;
    this.profileSummaries = profileSummaries;
    this.skillSummaries = skillSummaries;
    this.skills = skills;
    this.userSkills = userSkills;
    this.resumeExpertiseGroups = resumeExpertiseGroups;
    this.associations = associations;
    this.educations = educations;
    this.projects = projects;
    this.reviewComments = reviewComments;
    this.dateRequested = dateRequested || null;
    this.isSnapshot = isSnapshot;
    this.maxApprovedSpinDate = maxApprovedSpinDate;
    this.isProjectMostRecent = isProjectMostRecent;
    this.useOriginal = false;
  }
}
