import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthorizedHttp } from 'src/app/common/services/authorized-http.service';
import { environment } from 'src/environments/environment';
import { AttributeFilter } from '../data-models/AttributeFilter';
import { RAttribute } from '../data-models/commonDataModels';

@Injectable()
export class AttributeService {
    private _apiEndpoint = `${environment.apiEndpoint}/api/common`;
    private _myAttributes: RAttribute [];

    constructor(private http: AuthorizedHttp) { }

    public getAttributes(attributeType: string, filter: AttributeFilter): Observable<any> {
        return this.http
            .getWithParams(`${this._apiEndpoint}/rAttribute/${attributeType}`, filter)
            .pipe(
                map(result => {
                    this._myAttributes = result;
                    return this._myAttributes;
                  }),
                  catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
                );
    }

    public updateAttribute(attribute: RAttribute): Observable<any> {
        return this.http.patch(`${this._apiEndpoint}/updateRAttribute`, attribute)
            .pipe(
                map(result => {
                    return result;
                  }),
                catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
            );
    }
}
