import { DateTime } from 'luxon';

export class RNonOnlinerEducation {
    public id: number;
    public educationId: number;
    public startDate: Date;
    public endDate: Date;
    public institutionCity: string;
    public userId: string;
    public statusId: number;
    public comments: string;
    public educationTypeId: number;
    public dateModified: Date;
    public isUsed: boolean;

    constructor(id: number, educationId: number, comments: string, userId: string, startDate: Date, endDate: Date, institutionCity: string, statusId: number, educationTypeId: number, dateModified: Date, isUsed: boolean) {
            this.id = id;
            this.educationId = educationId;
            this.comments = comments;
            this.userId = userId;
            this.startDate = startDate;
            this.endDate = endDate;
            this.institutionCity = institutionCity;
            this.statusId = statusId;
            this.educationTypeId = educationTypeId;
            this.dateModified = dateModified;
            this.isUsed = isUsed;
    }
}
