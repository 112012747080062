import { Injectable } from '@angular/core';
import { Observable, of, throwError, Subject } from 'rxjs';
import { AuthorizedHttp } from './authorized-http.service';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SkillSummary } from '../data-models/SkillSummary';
import { CommonService } from "./common.service";

@Injectable()
export class SkillSummaryService {
  private _apiEndpoint = `${environment.apiEndpoint}/api/skillsummary`;
  private _skillSummaries: SkillSummary[];
  private _skillSummary: SkillSummary;
  private _isDeleted: boolean;

  constructor(private http: AuthorizedHttp, private commonService: CommonService) {
    const headers: any = {
      'Content-Type': 'application/json; charset=utf-8', /*or whatever type is relevant */
      'Accept': 'application/json'
    }
  }

  public getSkillSummaries(userId: string): Observable<SkillSummary[]> {
    userId = this.commonService.encrypt(userId);
    return this.http
      .get(`${this._apiEndpoint}/mySkillSummaries/` + userId)
      .pipe(
        map(result => {
          this._skillSummaries = result;
          return this._skillSummaries;
        }),
        catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
      );
  }
  public getUserSkillSummaries(userId: string): Observable<SkillSummary[]> {
    userId = this.commonService.encrypt(userId);
    return this.http
      .get(`${this._apiEndpoint}/userSkillSummaries/` + userId)
      .pipe(
        map(result => {
          this._skillSummaries = result;
          return this._skillSummaries;
        }),
        catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
      );
  }

  public postNewSkillSummary(skillSummary: SkillSummary, userId: string): Observable<SkillSummary> {
    userId = this.commonService.encrypt(userId);
    return this.http
      .post(`${this._apiEndpoint}/addNewSkillSummary/` + userId, JSON.stringify(skillSummary))
      .pipe(
        map(result => {
          this._skillSummary = result;
          return this._skillSummary;
        }),
        catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
      );
  }

  public updateSkillSummary(skillSummary: SkillSummary, userId: string): Observable<SkillSummary> {
    userId = this.commonService.encrypt(userId);
    return this.http
      .patch(`${this._apiEndpoint}/updateSkillSummary/` + userId, JSON.stringify(skillSummary))
      .pipe(
        map(result => {
          this._skillSummary = result;
          return this._skillSummary;
        }),
        catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
      );
  }

  public deleteSkillSummary(skillSummary: SkillSummary, userId: string): Observable<SkillSummary> {
    userId = this.commonService.encrypt(userId);
    return this.http
      .patch(`${this._apiEndpoint}/deleteSkillSummary/` + userId, JSON.stringify(skillSummary))
      .pipe(
        map(result => {
          this._skillSummary = result;
          return this._skillSummary;
        }),
        catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
      );
  }
}
