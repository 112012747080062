<mat-form-field fxFlex>
    <mat-label>Onliner Name</mat-label>
    <input type="text" 
          matInput 
          placeholder="Choose Onliner"
          aria-label="Onliner" 
          [matAutocomplete]="auto" 
          [formControl]="onlinerName"
          #onlinerInput required>
    <i class="material-icons" matSuffix>keyboard_arrow_down</i>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="onlinerDisplay" (optionSelected)="onlinerSelected($event)">        
        <ng-container *ngFor="let option of filteredOnliners | async">
            <mat-option [value]="option">
            {{ onlinerDisplay(option) }}
            <span *ngIf="option.isInactive" class="isInactive">(Inactive)</span>
            </mat-option>
        </ng-container>
    </mat-autocomplete>
    <mat-error *ngIf="onlinerName.hasError('required')">{{getOnlinerNameErrorMessage(onlinerName)}}</mat-error>    
  </mat-form-field>
