import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from '@visurel/iconify-angular';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { PageLayoutModule } from 'src/@vex/components/page-layout/page-layout.module';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { ContainerModule } from 'src/@vex/directives/container/container.module';
import { ResumesSearchComponent } from './resumes-search.component';
import { ResumesSearchInputModule } from './search-input/search-input-module';
import { ResumesSearchResults } from './search-results/search-results.component';

@NgModule({
    declarations: [        
        ResumesSearchResults,
        ResumesSearchComponent
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        CommonModule,
        FlexLayoutModule,
        MatDialogModule,
        MatTableModule,
        MatIconModule,
        MatProgressSpinnerModule,
        SecondaryToolbarModule,
        BreadcrumbsModule, 
        MatButtonModule,
        PageLayoutModule,
        ContainerModule,
        TranslateModule,
        MatInputModule,
        IconModule,
        ScrollingModule,
        MatSortModule,
        ResumesSearchInputModule,
    ],
    exports: [ 
        ResumesSearchComponent
    ],
})
export class ResumesSearchModule {
    
}