export class AltOnlinerDefault {
  public userId: string;
  public altOnlinerUserId: string;
  public altOnlinerUserFirstName: string;
  public altOnlinerUserLastName: string;
  public modifiedDate: Date;
  public isOnliner: boolean;

  constructor(userId: string, altOnlinerUserId: string, modifiedDate: Date, isOnliner: boolean, altOnlinerUserFirstName?: string, altOnlinerUserLastName?: string, ) {
      this.userId = userId;
      this.altOnlinerUserId = altOnlinerUserId;
      this.modifiedDate = modifiedDate;
      this.isOnliner = isOnliner;
      this.altOnlinerUserFirstName = altOnlinerUserFirstName;
      this.altOnlinerUserLastName = altOnlinerUserLastName;
  }
}
