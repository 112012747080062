import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  ElementRef,
} from "@angular/core";
import { Resume } from "../../common/data-models/Resume";
import { ResumePdfBuilderService } from "../../common/services/resume-pdf-builder.service";
import { iconsFA } from "src/static-data/icons-fa";
import { iconsIC } from "src/static-data/icons-ic";
import { fadeInUp400ms } from "src/@vex/animations/fade-in-up.animation";
import { environment } from "../../../environments/environment";
import icMail from "@iconify-icons/ic/twotone-mail";
import icPhone from "@iconify-icons/ic/twotone-phone";
import { HttpClient } from "@angular/common/http";
import { formatDate } from "@angular/common";
import { RProject } from "src/app/common/data-models/project";
import { isNull } from "util";
import { isNullOrUndefined } from "@swimlane/ngx-datatable";
import { NavigationService } from "src/@vex/services/navigation.service";
import { AltOnlinerDefault } from "../../common/data-models/AltOnlinerDefault";
import { AuthService } from "src/app/common/services/auth.service";
import { OnlinerService } from "src/app/common/services/onliner.service";
import { Employee } from "src/app/common/data-models/Employee";
@Component({
  selector: "obs-resume-html-live-preview",
  templateUrl: "./html-live-preview.component.html",
  styleUrls: ["./html-live-preview.component.scss"],
  animations: [fadeInUp400ms],
})
export class ResumeHtmlLivePreviewComponent implements OnInit {
  @Input() public resume: Resume;
  @Input() public shouldEnablePreview: boolean;
  @Input() public isOldSnapshotResume: boolean;
  @Output() closePreview = new EventEmitter();
  @ViewChild("resume-output") public resumeOutput: ElementRef;
  private isSlideChecked: boolean = this.navService.getIsSlideChecked();
  private altOnlinerDefault: AltOnlinerDefault =
    this.navService.getAltOnlinerDefault();
  get disablePreview(): boolean {
    return !this.shouldEnablePreview;
  }

  public wordmarkLogo = "assets/img/logo/online-business-systems-logo-wordmark-sm.png";
  public taglineLogo ="assets/img/logo/online-tagline-sm.png";

  public icMail = icMail;
  public icPhone = icPhone;
  public faIcons = iconsFA;
  public icIcons = iconsIC;
  public isLoading = true;
  public obsLogo: any;
  employee: string;
  employeeProfile: Employee;
  onliner = true;
  public constructor(
    private http: HttpClient,
    private navService: NavigationService,
    private resumePdfBuilderService: ResumePdfBuilderService,
    private authService: AuthService,
    private onlinerService: OnlinerService
  ) { }

  ngOnInit(): void {
    this.loadTaglineLogoBase64();
    this.loadWordmarkLogoBase64();
    this.isLoading = false;
    this.getName();
  }

  public generatePdf(action = "open") {
    switch (action) {
      case "open":
        this.resumePdfBuilderService.open(this.resume);
        break;
      case "print":
        this.resumePdfBuilderService.print(this.resume);
        break;
      case "download":
        this.resumePdfBuilderService.download(this.resume);
        break;
      default:
        this.resumePdfBuilderService.open(this.resume);
        break;
    }
  }

  public isOnliner(): boolean {
    const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  
    const userId = this.isSlideChecked
      ? this.altOnlinerDefault?.altOnlinerUserId
      : this.resume?.user?.userId || this.employeeProfile?.userId;
    
    return userId ? this.onliner = !format.test(userId) : this.onliner = false;
  }
  

  public async getName(): Promise<string> {
    if (this.resume && this.resume.user) {
      const preferredPronouns = this.resume.user.preferredPronouns || "";
      this.employee = `${this.resume.user.firstName} ${this.resume.user.lastName} ${preferredPronouns}`;
    } else {
      try {
        const onlinerProfile = await this.onlinerService
          .getOnliner(this.authService.getUserId())
          .toPromise();
  
        if (onlinerProfile) {
          this.employee = `${onlinerProfile.firstName} ${onlinerProfile.lastName} ${onlinerProfile.preferredPronouns || ''}`;
          this.employeeProfile = onlinerProfile;
        } else {
          return "";
        }
      } catch (error) {
        return "";
      }
    }
  }
  
  

  public getProjectResponsibilitiesIncludedStatement(): string {
    return `${this.resume.user.firstName}${this.resume.user.firstName
      .slice(this.resume.user.firstName.length - 1)
      .toLowerCase() === "s"
      ? `'`
      : "'s"
      } responsibilities:`;
  }

  public getProfileSummary(): string {
    return this.resume.profileSummaries[0]
      ? this.resume.profileSummaries[0].summary
      : "";
  }

  public getAccreditation(): string {
    return this.resume.accreditation
      ? this.resume.accreditation
      : "";
  }

  public getProjectJobTitle(project: RProject): string {
    return (
      project.jobTitles?.map((x) => x.jobTitleObj.name).join(", ") ||
      project.jobTitleObj.name
    );
  }

  public getProjectName(project: RProject, projectCtr: RProject[]): string {
    if (projectCtr.length >= 1) {
      if (
        project.projectName != "null" &&
        project.projectName != null &&
        project.projectName != ""
      ) {
        return `${project.projectName} - `;
      }
    }
  }

  public getProjectExperienceDateInterval(
    startDate: Date,
    endDate: Date
  ): string {
    return `${formatDate(startDate, "MM/yyyy", "en-CA")} - ${endDate ? formatDate(endDate, "MM/yyyy", "en-CA") : "Present"
      }`;
  }

  public loadTaglineLogoBase64() {
    this.http
      .get(this.taglineLogo, { responseType: "blob" })
      .subscribe((res) => {
        const reader = new FileReader();
        reader.readAsDataURL(res);
      });
  }

  public loadWordmarkLogoBase64() {
    this.http
      .get(this.wordmarkLogo, { responseType: "blob" })
      .subscribe((res) => {
        const reader = new FileReader();
        reader.readAsDataURL(res);
      });
  }
}
