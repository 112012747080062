import { RAttribute } from "./commonDataModels";

export class ProjectIndustry {
  public id: number;
  public projectId: number;
  public industryId: number;
  public key: number;
  public industryObj: RAttribute;

  constructor(projectId: number, industryId: number, industryObj?: RAttribute) {
    this.projectId = projectId;
    this.industryId = industryId;
    this.industryObj = industryObj;
    this.key = Date.now();
  }
}
