import { HttpClient, HttpHeaders, HttpHeaderResponse} from '@angular/common/http';

import {empty as observableEmpty, Observable, throwError} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import { AuthService } from 'src/app/common/services/auth.service';
import { UtilityService } from 'src/app/common/services/utility.service';

@Injectable()
export class AuthorizedHttp {

  constructor(
    private http: HttpClient, 
    private authService: AuthService, 
    private router: Router, 
    private utilityService: UtilityService) {}

  private createAuthorizationHeader() {
    let token = this.authService.accessToken;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return httpOptions;
  }

  private createAuthorizationHeaderFile() {
    let token = this.authService.accessToken;
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return httpOptions;
  }

  get(url) {
    return this.protectSessionExpired( () => 
      this.intercept(this.http.get(url, this.createAuthorizationHeader()) )
    );
  }

  getWithParams(url, params) {
    return this.protectSessionExpired( () => 
      this.intercept(this.http.get(url, { headers: this.createAuthorizationHeader().headers, params }) )
    );
  }

  ///getText receives a base64 encoded string response from API. May be useful for images or binary files returned from API.
  getText(url) {
    return this.protectSessionExpired( () => 
      this.intercept(this.http.get(url, { headers: this.createAuthorizationHeader().headers, responseType: 'text' as 'json' }))
    );
  }

  post(url, data) {
    return this.protectSessionExpired( () => 
      this.intercept(this.http.post(url, data, this.createAuthorizationHeader()))
    );
  }

  postFile(url, data) {
    return this.protectSessionExpired( () => 
      this.intercept(this.http.post(url, data, this.createAuthorizationHeaderFile()))
    );
  }

  postWithNoData(url) {
    return this.protectSessionExpired( () => 
      this.intercept(this.http.post(url, '', this.createAuthorizationHeader()))
    );
  }

  put(url, data) {
    return this.protectSessionExpired( () =>
      this.intercept(this.http.put(url, data, this.createAuthorizationHeader()))
    );
  }

  patch(url, data) {
    return this.protectSessionExpired( () => 
      this.intercept(this.http.patch(url, data, this.createAuthorizationHeader()))
    );
  }

  delete(url, body = {}) {
    return this.protectSessionExpired( () => {
      const deleteOptions = {...this.createAuthorizationHeader(), body};
      return this.intercept(this.http.delete(url, deleteOptions))
    });
  }

  private protectSessionExpired(restCall: () => Observable<any>): Observable<any> {
    if(this.authService.isSessionExpired) {
      this.utilityService.showSessionExpiredDialog();
      return throwError({error: { exceptionMessage: 'Session Expired' }});
    }
    
    return restCall();
  }

  private intercept(observable: Observable<any>): Observable<any>
  {
      return observable.pipe(catchError(err => {
          if (err.status === 403) {
            return this.forbidden();
          } else if (err.status === 404) {
              return this.notFound()
          } else {
              return throwError(err);
          }
      }));
  }

  refresh(): Observable<any>{
    location.reload();
    return observableEmpty();
  }

  notFound(): Observable<any> {
    this.router.navigate(['404']);
    return observableEmpty();
  }

  forbidden(): Observable<any>
  {
      this.router.navigate(['/forbidden']);
      return observableEmpty();
  }

}
