import { RAttribute } from "./../../common/data-models/commonDataModels";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import {
  Component,
  OnInit,
  Inject,
  ViewEncapsulation,
  Output,
  EventEmitter,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import { ConfirmationDialogComponent, ConfirmDialog } from "../confirmation-dialog/confirmation-dialog.component";
import { CommonService } from "src/app/common/services/common.service";
import { SnackBarService } from "src/app/common/services/snackbar.service";

export class PickList {
  title: string;
  message: string;
  okButtonTitle: string;
  cancelButtonTitle: string;
  theme: string;
  unitsReceived: number;
  comments: string;
  pickListData: RAttribute[];
  isMultiple: boolean;
  maxSelection: number;
  selectedItems: RAttribute[] = [];
  listType: string;
}

@Component({
  selector: "app-pick-list",
  templateUrl: "./pick-list.component.html",
  styleUrls: ["./pick-list.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PickListComponent implements OnInit {
  @Output() addItem: EventEmitter<RAttribute> = new EventEmitter();
  @ViewChild("itemsSelectInput") itemsSelectInputField: MatSelect;
  public initialStrLimit: number;
  public filterStrLimit: number;
  public maxStrLimit: number;
  public fieldName = "filterStr";
  public resultString: string;
  pickList: PickList;
  filterStr: UntypedFormControl;
  pickListData: RAttribute[] = [];
  filteredPickListData: RAttribute[];
  isLoading: boolean = false;
  selectedItems: RAttribute[] = [];
  errorMessage: string;
  addConfirmationDialog: ConfirmationDialogComponent;

  constructor(
    public dialogRef: MatDialogRef<PickListComponent>,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private commonService: CommonService,
    private snackBarService: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: PickList
  ) {
    this.pickList = data;
    this.selectedItems = data.selectedItems;

    if (!this.pickList.theme || this.pickList.theme === "") {
      this.pickList.theme = "success";
    }
  }

  ngOnInit() {
    this.isLoading = true;
    this.resetFilter();
    setTimeout(() => {
      this.pickListData = this.pickList.pickListData;
      this.resetFilter();
      this.isLoading = false;
    });

    if (this.pickList.isMultiple)
      this.dialogRef.backdropClick().subscribe(() => {
        this.dialogRef.close();
      });
  }

  public confirmSelection() {
    this.dialogRef.close({
      result: true,
      pickListItems: this.selectedItems
    });
  }

  public focusResults() {
    this.itemsSelectInputField.focus();
    this.cdr.detectChanges();
  }

  public isFiltered(filterStr: string) {
    if (filterStr === "") {
      this.resetFilter();
      return;
    }
    type AttributeCompare = { name: string };
    const filterFn = (rAttribute: AttributeCompare) =>
      rAttribute.name.toLowerCase().includes(filterStr.toLowerCase());

    const rAttributes = this.pickListData;
    this.filteredPickListData = (rAttributes as AttributeCompare[]).filter(
      filterFn
    ) as typeof rAttributes;

    // no values returned by the filter
    // display 'Other' as a choice
    if (
      this.filteredPickListData.length == 0 ||
      this.filteredPickListData.findIndex(
        (x) => x.name.toLowerCase() === filterStr.toLowerCase()
      ) < 0
    ) {
      if (this.filteredPickListData.length == 0)
        this.errorMessage = "No results found.";
      this.filteredPickListData.push(
        new RAttribute(
          0,
          0,
          filterStr,
          null,
          this.pickListData[0].attributeTypeId,
          true,
          true,
          new Date(),
          new Date()
        )
      );
    } else {
      this.errorMessage = "";
    }
    const encodedStr = encodeURIComponent(filterStr);
    const byteLength = new Blob([encodedStr]).size;
    if (byteLength > this.maxStrLimit) {
      const truncatedEncodedStr = encodedStr.slice(0, this.maxStrLimit);
      this.resultString = truncatedEncodedStr;
      const lastIndex = truncatedEncodedStr.lastIndexOf('%');
      if(lastIndex >= this.maxStrLimit - 3) {
        this.resultString = truncatedEncodedStr.substring(0, lastIndex);
      }
      const truncatedDecodedStr = decodeURIComponent(this.resultString);
      this.filterStr.setValue(truncatedDecodedStr);
    }
    this.filterStrLimit = Math.max(0, this.maxStrLimit - encodedStr.length);
    if (this.filterStrLimit === 0) {
      this.initialStrLimit = this.filterStrLimit;
    } else {
      this.initialStrLimit = this.maxStrLimit;
    }
  }

  public getItemName(pickListItem: RAttribute): string {
    if (pickListItem.id === 0)
      return `+ Add "${pickListItem.name}" as ${this.pickList.listType}`;
    return pickListItem.name;
  }

  public listItemSelected(pickListItem: RAttribute) {
    if (this.pickList.isMultiple) {
      this.errorMessage = "";
      if (this.selectedItems.length >= this.pickList.maxSelection) {
        this.errorMessage = "Max selected items reached.";
        return;
      }
      //same value selected
      if (this.selectedItems.find((x) => x.id === pickListItem.id)) return;

      if (pickListItem.id === 0) {
        this.addItemToListConfirmationDialog(pickListItem);
      } else {
        this.selectedItems.push(pickListItem);
        this.pickListData.push(pickListItem);
      }

    } else {
      if (pickListItem.id === 0) {
        this.addItemToListConfirmationDialog(pickListItem);
      } else {
        this.dialogRef.close({ result: true, pickListItem: pickListItem });
      }
    }
  }

  public listItemDeselect(pickListItem: RAttribute) {
    this.errorMessage = "";
    this.selectedItems.splice(
      this.selectedItems.findIndex((x) => x.id === pickListItem.id),
      1
    );
  }

  private addItemToListConfirmationDialog(pickListItem: RAttribute) {
    const confirmDialog = new ConfirmDialog();
    confirmDialog.message = `You are adding ${pickListItem.name} to the ${this.pickList.listType} list. Continue?`;
    confirmDialog.okButtonTitle = "Yes";
    confirmDialog.title = `New ${this.pickList.listType}`;

    const addConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: confirmDialog,
      disableClose: true
    });

    addConfirmationDialog.afterClosed().subscribe((closeData) => {
      if (closeData === "ok") {
        if (this.pickList.isMultiple) {
          this.addItem.emit(pickListItem);
          this.selectedItems.push(pickListItem);
          this.pickListData.push(pickListItem);
        }
        else
          this.dialogRef.close({ result: true, pickListItem: pickListItem });
      }
    });
  }

  resetFilter() {
    this.filterStr = new UntypedFormControl("");
    this.filteredPickListData = this.pickListData;
    this.commonService.getTextLimit(this.fieldName).subscribe(
      (textLimit) => {
        this.filterStrLimit = textLimit;
        this.initialStrLimit = textLimit;
        this.maxStrLimit = textLimit;
      },
      (error) => {
        this.snackBarService.error(error);
      }
    );
  }
}

@Component({
  selector: "pick-list-dialog",
  templateUrl: "pick-list-dialog.html",
  styleUrls: ["./pick-list.component.scss"],
})
export class PickListDialog {
  constructor(public dialogRef: MatDialogRef<PickListComponent>) { }

  public closeDialog() {
    this.dialogRef.close();
  }
}
