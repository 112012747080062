import { AuthService } from 'src/app/common/services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from "@angular/router";
import { Observable, of } from 'rxjs';
import { MsalBroadcastService } from '@azure/msal-angular';
import { filter } from 'rxjs/operators';
import { InteractionStatus } from '@azure/msal-browser';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private authService: AuthService,
        private router: Router,
        private msalBroadcastService: MsalBroadcastService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<boolean> | Promise<boolean> | boolean {
        if (route.data.isIgnoreRoute) {
            return false;
        }

        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None)
            )
            .pipe(() => {
                const currentAccount = this.authService.getUserProfile()
                if (!currentAccount) {
                    return of(true); // let MsalGuard to manage login redirect
                }

                // check if user has enough permission
                const userRoles: string[] = currentAccount.idTokenClaims.roles || [];
                const allowedRoles = route.data["roles"];

                const matchingRoles = userRoles.filter(x => allowedRoles.includes(x));
                if (matchingRoles.length == 0) {
                    this.router.navigate(['/forbidden']);
                    return of(false);
                }

                return of(true);
            })
    }
}
