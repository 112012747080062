import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import icShoppingBasket from '@iconify-icons/ic/twotone-shopping-basket';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'vex-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  @Input() customTemplate: TemplateRef<any>;
  icShoppingBasket = icShoppingBasket;
  buildNumber: string;
  year: number;

  constructor() {
  }

  ngOnInit() {
    this.buildNumber = environment.buildNumber;
    var d = new Date();
    this.year = d.getFullYear();
  }

  ngOnDestroy(): void { }
}
