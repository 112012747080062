import { SnackBarService } from "./../common/services/snackbar.service";
import { MatDialog } from "@angular/material/dialog";
import { Component, OnInit, HostListener } from "@angular/core";
import {
  CommonService,
  DropDown,
} from "src/app/common/services/common.service";
import {
  ResumeService,
  ResumeStatusTypes,
} from "../common/services/resume.service";
import { ProfileSummaryService } from "../common/services/profileSummary.service";
import { SkillSummaryService } from "../common/services/skillSummary.service";
import { SkillsService } from "../common/services/skill.service";
import { EducationService } from "../common/services/education.service";
import { AssociationsService } from "../common/services/association.service";
import { ProjectService } from "../common/services/project.service";
import { AuthService } from "../common/services/auth.service";
import icMoreVert from "@iconify-icons/ic/twotone-more-vert";
import { BehaviorSubject, forkJoin, Observable } from "rxjs";
import {
  ConfirmationDialogComponent,
  ConfirmDialog,
} from "../shared/confirmation-dialog/confirmation-dialog.component";
import { OnlinerProfile, RAttribute } from "../common/data-models/commonDataModels";
import { Resume } from "../common/data-models/Resume";
import { RAssociation } from "../common/data-models/RAssociation";
import { EducationModel } from "../common/data-models/EducationModel";
import { RProject } from "../common/data-models/project";
import { RSkill } from "../common/data-models/RSkill";
import { Employee } from "../common/data-models/Employee";
import { SkillSummary } from "../common/data-models/SkillSummary";
import { ProfileSummary } from "../common/data-models/ProfileSummary";
import { OnlinerService } from "../common/services/onliner.service";
import {
  BuildResumeFormUtilService,
  BuildResumeState,
} from "./build-resume-form-state.service";
import { NavigationService } from "../../@vex/services/navigation.service";
import { AltOnlinerDefault } from "../common/data-models/AltOnlinerDefault";
import { RAttributeGroupNames } from "../common/enums/rAttributeGroupEnum";
import { UserService } from "../common/services/user.service";
import { Store, select, } from "@ngrx/store";
import { State } from "src/app/build-resume-common/store/multi-select-skills-card.reducer";
import * as userSkillsActions from "src/app/build-resume-common/store/multi-select-skills-card.actions";
import { RResumeExpertiseGroup } from "../common/data-models/RResumeExpertiseGroup";

@Component({
  selector: "obs-build-resume",
  templateUrl: "./build-resume.component.html",
  styleUrls: ["./build-resume.component.scss"],
})
export class BuildResumeComponent implements OnInit {
  public resumes: Resume[];
  public refResumeId: number;
  public fullyLoaded: number[] = [];
  public profileSummaries: DropDown<ProfileSummary>[];
  public spins: RAttribute[];
  public skillSummaries: DropDown<SkillSummary>[];
  public skills: DropDown<RSkill>[];
  public userSkills: DropDown<RSkill>[];
  public resumeExpertiseGroups: RResumeExpertiseGroup[];
  public associations: DropDown<RAssociation>[];
  public educations: DropDown<EducationModel>[];
  public projects: DropDown<RProject>[];
  private userId: string;
  public canAddNewResume: boolean;
  public isLoading = true;
  public isLoading$: BehaviorSubject<boolean>;
  public isCloning: boolean = false;
  public isHidden: boolean = true;
  public formsListState: BuildResumeState;
  private isSlideChecked: boolean = this.navigationService.getIsSlideChecked();
  private altOnlinerDefault: AltOnlinerDefault =
    this.navigationService.getAltOnlinerDefault();
  public cloneResumeStatus: string = "";
  icMoreVert = icMoreVert;
  private isResumeDropDownOptionsLoaded: boolean = false;

  employee: Employee;

  public constructor(
    public commonService: CommonService,
    private navigationService: NavigationService,
    private onlinerService: OnlinerService,
    private resumeService: ResumeService,
    private profileSummaryService: ProfileSummaryService,
    private skillSummaryService: SkillSummaryService,
    private skillService: SkillsService,
    private educationService: EducationService,
    private associationService: AssociationsService,
    private projectService: ProjectService,
    private authService: AuthService,
    private userService: UserService,
    public snackBarService: SnackBarService,
    public dialog: MatDialog,
    private buildResumeStateService: BuildResumeFormUtilService,
    private store: Store<{ userSkills: State }>,
  ) { }

  @HostListener("window:beforeunload")
  canDeactivate(): boolean | Observable<boolean> {
    if(!this.authService.isAuthenticated()) return true;
    return this.formsListState !== BuildResumeState.isDirty;
  }

  public ngOnInit(): void {
    this.canAddNewResume = true;

    this.userId = this.authService.getUserId();

    if (!this.resumes) {
      this.resumes = new Array();
    }
    this.isLoading$ = new BehaviorSubject<boolean>(this.isLoading);
    this.loadResumes();

    this.buildResumeStateService
      .getFormsState()
      .subscribe(({ state, resume }) => {
        this.formsListState = state;

        if (state === BuildResumeState.refresh) {
          this.refresh(resume);
        }

        if (state === BuildResumeState.pristine) {
          //this.setCloning(false);
        }
      });

  }

  private getResumeDropDownOptions(): void {
    // if the toggle is on, use the default user's id
    const userId = this.isSlideChecked
      ? this.altOnlinerDefault.altOnlinerUserId
      : this.userId;
    forkJoin([
      this.commonService.getAttributesByGroupName(RAttributeGroupNames.Spins),
      this.profileSummaryService.getProfileSummaries(userId),
      this.skillSummaryService.getSkillSummaries(userId),
      this.skillService.getRSkills(userId),
      this.associationService.getRAssociations(userId),
      this.educationService.getMyEducationRecords(userId),
      this.projectService.getProjects(userId),
      this.userService.getOnlinerProfileDefault(userId),
      this.onlinerService.getOnliner(userId)
    ]).subscribe(
      ([
        spins,
        profileSummaries,
        skillSummaries,
        skills,
        associations,
        educations,
        projects,
        onlinerProfile,
        onliner,
      ]: [RAttribute[], ProfileSummary[], SkillSummary[], RSkill[], RAssociation[], EducationModel[], RProject[],  OnlinerProfile, Employee ]) => {
        this.profileSummaries =
          this.commonService.loadProfileSummariesDropDown(profileSummaries);

        this.skillSummaries =
          this.commonService.loadSkillSummariesDropDown(skillSummaries);

        this.skills = this.commonService.loadSkillsDropDown(
          this.sortSkillsByExpertise(skills));

        this.userSkills = this.commonService.loadUserSkillsDropDown(skills);

        this.associations =
          this.commonService.loadAssociationsDropDown(associations);

        this.educations = this.commonService.loadEducationsDropDown(educations);

        this.projects = this.commonService.loadProjectsDropDown(projects);

        if(onlinerProfile != null) {
          onliner.preferredPronouns = onlinerProfile.pronoun != null ? onlinerProfile.pronoun.name : "";
        } else {
          onliner.preferredPronouns = "";
        }
        this.loadEmployee(onliner);

        this.isLoading$.subscribe((isLoading) => {
            if (!isLoading) {
               this.loadSpins(
                   this.resumes.map((x) => x.spins),
                   spins
               );
               this.resumes.forEach((e) => {
                if (e.spins != null) {
                  e.spins.name = e.spins.name;
                }
                   
               });
           }
        });
        this.isResumeDropDownOptionsLoaded = true;
      },
      (error) => {
        this.snackBarService.error(error);
      }
    );

    this.store.dispatch(userSkillsActions.loadExpertiseTypeData({ userId: this.userId }));
  }

  private sortSkillsByExpertise(data): RSkill[] {
    return data.sort(function (skill1, skill2) {
      if (skill1.expertiseType?.name > skill2.expertiseType?.name) return 1;
      if (skill1.expertiseType?.name < skill2.expertiseType?.name) return -1;

      if (skill1.skill.name > skill2.skill.name) return 1;
      if (skill1.skill.name < skill2.skill.name) return -1;
    });
  }

  public addBuildResume(): void {
    if (this.formsListState === BuildResumeState.isDirty) {
      this.showConfirmationDialog();
    } else {
      this.resumes.unshift(this.createNewEmptyResume());
      this.canAddNewResume = false;
      this.loadDropdownOptions();
      this.setCloning(false);
    }
  }

  public isFullyLoaded(id: number): boolean {
    return this.fullyLoaded.includes(id) && this.isResumeDropDownOptionsLoaded;
    // return true;
  }

  public isNonGoldApproved(resumeCheck: Resume): boolean {
    return (
      resumeCheck?.statusType?.name === ResumeStatusTypes.APPROVED &&
      resumeCheck?.dateApproved !== resumeCheck?.maxApprovedSpinDate
    );
  }

  public onResumeHidden() {
    this.isHidden = !this.isHidden;
  }

  createNewEmptyResume(): Resume {
    return new Resume(
      -1,
      this.employee,
      null,
      -1,
      false,
      false,
      null,
      null,
      null,
      -1,
      "",
      -1,
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      null,
      false,
      null,
      true
    );
  }

  public cloneResume(refResume: Resume): void {
    this.cloneResumeStatus = refResume.statusType.name;
    if (refResume) {
      this.refResumeId = refResume.id;

      this.setCloning(true);

      this.resumes.unshift({
        ...refResume,
        reviewComments: [],
        id: -1,
        statusType: {
          ...refResume.statusType,
          id: -1,
          name: ResumeStatusTypes.NEW,
        },
        statusTypeId: -1,
        isSnapshot: false,
      });
      this.navigationService.getScrollToTopButton().nativeElement.click();
      this.canAddNewResume = false;
    }
  }

  public loadResumes(): void {
    // if the toggle is on, use the default user's id
  const userId = this.isSlideChecked
      ? this.altOnlinerDefault.altOnlinerUserId
      : this.userId;
    this.resumeService.getResumes(userId).subscribe((data: Resume[]) => {
      this.resumes = new Array();
      this.setCloning(false);

      data.forEach((element) => {
        this.resumes.push(
          new Resume(
            element.id,
            element.user,
            element.statusType,
            element.statusTypeId,
            element.isClientHidden,
            element.isProfessionalResume,
            element.dateModified,
            element.dateApproved,
            element.spins,
            element.spinsId,
            element.accreditation,
            element.profileSummaryId,
            element.profileSummaries,
            [], //lazy load skillSummaries
            [], //lazy load skills
            [], //lazy load userSkills
            [], //lazy load resumeExpertiseGroups
            [], //lazy load associations
            [], //lazy load educations
            [], //lazy load projects
            element.reviewComments,
            element.dateRequested,
            element.isSnapshot,
            element.maxApprovedSpinDate,
            element.isProjectMostRecent
          )
        );
      });
      this.isLoading$.next((this.isLoading = false));
    });
  }

  public loadSpins(userSpins: RAttribute[], spins: RAttribute[]): void {
    this.spins = new Array();

    //add original values (others are not included)
    spins
      .filter((x) => !x.isOther)
      .forEach((element) => {
        element.name = element.name;
        this.spins.push(element);
      });

    userSpins
      .filter((us) => {
        return us?.isOther && !this.spins.map((s) => s.id).includes(us.id);
      })
      .forEach((element) => {
        element.name = element.name;
        this.spins.push(element);
      });
  }

  public updateResumeAndLoadSpins(resume: Resume, spins: RAttribute[]): void {
    // this.setCloning(false);

    let updateItem = this.resumes.find((item) =>
      [resume.id, -1].includes(item.id)
    );

    let index = this.resumes.indexOf(updateItem);

    this.resumes[index] = resume;

    this.cleanNewResumeFromFullyLoaded();

    this.isLoading = false;
  }

  cleanNewResumeFromFullyLoaded() {
    const index = this.fullyLoaded.findIndex((x) => x === -1);
    if (index !== -1) {
      this.fullyLoaded.splice(index, 1);
    }
  }

  public loadEmployee(data: any): void {
      this.employee = data;
  }

  showConfirmationDialog() {
    const confirmDialog = new ConfirmDialog();

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "500px",
      data: confirmDialog,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === "ok") {
        this.buildResumeStateService.setFormsState(
          BuildResumeState.pristine,
          null
        );

        this.resumes.unshift(
          new Resume(
            -1,
            this.employee,
            null,
            -1,
            false,
            false,
            null,
            null,
            null,
            -1,
            "",
            -1,
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            null,
            false,
            null,
            true
          )
        );
        this.canAddNewResume = false;
      }
    });
  }

  private refresh(resume: Resume): void {
    this.commonService.getSpins().subscribe((spins) => {
      this.updateResumeAndLoadSpins(resume, spins);
    });
    this.buildResumeStateService.setFormsState(
      BuildResumeState.pristine,
      resume
    );
  }

  public delete(): void {
    this.resumes.shift();
    this.canAddNewResume = true;
    this.cleanNewResumeFromFullyLoaded();
  }

  public markDirty(): void {
    this.buildResumeStateService.setFormsState(BuildResumeState.isDirty, null);
  }

  public markClean(): void {
    this.buildResumeStateService.setFormsState(BuildResumeState.pristine, null);
    // this.setCloning(false);
  }

  public markFullyLoaded(resumeId: number): void {
    if (!this.fullyLoaded.includes(resumeId)) {
      this.fullyLoaded.push(resumeId);
    } else {
      this.fullyLoaded.splice(this.fullyLoaded.indexOf(resumeId), 1);
    }
  }

  public setCloning(flag: boolean): void {
    this.isCloning = flag;
  }

  public refreshList(): void {
    this.isLoading = true;
    this.loadResumes();
    if (!this.isResumeDropDownOptionsLoaded) {
      this.getResumeDropDownOptions();
    }
    this.canAddNewResume = true;
    this.fullyLoaded = [];
  }

  public loadDropdownOptions() : void {
    if (!this.isResumeDropDownOptionsLoaded) {
      this.getResumeDropDownOptions();
    }
  }
}
