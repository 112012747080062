import {
  RAttribute,
  RExpertiseType,
  SavedQuery,
} from "./../data-models/commonDataModels";
import { throwError, of, Observable } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

import { AuthorizedHttp } from "./authorized-http.service";
import { Skill } from "../data-models/commonDataModels";
import { Resume } from "../data-models/Resume";
import { Employee } from "../data-models/Employee";
import { OnlineTeam } from "../data-models/OnlineTeam";
import { EmployeeToSave } from "../data-models/EmployeeToSave";
import { CommonService } from "./common.service";

@Injectable()
export class OnlinerService {
  private apiEndpoint: string = `${environment.apiEndpoint}/api/Onliner`;
  private _onliners: Employee[];
  private _onlinersSearchResults: Employee[];
  private _onliner: Employee;
  private _onlinersExcludingDefault: Employee[];

  constructor(private http: AuthorizedHttp, private commonService: CommonService) {}

  public getOnliners(): Observable<Employee[]> {
    return this._onliners
      ? of(this._onliners)
      : this.http.get(`${this.apiEndpoint}/onliners`).pipe(
          map((result) => {
            this._onliners = result;
            return result;
          }),
          catchError((errorResponse: any) =>
            throwError(errorResponse.error.exceptionMessage || "Server error")
          )
        );
    }

  public getActiveOnliners(): Observable<Employee[]> {
    return this.http.get(`${this.apiEndpoint}/activeOnliners`).pipe(
      map((result) => {
        this._onliners = result;
        return result;
      }),
      catchError((errorResponse: any) =>
        throwError(errorResponse.error.exceptionMessage || "Server error")
      )
    );
  }

  public getOnliner(userId: string): Observable<Employee> {
    userId = this.commonService.encrypt(userId);
    return this.http.get(`${this.apiEndpoint}/onliners/${userId}`).pipe(
      map((result) => {
        this._onliner = result;
        return result;
      }),
      catchError((errorResponse: any) =>
        throwError(errorResponse.error.exceptionMessage || "Server error")
      )
    );
  }

  public getOnlinersExcludingDefault(): Observable<Employee[]> {
    return this._onlinersExcludingDefault
      ? of(this._onlinersExcludingDefault)
      : this.getOnliners().pipe(
          map((onliners) => {
            this._onlinersExcludingDefault = onliners.filter(
              (o) => o.userId !== defaultOnliner.userId
            );
            return this._onlinersExcludingDefault;
          })
        );
  }

  // search via preset
  getOnlinersUsingPresetQuery(query: string) {
    var encodedQuery = query;
    while (
      encodedQuery.includes("#") ||
      encodedQuery.includes("&") ||
      encodedQuery.includes("+")
    ) {
      encodedQuery = encodedQuery.replace("#", encodeURIComponent("#"));
      encodedQuery = encodedQuery.replace("&", encodeURIComponent("&"));
      encodedQuery = encodedQuery.replace("+", encodeURIComponent("+"));
    }
    return this.http
      .get(`${this.apiEndpoint}/onliners/search?presetQuery=${encodedQuery}`)
      .pipe(
        map((result) => {
          this._onlinersSearchResults = result;
          return result;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }

  getOnlinersUsingWildCard(
    query: string,
    startWith: boolean,
    approvedResume: boolean
  ) {
    var encodedQuery = query;
    while (
      encodedQuery.includes("#") ||
      encodedQuery.includes("&") ||
      encodedQuery.includes("+")
    ) {
      encodedQuery = encodedQuery.replace("#", encodeURIComponent("#"));
      encodedQuery = encodedQuery.replace("&", encodeURIComponent("&"));
      encodedQuery = encodedQuery.replace("+", encodeURIComponent("+"));
    }
    return this.http
      .get(
        `${this.apiEndpoint}/onliners/searchWildcard?presetQuery=${encodedQuery}&startWith=${startWith}&approvedResume=${approvedResume}`
      )
      .pipe(
        map((result) => {
          this._onlinersSearchResults = result;
          return result.sort((a, b) => {
            if (a.lastName === b.lastName)
              return a.firstName > b.firstName ? 1 : -1;
            else if (a.lastName > b.lastName) return 1;
            else return -1;
          });
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }

  public getSavedQueries(userId: string): Observable<SavedQuery[]> {
    userId = this.commonService.encrypt(userId);
    return this.http.get(`${this.apiEndpoint}/mySavedQueries/${userId}`).pipe(
      map((result) => {
        return result;
      }),
      catchError((errorResponse: any) =>
        throwError(errorResponse.error.exceptionMessage || "Server error")
      )
    );
  }

  public postNewQuery(
    newQuery: SavedQuery,
    userId: string
  ): Observable<SavedQuery> {
    userId = this.commonService.encrypt(userId);
    return this.http
      .post(
        `${this.apiEndpoint}/addNewQuery/` + userId,
        JSON.stringify(newQuery)
      )
      .pipe(
        map((result) => {
          return result;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }

  public updateSavedQuery(
    newQuery: SavedQuery,
    userId: string
  ): Observable<SavedQuery> {
    userId = this.commonService.encrypt(userId);
    return this.http
      .patch(
        `${this.apiEndpoint}/updateQuery/` + userId,
        JSON.stringify(newQuery)
      )
      .pipe(
        map((result) => {
          return result;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }

  public deleteSavedQuery(
    userId: string,
    queryToDel: SavedQuery
  ): Observable<SavedQuery> {
    userId = this.commonService.encrypt(userId);
    return this.http
      .patch(
        `${this.apiEndpoint}/deleteQuery/` + userId,
        JSON.stringify(queryToDel)
      )
      .pipe(
        map((result) => {
          return result;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }

  public upsertOnliner(
    employeeToSave: EmployeeToSave,
    userId: string,
  ): Observable<Employee> {
    userId = this.commonService.encrypt(userId);
    return this.http
      .post(
        `${this.apiEndpoint}/upsertOnliner/` + userId,
        JSON.stringify(employeeToSave)
      )
      .pipe(
        map((result) => {
          return result;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse)
        )
      );
  }

  public validateAccountingUserId(
    userId: string,
    accountingUserID: string
  ): Observable<string> {
    return this.http.get(`${this.apiEndpoint}/${this.commonService.encrypt(userId)}/ValidateAccountingUserId/${this.commonService.encrypt(accountingUserID)}`).pipe(
      map((result) => result),
      catchError((errorResponse: any) =>
        throwError(errorResponse.error.exceptionMessage || "Server error")
      )
    );
  }

  public getCareerGroupByAccountingUserId(
    userId: string,
    accountingUserID: string
  ): Observable<string> {
    return this.http.get(`${this.apiEndpoint}/${this.commonService.encrypt(userId)}/GetCareerGroupByAccountingUserId/${this.commonService.encrypt(accountingUserID)}`).pipe(
      map((result) => result),
      catchError((errorResponse: any) =>
        throwError(errorResponse.error.exceptionMessage || "Server error")
      )
    );
  }
  
  // public getOnlinerPublicGoals(onlinerId: string): Observable<GoalTrackerGoals[]> {
  //   return this.http
  //     .get(`${this.apiEndpoint}/onlinerGoals/${onlinerId}`)
  //     .pipe(
  //       catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
  //     );
  // }
}

export const defaultOnliner: Employee = {
  userId: "all",
  firstName: "All",
  lastName: "",
  careerManager: "",
  email: "",
  isCMDelegate: false,
  isInactive: false,
  resumes: [],
  careerManagerInfo: null,
  onlineTeamID: null,
  onlineTeam: null
};
// export const defaultAssociation: Association = { assocId: -1, assocName: 'All', isActive: true };
//xport const defaultCertCourseName: CertificationName = { cert_Id: -1, cert_Name: 'All', cert_Desc: '',vendorID: -1, skillID: -1, isActive: true };
export const defaultSkill: Skill = {
  skillId: -1,
  name: "All",
  description: "",
  complexityRatingId: -1,
  skillIndicator: "",
  isActive: true,
};
export const defaultRExpertiseType: RExpertiseType = {
  id: -1,
  name: "All",
  isActive: true,
};
export const defaultAttribute: RAttribute = {
  id: -1,
  aliasId: 0,
  attributeTypeId: -1,
  name: "All",
  value: "",
  isActive: true,
  isOther: false,
  createdDate: new Date(),
  modifiedDate: new Date(),
};
