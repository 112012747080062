import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { AuthorizedHttp } from "./authorized-http.service";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { NonOnlinerResume } from "../data-models/NonOnlinerResume";
import {WorkflowResume} from '../data-models/WorkflowResume';
import { CommonService } from "./common.service";

@Injectable()
export class ResumeNOService {
  private _apiEndpoint = `${environment.apiEndpoint}/api/nonOnlinerResume`;
  private _resumes: NonOnlinerResume[];
  private _resume: NonOnlinerResume;
  private _originalResume: NonOnlinerResume;
  private _isDeleted: boolean;

  constructor(private http: AuthorizedHttp, private commonService: CommonService) {
    const headers: any = {
      "Content-Type":
        "application/json; charset=utf-8" /*or whatever type is relevant */,
      Accept: "application/json",
    };
  }

  public getResumesPendingApproval():Observable<WorkflowResume[]>{            
    return this.http.get(`${this._apiEndpoint}/resumesPendingApproval`).pipe(
      map((result) => {        
        return result;
      }),
      catchError((errorResponse: any) =>
        throwError(errorResponse.error.exceptionMessage || "Server error")
      )
    );
  }

  public getApprovedResumes(resumeOwnerUserId: string): Observable<NonOnlinerResume[]> {
    resumeOwnerUserId = this.commonService.encrypt(resumeOwnerUserId);
    return this.http.get(`${this._apiEndpoint}/approvedResumes/${resumeOwnerUserId}`).pipe(
      map((result) => {
        
        this._resumes = result;
        return this._resumes;
      }),
      catchError((errorResponse: any) =>
        throwError(errorResponse.error.exceptionMessage || "Server error")
      )
    );
  }

  public getResumes(resumeOwnerUserId: string): Observable<NonOnlinerResume[]> {
    resumeOwnerUserId = this.commonService.encrypt(resumeOwnerUserId);
    return this.http.get(`${this._apiEndpoint}/resumes/${resumeOwnerUserId}`).pipe(
      map((result) => {
        
        this._resumes = result;
        return this._resumes;
      }),
      catchError((errorResponse: any) =>
        throwError(errorResponse.error.exceptionMessage || "Server error")
      )
    );
  }

  public getResume(loggedUserId: string, resumeId: number): Observable<NonOnlinerResume> {
    loggedUserId = this.commonService.encrypt(loggedUserId);
    return this.http.get(`${this._apiEndpoint}/resumes/${loggedUserId}/${resumeId}`).pipe(
      map((result) => {
        result.projects = result.projects.map(pr => {
          pr.responsibilities = pr.responsibilities?.sort((a, b) => a.sortOrder - b.sortOrder);
          return pr;
        });
        this._resume = result;
        return this._resume;
      }),
      catchError((errorResponse: any) =>
        throwError(errorResponse.error.exceptionMessage || "Server error")
      )
    );
  }

  public getSnapshotResume(loggedUserId: string, resumeId: number): Observable<NonOnlinerResume> {
    loggedUserId = this.commonService.encrypt(loggedUserId);
    return this.http.get(`${this._apiEndpoint}/resumes/snapshot/${loggedUserId}/${resumeId}`).pipe(
      map((result) => {
        result.projects = result.projects.map(pr => {
          pr.responsibilities = pr.responsibilities?.sort((a, b) => a.sortOrder - b.sortOrder);
          return pr;
        });
        this._originalResume = result;
        return this._originalResume;
      }),
      catchError((errorResponse: any) =>
        throwError(errorResponse.error.exceptionMessage || "Server error")
      )
    );
  }

  public postNewResume(
    resumeModel: NonOnlinerResume,
    resumeOwnerUserId: string
  ): Observable<NonOnlinerResume> {
    resumeOwnerUserId = this.commonService.encrypt(resumeOwnerUserId);
    return this.http
      .post(
        `${this._apiEndpoint}/addNewResume/${resumeOwnerUserId}`,
        JSON.stringify(resumeModel)
      )
      .pipe(
        map((result) => {
          this._resume = result;
          return this._resume;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }

  public updateResume(resumeModel: NonOnlinerResume, resumeOwnerUserId: string): Observable<NonOnlinerResume> {
    resumeOwnerUserId = this.commonService.encrypt(resumeOwnerUserId);
    return this.http
      .patch(
        `${this._apiEndpoint}/updateResume/${resumeOwnerUserId}`,
        JSON.stringify(resumeModel)
      )
      .pipe(
        map((result) => {
          this._resume = result;
          return this._resume;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }

  public deleteResume(resumeId: number): Observable<Boolean> {
    return this.http
      .delete(`${this._apiEndpoint}/deleteResume/${resumeId}`)
      .pipe(
        map((result) => {
          this._isDeleted = (result != 0);
          return this._isDeleted;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }
}

export class ResumeStatusTypes {
  public static NEW = 'New';
  public static PENDING_CM_REVIEW = 'Pending CM Review';
  public static REVISION_REQUIRED_CM = 'Revision Required by CM';
  public static PENDING_RMT_REVIEW = 'Pending RMT Review';
  public static APPROVED = 'Approved';
  public static REVISION_REQUIRED_RMT = 'Revision Required by RMT';
  public static REQUEST_CANCELED = 'Request Cancelled';
}