import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { fadeInUp400ms } from "src/@vex/animations/fade-in-up.animation";
import { RAttribute } from "src/app/common/data-models/commonDataModels";
import { EducationModel } from "src/app/common/data-models/EducationModel";
import { RProject } from "src/app/common/data-models/project";
import { RAssociation } from "src/app/common/data-models/RAssociation";
import { RSkill } from "src/app/common/data-models/RSkill";
import { AssociationsService } from "src/app/common/services/association.service";
import { CommonService } from "src/app/common/services/common.service";
import { EducationService } from "src/app/common/services/education.service";
import { Employee } from "src/app/common/data-models/Employee";
import { ProjectService } from "src/app/common/services/project.service";
import { SkillsService } from "src/app/common/services/skill.service";
import icInfoCircle from "@iconify-icons/uil/info-circle";
import {
  ConfirmationDialogComponent,
  ConfirmDialog,
} from "src/app/shared/confirmation-dialog/confirmation-dialog.component";
import { ProfileSummaryService } from "src/app/common/services/profileSummary.service";
import { SkillSummaryService } from "src/app/common/services/skillSummary.service";

export interface DialogData {
  onliner: Employee;
  value: string;
  displaySummary: boolean;
  startWith: boolean;
}

@Component({
  selector: "obs-onliner-result-modal",
  templateUrl: "./onliner-result-modal.component.html",
  styleUrls: ["./onliner-result-modal.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: [fadeInUp400ms],
})
export class OnlinerResultModalComponent implements OnInit {
  displayedColumnsSkill = ["Skill", "Expertise"];
  displayedColumnsAssociation = ["Association", "Date Joined"];
  displayedColumnsEducation = ["Education", "Date Achieved"];
  displayedColumnsProject = ["Project", "Employer", "Duration", "Industry"];
  public onliner: Employee;
  public panelSkillOpenState = true;
  public panelEducationOpenState = false;
  public panelProjectOpenState = false;
  public panelAssociationOpenState = false;
  public userId: string;
  public expertiseLevels: RAttribute[];
  public matchingSkillsDisplayData = new MatTableDataSource<RSkill>();
  public matchingAssociationsDisplayData =
    new MatTableDataSource<RAssociation>();
  public matchingEducationsDisplayData =
    new MatTableDataSource<EducationModel>();
  public matchingProjectsDisplayData = new MatTableDataSource<RProject>();
  public mapListToArrays: { [key: string]: string[] } = {};
  public foundString: string[] = [];
  public query: string;
  public displayQuery: string;
  public displaySummary: boolean;
  disableAnimation = true;
  icInfoCircle = icInfoCircle;
  expertiseInfo: string;
  public isLoading = true;

  constructor(
    private skillService: SkillsService,
    public associationService: AssociationsService,
    public projectService: ProjectService,
    public educationService: EducationService,
    public profileSummaryService: ProfileSummaryService,
    public skillSummaryService: SkillSummaryService,
    public commonService: CommonService,
    public dialogRef: MatDialogRef<OnlinerResultModalComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  async ngOnInit(): Promise<void> {
    this.onliner = this.data.onliner;
    this.userId = this.onliner.userId;
    this.query = this.data.value;
    this.displayQuery = this.query;
    if (this.data.startWith)
      this.displayQuery = this.displayQuery.slice(
        2,
        this.displayQuery.length - 2
      );
    this.displaySummary = this.data.displaySummary;
    if (this.displaySummary) this.initValue(this.query);
    else this.isLoading = false;
    this.expertiseLevels = await this.commonService
      .getExpertiseLevels()
      .toPromise();
    this.expertiseLevels = this.expertiseLevels.sort((a, b) =>
      Number(a.value) < Number(b.value) ? -1 : 1
    );
    this.expertiseInfo = this.infoTooltip();

    this.skillService.getUserSkills(this.userId).subscribe((skill) => {
      this.matchingSkillsDisplayData.data = skill;
      this.matchingSkillsDisplayData.data.forEach((obj) => {
        var expertiseLevelObj = this.expertiseLevels.find(
          (x) => x.id === obj.expertiseLevelId
        );
        obj["expertiseLevel"] = expertiseLevelObj.name;
        obj.skill.name = obj.skill.name;
      });
    });

    this.associationService
      .getUserRAssociations(this.userId)
      .subscribe((association) => {
        this.matchingAssociationsDisplayData.data = association;
        this.matchingAssociationsDisplayData.data.forEach((obj) => {
          obj.assoc.name = obj.assoc.name;
        });
      });

    this.educationService
      .getUserEducationRecords(this.userId)
      .subscribe((education) => {
        this.matchingEducationsDisplayData.data = education;
        this.matchingEducationsDisplayData.data = education.sort(function (
          a,
          b
        ) {
          if (a.endDate === null) {
            return -1;
          } else if (b.endDate === null) {
            return 1;
          }
          return a.endDate > b.endDate ? -1 : 1;
        });
        this.matchingEducationsDisplayData.data = education.sort(function (
          a,
          b
        ) {
          if (a.endDate === b.endDate)
            return a.education.name <
              b.education.name
              ? -1
              : 1;
          return 0;
        });
        this.matchingEducationsDisplayData.data.forEach((obj) => {
          obj.education.name = obj.education.name;
        });
      });

    this.projectService.getUserProjects(this.userId).subscribe((project) => {
      this.matchingProjectsDisplayData.data = project.sort((a, b) =>
        a.startDate > b.startDate ? -1 : 1
      );
      this.matchingProjectsDisplayData.data.forEach((obj) => {
        obj.jobTitles.forEach((title) => {
          title.jobTitleObj.name = title.jobTitleObj.name.trim()
        });
        obj.industries.forEach((industry) => {
          industry.industryObj.name = industry.industryObj.name.trim()

        });
        obj.company.name = obj.company.name.trim();
      });
    });
  }

  infoTooltip(): string {
    var returnString = "";
    this.expertiseLevels.forEach((a) => {
      returnString =
        returnString +
        a.name +
        ": " +
        this.commonService.getExperienceLength(a, this.expertiseLevels) +
        "\n";
    });
    return returnString;
  }

  onInfoClicked() {
    const confirmDialog = new ConfirmDialog();
    confirmDialog.title = "Experience";
    var returnString = "";
    this.expertiseLevels.forEach((a) => {
      returnString =
        returnString +
        a.name +
        ": " +
        this.commonService.getExperienceLength(a, this.expertiseLevels) +
        "<br>";
    });
    confirmDialog.message = returnString;
    confirmDialog.cancelButtonTitle = null;
    confirmDialog.okButtonTitle = "Ok";

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "500px",
      data: confirmDialog,
      disableClose: true,
    });
  }

  async initValue(value: string) {
    var projects = await this.projectService
      .getUserProjects(this.userId)
      .toPromise();
    var assocs = await this.associationService
      .getUserRAssociations(this.userId)
      .toPromise();
    var skills = await this.skillService.getUserSkills(this.userId).toPromise();
    var edus = await this.educationService
      .getUserEducationRecords(this.userId)
      .toPromise();
    var skillSumm = await this.skillSummaryService
      .getUserSkillSummaries(this.userId)
      .toPromise();
    var profileSumm = await this.profileSummaryService
      .getUserProfileSummaries(this.userId)
      .toPromise();

    var escapedQuery = this.query.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    var regExp = new RegExp(escapedQuery, "gi");

    this.mapListToArrays["Name"] = [
      this.onliner.firstName,
      this.onliner.lastName,
    ];
    if (projects != null) {
      this.mapListToArrays["Companies"] = projects.map(
        (proj) => proj.company.name
      );
      this.mapListToArrays["Project Descriptions"] = projects.map(
        (proj) => proj.projectDescription
      );
      this.mapListToArrays["Project Responsibilities"] = projects
        .map((proj) => proj.responsibilities)
        .reduce((prev, next) => prev.concat(next), [])
        .map((res) => res.responsibility);
      this.mapListToArrays["Industries"] = projects
        .map((proj) => proj.industries)
        .reduce((prev, next) => prev.concat(next), [])
        .map((industries) => industries.industryObj.name);
      this.mapListToArrays["Job Titles"] = projects
        .map((proj) => proj.jobTitles)
        .reduce((prev, next) => prev.concat(next), [])
        .map((titles) => titles.jobTitleObj.name);
    }
    if (assocs != null)
      this.mapListToArrays["Associations"] = assocs.map(
        (assoc) => assoc.assoc.name
      );
    if (skills != null)
      this.mapListToArrays["Skills"] = skills.map((skill) => skill.skill.name);
    if (edus != null) {
      this.mapListToArrays["Degrees"] = edus.map((edu) => edu.education.name);
      this.mapListToArrays["Institution Cities"] = edus.map(
        (edu) => edu.institutionCity
      );
    }
    if (skillSumm != null)
      this.mapListToArrays["Skill Summaries"] = [
        ...skillSumm.map((summ) => summ.name),
        ...skillSumm.map((summ) => summ.summary),
      ];
    if (profileSumm != null)
      this.mapListToArrays["Profile Summaries"] = [
        ...profileSumm.map((summ) => summ.name),
        ...profileSumm.map((summ) => summ.summary),
      ];
    Object.keys(this.mapListToArrays).forEach((key) => {
      if (
        this.mapListToArrays[key].filter((a) => {
          if (!this.data.startWith)
            return a?.toLowerCase().includes(value.toLowerCase());
          else return a ? regExp.test(a) : false;
        }).length != 0
      ) {
        this.foundString.push(key);
      }
    });
    this.foundString = this.foundString.sort((a, b) => (a < b ? -1 : 1));
    this.isLoading = false;
  }
}
