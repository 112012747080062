import { createAction, props } from "@ngrx/store";
import {
  RAttribute, 
  RCompetencySkillCategory
} from "../../common/data-models/commonDataModels";
import { Skill } from "../../build-resume/build-resume-card/models/Skill";
import { OnlinerSkill } from "../../build-resume/build-resume-card/models/OnlinerSkill";

export const loadExpertiseTypeData = createAction(
  "[Resume User Skills] LOAD_EXPERTISE_TYPE_DATA",
  props<{ userId: string; }>()
);

export const setBackendDataIsLoadedStatusToFailed = createAction(
  "[Resume User Skills] SET_BACKEND_DATA_IS_LOADED_STATUS",
  props<{ dataName: string; }>()
);

export const setInitialOnlinerSkills = createAction(
  "[Resume User Skills] SET_INITIAL_ONLINER_SKILLS", 
  props<{ onlinerSkills: OnlinerSkill[]; }>()
);

export const setSkills = createAction(
  "[Resume User Skills] SET_SKILLS", 
  props<{ skills: Skill[]; }>()
);

export const addOnlinerSkill = createAction(
  "[Resume User Skills] ADD_ONLINER_SKILL", 
  props<{ userId: string, competencySkillCategory: RCompetencySkillCategory; }>()
);

export const removeOnlinerSkill = createAction(
  "[Resume User Skills] REMOVE_ONLINER_SKILL", 
  props<{ onlinerSkillToDelete: OnlinerSkill; }>()
);

export const updateExpertiseType = createAction(
  "[Resume User Skills] UPDATE_ONLINER_SKILL_EXPERTISE_TYPE", 
  props<{ onlinerSkillToUpdate: OnlinerSkill; newExpertiseType: RAttribute; }>()
);

export const saveOtherExpertiseType = createAction(
  "[Resume User Skills] UPDATE_ONLINER_SKILL_SAVE_NEW_EXPERTISE_TYPE", 
  props<{ onlinerSkillToUpdate: OnlinerSkill; newExpertiseType: RAttribute; userId: string }>()
);

export const saveGroupExpertiseType = createAction(
  "[Resume User Skills] SAVE_NEW_EXPERTISE_TYPE", 
  props<{ newExpertiseType: RAttribute; userId: string }>()
);

export const saveOtherExpertiseTypeDone = createAction(
  "[Resume User Skills] SAVE_OTHER_EXPERTISE_TYPE_DONE", 
  props<{ onlinerSkillToUpdate: OnlinerSkill; newExpertiseType: RAttribute; userId: string }>()
);

export const saveGroupExpertiseTypeDone = createAction(
  "[Resume User Skills] SAVE_EXPERTISE_TYPE_DONE", 
  props<{ newExpertiseType: RAttribute; userId: string }>()
);

export const setUserSkillsExpertiseTypes = createAction(
  "[Resume User Skills] SET_EXPERTISE_TYPES", 
  props<{ expertiseTypes: RAttribute[]; }>()
);

export const addUserSkillExpertiseType = createAction(
  '[Resume User Skills] ADD_USER_SKILL_EXPERTISE_TYPE',
  props<{ expertiseType: { id: number, name: string } }>()
);

export const saveSkills = createAction(
  "[Resume User Skills] SAVE_SKILLS", 
  props<{ userId: string, onlinerSkills: OnlinerSkill[]; }>()
);

export const saveSkillsDone = createAction(
  "[Resume User Skills] SAVE_SKILLS_DONE", 
  props<{ userId: string, onlinerSkills: OnlinerSkill[]; }>()
);

export const cancelOnlinerSkillChanges = createAction(
  "[Resume User Skills] CANCEL_ONLINER_SKILLS_CHANGES"
);

// START FILTER-RELATED ACTIONS 
export const setSkillNameFilterValue = createAction(
  "[Resume User Skills] SET_SKILLS_SKILL_NAME_FILTER_VALUE", 
  props<{ value: string, resetFilterResult: boolean }>()
);

export const setExpertiseTypeFilterValue = createAction(
  "[Resume User Skills] SET_EXPERTISE_TYPE_NAME_FILTER_VALUE", 
  props<{ value: string, resetFilterResult: boolean }>()
);

export const resetExpertiseTypeFilterValue = createAction(
  "[Resume User Skills] SET_EXPERTISE_TYPE_RESET_FILTER_VALUE"
);

export const resetSkillFilterValue = createAction(
  "[Resume User Skills] SET_SKILLS_RESET_FILTER_VALUE"
);
// END FILTER-RELATED ACTIONS 

export const setIsSaving = createAction(
  "[Resume User Skills] SET_IS_SAVING", 
  props<{ value: boolean }>()
);

export const resetStore = createAction("[Resume User Skills] RESET_STORE");