import { forkJoin } from "rxjs";
import { Pipe, PipeTransform, Inject } from "@angular/core";
import { RProject } from "../../common/data-models/project";
import { RSkill } from "src/app/common/data-models/RSkill";
import { SkillSummary } from "src/app/common/data-models/SkillSummary";
import { RAssociation } from "src/app/common/data-models/RAssociation";
import { EducationModel } from "src/app/common/data-models/EducationModel";
import { Resume } from "src/app/common/data-models/Resume";
import { HttpClient } from "@angular/common/http";
import { formatDate } from "@angular/common";
import { ProjectIndustry } from "../data-models/ProjectIndustry";
import { RProjectGroup } from "../data-models/ProjectGroup";
import { isNullOrUndefined } from "@swimlane/ngx-datatable";
import { RResumeExpertiseGroup } from "../data-models/RResumeExpertiseGroup";

@Pipe({
  name: "filterSkillExpertiseUnique",
  pure: false,
})
export class FilterSkillExpertiseUniquePipe implements PipeTransform {
  transform(value: RSkill[], args?: any): string[] {
    const uniqueArray = [
      ...new Set(
        value
          .filter((item) => item.skillId > 0)
          .map((item) => {
            return item.expertiseType?.name;
          })
      ),
    ];

    return uniqueArray;
  }
}

@Pipe({
  name: "filterSkillByExpertise",
  pure: false,
})
export class FilterSkillByExpertisePipe implements PipeTransform {
  transform(items: RSkill[], expertise: string): RSkill[] {
    if (!items) {
      return items;
    }

    return items.filter(
      (item) => item.skillId > 0 && item.expertiseType?.name === expertise
    );
  }
}

@Pipe({
  name: "filterResumeExpertiseGroupUnique",
  pure: false,
})
export class FilterResumeExpertiseGroupUniquePipe implements PipeTransform {
  transform(value: RResumeExpertiseGroup[], args?: any): string[] {
    if (value && Array.isArray(value) && value.length > 0) {
      let uniqueArray = [
        ...new Set(
          value
            .filter((item) => item?.expertiseTypeId > 0)
            .sort((a, b) => (a?.expertiseSortOrder || 0) - (b?.expertiseSortOrder || 0))
            .map((item) => item.expertiseType?.name)
            .filter((name) => name) 
        ),
      ];
      return uniqueArray;
    }
  }
}

@Pipe({
  name: "filterResumeExpertiseGroupByExpertise",
  pure: false,
})
export class FilterResumeExpertiseGroupByExpertisePipe implements PipeTransform {
  transform(items: RResumeExpertiseGroup[], expertise: string): RResumeExpertiseGroup[] {
    if (!items) {
      return items;
    }

    return items.filter(
      (item) => item.skillId > 0 && item.expertiseType?.name === expertise
    ).sort((a, b) => a.sortOrder - b.sortOrder);
  }
}

@Pipe({
  name: "filterEducationByType",
  pure: false,
})
export class FilterEducationByTypePipe implements PipeTransform {
  transform(items: EducationModel[], type: string): any {
    if (!items) {
      return items;
    }

    return items.filter(
      (item) => item.educationId > 0 && item.educationType?.name === type
    );
  }
}

@Pipe({
  name: "getProjectIndustriesUnique",
  pure: false,
})
export class GetProjectIndustriesUniquePipe implements PipeTransform {
  transform(value: RProject[], args?: any): string[] {
    const uniqueArray = [
      ...new Set(
        value
          .filter((item) => item.id > 0)
          .map((item) => {
            return (
              item.industries || [
                new ProjectIndustry(item.id, item.industry.id, item.industry),
              ]
            );
          })
          .reduce((a, b) => a.concat(b), [])
          .map((item) => item.industryObj.name)
      ),
    ].sort((a, b) =>
      a.toUpperCase().localeCompare(
        b.toUpperCase()
      )
    );

    return uniqueArray;
  }
}

@Pipe({
  name: "getAssociations",
  pure: false,
})
export class GetAssociationsPipe implements PipeTransform {
  transform(items: RAssociation[], args?: any): RAssociation[] {
    if (!items) {
      return items;
    }

    return items.filter((item) => item.assocId > 0);
  }
}

@Pipe({
  name: "getProjects",
  pure: false,
})
export class GetProjectsPipe implements PipeTransform {
  transform(items: RProject[], args?: any): RProjectGroup[] {
    if (!items) {
      return [];
    }
    const g = items
      .filter((item) => item.id > 0)
      .reduce((group: RProjectGroup[], project) => {
        if (group.length > 0) {
          const prevProjectGroup = group[group.length - 1];
          if (prevProjectGroup.company.id === project.company.id) {
            if (prevProjectGroup.startDate > project.startDate)
              prevProjectGroup.startDate = project.startDate;
            if (
              prevProjectGroup.endDate &&
              prevProjectGroup.endDate < project.endDate
            )
              prevProjectGroup.endDate = project.endDate;
            prevProjectGroup.projects.push(project);
          } else group.push(new RProjectGroup(project));
        } else group.push(new RProjectGroup(project));
        return group;
      }, []);

    return g;
  }
}

@Pipe({
  name: "getSkillSummaries",
  pure: false,
})
export class GetSkillSummariesPipe implements PipeTransform {
  transform(items: SkillSummary[], args?: any): SkillSummary[] {
    if (!items) {
      return items;
    }

    return items.filter((item) => item.id > 0);
  }
}

export abstract class ResumeBuilderService {
  private _resume: Resume;
  protected get resume(): Resume {
    return this._resume;

    /*
    //Debug purposes - mock a more real resume
    if (this._resume) {
      return {
        ...this._resume,
        user: {
          ...this._resume.user,
          firstName: "Johnathan Albino",
          lastName: "Castro",
        },
        isClientHidden: false,
        profileSummaries: [
          {
            ...this._resume.profileSummaries[0],
            summary: `Johnathan is an experienced full stack software developer with over 14 years of experience, including 8 years in an American multinational company, working on applications for different industries, such as MES (Manufacturing Execution Systems) and Health Care. He has demonstrated strong problem-solving skills and attention to detail.`,
          },
        ],
        skillSummaries: [
          {
            ...this._resume.skillSummaries[0],
            name: "Software Development",
            summary:
              "Johnathan has a strong background in backend applications using Java and C# and vast experience in frontend applications using Angular, Ext JS, HTML5, JavaScript, and CSS3. His experience allows him to easily deliver solutions with new technologies and tools. He has on-site international experience working on important projects for big companies like Facebook and ADC in Germany and Ireland.",
          },
          {
            ...this._resume.skillSummaries[0],
            name: "Software Design",
            summary:
              "Johnathan designed solutions for big and important projects for Sanmina’s clients, providing the best solutions for Manufacturing Enterprise System through business analysis, architecture, design, documentation, development, and testing in compliance with the corporation ideology and standards.",
          },
          {
            ...this._resume.skillSummaries[0],
            name: "Leadership, Process and Quality Improvement",
            summary:
              "Johnathan has led important projects for Sanmina’s clients, such as ADC, a huge company that manufactures medical products. He not only led the development team, but also acted as a developer, tester, technical architect, and system analyst.",
          },
        ],
        skills: [
          {
            ...this._resume.skills[0],
            expertiseType: {
              ...this._resume.skills[0].expertiseType,
              name: "Server",
            },
            skill: {
              ...this._resume.skills[0].skill,
              name: "Java",
            },
          },
          {
            ...this._resume.skills[0],
            expertiseType: {
              ...this._resume.skills[0].expertiseType,
              name: "Server",
            },
            skill: {
              ...this._resume.skills[0].skill,
              name: "C#, VB.Net, ASP.NET, Windows Forms",
            },
          },
          {
            ...this._resume.skills[0],
            expertiseType: {
              ...this._resume.skills[0].expertiseType,
              name: "Server",
            },
            skill: {
              ...this._resume.skills[0].skill,
              name: "Oracle Database & PL/SQL",
            },
          },
          {
            ...this._resume.skills[0],
            expertiseType: {
              ...this._resume.skills[0].expertiseType,
              name: "Server",
            },
            skill: {
              ...this._resume.skills[0].skill,
              name: "SQL Server Database",
            },
          },
          {
            ...this._resume.skills[0],
            expertiseType: {
              ...this._resume.skills[0].expertiseType,
              name: "Server",
            },
            skill: {
              ...this._resume.skills[0].skill,
              name: "Postgres Database & PL/pgSQL",
            },
          },

          {
            ...this._resume.skills[0],
            expertiseType: {
              ...this._resume.skills[0].expertiseType,
              name: "Web",
            },
            skill: {
              ...this._resume.skills[0].skill,
              name: "HTML5, CSS3",
            },
          },
          {
            ...this._resume.skills[0],
            expertiseType: {
              ...this._resume.skills[0].expertiseType,
              name: "Web",
            },
            skill: {
              ...this._resume.skills[0].skill,
              name: "JavaScript, TypeScript",
            },
          },
          {
            ...this._resume.skills[0],
            expertiseType: {
              ...this._resume.skills[0].expertiseType,
              name: "Web",
            },
            skill: {
              ...this._resume.skills[0].skill,
              name: "AngularJS, 2+",
            },
          },
          {
            ...this._resume.skills[0],
            expertiseType: {
              ...this._resume.skills[0].expertiseType,
              name: "Web",
            },
            skill: {
              ...this._resume.skills[0].skill,
              name: "Ext JS",
            },
          },
          {
            ...this._resume.skills[0],
            expertiseType: {
              ...this._resume.skills[0].expertiseType,
              name: "Web",
            },
            skill: {
              ...this._resume.skills[0].skill,
              name: "Bootstrap, JQuery",
            },
          },
          {
            ...this._resume.skills[0],
            expertiseType: {
              ...this._resume.skills[0].expertiseType,
              name: "Web",
            },
            skill: {
              ...this._resume.skills[0].skill,
              name: "MVC",
            },
          },
          {
            ...this._resume.skills[0],
            expertiseType: {
              ...this._resume.skills[0].expertiseType,
              name: "Web",
            },
            skill: {
              ...this._resume.skills[0].skill,
              name: "REST, SOAP",
            },
          },

          {
            ...this._resume.skills[0],
            expertiseType: {
              ...this._resume.skills[0].expertiseType,
              name: "Tools",
            },
            skill: {
              ...this._resume.skills[0].skill,
              name: "GIT, SVN",
            },
          },
          {
            ...this._resume.skills[0],
            expertiseType: {
              ...this._resume.skills[0].expertiseType,
              name: "Tools",
            },
            skill: {
              ...this._resume.skills[0].skill,
              name: "Github, Bitbucket, GitLab",
            },
          },
          {
            ...this._resume.skills[0],
            expertiseType: {
              ...this._resume.skills[0].expertiseType,
              name: "Tools",
            },
            skill: {
              ...this._resume.skills[0].skill,
              name: "JIRA, Confluence",
            },
          },
          {
            ...this._resume.skills[0],
            expertiseType: {
              ...this._resume.skills[0].expertiseType,
              name: "Tools",
            },
            skill: {
              ...this._resume.skills[0].skill,
              name: "Jenkins, TeamCity",
            },
          },
          {
            ...this._resume.skills[0],
            expertiseType: {
              ...this._resume.skills[0].expertiseType,
              name: "Tools",
            },
            skill: {
              ...this._resume.skills[0].skill,
              name: "Docker",
            },
          },
          {
            ...this._resume.skills[0],
            expertiseType: {
              ...this._resume.skills[0].expertiseType,
              name: "Tools",
            },
            skill: {
              ...this._resume.skills[0].skill,
              name: "Eclipse, Visual Studio, Visual Studio Code",
            },
          },

          {
            ...this._resume.skills[0],
            expertiseType: {
              ...this._resume.skills[0].expertiseType,
              name: "Methodologies & Business Skills",
            },
            skill: {
              ...this._resume.skills[0].skill,
              name: "Agile/Scrum",
            },
          },
          {
            ...this._resume.skills[0],
            expertiseType: {
              ...this._resume.skills[0].expertiseType,
              name: "Methodologies & Business Skills",
            },
            skill: {
              ...this._resume.skills[0].skill,
              name: "Test Driven Development (TDD)",
            },
          },
          {
            ...this._resume.skills[0],
            expertiseType: {
              ...this._resume.skills[0].expertiseType,
              name: "Methodologies & Business Skills",
            },
            skill: {
              ...this._resume.skills[0].skill,
              name: "Requirements Gathering & Analysis",
            },
          },
          {
            ...this._resume.skills[0],
            expertiseType: {
              ...this._resume.skills[0].expertiseType,
              name: "Methodologies & Business Skills",
            },
            skill: {
              ...this._resume.skills[0].skill,
              name: "Advanced Troubleshooting",
            },
          },
          {
            ...this._resume.skills[0],
            expertiseType: {
              ...this._resume.skills[0].expertiseType,
              name: "Methodologies & Business Skills",
            },
            skill: {
              ...this._resume.skills[0].skill,
              name: "Leadership",
            },
          },
        ],

        educations: [
          {
            ...this._resume.educations[0],
            education: {
              ...this._resume.educations[0].education,
              name:
                "MBA - 2011-2012 - Oriented Software Engineering for SOA Services (Wyden University, São Paulo – Brazil)",
            },
            educationType: {
              ...this._resume.educations[0].educationType,
              name: "Degree",
            },
          },
          {
            ...this._resume.educations[0],
            education: {
              ...this._resume.educations[0].education,
              name:
                "B.Sc. / Graduation - 2005-2008 - System Analysis (PUCC University, São Paulo – Brazil)",
            },
            educationType: {
              ...this._resume.educations[0].educationType,
              name: "Degree",
            },
          },
          {
            ...this._resume.educations[0],
            education: {
              ...this._resume.educations[0].education,
              name: "Programming in HTML5 with JavaScript and CSS3 (70-480)",
            },
            educationType: {
              ...this._resume.educations[0].educationType,
              name: "Certification/Course",
            },
          },
          {
            ...this._resume.educations[0],
            education: {
              ...this._resume.educations[0].education,
              name: "Advanced Distributed Systems Design, Udi Dahan",
            },
            educationType: {
              ...this._resume.educations[0].educationType,
              name: "Certification/Course",
            },
          },
        ],

        projects: [
          {
            ...this._resume.projects[0],
            jobTitleObj: {
              ...this._resume.projects[0].jobTitleObj,
              name: "Software Developer",
            },
            company: {
              ...this._resume.projects[0].company,
              name: "Online Business Systems / The Restall Foundation",
            },
            startDate: this._resume.projects[0].startDate,
            endDate: this._resume.projects[0].endDate,
            projectDescription:
              "To develop a platform and a website for an initiative where users subscribe to receive the names of 2 different Canada’s Fallen Soldiers every weekday, Johnathan architected, designed, and built a full-stack serverless application with React and AWS Amplify, running entirely in the cloud.",
            responsibilities: [
              {
                ...this._resume.projects[0].responsibilities[0],
                responsibility:
                  "Architected, designed, developed and led a full-stack serverless React application running entirely in AWS.",
              },
              {
                ...this._resume.projects[0].responsibilities[0],
                responsibility:
                  "Use of React Hooks and various AWS services: AWS Amplify, AWS Cognito, GraphQL APIs with AWS Appsync, DynamoDB database, AWS Lambda, AWS ElasticSearch, Amazon SES (Simple Email Service), and AWS S3 Storage.",
              },
            ],
          },
          {
            ...this._resume.projects[0],
            jobTitleObj: {
              ...this._resume.projects[0].jobTitleObj,
              name: "Software Developer",
            },
            company: {
              ...this._resume.projects[0].company,
              name: "Online Business Systems / iQmetrix",
            },
            startDate: this._resume.projects[0].startDate,
            endDate: this._resume.projects[0].endDate,
            projectDescription:
              "To provide their customers with secure methods for processing credit card transactions, Johnathan worked on different development tasks to resolve complex problems and implement new features. Other than that, he helped iQmetrix team to go through a certification process for Ingenico Telium RBA and UIA family of devices.",
            responsibilities: [
              {
                ...this._resume.projects[0].responsibilities[0],
                responsibility:
                  "Worked on different complex backlog items and resolving them with properly design and solution.",
              },
              {
                ...this._resume.projects[0].responsibilities[0],
                responsibility:
                  "Mentored an iQmetrix’s new coop with technical directions by being forward-thinking and looking for a solution.",
              },
              {
                ...this._resume.projects[0].responsibilities[0],
                responsibility:
                  "Implemented Pin Bypass solution in a C# driver code that was bought by iQmetrix and did not have this feature built in.",
              },
              {
                ...this._resume.projects[0].responsibilities[0],
                responsibility:
                  "Helped with massive regression tests in the platform as iQmetrix Payments Team did not have QAs anymore, it was assigned for developers to do the job.",
              },
            ],
          },
          {
            ...this._resume.projects[0],
            jobTitleObj: {
              ...this._resume.projects[0].jobTitleObj,
              name: "Software Developer",
            },
            company: {
              ...this._resume.projects[0].company,
              name: "Online Business Systems / Wawanesa Insurance",
            },
            startDate: this._resume.projects[0].startDate,
            endDate: this._resume.projects[0].endDate,
            projectDescription:
              "To provide a better performance and usability of a web application which is used by Brokers.",
            responsibilities: [
              {
                ...this._resume.projects[0].responsibilities[0],
                responsibility:
                  "Worked on different complex backlog items and resolving them with properly design and solution.",
              },
              {
                ...this._resume.projects[0].responsibilities[0],
                responsibility:
                  "Maintained different environments keeping them up and running in a team weekly rotation work.",
              },
              {
                ...this._resume.projects[0].responsibilities[0],
                responsibility:
                  "Improved a web application developed in AngularJS to have a better performance by reducing the number of memory leaks.",
              },
            ],
          },
          {
            ...this._resume.projects[0],
            jobTitleObj: {
              ...this._resume.projects[0].jobTitleObj,
              name: "Software Developer",
            },
            company: {
              ...this._resume.projects[0].company,
              name: "Sanmina/42Q, São Paulo – Brazil",
            },
            startDate: this._resume.projects[0].startDate,
            endDate: this._resume.projects[0].endDate,
            projectDescription: `Sanmina is one of the biggest manufacture company in the world. Recognized as a technology leader, Sanmina provides end-to-end design and solution to Original Equipment Manufacturers.`,
            responsibilities: [
              {
                ...this._resume.projects[0].responsibilities[0],
                responsibility:
                  "Worked with distributed software applications to test/automate/integrate equipment hardware through communication to MES (Manufacturing Execution Systems) for customers in many countries, like Canada, Ireland, China, United States, India, and Germany.",
              },
              {
                ...this._resume.projects[0].responsibilities[0],
                responsibility:
                  "Provided the best solutions for Manufacturing Enterprise System through business analysis, architecture, design, processes, project management, development, and testing.",
              },
              {
                ...this._resume.projects[0].responsibilities[0],
                responsibility:
                  "Since August 2016 - worked with technologies such as Java SE / EE, Docker, Rest API (Jersey), Liferay, JavaScript, Ext JS, AngularJS / Angular 2 / Angular 6, HTML5, CSS3, Bootstrap, PostgreSQL 9.2, Shell Script and AWS Lambda. Focused on Object-Oriented development, UML, Documentation, Design Patterns, and Java best practices.",
              },
              {
                ...this._resume.projects[0].responsibilities[0],
                responsibility:
                  "From October 2010 to August 2016 - worked with technologies such as Microsoft .Net Framework 2.0/3.5/4.0/4.5 (C#), ASP.NET MVC 3, HTML5, CSS3, JavaScript, Ext JS, AngularJS, SQL Server, and PostgreSQL 9.2.",
              },
            ],
          },
          {
            ...this._resume.projects[0],
            jobTitleObj: {
              ...this._resume.projects[0].jobTitleObj,
              name: "Software Developer",
            },
            company: {
              ...this._resume.projects[0].company,
              name: "Unimed Campinas, São Paulo – Brazil",
            },
            startDate: this._resume.projects[0].startDate,
            endDate: this._resume.projects[0].endDate,
            projectDescription:
              "Unimed is the biggest healthcare insurance company from the countryside of the state of São Paulo.",
            responsibilities: [
              {
                ...this._resume.projects[0].responsibilities[0],
                responsibility:
                  "Worked on electronic health record (EHR) application which contained the patient’s medical history, diagnosis, medications, treatment plans, etc.",
              },
              {
                ...this._resume.projects[0].responsibilities[0],
                responsibility:
                  "Took care of different internal applications used by employees, secretaries, doctors, etc.",
              },
              {
                ...this._resume.projects[0].responsibilities[0],
                responsibility:
                  "Worked with technologies such as C#, VB.Net, PL/SQL and PL/SQL Web Toolkit (Oracle).",
              },
            ],
          },
          {
            ...this._resume.projects[0],
            jobTitleObj: {
              ...this._resume.projects[0].jobTitleObj,
              name: "Software Developer",
            },
            company: {
              ...this._resume.projects[0].company,
              name: "Modo Comunicação Direta Ltda, São Paulo - Brazil",
            },
            startDate: this._resume.projects[0].startDate,
            endDate: this._resume.projects[0].endDate,
            projectDescription:
              "Modo is a big ad agency providing services for big customers in Brazil.",
            responsibilities: [
              {
                ...this._resume.projects[0].responsibilities[0],
                responsibility:
                  "Worked with different applications in different segments for big customers like Terra da Gente Magazine and Dow Agrosciences.",
              },
              {
                ...this._resume.projects[0].responsibilities[0],
                responsibility:
                  "Worked with technologies such as Fireworks, Flash, Dreamweaver, CorelDraw X3, Photoshop CS2, C#, VB.Net, Delphi 6.0, Java, JSP, JSF, PHP, SQL Server, and MySQL.",
              },
            ],
          },
        ],
      };
    } else {
      return this._resume;
    }
    */
  }

  protected set resume(value: Resume) {
    this._resume = value;
  }

  protected wordmarkLogo ="assets/img/logo/online-business-systems-logo-wordmark-sm.png";
  protected taglineLogo ="assets/img/logo/online-tagline-sm.png";

  constructor(
    protected http: HttpClient,
    protected getSkillSummariesPipe: GetSkillSummariesPipe,
    protected filterSkillExpertiseUniquePipe: FilterSkillExpertiseUniquePipe,
    // protected filterResumeExpertiseGroupUniquePipe: FilterResumeExpertiseGroupUniquePipe,
    // protected filterResumeExpertiseGroupByExpertisePipe: FilterResumeExpertiseGroupByExpertisePipe,
    protected filterEducationByTypePipe: FilterEducationByTypePipe,
    protected getProjectIndustriesUniquePipe: GetProjectIndustriesUniquePipe,
    protected getAssociationsPipe: GetAssociationsPipe,
    protected filterSkillByExpertisePipe: FilterSkillByExpertisePipe,
    protected getProjectsPipe: GetProjectsPipe
  ) {}

  protected getAllImages(): Promise<any> {
    return new Promise(async (resolve) => {
      forkJoin([
        this.readFileAsDataURL(
          await this.getTaglineLogoBase64(this.taglineLogo)
        ),
        this.readFileAsDataURL(
          await this.getTaglineLogoBase64(this.wordmarkLogo)
        ),
      ]).subscribe(([taglineLogoBase64, wordmarkLogoBase64]) => {
        resolve({ taglineLogoBase64, wordmarkLogoBase64 });
      });
    });
  }

  protected getTaglineLogoBase64(imageUrl): Promise<any> {
    return new Promise((resolve) => {
      this.http.get(imageUrl, { responseType: "blob" }).subscribe((res) => {
        resolve(res);
      });
    });
  }

  protected readFileAsDataURL = (res) => {
    const fileReader = new FileReader();

    return new Promise((resolve, reject) => {
      fileReader.onerror = () => {
        fileReader.abort();
        reject(new DOMException("Problem parsing file."));
      };

      fileReader.onloadend = () => {
        resolve(fileReader.result);
      };

      fileReader.readAsDataURL(res);
    });
  };

  protected getFileName(extension: string): string {
    const spin =
      this.resume.spins?.name.toLowerCase() === "master"
        ? ""
        : this.resume.spins?.name
            .toLowerCase()
            .replace(/\s/g, "_");

    return `${(
      this.resume.user?.firstName.charAt(0) + this.resume.user?.lastName
    ).toLowerCase()}${spin ? "_" + spin : ""}.${extension}`;
  }

  protected toMultipleLines(text: string): string[] {
    return text.split(/[\r\n]+/);
  }

  protected getProfileSummary(): string {
    return this.resume.profileSummaries[0]
      ? this.resume.profileSummaries[0].summary
      : "";
  }

  protected getProfileSummaryOriginalResume(originalResume: Resume) {
    return originalResume.profileSummaries[0]
      ? originalResume.profileSummaries[0].summary
      : "";
  }

  protected getName(): string {
    return (
      `${this.resume.user?.firstName} ${this.resume.user?.lastName}`
    );
  }

  protected getPronouns(): string {
      var pronouns;
      isNullOrUndefined(this.resume.user.preferredPronouns)
          ? (pronouns = "")
          : (pronouns = "(" + this.resume.user.preferredPronouns + ")");
      return pronouns;
  }

  protected getAccreditation(): string {
    return this.resume.accreditation;
  }

  protected getSkillSummaries(): SkillSummary[] {
    return this.getSkillSummariesPipe.transform(this.resume.skillSummaries);
  }

  protected getSkillSummariesOriginalResume(
    originalResume: Resume
  ): SkillSummary[] {
    return this.getSkillSummariesPipe.transform(originalResume.skillSummaries);
  }

  protected getUniqueExpertiseTypes() {
    return this.filterSkillExpertiseUniquePipe.transform(this.resume.skills);
  }

  getUniqueExpertiseTypesOriginalResume(originalResume: Resume) {
    return this.filterSkillExpertiseUniquePipe.transform(originalResume.skills);
  }

  /**
   * Retrieves an array of unique expertise types from the resume expertise groups.
   * @returns An array of unique expertise type names.
   */
  protected getResumeExpertiseGroupsUniqueExpertiseTypes(): string[] {
    return this.resume.resumeExpertiseGroups?.map((group: any) => group.expertiseType?.map((type: any) => type?.name)?.sort((a: any, b: any) => a.SortExpertiseGroup - b.SortExpertiseGroup)) ?? [];
  }

    /**
   * Retrieves an array of unique expertise types from the originalResume expertise groups.
   * @returns An array of unique expertise type names.
   */
  protected getResumeExpertiseGroupsUniqueExpertiseTypesOriginalResume(originalResume: Resume): string[] {
    return originalResume.resumeExpertiseGroups?.map((group: any) => group.expertiseType?.map((type: any) => type?.name)?.sort((a: any, b: any) => a.SortExpertiseGroup - b.SortExpertiseGroup)) ?? [];
  }

  protected getProjectResponsibilitiesIncludedStatement(): string {
    return (
      "\n" +
      `${this.resume.user.firstName}${
        this.resume.user.firstName
          .slice(this.resume.user.firstName.length - 1)
          .toLowerCase() === "s"
          ? `'`
          : "'s"
      } responsibilities:`
    );
  }

  protected getEducationsByType(type: string): EducationModel[] {
    return this.filterEducationByTypePipe.transform(
      this.resume.educations,
      type
    );
  }

  protected getEducationsByTypeOrigResume(
    type: string,
    originalResume: Resume
  ): EducationModel[] {
    return this.filterEducationByTypePipe.transform(
      originalResume.educations,
      type
    );
  }

  protected getIndustryExperience(): string[] {
    return this.getProjectIndustriesUniquePipe.transform(this.resume.projects);
  }

  protected getIndustryExperienceOrigResume(originalResume: Resume): string[] {
    return this.getProjectIndustriesUniquePipe.transform(
      originalResume.projects
    );
  }

  protected getProjectExperienceDateInterval(
    startDate: Date,
    endDate: Date
  ): string {
    return `${formatDate(startDate, "MM/yyyy", "en-CA")} - ${
      endDate ? formatDate(endDate, "MM/yyyy", "en-CA") : "Present"
    }`;
  }

  protected getAssociations(): RAssociation[] {
    return this.getAssociationsPipe.transform(this.resume.associations);
  }

  protected getOriginalResumeAssociations(
    originalResume: Resume
  ): RAssociation[] {
    return this.getAssociationsPipe.transform(originalResume.associations);
  }

  protected getSkillsByExpertiseType(expertise: string) {
    return this.filterSkillByExpertisePipe.transform(
      this.resume.skills,
      expertise
    );
  }

  protected getProjects(): RProjectGroup[] {
    return this.getProjectsPipe.transform(this.resume.projects);
  }

  protected getProjectsOriginalResume(originalResume: Resume) {
    return this.getProjectsPipe.transform(originalResume.projects);
  }

  protected getHeaderContent(): string {
    return `${this.getName() + " " + this.getPronouns()} - Online Business Systems`;
  }
}
