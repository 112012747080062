import { AlternateOnlinerMaintenanceRoutingModule } from './alternateOnlinerMaintenance-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconModule } from '@visurel/iconify-angular';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { MatButtonModule } from '@angular/material/button';
import { PageLayoutModule } from 'src/@vex/components/page-layout/page-layout.module';
import { ContainerModule } from 'src/@vex/directives/container/container.module';
import { TranslateModule } from '@ngx-translate/core';
import {MatInputModule} from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { AlternateOnlinerMaintenanceComponent } from './alternateOnlinerMaintenance.component';
import { AlternateOnlinerMaintenanceCardComponent } from './alternateOnlinerMaintenance-card/alternateOnlinerMaintenance-card.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ResumesSearchInputModule } from '../searchResumes/search-input/search-input-module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTableModule } from '@angular/material/table';

@NgModule({
  declarations: [AlternateOnlinerMaintenanceCardComponent, AlternateOnlinerMaintenanceComponent],
  imports: [
    FormsModule,
    CommonModule,
    FlexLayoutModule,
    MatDialogModule,
    MatIconModule,
    MatExpansionModule,
    MatInputModule,
    IconModule,
    SecondaryToolbarModule,
    BreadcrumbsModule,
    MatButtonModule,
    PageLayoutModule,
    ContainerModule,
    TranslateModule,    
    MatProgressSpinnerModule,
    AlternateOnlinerMaintenanceRoutingModule,
    ReactiveFormsModule,
    ResumesSearchInputModule,
    MatAutocompleteModule,
    MatTableModule,
  ],
  exports: [AlternateOnlinerMaintenanceCardComponent, AlternateOnlinerMaintenanceComponent],

})

export class AlternateOnlinerMaintenanceModule { }