import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatIconModule } from "@angular/material/icon";
import { IconModule } from "@visurel/iconify-angular";
import { SecondaryToolbarModule } from "src/@vex/components/secondary-toolbar/secondary-toolbar.module";
import { BreadcrumbsModule } from "src/@vex/components/breadcrumbs/breadcrumbs.module";
import { MatButtonModule } from "@angular/material/button";
import { PageLayoutModule } from "src/@vex/components/page-layout/page-layout.module";
import { ContainerModule } from "src/@vex/directives/container/container.module";
import { TranslateModule } from "@ngx-translate/core";
import { MatInputModule } from "@angular/material/input";
import { MatDialogModule } from "@angular/material/dialog";
import { ResumePdfBuilderService} from "../common/services/resume-pdf-builder.service";
import { ResumeDocxBuilderService} from "../common/services/resume-docx-builder.service";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import {ResumePreviewComponent} from './resume-preview.component';
import { ResumePreviewErrorComponent } from "./error/error.component";
import { ResumePdfPreviewComponent } from "./pdf-preview/pdf-preview.component";
import { ResumePreviewActionButtonsComponent } from "./action-buttons/action-buttons.component";

@NgModule({
  declarations: [
    ResumePreviewComponent,
    ResumePreviewErrorComponent,
    ResumePdfPreviewComponent,
    ResumePreviewActionButtonsComponent
  ],
  imports: [
    MatAutocompleteModule,
    CommonModule,
    FlexLayoutModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    IconModule,
    SecondaryToolbarModule,
    BreadcrumbsModule,
    MatButtonModule,
    PageLayoutModule,
    ContainerModule,
    TranslateModule,    
    MatProgressSpinnerModule,
  ],
  exports: [ResumePreviewComponent, ResumePreviewErrorComponent, ResumePdfPreviewComponent, ResumePreviewActionButtonsComponent],
  providers: [ResumePdfBuilderService, ResumeDocxBuilderService],
})
export class ResumePreviewModule {}

