export class Stack<T> {
    private _store: T[] = [];
    private _count: number;

    constructor() {
        this._count = 0;
    }

    public push(val: T) {
        this._store.push(val);
        this._count++;
    }

    public pop(): T | undefined {
        this._count--;
        return this._store.pop();
    }

    public peek(): T | undefined {
        return this._store[this._count-1];
    }

    public replaceLastValue(newValue: T) {
        this._store[this._count - 1] = newValue;
    }

    public items(): T[] {
        return this._store;
    }

    public count() : number {
        return this._store.length;
    }
}