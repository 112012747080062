import { Injectable } from "@angular/core";
import { Resume } from "src/app/common/data-models/Resume";
import { HttpClient } from "@angular/common/http";
import {
  FilterSkillExpertiseUniquePipe,
  FilterSkillByExpertisePipe,
  FilterEducationByTypePipe,
  GetProjectIndustriesUniquePipe,
  GetAssociationsPipe,
  GetProjectsPipe,
  GetSkillSummariesPipe,
} from "./resume-builder.service";

import {
  AlignmentType,
  Document,
  HeadingLevel,
  Packer,
  Paragraph,
  TabStopPosition,
  TabStopType,
  TextRun,
  Media,
  Table,
  TableCell,
  TableRow,
  WidthType,
  BorderStyle,
  TableLayoutType,
  StyleForCharacter,
  StyleForParagraph,
  Footer,
  Header,
  PageNumber,
  PageNumberFormat,
  PageOrientation,
  PictureRun,
  IStylesOptions,
} from "docx";
import { saveAs } from "file-saver";
import { ResumeBuilderService } from "./resume-builder.service";
import { RProject } from "../data-models/project";
import { SkillSummary } from "../data-models/SkillSummary";
import { RSkill } from "../data-models/RSkill";
import { EducationModel } from "../data-models/EducationModel";
import { RAssociation } from "../data-models/RAssociation";
import { ProjectIndustry } from "../data-models/ProjectIndustry";
import { ResumeStatusTypes } from "./resume.service";

const BORDER_NONE = {
  top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
  bottom: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
  left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
  right: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
  insideVertical: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
  insideHorizontal: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
};

@Injectable()
export class ResumeDocxBuilderService extends ResumeBuilderService {
  private resumeDiff: ResumeDiff;
  private _originalResume: Resume;
  constructor(
    public http: HttpClient,
    public filterSkillExpertiseUniquePipe: FilterSkillExpertiseUniquePipe,
    public filterSkillByExpertisePipe: FilterSkillByExpertisePipe,
    public filterEducationByTypePipe: FilterEducationByTypePipe,
    public getProjectIndustriesUniquePipe: GetProjectIndustriesUniquePipe,
    public getAssociationsPipe: GetAssociationsPipe,
    public getProjectsPipe: GetProjectsPipe,
    public getSkillSummariesPipe: GetSkillSummariesPipe
  ) {
    super(
      http,
      getSkillSummariesPipe,
      filterSkillExpertiseUniquePipe,
      filterEducationByTypePipe,
      getProjectIndustriesUniquePipe,
      getAssociationsPipe,
      filterSkillByExpertisePipe,
      getProjectsPipe
    );
  }

  public async download(resume: Resume, originalResume?: Resume) {
    await this.executeOperation(resume, originalResume, "download");
  }

  private async executeOperation(resume: Resume, originalResume?: Resume, action = "download",) {
    this.resume = resume;
    if (!originalResume) {
      this._originalResume = resume;
    }
    else {
      this._originalResume = originalResume;
    }

    this.resumeDiff = new ResumeDiff(resume, originalResume);

    const documentDefinition = await this.getDocumentDefinition();

    switch (action) {
      case "download":
        Packer.toBlob(documentDefinition).then((blob) => {
          saveAs(blob, this.getFileName("docx"));
        });
      default:
        break;
    }
  }

  private async getDocumentDefinition() {
    return await this.generateDocument();
  }

  private async generateDocument() {
    const images = await this.getAllImages();

    return this.buildDocumentObject(
      images.taglineLogoBase64,
      images.wordmarkLogoBase64
    );
  }

  private buildDocumentObject(
    taglineLogoBase64: string,
    wordmarkLogoBase64: string
  ): Document {
    const document = new Document({
      creator: "Online Business Systems",
      styles: this.createStyles(),
    });

    const wordmarkLogo = Media.addImage(document, wordmarkLogoBase64, 160, 70);

    const taglineLogo = Media.addImage(document, taglineLogoBase64, 190, 12);

    const headerContent = this.getHeaderContent();

    const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

    document.addSection({
      margins: {
        top: 1500,
        right: 630,
        bottom: 1300,
        left: 1550,
      },
      size: {
        orientation: PageOrientation.PORTRAIT,
        width: 12250,
        height: 15850,
      },
      properties: {
        pageNumberStart: 1,
        pageNumberFormatType: PageNumberFormat.DECIMAL,
        titlePage: true,
      },
      headers: {
        first: this.buildFirstHeader(wordmarkLogo),
        default: this.buildDefaultHeader(headerContent),
      },
      footers: {
        default: this.buildFooter(taglineLogo),
        first: this.buildFooter(taglineLogo),
      },
      children: [
        new Paragraph({
          alignment: AlignmentType.RIGHT,
          children: [
            new TextRun({
              text: this.getName(),
              style: "name",
            }),
            new TextRun({
              text: " ",
            }),
            new TextRun({
              text: (!this.resume.useOriginal && (this.resume.statusType.name === ResumeStatusTypes.APPROVED || this.resume.statusType.name === ResumeStatusTypes.PENDING_CM_REVIEW || this.resume.statusType.name === ResumeStatusTypes.PENDING_RMT_REVIEW))
                ? this._originalResume.user.preferredPronouns !== null ? "(" + this._originalResume.user.preferredPronouns + ")" : ""
                : this.getPronouns(),
              style: this.resumeDiff.getPronounDiff() ? "pronoun-green" : "pronoun",
            }),
          ],
          spacing: {
            before: 40,
          },
          keepNext: true,
        }),

        new Paragraph({
          alignment: AlignmentType.RIGHT,
          children: [
            new TextRun({
              text: this.getAccreditation(),
              style: this.resumeDiff.getAccreditationDiff() ? "accreditation-green" : "accreditation",
            }),
          ],
          spacing: {
            before: 0,
          },
          keepNext: true,
        }),

        this.buildProfileAndSkillSummaryTable(),

        this.createHeading1Paragraph(
          (this.resume.isProfessionalResume ? "PROFESSIONAL" : "TECHNICAL") +
          " EXPERTISE"
        ),
        this.resume.resumeExpertiseGroups?.length > 0 ?
            this.buildResumeExpertizeGroupsProfessionalExpertiseTable() :
            this.buildProfessionalExpertiseTable(),

        // this.createBody(""), //don't know why, but without this, the other two sections get together in the same table.
        new Paragraph(""), //it's a known issue: https://github.com/dolanmiu/docx/issues/494

        this.buildEducationAndIndustryExperienceTable(),

        format.test(this.resume.user.userId)
          ? this.createHeading1Paragraph("WORK EXPERIENCE")
          : this.createHeading1Paragraph("PROJECT EXPERIENCE"),

        ...this.buildProjectExperience(),

        ...this.buildAssociations(),
      ],
    });

    return document;
  }

  private createStyles(): IStylesOptions {
    const font = "Trebuchet MS";
    const color = "#000";
    const colorGreen = "#059033";
    return {
      importedStyles: [
        new StyleForCharacter({
          id: "default-character-styles",
          name: "Default Character Styles",
          basedOn: "Normal",
          run: {
            color: color,
            font: font,
          },
        }),
        new StyleForParagraph({
          id: "default-title-styles",
          name: "Default Title Styles",
          basedOn: "Normal",
          run: {
            bold: true,
            font: font,
          },
        }),
      ],
      paragraphStyles: [
        {
          id: "title",
          name: "Title",
          basedOn: "default-title-styles",
          quickFormat: true,
          run: {
            size: 26, //equilavent to 13px
          },
          paragraph: {
            spacing: {
              before: 276,
              after: 185,
            },
            indent: {
              right: 400,
            },
          },
        },
        {
          id: "title-2",
          name: "Title Heading 2",
          basedOn: "title",
          quickFormat: true,
          run: {
            size: 22, //equilavent to 11px
          },
          paragraph: {
            spacing: {
              before: 70,
            },
          },
        },
        {
          id: "title-mb-3",
          name: "Title Heading With Less Margin Bottom",
          basedOn: "title",
          quickFormat: true,
          paragraph: {
            spacing: {
              after: 70,
            },
          },
        },
        {
          id: "title-2-mb-3",
          name: "Title Heading 2 With Less Margin Bottom",
          basedOn: "title-2",
          quickFormat: true,
          paragraph: {
            spacing: {
              after: 70,
            },
          },
        },
        {
          id: "subtitle",
          name: "Subtitle",
          basedOn: "default-title-styles",
          quickFormat: true,
          run: {
            size: 22, //equilavent to 11px
          },
          paragraph: {
            spacing: {
              after: 100,
            },
            indent: {
              right: 400,
            },
          },
        },
        {
          id: "subtitle-green",
          name: "Subtitle Green",
          basedOn: "subtitle",
          run: {
            color: colorGreen
          }
        },
        {
          id: "subtitle-mt-5",
          name: "Subtitle Margin Top 300",
          basedOn: "subtitle",
          quickFormat: true,
          run: {
            size: 22, //equilavent to 11px
          },
          paragraph: {
            spacing: {
              before: 300,
            },
          },
        },
        {
          id: "subtitle-2",
          name: "Subtitle 2",
          basedOn: "subtitle",
          quickFormat: true,
          run: {
            size: 20, //equilavent to 10px
          },
          paragraph: {
            spacing: {
              after: 20,
            },
          },
        },
        {
          id: "subtitle-mt-4-mb-2",
          name: "Subtitle Margin Top 10",
          basedOn: "subtitle",
          quickFormat: true,
          paragraph: {
            spacing: {
              before: 150,
              after: 50,
            },
          },
        },
        {
          id: "subtitle-2-mt-4-mb-2",
          name: "Subtitle Small Margin Top 10",
          basedOn: "subtitle-2",
          quickFormat: true,
          paragraph: {
            spacing: {
              before: 150,
              after: 50,
            },
          },
        },
        {
          id: "subtitle-2-mb-1",
          name: "Subtitle Small Margin Bottom 2",
          basedOn: "subtitle-2",
          quickFormat: true,
          paragraph: {
            spacing: {
              after: 10,
            },
          },
        },
        {
          id: "bullet",
          name: "Bullet Points",
          basedOn: "Normal",
          quickFormat: true,
          run: {
            size: 18, //equilavent to 9px
            color: color,
            font: font,
          },
          paragraph: {
            spacing: {
              after: 35,
            },
            indent: {
              right: 400,
            },
          },
        },
        {
          id: "bullet-green",
          name: "Bullet Points Green",
          basedOn: "bullet",
          run: {
            color: colorGreen,
          }
        },
        {
          id: "bullet-no-margin",
          name: "Bullet Points With no Margin",
          basedOn: "bullet",
          quickFormat: true,
          paragraph: {
            indent: {
              right: 0,
            },
          },
        },
        {
          id: "bullet-no-margin-green",
          name: "Bullet Points With no Margin Green",
          basedOn: "bullet-no-margin",
          run: {
            color: colorGreen,
          }
        },
      ],

      characterStyles: [
        {
          id: "name",
          name: "User Name",
          basedOn: "default-character-styles",
          run: {
            size: 32, //equilavent to 16px
            bold: true,
          },
        },
        {
          id: "pronoun",
          name: "User Pronouns",
          basedOn: "default-character-styles",
          run: {
            size: 32, //equilavent to 16px
            bold: true,
          },
        },
        {
          id: "pronoun-green",
          name: "User Pronouns Green",
          basedOn: "pronoun",
          run: {
            color: colorGreen
          },
        },
        {
          id: "accreditation",
          name: "User Accreditation",
          basedOn: "default-character-styles",
          run: {
            size: 26, //equilavent to 16px
          },
        },
        {
          id: "accreditation-green",
          name: "User Accreditation Green",
          basedOn: "accreditation",
          run: {
            color: colorGreen
          },
        },
        {
          id: "body",
          name: "Body",
          basedOn: "default-character-styles",
          run: {
            size: 18, //equilavent to 9px
          },
        },
        {
          id: "body-green",
          name: "Body Green",
          basedOn: "body",
          run: {
            color: colorGreen
          },
        },
        {
          id: "body-italic",
          name: "Body Italic",
          basedOn: "body",
          run: {
            italics: true,
          },
        },
        {
          id: "body-italic-green",
          name: "Body Italic Green",
          basedOn: "body-italic",
          run: {
            color: colorGreen,
          },
        },
        {
          id: "body-italic-size-20",
          name: "Body Italic Size 20",
          basedOn: "body-italic",
          run: {
            size: 20, //equilavent to 10px
          },
        },
        {
          id: "body-italic-size-20-green",
          name: "Body Italic Size 20 Green",
          basedOn: "body-italic-size-20",
          run: {
            color: colorGreen
          },
        },
        {
          id: "body-size-18",
          name: "Body Size 18",
          basedOn: "body",
          run: {
            size: 18, //equilavent to 9px
          },
        },
        {
          id: "body-size-18-green",
          name: "Body Size 18 Green",
          basedOn: "body-size-18",
          run: {
            color: colorGreen
          },
        },
        {
          id: "page-header",
          name: "Page Header",
          basedOn: "body-italic",
          run: {
            size: 22, //equilavent to 11px
          },
        },
        {
          id: "page-footer",
          name: "Page Footer",
          basedOn: "body-italic",
          run: {
            size: 16, //equilavent to 8px
          },
        },
        {
          id: "text-green",
          name: "Text Green",
          run: {
            color: colorGreen
          }
        },
      ],
    };
  }

  private buildFirstHeader(wordmarkLogo: PictureRun): Header {
    return new Header({
      children: [
        new Paragraph({
          children: [wordmarkLogo],
        }),
      ],
    });
  }

  private buildDefaultHeader(headerContent: string): Header {
    return new Header({
      children: [
        new Paragraph({
          children: [
            new TextRun({
              text: headerContent,
              style: "page-header",
            }),
          ],
          alignment: AlignmentType.RIGHT,
        }),
      ],
    });
  }

  private buildFooter(taglineLogo: PictureRun): Footer {
    return new Footer({
      children: [
        new Paragraph({
          children: [
            new TextRun({
              text: "www.obsglobal.com",
              style: "page-footer",
            }),
            new TextRun("\t"),
            new TextRun({
              children: ["Page ", PageNumber.CURRENT],
              style: "page-footer",
            }),
            new TextRun("\t"),
            taglineLogo,
          ],
          tabStops: [
            {
              type: TabStopType.LEFT,
              position: TabStopPosition.MAX / 2,
            },
            {
              type: TabStopType.CENTER,
              position: TabStopPosition.MAX,
            },
          ],
        }),
      ],
    });
  }

  private buildProfileAndSkillSummaryTable(): Table {
    return new Table({
      alignment: AlignmentType.CENTER,
      borders: BORDER_NONE,
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                this.createHeading1Paragraph("PROFILE"),
                ...this.buildProfileSummary(),
              ],
              width: {
                size: 31.5,
                type: WidthType.PERCENTAGE,
              },
              margins: {
                right: 450,
              },
            }),
            new TableCell({
              children: [
                this.createHeading1Paragraph("SKILL SUMMARY"),
                ...this.buildSkillSummary(),
              ],
              width: {
                size: 68.5,
                type: WidthType.PERCENTAGE,
              },
            }),
          ],
        }),
      ],
      width: {
        size: 100,
        type: WidthType.AUTO,
      },
    });
  }

  private buildProfileSummary(): Paragraph[] {
    const isDiff = this.resumeDiff.getProfileSummariesDiff();
    const profileSummary = this.getProfileSummary();
    return this.toMultipleLines(profileSummary).map((line) =>
      this.createBodyItalicParagraph(line, isDiff)
    );
  }

  private buildSkillSummary(): Paragraph[] {
    const skillSummariesArr = this.resumeDiff.getSkillSummariesDiff();
    return this.getSkillSummaries()
      .map(skillSummary => {
        const skillSummaryDiff = skillSummariesArr.find(skillSummaryDiff => skillSummaryDiff.skillSummaryId === skillSummary.id);
        const isDiff = skillSummaryDiff && skillSummaryDiff.isDiff;
        let arr: Paragraph[] = [];
        arr.push(
          this.createSubHeadingParagraph(
            skillSummary.name,
            true,
            false,
            isDiff
          )
        );

        arr = [
          ...arr,
          ...this.toMultipleLines(skillSummary.summary).map(
            (line) => {
              return this.createBodyParagraph(line, isDiff);
            }
          ),
        ];

        return arr;
      })
      .reduce((prev, curr) => prev.concat(curr), []);
  }

  private buildProfessionalExpertiseTable(): Table {
    const uniqueExpertiseTypes = this.getUniqueExpertiseTypes();
    const skillsArr = this.resumeDiff.getSkillsDiff();
    return new Table({
      borders: BORDER_NONE,
      alignment: AlignmentType.CENTER,
      rows: [
        new TableRow({
          children:
            uniqueExpertiseTypes.length == 0
              ? [new TableCell({ children: [] })]
              : [
                ...uniqueExpertiseTypes
                  .map((expertise, index) => {
                    const arr: TableCell[] = [];

                    arr.push(
                      new TableCell({
                        children: [
                          this.createSubHeadingParagraph(
                            expertise,
                            true
                          ),
                          ...this.getSkillsByExpertiseType(expertise).map(
                            (skill) => {
                              const skillDiff = skillsArr.find(skillDiff => skillDiff.skillId === skill.id);
                              const isDiff = skillDiff && skillDiff.isDiff;
                              return this.createBulletParagraph(
                                skill.skill.name,
                                index === uniqueExpertiseTypes.length - 1
                                  ? isDiff ? "bullet-no-margin-green" : "bullet-no-margin"
                                  : isDiff ? "bullet-green" : "bullet"
                              )
                            }),
                        ],

                        width: {
                          size: 25,
                          type: WidthType.PERCENTAGE,
                        },
                      })
                    );

                    return arr;
                  })
                  .reduce((prev, curr) => prev.concat(curr), []),
              ],
        }),
      ],
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      layout: TableLayoutType.FIXED,
    });
  }

  private buildResumeExpertizeGroupsProfessionalExpertiseTable(): Table {
    const uniqueExpertiseTypes = this.getUniqueExpertiseTypes();
    const skillsArr = this.resumeDiff.getSkillsDiff();
    this.resume.resumeExpertiseGroups.sort((a, b) => a.expertiseSortOrder - b.expertiseSortOrder);
    var uniqueREGExpertiseTypes = [...new Set(this.resume.resumeExpertiseGroups
                                    .map(expertiseGroup => expertiseGroup.expertiseType.id))];
    return new Table({
      borders: BORDER_NONE,
      alignment: AlignmentType.CENTER,
      rows: [
        new TableRow({
          children:
          uniqueREGExpertiseTypes.length == 0
              ? [new TableCell({ children: [] })]
              : [
                ...uniqueREGExpertiseTypes
                  .map((expertiseId, index) => {
                    const arr: TableCell[] = [];
                    let resumeExpertiseGroups = this.resume.resumeExpertiseGroups.filter((reg) => reg.expertiseType?.id === expertiseId)
                          .sort((a, b) => a.sortOrder - b.sortOrder);
                    arr.push(
                      new TableCell({
                        children: [
                          this.createSubHeadingParagraph(
                            resumeExpertiseGroups[0]?.expertiseType?.name,
                            true
                          ),
                          ...resumeExpertiseGroups.map(
                            (item) => {
                              const skillDiff = skillsArr.find(skillDiff => skillDiff.skillId === item.skill.id);
                              const isDiff = skillDiff && skillDiff.isDiff;
                              return this.createBulletParagraph(
                                item.skill.skill.name,
                                index === uniqueExpertiseTypes.length - 1
                                  ? isDiff ? "bullet-no-margin-green" : "bullet-no-margin"
                                  : isDiff ? "bullet-green" : "bullet"
                              )
                            }),
                        ],

                        width: {
                          size: 25,
                          type: WidthType.PERCENTAGE,
                        },
                      })
                    );

                    return arr;
                  })
                  .reduce((prev, curr) => prev.concat(curr), []),
              ],
        }),
      ],
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      layout: TableLayoutType.FIXED,
    });
  }

  private buildEducationAndIndustryExperienceTable(): Table {
    return new Table({
      borders: BORDER_NONE,
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                this.createHeadingMB3Paragraph("EDUCATION"),
                ...this.buildEducation(),
              ],
              width: {
                size: 50,
                type: WidthType.PERCENTAGE,
              },
            }),
            new TableCell({
              children: [
                this.createHeadingMB3Paragraph("INDUSTRY EXPERIENCE"),
                ...this.buildIndustryExperience(),
              ],
              width: {
                size: 50,
                type: WidthType.PERCENTAGE,
              },
            }),
          ],
        }),
      ],

      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      layout: TableLayoutType.FIXED,
    });
  }

  private buildEducation(): Paragraph[] {
    const educationsArr = this.resumeDiff.getEducationsDiff();
    let arr: Paragraph[] = [];
    arr = [
      ...this.getEducationsByType("Degree").map((education) => {
        const educationDiff = educationsArr.find(educationDiff => educationDiff.educationId === education.id);
        return this.createBulletParagraph(
          education.education.name +
          (education.institutionCity === null ||
            education.institutionCity === ""
            ? ""
            : " - " + education.institutionCity),
          educationDiff && educationDiff.isDiff ? "bullet-green" : "bullet",
        )
      }),
    ];

    const relevantCourses = this.getEducationsByType("Certification/Course");
    if (relevantCourses.length > 0) {
      arr = [
        ...arr,
        this.createSubtitleMT4MB2Paragraph("Relevant Courses/Certifications"),
        ...relevantCourses.map((education) => {
          const educationDiff = educationsArr.find(educationDiff => educationDiff.educationId === education.id);
          return this.createBulletParagraph(
            education.education.name,
            educationDiff && educationDiff.isDiff ? "bullet-green" : "bullet",
          )
        }),
      ];
    }

    return arr;
  }

  private buildIndustryExperience(): Paragraph[] {
    const industriesArr = this.resumeDiff.getIndustriesDiff();
    return this.getIndustryExperience().map((industry) => {
      const industryDiff = industriesArr.find(industryDiff => industryDiff.industryName === industry)
      return this.createBulletParagraph(industry, industryDiff && industryDiff.isDiff ? "bullet-green" : "bullet")
    });
  }

  private buildProjectExperience(): Paragraph[] {
    const projectsDiffArr = this.resumeDiff.getProjectsDiff();
    return this.getProjects()
      .map((projectGroup) => {
        let arr: Paragraph[] = [];
        const isGroupedProject = projectGroup.projects.length > 1;
        const isIndented = isGroupedProject && !this.resume.isClientHidden;

        if (isGroupedProject && !this.resume.isClientHidden) {
          arr.push(
            this.createSubHeadingParagraph(
              projectGroup.company.name.trim(),
              true
            )
          );
          arr.push(
            this.createBodyMB10Paragraph(
              this.getProjectExperienceDateInterval(
                projectGroup.startDate,
                projectGroup.endDate
              )
            )
          );
          arr.push(this.createSubHeadingParagraph("", false));
        }

        projectGroup.projects.forEach((project) => {
          const projectDiff = projectsDiffArr.find(projectDiff => projectDiff.projectId === project.id);
          const isJobTitleDiff = projectDiff && projectDiff.isJobTitleDiff;
          const isDatesChanged = projectDiff && projectDiff.isDatesChanged;
          const isCompanyChanged = projectDiff && projectDiff.isCompanyChanged;
          const isDescriptionChanged = projectDiff && projectDiff.isDescriptionChanged;
          const differentResponsibilities = projectDiff && projectDiff.differentResponsibilities;
          let projectName = (project.projectName === null) ? '' : project.projectName + ' - ';
          let titles = project.jobTitles
            ?.map((job) => job.jobTitleObj.name)
            .join(", ") || project.jobTitleObj.name;

          arr.push(
            this.createSubHeadingParagraph(
              projectName +
              titles
              ,
              true,
              isIndented,
              isJobTitleDiff
            )
          );

          if (!this.resume.isClientHidden && !isGroupedProject) {
            arr.push(
              this.createSubHeadingParagraph(
                projectGroup.company.name.trim(),
                true,
                false,
                isCompanyChanged
              )
            );
          }

          arr.push(
            this.createBodyMB10Paragraph(
              this.getProjectExperienceDateInterval(
                project.startDate,
                project.endDate
              ),
              isIndented,
              isDatesChanged
            )
          );

          arr = [
            ...arr,
            ...this.toMultipleLines(
              project.projectDescription
            ).map((line) =>
              this.createBodyItalicSize20Paragraph(line, isIndented, isDescriptionChanged)
            ),
          ];

          if (project.responsibilities.length > 0) {
            arr.push(
              this.createBodySize18Paragraph(
                this.getProjectResponsibilitiesIncludedStatement(),
                isIndented
              )
            );

            for (let i = 0; i < project.responsibilities.length; i++) {
              const isDiff = differentResponsibilities && differentResponsibilities.includes(project.responsibilities[i].id);
              arr.push(
                this.createBulletParagraph(
                  project.responsibilities[i].responsibility,
                  isDiff ? "bullet-green" : "bullet",
                  isIndented
                )
              );
            }
          }
          arr.push(this.createSubHeadingParagraph("", false));
        });

        return arr;
      })
      .reduce((prev, curr) => prev.concat(curr), []);
  }

  private buildAssociations(): Paragraph[] {
    const associationsDiffArr = this.resumeDiff.getAssociationsDiff();
    let arr: Paragraph[] = [];
    if (this.resume.associations.length > 0) {
      arr.push(this.createHeading1Paragraph("ASSOCIATIONS"));

      arr = [
        ...arr,
        ...this.getAssociations()
          .map((association) => {
            const associationDiff = associationsDiffArr.find(associationDiff => associationDiff.associationId === association.id);
            let arr: Paragraph[] = [];

            arr.push(
              this.createSubHeadingParagraph(
                association.assoc.name,
                true,
                false,
                associationDiff && associationDiff.isDiff
              )
            );

            return arr;
          })
          .reduce((prev, curr) => prev.concat(curr), []),
      ];
    }
    return arr;
  }

  private createHeading1Paragraph(text: string): Paragraph {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_1,
      style: "title",
      keepLines: true,
      keepNext: true,
    });
  }

  private createHeading1ParagraphWithPageBreak(text: string): Paragraph {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_1,
      style: "title",
      pageBreakBefore: true,
      // paragraph: {
      spacing: {
        before: 0,
        after: 185,
      },
      // indent: {
      //   right: 400,
      // },
      // },
    });
  }

  private createHeading2Paragraph(text: string): Paragraph {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_2,
      style: "title-2",
    });
  }

  private createHeading2MB3Paragraph(text: string): Paragraph {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_2,
      style: "title-2-mb-3",
    });
  }

  private createHeadingMB3Paragraph(text: string): Paragraph {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_2,
      style: "title-mb-3",
    });
  }

  private createSubHeadingParagraph(
    text: string,
    keepNext: boolean,
    isIndented?: boolean,
    isDiff?: boolean
  ): Paragraph {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_3,
      style: isDiff ? "subtitle-green" : "subtitle",
      keepLines: keepNext,
      keepNext: keepNext,
      indent: { left: isIndented ? 400 : 0 },
    });
  }

  private createSubHeadingMT5Paragraph(text: string): Paragraph {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_3,
      style: "subtitle-mt-5",
    });
  }

  private createSubHeading2Paragraph(text: string): Paragraph {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_4,
      style: "subtitle-2",
    });
  }

  private createSubHeading2MB1Paragraph(text: string): Paragraph {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_4,
      style: "subtitle-2-mb-1",
      keepLines: true,
      keepNext: true,
    });
  }

  private createSubtitle2MT4MB2Paragraph(text: string): Paragraph {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_4,
      style: "subtitle-2-mt-4-mb-2",
    });
  }

  private createSubtitleMT4MB2Paragraph(text: string): Paragraph {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_4,
      style: "subtitle-mt-4-mb-2",
    });
  }

  private createBodyParagraph(text: string, isDiff: boolean): Paragraph {
    return new Paragraph({
      children: [
        new TextRun({
          text: text,
          style: isDiff ? "body-green" : "body",
        }),
      ],
      spacing: {
        after: 250,
      },
    });
  }

  private createBodyMB10Paragraph(
    text: string,
    isIndented?: boolean,
    isDiff?: boolean
  ): Paragraph {
    const color = "#000";
    const colorDiff = "#059033";
    return new Paragraph({
      keepLines: true,
      keepNext: true,
      style: "text-green",
      children: [
        new TextRun({
          text: text,
          color: isDiff ? colorDiff : color,
          style: "body",
        }),
      ],
      spacing: {
        after: 100,
      },
      indent: { start: isIndented ? 400 : 0 },
    });
  }

  private createBodySize18Paragraph(
    text: string,
    isIndented?: boolean,
    isDiff?: boolean
  ): Paragraph {
    return new Paragraph({
      keepLines: true,
      keepNext: true,
      children: [
        new TextRun({
          text: text,
          style: isDiff ? "body-size-18-green" : "body-size-18",
        }),
      ],
      spacing: {
        after: 100,
      },
      indent: {
        left: isIndented ? 400 : 0,
      },
    });
  }

  private createBodyItalicParagraph(text: string, isDiff?: boolean): Paragraph {
    return new Paragraph({
      children: [
        new TextRun({
          text: text,
          style: isDiff ? "body-italic-green" : "body-italic",
        }),
      ],
    });
  }

  private createBodyItalicSize20Paragraph(
    text: string,
    isIndented?: boolean,
    isDiff?: boolean
  ): Paragraph {
    return new Paragraph({
      keepLines: true,
      keepNext: true,
      children: [
        new TextRun({
          text: text,
          style: isDiff ? "body-italic-size-20-green" : "body-italic-size-20",
        }),
      ],
      spacing: {
        after: 250,
      },
      indent: {
        left: isIndented ? 400 : 0,
      },
    });
  }

  private createBulletParagraph(
    text: string,
    style: string = "bullet",
    isIndented?: boolean,
  ): Paragraph {
    return new Paragraph({
      keepLines: true,
      keepNext: true,
      text: text.trim(),
      style: style,
      bullet: {
        level: 0,
      },
      indent: {
        firstLine: 10,
        start: isIndented ? 420 : 10,
        left: isIndented ? 420 : 0,
      },
      leftTabStop: isIndented ? 420 : 180,
    });
  }
}

class ResumeDiff {
  private resume: Resume;
  private resumeSnapShot: Resume;

  constructor(resume: Resume, resumeSnapShot: Resume) {
    this.resume = resume;
    this.resumeSnapShot = resumeSnapShot;
  }

  public getAccreditationDiff(): boolean {
    return this.resumeSnapShot && this.resumeSnapShot.accreditation !== this.resume.accreditation;
  }

  public getPronounDiff(): boolean {
    return this.resumeSnapShot && this.resumeSnapShot.user.preferredPronouns !== this.resume.user.preferredPronouns;
  }

  public getProfileSummariesDiff(): boolean {
    return this.resumeSnapShot && !JSON.stringify(this.resumeSnapShot.profileSummaries).includes(JSON.stringify(this.resume.profileSummaries[0]));
  }

  public getSkillSummariesDiff(): any[] {
    return this.resumeSnapShot
      ? this.resume.skillSummaries.map((skillSummary: SkillSummary) => {
        return {
          isDiff: !JSON.stringify(this.resumeSnapShot.skillSummaries).includes(JSON.stringify(skillSummary)),
          skillSummaryId: skillSummary.id
        }
        
      })
      : [{
        isDiff: false,
        skillSummaryId: 0
      }]
  }

  public getSkillsDiff(): any[] {
    return this.resumeSnapShot
      ? this.resume.skills.map((skill: RSkill) => ({
        isDiff: !JSON.stringify(this.resumeSnapShot.skills).includes(JSON.stringify(skill)),
        skillId: skill.id
      }))
      : [{
        isDiff: false,
        skillId: 0
      }]
  }

  public getEducationsDiff(): any[] {
    return this.resumeSnapShot
      ? this.resume.educations.map((education: EducationModel) => ({
        isDiff: !JSON.stringify(this.resumeSnapShot.educations).includes(JSON.stringify(education)),
        educationId: education.id
      }))
      : [{
        isDiff: false,
        educationId: 0
      }]
  }

  public getAssociationsDiff(): any[] {
    return this.resumeSnapShot
      ? this.resume.associations.map((association: RAssociation) => {
        const snapshotAssociation = this.resumeSnapShot.associations.find((p: any) => p.id === association.id);
        return {
          isDiff: snapshotAssociation ? (association.id !== snapshotAssociation.id) : true,
          associationId: association.id
        }
      })
      : [{
        isDiff: false,
        associationId: 0
      }]
  }

  public getProjectsDiff(): any[] {
    return this.resumeSnapShot
      ? this.resume.projects.map((project: RProject) => {
        const snapshotProject = this.resumeSnapShot.projects.find((p: any) => p.id === project.id);
        const isJobTitleObjChanged = !snapshotProject || JSON.stringify(project.jobTitleObj) !== JSON.stringify(snapshotProject.jobTitleObj);
        const isJobTitlesChanged = snapshotProject && JSON.stringify(project.jobTitles) !== JSON.stringify(snapshotProject.jobTitles);
        const isCompanyChanged = snapshotProject && project.company.id !== snapshotProject.company.id;
        const isStartDateChanged = snapshotProject && project.startDate !== snapshotProject.startDate;
        const isEndDateChanged = snapshotProject && project.endDate !== snapshotProject.endDate;
        const isDescriptionChanged = snapshotProject && project.projectDescription !== snapshotProject.projectDescription;
        let differentResponsibilities: number[] = [];
        if (snapshotProject && project.responsibilities && snapshotProject.responsibilities) {
          const snapshotResponsibilityIds = snapshotProject.responsibilities.map((resp: any) => resp.id);
          const newResponsibilityIds = project.responsibilities.filter((resp: any) => !snapshotResponsibilityIds.includes(resp.id)).map((resp: any) => resp.id);
          differentResponsibilities = newResponsibilityIds;
        } else if (!snapshotProject && project.responsibilities) {
          differentResponsibilities = project.responsibilities.map((resp: any) => resp.id);
        }
        return {
          isJobTitleDiff: snapshotProject ? (isJobTitlesChanged || isJobTitleObjChanged) : true,
          isDatesChanged: snapshotProject ? (isStartDateChanged || isEndDateChanged) : true,
          isCompanyChanged: snapshotProject ? isCompanyChanged : true,
          isDescriptionChanged: snapshotProject ? isDescriptionChanged : true,
          differentResponsibilities: differentResponsibilities,
          projectId: project.id
        }
      })
      : [{
        isJobTitleDiff: false,
        isDatesChanged: false,
        isCompanyChanged: false,
        isDescriptionChanged: false,
        differentResponsibilities: [],
        projectId: 0
      }]
  }

  public getIndustriesDiff(): any[] {
    if (this.resumeSnapShot) {
      const industriesArr = [];
      const industriesSnapShotArr = [];
      this.resumeSnapShot.projects.map((project: RProject) => {
        project.industries !== null ? industriesSnapShotArr.push(...project.industries.map(industry => industry)) : industriesSnapShotArr.push({ ...project.industry });
      });
      this.getProjectsDiff().map(projectDiff => {
        const project = this.resume.projects.find(project => project.id === projectDiff.projectId);
        project.industries !== null ? project.industries.map((industry: ProjectIndustry) => {
          industriesArr.push({
            isDiff: !industriesSnapShotArr.find((industrySnapShot: ProjectIndustry) => industrySnapShot.id === industry.id),
            industryName: industry.industryObj.name
          })
        }) : industriesArr.push({
          isDiff: !industriesSnapShotArr.find((industrySnapShot: ProjectIndustry) => industrySnapShot.id === project.industry.id),
          industryName: project.industry.name
        });
      });
      return industriesArr;
    } else {
      return [{
        isDiff: false,
        industryName: ""
      }]
    }
  }
}
