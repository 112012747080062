<div>
  <button
    fxHide.lt-lg
    [disabled]="disabled"
    class="mat-raised-button mat-button-base mr-2 bg-green-500 text-contrast-white"
    mat-raised-button
    type="button"
    (click)="open()"
  >
    <ic-icon
      [icon]="faIcons.eye"
      class="ltr:-ml-1 rtl:-mr-1 ltr:mr-2 rtl:ml-2"
      inline="true"
      size="18px"
    ></ic-icon>
    <span>{{ "open" | translate }}</span>
  </button>
  <button
    fxHide.lt-lg
    type="button"
    [disabled]="disabled"
    class="mat-raised-button mat-button-base mr-2 bg-obs-light-orange text-contrast-white"
    mat-raised-button
    (click)="print()"
  >
    <ic-icon
      [icon]="icIcons.print"
      class="ltr:-ml-1 rtl:-mr-1 ltr:mr-2 rtl:ml-2"
      inline="true"
      size="18px"
    ></ic-icon>
    <span>{{ "print" | translate }}</span>
  </button>
  <button
    type="button"
    [disabled]="disabled"
    class="mat-raised-button mat-button-base mr-2 bg-obs-blue text-contrast-white"
    mat-raised-button
    (click)="downloadPDF()"
  >
    <ic-icon
      [icon]="faIcons.download"
      class="ltr:-ml-1 rtl:-mr-1 ltr:mr-2 rtl:ml-2"
      inline="true"
      size="18px"
    >
    </ic-icon>
    <span>{{ "PDF" | translate }}</span>
  </button>
  <button
    type="button"
    [disabled]="disabled"
    class="mat-raised-button mat-button-base mr-2 bg-obs-blue text-contrast-white"
    mat-raised-button
    (click)="downloadWord()"
  >
    <ic-icon
      [icon]="faIcons.download"
      class="ltr:-ml-1 rtl:-mr-1 ltr:mr-2 rtl:ml-2"
      inline="true"
      size="18px"
    >
    </ic-icon>
    <span>{{ "DOCX" | translate }}</span>
  </button>
</div>
