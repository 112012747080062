export class NonOnliner {
  public userId: string;
  public firstName: string;
  public lastName: string;
  public accountingUserId: string;
  public isActive: boolean;
  public comments: string;  
  public modifiedDate: Date;
  public isOnliner: boolean;

    constructor(userId: string, firstName: string, lastName: string, accountingUserId: string, comments: string, isActive: boolean, modifiedDate: Date, isOnliner: boolean) {
      this.userId = userId;
      this.firstName = firstName;
      this.lastName = lastName;
      this.accountingUserId = accountingUserId;
      this.comments = comments;
      this.isActive = isActive;
      this.modifiedDate = modifiedDate;
      this.isOnliner = isOnliner;
  }
}
