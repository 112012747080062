import { SnackBarService } from "../common/services/snackbar.service";
import { MatDialog } from "@angular/material/dialog";
import { Component, OnInit, HostListener } from "@angular/core";
import { CommonService } from "src/app/common/services/common.service";
import {
  ResumeStatusTypes,
} from "../common/services/resume.service";
import { AuthService } from "../common/services/auth.service";
import icMoreVert from "@iconify-icons/ic/twotone-more-vert";
import { forkJoin, Observable } from "rxjs";
import {
  ConfirmationDialogComponent,
  ConfirmDialog,
} from "../shared/confirmation-dialog/confirmation-dialog.component";
import { RAttribute } from "../common/data-models/commonDataModels";
import { NonOnlinerResume } from "../common/data-models/NonOnlinerResume";
import { RNonOnlinerEducation } from "../common/data-models/RNonOnlinerEducation";
import { AlternateOnlinerMaintenanceService } from "../common/services/alternateOnlinerMaintenance.service";
import { NonOnliner } from "../common/data-models/NonOnliner";
import { formatDate } from "@angular/common";
import {
  BuildResumeNOFormUtilService,
  BuildResumeState,
} from "./build-resumeNO-form-state.service";
import { ResumeNOService } from "../common/services/resumeNO.service";
import { NavigationService } from "../../@vex/services/navigation.service";
import { AltOnlinerDefault } from "../common/data-models/AltOnlinerDefault";
import { Router } from "@angular/router";
import { SkillsNOService } from "../common/services/skillNO.service";
import { RNonOnlinerSkill } from "../common/data-models/RNonOnlinerSkill";
import { AssociationsNOService } from "../common/services/associationNO.service";
import { RNonOnlinerAssociation } from "../common/data-models/RNonOnlinerAssociation";
import { ProjectNOService } from "../common/services/projectNO.service";
import { RNonOnlinerProject } from "../common/data-models/RNonOnlinerProject";
import { SkillSummaryNOService } from "../common/services/skillSummaryNO.service";
import { RNonOnlinerSkillSummary } from "../common/data-models/RNonOnlinerSkillSummary";
import { ProfileSummaryNOService } from "../common/services/profileSummaryNO.service";
import { ProfileSummaryNO } from "../common/data-models/ProfileSummaryNO";
import { EducationNOService } from "../common/services/educationNO.service";
import { RNonOnlinerResumeExpertiseGroup } from "../common/data-models/RNonOnlinerResumeExpertiseGroup";
import { Store, select, } from "@ngrx/store";
import { State } from "src/app/build-resume-common/store/multi-select-skills-card.reducer";
import * as userSkillsActions from "src/app/build-resume-common/store/multi-select-skills-card.actions";

export interface DropDown<T> {
  id: number;
  value: number;
  viewValue: string;
  element: T;
  icon?: string;
}

@Component({
  selector: "obs-build-resumeNO",
  templateUrl: "./build-resumeNO.component.html",
  styleUrls: ["./build-resumeNO.component.scss"],
})
export class BuildResumeNOComponent implements OnInit {
  public resumes: NonOnlinerResume[];
  public refResumeId: number;
  public fullyLoaded: number[] = [];
  public profileSummaries: DropDown<ProfileSummaryNO>[];
  public spins: RAttribute[];
  public skillSummaries: DropDown<RNonOnlinerSkillSummary>[];
  public skills: DropDown<RNonOnlinerSkill>[];
  public userSkills: DropDown<RNonOnlinerSkill>[];
  public resumeExpertiseGroups: RNonOnlinerResumeExpertiseGroup[];
  public associations: DropDown<RNonOnlinerAssociation>[];
  public educations: DropDown<RNonOnlinerEducation>[];
  public projects: DropDown<RNonOnlinerProject>[];
  private userId: string;
  public canAddNewResume: boolean;
  public isLoading = true;
  public isCloning: boolean = false;
  public formsListState: BuildResumeState;
  private altOnlinerDefault: AltOnlinerDefault =
    this.navigationService.getAltOnlinerDefault();
  icMoreVert = icMoreVert;

  nonOnliner: NonOnliner;

  public constructor(
    public commonService: CommonService,
    private alternateOnlinerMaintenanceService: AlternateOnlinerMaintenanceService,
    private resumeNOService: ResumeNOService,
    private profileSummaryService: ProfileSummaryNOService,
    private skillSummaryService: SkillSummaryNOService,
    private skillService: SkillsNOService,
    private educationService: EducationNOService,
    private associationService: AssociationsNOService,
    private projectService: ProjectNOService,
    private authService: AuthService,
    public snackBarService: SnackBarService,
    public dialog: MatDialog,
    private router: Router,
    private buildResumeStateService: BuildResumeNOFormUtilService,
    private navigationService: NavigationService,
    private store: Store<{ userSkills: State }>
  ) { }

  @HostListener("window:beforeunload")
  canDeactivate(): boolean | Observable<boolean> {
    if(!this.authService.isAuthenticated()) return true;
    return this.formsListState !== BuildResumeState.isDirty;
  }

  public ngOnInit(): void {
    if (!this.navigationService.getIsSlideChecked()) {
      this.router.navigateByUrl("/app/build-resume");
      return;
    }
    this.canAddNewResume = true;

    this.userId = this.authService.getUserId();

    if (!this.resumes) {
      this.resumes = new Array();
    }

    this.buildResumeStateService
      .getFormsState()
      .subscribe(({ state, resume }) => {
        this.formsListState = state;

        if (state === BuildResumeState.refresh) {
          this.refresh(resume);
        }

        if (state === BuildResumeState.pristine) {
          this.setCloning(false);
        }
      });

    this.getResumeDropDownOptions();
  }

  private getResumeDropDownOptions(): void {
    forkJoin([
      this.commonService.getSpins(),
      this.profileSummaryService.getProfileSummariesNO(
        this.altOnlinerDefault.altOnlinerUserId
      ),
      this.skillSummaryService.getSkillSummaries(
        this.altOnlinerDefault.altOnlinerUserId
      ),
      this.skillService.getRSkills(this.altOnlinerDefault.altOnlinerUserId),
      this.associationService.getRAssociationsNO(
        this.altOnlinerDefault.altOnlinerUserId
      ),
      this.educationService.getMyEducationRecords(
        this.altOnlinerDefault.altOnlinerUserId
      ),
      this.projectService.getProjects(this.altOnlinerDefault.altOnlinerUserId),
      this.alternateOnlinerMaintenanceService.getByUserId(
        this.altOnlinerDefault.altOnlinerUserId
      ),
    ]).subscribe(
      ([
        spins,
        profileSummaries,
        skillSummaries,
        skills,
        associations,
        educations,
        projects,
        onliner,
      ]) => {
        this.loadProfileSummariesDropDown(profileSummaries);

        this.loadSkillSummariesDropDown(skillSummaries);

        this.loadSkillsDropDown(this.sortSkillsByExpertise(skills));

        this.userSkills = this.commonService.loadUserSkillsDropDown(skills);

        this.loadAssociationsDropDown(associations);

        this.loadEducationsDropDown(educations);

        this.loadProjectsDropDown(projects);

        this.loadEmployee(onliner);

        this.loadResumesAndSpins(spins);
      },
      (error) => {
        this.snackBarService.error(error);
      }
    );

    this.store.dispatch(userSkillsActions.loadExpertiseTypeData({ userId: this.userId }));
  }

  private sortSkillsByExpertise(data): RNonOnlinerSkill[] {
    return data.sort(function (skill1, skill2) {
      if (skill1.expertiseType?.name > skill2.expertiseType?.name) return 1;
      if (skill1.expertiseType?.name < skill2.expertiseType?.name) return -1;

      if (skill1.skill.name > skill2.skill.name) return 1;
      if (skill1.skill.name < skill2.skill.name) return -1;
    });
  }

  public addBuildResume(): void {
    if (this.formsListState === BuildResumeState.isDirty) {
      this.showConfirmationDialog();
    } else {
      this.resumes.unshift(this.createNewEmptyResume());
      this.canAddNewResume = false;

      this.setCloning(false);
    }
  }

  public isFullyLoaded(id: number): boolean {
    return this.fullyLoaded.includes(id);
    // return true;
  }

  createNewEmptyResume(): NonOnlinerResume {
    return new NonOnlinerResume(
      -1,
      this.nonOnliner,
      null,
      -1,
      false,
      false,
      null,
      null,
      null,
      -1,
      "",
      -1,
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      null,
      false,
      null,
      true
    );
  }

  public cloneResume(refResume: NonOnlinerResume): void {
    if (refResume) {
      this.refResumeId = refResume.id;

      this.setCloning(true);

      this.resumes.unshift({
        ...refResume,
        reviewComments: [],
        id: -1,
        statusType: {
          ...refResume.statusType,
          id: -1,
          name: ResumeStatusTypes.NEW,
        },
        statusTypeId: -1,
        isSnapshot: false,
      });
      this.canAddNewResume = false;
    }
  }

  public loadResumesAndSpins(spins: any): void {
    this.resumeNOService
      .getResumes(this.altOnlinerDefault.altOnlinerUserId)
      .subscribe((data: NonOnlinerResume[]) => {
        this.resumes = new Array();
        this.setCloning(false);

        data.forEach((element) => {
          this.resumes.push(
            new NonOnlinerResume(
              element.id,
              this.nonOnliner,
              element.statusType,
              element.statusTypeId,
              element.isClientHidden,
              element.isProfessionalResume,
              element.dateModified,
              element.dateApproved,
              element.spins,
              element.spinsId,
              element.accreditation,
              element.profileSummaryId,
              element.profileSummaries,
              [], //lazy load
              [], //lazy load
              [], //lazy load
              [], //lazy load
              [], //lazy load
              [], //lazy load
              [], //lazy load
              element.reviewComments,
              element.dateRequested,
              element.isSnapshot,
              element.maxApprovedSpinDate,
              element.isProjectMostRecent
            )
          );
        });

        if (spins) {
          this.loadSpins(
            this.resumes.map((x) => x.spins),
            spins
          );
          this.resumes.forEach((e) => {
            e.spins.name = e.spins.name;
          });
        }
        this.isLoading = false;
      });
  }

  public loadSpins(userSpins: RAttribute[], spins: RAttribute[]): void {
    this.spins = new Array();

    //add original values (others are not included)
    spins
      .filter((x) => !x.isOther)
      .forEach((element) => {
        element.name = element.name;
        this.spins.push(element);
      });

    userSpins
      .filter((us) => {
        return us.isOther && !this.spins.map((s) => s.id).includes(us.id);
      })
      .forEach((element) => {
        element.name = element.name;
        this.spins.push(element);
      });
  }

  public updateResumeAndLoadSpins(
    resume: NonOnlinerResume,
    spins: RAttribute[]
  ): void {
    // this.setCloning(false);

    let updateItem = this.resumes.find((item) =>
      [resume.id, -1].includes(item.id)
    );

    let index = this.resumes.indexOf(updateItem);

    this.resumes[index] = resume;

    this.cleanNewResumeFromFullyLoaded();
  }

  cleanNewResumeFromFullyLoaded() {
    const index = this.fullyLoaded.findIndex((x) => x === -1);
    if (index !== -1) {
      this.fullyLoaded.splice(index, 1);
    }
  }

  public loadProfileSummariesDropDown(data: any): void {
    this.profileSummaries = new Array();
    data.forEach((element) => {
      this.profileSummaries.push(
        this.createDropDownItem({
          id: element.id,
          value: element.id,
          viewValue: element.name,
          element: element,
        })
      );
    });
  }

  public loadSkillSummariesDropDown(data: any): void {
    this.skillSummaries = new Array();
    data.forEach((element) => {
      this.skillSummaries.push(
        this.createDropDownItem({
          id: element.id,
          value: element.id,
          viewValue: element.name,
          element: element,
        })
      );
    });
  }

  public loadSkillsDropDown(data: any): void {
    this.skills = new Array();

    data.forEach((element) => {
      this.skills.push(
        this.createDropDownItem({
          id: element.id,
          value: element.skillId,
          viewValue: `${element.expertiseType?.name} - ${element.skill.name}`,
          element: element,
          icon: element.isStarred ? "star" : null,
        })
      );
    });
  }

  public loadAssociationsDropDown(data: any): void {
    this.associations = new Array();
    data.forEach((element) => {
      this.associations.push(
        this.createDropDownItem({
          id: element.assocId,
          value: element.id,
          viewValue: element.assoc.name,
          element: element,
        })
      );
    });
  }

  public loadEducationsDropDown(data: any): void {
    this.educations = new Array();
    data.forEach((element) => {
      this.educations.push(
        this.createDropDownItem({
          id: element.educationId,
          value: element.id,
          viewValue: element.education.name,
          element: element,
        })
      );
    });
  }

  public loadProjectsDropDown(data: any): void {
    this.projects = new Array();
    data.forEach((element) => {
      this.projects.push(
        this.createDropDownItem({
          id: element.id,
          value: element.id,
          viewValue: `${formatDate(element.startDate, "MM/yyyy", "en-CA")} - ${element.endDate
            ? formatDate(element.endDate, "MM/yyyy", "en-CA")
            : "Present"
            } - ${element.jobTitles
              .map((x) => x.jobTitleObj.name)
              .join(", ")} at ${element.company.name} at ${element.company.name}`,
          element: element,
        })
      );
    });
  }

  public loadEmployee(data: any): void {
    this.nonOnliner = data;
  }

  createDropDownItem(dropDown: DropDown<any>): DropDown<any> {
    return {
      id: dropDown.id,
      value: dropDown.value,
      viewValue: dropDown.viewValue,
      element: dropDown.element,
      icon: dropDown.icon,
    };
  }

  showConfirmationDialog() {
    const confirmDialog = new ConfirmDialog();

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "500px",
      data: confirmDialog,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === "ok") {
        this.buildResumeStateService.setFormsState(
          BuildResumeState.pristine,
          null
        );

        this.resumes.unshift(
          new NonOnlinerResume(
            -1,
            this.nonOnliner,
            null,
            -1,
            false,
            false,
            null,
            null,
            null,
            -1,
            "",
            -1,
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            null,
            false,
            null,
            true
          )
        );
        this.canAddNewResume = false;
      }
    });
  }

  private refresh(resume: NonOnlinerResume): void {
    this.commonService.getSpins().subscribe((spins) => {
      this.updateResumeAndLoadSpins(resume, spins);
    });
    this.buildResumeStateService.setFormsState(
      BuildResumeState.pristine,
      resume
    );
  }

  public delete(): void {
    this.resumes.shift();
    this.canAddNewResume = true;
    this.cleanNewResumeFromFullyLoaded();
  }

  public markDirty(): void {
    this.buildResumeStateService.setFormsState(BuildResumeState.isDirty, null);
  }

  public markClean(): void {
    this.buildResumeStateService.setFormsState(BuildResumeState.pristine, null);
    // this.setCloning(false);
  }

  public markFullyLoaded(resumeId: number): void {
    if (!this.fullyLoaded.includes(resumeId)) {
      this.fullyLoaded.push(resumeId);
    } else {
      this.fullyLoaded.splice(this.fullyLoaded.indexOf(resumeId), 1);
    }
  }

  public setCloning(flag: boolean): void {
    this.isCloning = flag;
  }

  public refreshList(): void {
    this.isLoading = true;
    this.getResumeDropDownOptions();
    this.canAddNewResume = true;
    this.fullyLoaded = [];
  }
}
