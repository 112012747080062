import { NgModule, Component, HostListener, OnInit } from "@angular/core";
import { AttributeFilterService } from "../common/services/attribute-filter.service";
import { UntypedFormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { formatDate } from "@angular/common";
import { DataApprovalService } from "../common/services/dataApproval.service";
import { DataApproval } from "../common/data-models/DataApproval";
import { ResumeService } from "../common/services/resume.service";
import { SnackBarService } from "../common/services/snackbar.service";
import { GlobalConstants } from "../shared/global-constants";

@Component({
  selector: "obs-attribute-approvals",
  templateUrl: "./attribute-approvals.component.html",
  styleUrls: ["./attribute-approvals.component.scss"],
})
export class AttributeApprovalsComponent implements OnInit {
  filteredAttributes: DataApproval[];
  attributes: DataApproval[];
  filterCriteriaForm: UntypedFormGroup;
  isLoading = true;
  filterTitle: string;
  isTakingSnapshots: boolean;

  constructor(
    private dataApproval: DataApprovalService,
    private attributeFilterService: AttributeFilterService,
    private resumeService: ResumeService,
    private snackBarService: SnackBarService
  ) {}

  @HostListener("window:beforeunload")
  canDeactivate(): boolean | Observable<boolean> {
    return true;
  }

  prepareFilterForm(): void {
    this.filterCriteriaForm =
      this.attributeFilterService.getFilterCriteriaForm();
  }

  filterAttributes(event: any): void {
    const filterCriteriaModel =
      this.attributeFilterService.getFilterCriteriaModel();

    this.filteredAttributes = this.attributes.filter(
      (f) =>
        this.attributeFilterService.filterName(f, filterCriteriaModel) &&
        this.attributeFilterService.filterAttributeType(
          f,
          filterCriteriaModel
        ) &&
        this.attributeFilterService.filterCreatedDate(f, filterCriteriaModel) &&
        this.attributeFilterService.filterModifiedDate(
          f,
          filterCriteriaModel
        ) &&
        this.attributeFilterService.filterIsAttached(f, filterCriteriaModel)
    );

    this.updateFilterTitle();
  }

  updateFilterTitle(): void {
    this.filterTitle = "(All, All, All, All, All)";
    const appliedFilters = [];
    const filterCriteriaModel =
      this.attributeFilterService.getFilterCriteriaModel();
    Object.keys(filterCriteriaModel).forEach((key) => {
      if (filterCriteriaModel[key]) {
        if (key === "createdDate" || key === "modifiedDate") {
          appliedFilters.push(
            `'${formatDate(
              filterCriteriaModel[key],
              GlobalConstants.FULL_DATE_FORMAT,
              "en-CA"
            )}'`
          );
          return;
        }
        appliedFilters.push(`'${filterCriteriaModel[key]}'`);
      }
    });

    if (appliedFilters.length > 0) {
      this.filterTitle = appliedFilters.join(", ");
    }
  }

  getAttributeList(): void {
    this.dataApproval.getDataApproval().subscribe(
      (data) => {
        this.attributes = data;
      },
      (error) => {
        console.error(error);
      },
      () => {
        this.filterAttributes(true);
        this.isLoading = false;
      }
    );
  }

  ngOnInit() {
    this.prepareFilterForm();
    this.getAttributeList();
  }

  public async retakeSnapshots() {
    this.isTakingSnapshots = true;
    await this.resumeService.retakeSnapshots().toPromise();
    this.snackBarService.message("Snapshots retook successfully!");
    this.isTakingSnapshots = false;
  }
}
