import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { AuthorizedHttp } from "./authorized-http.service";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Resume } from "../data-models/Resume";
import {WorkflowResume} from '../data-models/WorkflowResume';

@Injectable()
export class WorkflowService {
  private _apiEndpoint = `${environment.apiEndpoint}/api/resume/workflow`;
  private _resumes: Resume[];
  private _resume: Resume;
  private _isDeleted: boolean;

  constructor(private http: AuthorizedHttp) {
    const headers: any = {
      "Content-Type":
        "application/json; charset=utf-8" /*or whatever type is relevant */,
      Accept: "application/json",
    };
  }

  public getPendingReviewResume(userId: string, resumeId: number): Observable<Resume> {
    
    return this.http
      .get(`${this._apiEndpoint}/${userId}/${resumeId}/pending`)
      .pipe(
        map((result) => {
          this._resume = result;
          return this._resume;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }

  public getPendingReviewOriginalResume(userId: string, resumeId: number): Observable<Resume> {
    
    return this.http
      .get(`${this._apiEndpoint}/${userId}/${resumeId}/pendingOrig`)
      .pipe(
        map((result) => {
          this._resume = result;
          return this._resume;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }
  
  public requestReview(userId: string, resumeId: number): Observable<any> {
    return this.http
      .postWithNoData(`${this._apiEndpoint}/review/${userId}/${resumeId}/request`)
      .pipe(
        map((result) => {
          this._resume = result;
          return this._resume;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }

  public deleteRequestReview(userId: string, resumeId: number): Observable<any> {
    return this.http
      .delete(`${this._apiEndpoint}/review/${userId}/${resumeId}/request`)
      .pipe(
        map((result) => {
          this._isDeleted = !!result;
          return this._isDeleted;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );;
  }

  public approveResumeReview(userId: string, resumeId: number, comment: string): Observable<boolean> {
    return this.http
      .post(`${this._apiEndpoint}/review/${userId}/${resumeId}/approve`, JSON.stringify(comment))
      .pipe(
        map((result:boolean) => {
          return result;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }

  public rejectResumeReview(userId: string, resumeId: number, comment: string): Observable<boolean> {
    return this.http
      .post(`${this._apiEndpoint}/review/${userId}/${resumeId}/reject`, JSON.stringify(comment))
      .pipe(
        map((result:boolean) => {
          return result;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || "Server error")
        )
      );
  }
}
