import { Component, Inject } from "@angular/core";
import * as _moment from "moment";
import { default as _rollupMoment } from "moment";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { RReviewComment } from "../common/data-models/RReviewComment";

export interface DialogData {
  reviewComments: RReviewComment[];
}

@Component({
  selector: "review-comments-modal",
  templateUrl: "review-comments-modal.component.html",
  styleUrls: ["review-comments-modal.component.scss"],
})
export class ReviewCommentsModalComponent {
  constructor(
    public dialogRef: MatDialogRef<ReviewCommentsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onOkClick(): void {
    this.dialogRef.close();
  }
}
