import { Injectable } from '@angular/core';
import { Observable, of, throwError, Subject } from 'rxjs';
import { AuthorizedHttp } from './authorized-http.service';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { RAssociation } from '../data-models/RAssociation';
import { CommonService } from './common.service';

@Injectable()
export class AssociationsNOService {
  private _apiEndpoint = `${environment.apiEndpoint}/api/associationsNO`;
  private _rAssociations: RAssociation[];
  private _rAssociation: RAssociation;
  private _isDeleted: boolean;

  constructor(private http: AuthorizedHttp, private commonService: CommonService) {
    const headers: any = {
        'Content-Type': 'application/json; charset=utf-8', /*or whatever type is relevant */
        Accept: 'application/json'
    }
  }

  public getRAssociationsNO(userId: string): Observable<RAssociation[]> {
    userId = this.commonService.encrypt(userId);
    return this.http
        .get(`${this._apiEndpoint}/myAssociationsNO/` + userId)
        .pipe(
          map(result => {
            this._rAssociations = result;
            return this._rAssociations;
          }),
          catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
        );
    }

    public postNewRAssociationNO(associationsModel: RAssociation, userId: string): Observable<RAssociation> {
      userId = this.commonService.encrypt(userId);
      return this.http
          .post(`${this._apiEndpoint}/addNewAssociationNO/`+ userId, JSON.stringify(associationsModel))
          .pipe(
            map(result => {
              this._rAssociation = result;
              return this._rAssociation;
            }),
            catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
          );
    }

    public updateRAssociationNO(associationsModel: RAssociation, userId: string): Observable<RAssociation> {
      userId = this.commonService.encrypt(userId);
      return this.http
          .patch(`${this._apiEndpoint}/updateAssociationNO/` + userId, JSON.stringify(associationsModel))
          .pipe(
            map(result => {
              this._rAssociation = result;
              return this._rAssociation;
            }),
            catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
          );
    }

    public deleteRAssociationNO(associationsModel: RAssociation, userId: string): Observable<RAssociation> {
      userId = this.commonService.encrypt(userId);
        return this.http
            .patch(`${this._apiEndpoint}/deleteAssociationNO/` + userId, JSON.stringify(associationsModel))
            .pipe(
                map(result => {
                    this._rAssociation = result;
                    return this._rAssociation;
                }),
                catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
            );
    }
}
