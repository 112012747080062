<div
  fxLayout="row"
  fxLayout.lt-sm="column"
  fxLayoutGap="16px"
  fxLayoutGap.lt-sm="0"
  class="field-position"
  *ngIf="panelOpenState"
>
  <div fxFlex="auto" fxHide.lt-lg class="fully-right mt-4">
    <mat-slide-toggle
      labelPosition="before"
      class="slide-toggle text-obs-blue"
      (change)="togglePreview()"
      >{{ "togglePreview" | translate }}</mat-slide-toggle
    >
  </div>
</div>

<div @fadeInUp class="card mb-3">
  <div class="px-6 py-4" [@.disabled]="disableAnimation">
    <mat-expansion-panel
      [disabled]="true"
      [expanded]="panelOpenState"
      class="no-pad"
      (closed)="closePanel()"
      (opened)="panelOpenState = true"
    >
      <mat-expansion-panel-header
        (click)="togglePanel($event)"
        class="p-0"
        *ngIf="!panelOpenState"
      >
        <mat-panel-title>
          <p class="header-info">
            <span
              class="font-bold"
              *ngIf="resume.spins"
              style="word-break: break-all"
            >
              {{ resume.spins?.isOther ? "Other - " : ""
              }}{{ resume.spins?.name }}
              <small [ngClass]="getResumeStatusColor()"
                >({{ resume.statusType?.name }})</small
              >
              <div
                matTooltip="Gold Resume"
                class="gold-resume"
                *ngIf="isGoldResume()"
              >
                <i class="fas fa-star"></i>
              </div>
            </span>
            <br />
            <span *ngIf="resume.dateModified">
              Last Updated on
              {{ resume.dateModified | date: dateFormat }}
            </span>
          </p>

          <!-- <button
            *ngIf="resume.reviewComments.length > 0"
            matTooltip="Comments"
            (click)="openReviewCommentsDialog($event)"
          >
            <i class="fas fa-comment"></i>
          </button> -->

          <button
            class="icon-button"
            matTooltip="Preview"
            (click)="openPreviewDialog($event)"
          >
            <i class="fas fa-file-alt"></i>
          </button>

          <button
            class="icon-button"
            matTooltip="Clone"
            (click)="cloneResume($event)"
          >
            <i class="fas fa-clone"></i>
          </button>

          <!-- more statuses need to be considered -->
          <button
            *ngIf="resume.statusType.name == 'New' && !isDeleting"
            class="icon-button"
            matTooltip="Delete"
            (click)="deleteResume($event)"
          >
            <i class="fas fa-trash"></i>
          </button>

          <button *ngIf="isDeleting" class="icon-button">
            <i class="fas fa-spin fa-spinner"></i>
          </button>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <form [formGroup]="resumeForm" fxLayout="column" style="width: 100%">
        <div fxLayout="row">
          <div
            class="pr-2"
            fxFlex="auto"
            fxFlex.lt-lg="auto"
            style="width: 100%"
          >
            <div
              fxLayout="row"
              fxLayout.lt-sm="column"
              fxLayoutGap="16px"
              fxLayoutAlign.lt-sm="start strech"
              fxLayoutGap.lt-sm="16px"
              class="field-position"
            >
              <div
                [class.fieldAdjust]="spinIsEmpty()"
                fxLayout="column"
                fxFlex="100%"
              >
              <mat-form-field
              fxFlex="fieldLength"
              
              (click)="displaySpinPickList($event)"
              >
                <mat-label>Spin</mat-label>
                <input
                  class="spinClickField"                  
                  type="text"
                  matInput
                  placeholder="Choose Spin"
                  aria-label="Spin"
                  [formControl]="spin"
                  #Spin
                  (keydown)="
                  !isKeyAllowedOnSpinField($event)
                  ? displaySpinPickList($event)
                  : true"
                  required
                />
                <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                <mat-error *ngIf="spinFormControl.hasError('required')">{{
                  getSpinNameErrorMessage()}}
                </mat-error>
              </mat-form-field>
              </div>

              <!-- <div *ngIf="resume.reviewComments.length > 0" class="fully-right">
                <button (click)="openReviewCommentsDialog($event)">
                  <i class="fas fa-comment fa-lg"></i>
                </button>
              </div> -->

              <div fxFlex="auto" class="fully-right">
                <mat-slide-toggle
                  class="slide-toggle"
                  formControlName="isClientHidden"
                  (change)="onIsClientHiddenChange($event)"
                  labelPosition="before"
                  >Hide Client Info</mat-slide-toggle
                >
              </div>
            </div>

            <div
              fxLayout="row"
              fxLayout.lt-sm="column"
              fxLayoutGap="16px"
              fxLayoutGap.lt-sm="0"
            >
              <mat-form-field fxFlex="100%">
                <mat-label>Accreditation</mat-label>
                <input
                  type="text"
                  matInput
                  placeholder="Enter Your Accreditation (Optional)"
                  matTooltip="Please enter no longer than 40 characters"
                  formControlName="accreditation"
                  (change)="onAccreditationChange($event)"
                />
              </mat-form-field>
            </div>

            <div
              fxLayout="row"
              fxLayout.lt-sm="column"
              fxLayoutGap="16px"
              fxLayoutGap.lt-sm="0"
              [class.fieldAdjust]="profileSummaryIsEmpty()"
            >
              <mat-form-field fxFlex="100%">
                <mat-label>Profile Summary</mat-label>
                <mat-select
                  class="select-style"
                  placeholder="Select a Profile Summary"
                  formControlName="profileSummary"
                  (selectionChange)="onProfileSummaryChange($event)"
                  required
                >
                  <mat-option
                    *ngFor="let profileSummary of profileSummaries"
                    [value]="profileSummary.id"
                  >
                    {{ profileSummary.viewValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="spinClass" *ngIf="profileSummaryIsEmpty()">
              Profile Summary required.
            </div>

            <div class="p-6" *ngIf="isLoading$ | async">
              <div
                fxLayout="column"
                fxLayoutAlign="start center"
                fxLayoutGap="24px"
              >
                <mat-spinner></mat-spinner>
              </div>
            </div>
            <div row *ngIf="fullyLoaded">
              <obs-multi-dropdown-card
                [parentForm]="resumeForm"
                formArrayName="skillSummaries"
                (onRemove)="removeFromSkillSummaryList($event)"
                (onAdd)="addToSkillSummaryList()"
                (onSelect)="skillSummaryChange($event)"
                (onDrag)="syncSkillSummaryList()"
                [objectDropDown]="skillSummaries"
                title="Skill Summary*"
                fieldLabel="Skill Summary"
                [readOnlyForm]="isReadOnly()"
                maxItems="3"
              ></obs-multi-dropdown-card>

              <div row *ngIf="isOldSnapshotResume === true && cloneInProgress ===false">
                <div row class="professional-resume-toggle">
                  <mat-icon
                    [icIcon]="icInfoCircle"
                    aria-hidden="false"
                    aria-label="info"
                    (click)="onInfoClicked()"
                  >
                  </mat-icon>
                  <div fxFlex="auto" class="fully-right">
                    <mat-slide-toggle
                      class="slide-toggle"
                      matTooltip="Override section heading."
                      matTooltipPosition="above"
                      formControlName="isProfessionalResume"
                      (change)="onIsProfessionalResumeChange($event)"
                      labelPosition="before"
                      >Professional Expertise</mat-slide-toggle
                    >
                  </div>
                </div>

                <obs-multi-select-card
                  [parentForm]="resumeForm"
                  formArrayName="skills"
                  [objectDropDown]="skills"
                  title="Skills*"
                  fieldLabel="Skill"
                  [readOnlyForm]="isReadOnly()"
                  leftTitle="Available Skills"
                  rightTitle="Included in Resume"
                  (onRightSideChange)="rightSideSkillsChange($event)"
                  [sortList]="getSortedSkillsDropDown"
                  [validate]="validateSkillExpertise"
                ></obs-multi-select-card>
              </div>

              <div row *ngIf="isOldSnapshotResume === false  || cloneInProgress === true">
                <div row class="professional-resume-toggle">
                  <mat-icon
                    [icIcon]="icInfoCircle"
                    aria-hidden="false"
                    aria-label="info"
                    (click)="onInfoClicked()">
                  </mat-icon>
                  <div fxFlex="auto" class="fully-right">
                    <mat-slide-toggle
                      class="slide-toggle"
                      matTooltip="Override section heading."
                      matTooltipPosition="above"
                      formControlName="isProfessionalResume"
                      (change)="onIsProfessionalResumeChange($event)"
                      labelPosition="before"
                      >Professional Expertise</mat-slide-toggle>
                  </div>
                </div>

                <obs-multi-select-skills-card
                  *ngIf="isOldSnapshotResume === false || cloneInProgress === true"
                  [parentForm]="resumeForm"
                  formArrayName="userSkills"
                  [objectDropDown]="userSkills"
                  title="Skills*"
                  fieldLabel="Skill"
                  [readOnlyForm]="isReadOnly()"
                  leftTitle="Onliner Skills"
                  [resumeId]="resume.id"
                  [sortList]="getSortedSkillsDropDown"
                  [validate]="validateSkillExpertise"
                  [resumeExpertiseGroups]="resumeExpertiseGroups"
                  (onRightSideChange)="rightSideUserSkillsGroupingsChange($event)">
                </obs-multi-select-skills-card>
              </div>

              <obs-multi-select-card
                [parentForm]="resumeForm"
                formArrayName="educations"
                [objectDropDown]="educations"
                title="Education*"
                fieldLabel="Education"
                [readOnlyForm]="isReadOnly()"
                leftTitle="Available Education"
                rightTitle="Included in Resume"
                (onRightSideChange)="rightSideEducationChange($event)"
              ></obs-multi-select-card>

              <div row class="professional-resume-toggle">
                <div fxFlex="auto" class="fully-right">
                  <mat-slide-toggle
                    class="slide-toggle"
                    matTooltip="Sort projects by most recent start date"
                    matTooltipPosition="above"
                    formControlName="isProjectMostRecent"
                    (change)="onIsProjectMostRecentChange($event)"
                    labelPosition="before"
                    >Sort by Most Recent</mat-slide-toggle
                  >
                </div>
              </div>

              <obs-multi-select-card
                [parentForm]="resumeForm"
                formArrayName="projects"
                [objectDropDown]="projects"
                title="Work Experience*"
                fieldLabel="Work Experience"
                [readOnlyForm]="isReadOnly()"
                leftTitle="Available Work Experience"
                rightTitle="Included in Resume"
                (onRightSideChange)="rightSideProjectsChange($event)"
                [sortList]="getSortedProjectsDropDown"
                [sortRightList]="getSortedProjectsDropDown"
                [refreshList]="refreshDropDownList"
              ></obs-multi-select-card>

              <obs-multi-select-card
                [parentForm]="resumeForm"
                formArrayName="associations"
                [objectDropDown]="associations"
                title="Associations"
                fieldLabel="Associations"
                [readOnlyForm]="isReadOnly()"
                leftTitle="Available Associations"
                rightTitle="Included in Resume"
                (onRightSideChange)="rightSideAssociationsChange($event)"
              ></obs-multi-select-card>
            </div>
          </div>
          <div
            class="p-20 bg-gray-500"
            fxFlex="50"
            fxHide.lt-lg
            *ngIf="panelOpenState && showPreview"
          >
            <obs-resume-html-live-preview
              [resume]="resume"
              [shouldEnablePreview]="shouldEnablePreview()"
              [isOldSnapshotResume]="isOldSnapshotResume"
            ></obs-resume-html-live-preview>
          </div>
        </div>
      </form>

      <mat-action-row>
        <div
          fxLayout="row"
          fxLayoutAlign="end center"
          fxLayoutGap="8px"
          class="clearfix"
        >
          <button
            class="mat-raised-button mat-button-base mt-3 bg-gray-500 text-contrast-black"
            mat-raised-button
            type="button"
            (click)="cancel()"
            [disabled]="(isLoading$ | async) || (isSubmitting$ | async)"
          >
            {{ "cancel" | translate }}
          </button>

          <button
            class="mat-raised-button mat-button-base mt-3 bg-green-500 text-contrast-white"
            mat-raised-button
            type="button"
            [hidden]="isReadOnly()"
            [disabled]="saveDisabled"
            (click)="save()"
          >
            <span *ngIf="isSubmitting$ | async"
              ><i class="fa fa-spinner fa-spin" aria-hidden="true"></i
            ></span>
            {{ "save" | translate }}
          </button>
        </div>
      </mat-action-row>
    </mat-expansion-panel>
  </div>
</div>
