import { Component, OnInit, Input } from "@angular/core";
import { Resume } from "../common/data-models/Resume";

@Component({
  selector: "obs-resume-preview",
  templateUrl: "./resume-preview.component.html",
  styleUrls: ["./resume-preview.component.scss"],
})
export class ResumePreviewComponent implements OnInit {
  @Input("resume") resume: Resume;
  @Input("scale") scale: number;
  @Input("previewType") previewType: string;
  @Input("originalResume") originalResume: Resume;

  public constructor(
    
  ) {}

  ngOnInit(): void {
  }

}