import { Injectable } from "@angular/core";
import pdfMake from "pdfmake-lite";
import { Resume } from "src/app/common/data-models/Resume";
import { HttpClient } from "@angular/common/http";
import {
  FilterSkillExpertiseUniquePipe,
  FilterSkillByExpertisePipe,
  FilterEducationByTypePipe,
  GetProjectIndustriesUniquePipe,
  GetAssociationsPipe,
  GetProjectsPipe,
  GetSkillSummariesPipe,
} from "./resume-builder.service";
import { ResumeBuilderService } from "./resume-builder.service";
import { ResumeStatusTypes } from "../../common/services/resume.service";
import { RProject } from "../data-models/project";
import { RProjectGroup } from "../data-models/ProjectGroup";

pdfMake.fonts = {
  Trebuchet: {
    normal: `${window.location.origin}/assets/fonts/Trebuchet-MS.ttf`,
    bold: `${window.location.origin}/assets/fonts/Trebuchet-MS-Bold.ttf`,
    italics: `${window.location.origin}/assets/fonts/Trebuchet-MS-Italic.ttf`,
  },
};

@Injectable()
export class ResumePdfBuilderService extends ResumeBuilderService {
  private _originalResume: Resume;
  private isAccreditationDiff: boolean = false;
  private isPronounDiff = false;  
  private isProfessionalExpertiseDiff: boolean = false;
  private isAssociationsDiff: boolean = false;
  constructor(
    public http: HttpClient,
    public filterSkillExpertiseUniquePipe: FilterSkillExpertiseUniquePipe,
    public filterSkillByExpertisePipe: FilterSkillByExpertisePipe,
    public filterEducationByTypePipe: FilterEducationByTypePipe,
    public getProjectIndustriesUniquePipe: GetProjectIndustriesUniquePipe,
    public getAssociationsPipe: GetAssociationsPipe,
    public getProjectsPipe: GetProjectsPipe,
    public getSkillSummariesPipe: GetSkillSummariesPipe
  ) {
    super(
      http,
      getSkillSummariesPipe,
      filterSkillExpertiseUniquePipe,
      filterEducationByTypePipe,
      getProjectIndustriesUniquePipe,
      getAssociationsPipe,
      filterSkillByExpertisePipe,
      getProjectsPipe
    );
  }

  public async open(resume: Resume) {
    await this.executeOperation(resume, "open");
  }

  public async print(resume: Resume) {
    await this.executeOperation(resume, "print");
  }

  public async download(resume: Resume, originalResume?: Resume) {
    await this.executeOperation(resume, "download", originalResume);
  }

  public async getUrl(resume: Resume): Promise<string> {
    this.resume = resume;

    const documentDefinition = await this.getDocumentDefinition();

    return this.getUrlAsync(documentDefinition);
  }

  public async getBase64(resume: Resume): Promise<string> {
    this.resume = resume;

    const documentDefinition = await this.getDocumentDefinition();

    return this.getBase64Async(documentDefinition);
  }

  public async getBase64Compare(
    resume: Resume,
    originalResume: Resume
  ): Promise<string> {
    this.resume = resume;
    this._originalResume = originalResume;
    this.isPronounDiff =
      JSON.stringify(this.resume.user.preferredPronouns) !==
      JSON.stringify(this._originalResume.user.preferredPronouns) &&
      this.resume.statusType.name != ResumeStatusTypes.APPROVED;
    this.isAccreditationDiff =
      JSON.stringify(this.resume.accreditation) !==
      JSON.stringify(this._originalResume.accreditation) &&
      this.resume.statusType.name != ResumeStatusTypes.APPROVED;
    this.isAssociationsDiff =
      JSON.stringify(this.resume.associations) !==
      JSON.stringify(this._originalResume.associations) &&
      this.resume.statusType.name != ResumeStatusTypes.APPROVED;

    const documentDefinition = await this.getDocumentDefinition();

    return this.getBase64Async(documentDefinition);
  }

  private async executeOperation(resume: Resume, action = "open", originalResume?: Resume) {
    this.resume = resume;
    if (!originalResume) {
      this._originalResume = resume;
    }
    else {
      this._originalResume = originalResume;
    }
    
    const documentDefinition = await this.getDocumentDefinition();

    switch (action) {
      case "open":
        pdfMake.createPdf(documentDefinition).open();
        break;
      case "print":
        pdfMake.createPdf(documentDefinition).print();
        break;
      case "download":
        pdfMake.createPdf(documentDefinition).download(this.getFileName("pdf"));
        break;
      default:
        pdfMake.createPdf(documentDefinition).open();
        break;
    }
  }

  private async getDocumentDefinition() {
    return await this.generateDocument();
  }

  private async generateDocument() {
    const images = await this.getAllImages();

    return this.buildDocumentObject(
      images.taglineLogoBase64,
      images.wordmarkLogoBase64
    );
  }

  private buildDocumentObject(
    taglineLogoBase64: string,
    wordmarkLogoBase64: string
  ) {
    const headerContent = this.getHeaderContent();
    const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

    return {
      pageSize: "LETTER",
      pageMargins: [77, 70, 33, 85],
      content: [
        {
          text:[
            { text: this.getName(), style: "name" },
             " ",
            { text: (!this.resume.useOriginal && (this.resume.statusType.name === ResumeStatusTypes.APPROVED || this.resume.statusType.name === ResumeStatusTypes.PENDING_CM_REVIEW || this.resume.statusType.name === ResumeStatusTypes.PENDING_RMT_REVIEW)) 
                ? this._originalResume.user.preferredPronouns !== null ? "(" + this._originalResume.user.preferredPronouns + ")" : ""
                 : this.getPronouns(),
              style: "pronouns"
            }
          ]
        },
        {
          text: this.getAccreditation(),
          style: "accreditation",
        },
        this.buildProfileAndSkillSummary(),
        this.createHeading1Paragraph(
          (this.resume.isProfessionalResume ? "PROFESSIONAL" : "TECHNICAL") +
          " EXPERTISE"
        ),         
        
        this.resume.resumeExpertiseGroups?.length > 0 ?
          this.buildResumeExpertizeGroupsProfessionalExpertise() :
          this.buildProfessionalExpertise(),

        this.buildEducationAndIndustryExperience(),

        format.test(this.resume.user.userId)
          ? this.createHeading1Paragraph("WORK EXPERIENCE")
          : this.createHeading1Paragraph("PROJECT EXPERIENCE"),
        this.buildProjectExperience(),

        this.buildAssociations(),
      ],
      header: (currentPage: number) =>
        currentPage === 1
          ? this.buildFirstHeader(wordmarkLogoBase64)
          : this.buildDefaultHeader(headerContent),
      footer: (currentPage: number) =>
        this.buildFooter(currentPage, taglineLogoBase64),

      info: {
        title: this.getFileName("pdf"),
        author: "Online Business Systems",
        subject: "RESUME",
        keywords: "RESUME, ONLINE RESUME",
      },
      defaultStyle: {
        font: "Trebuchet",
      },
      styles: this.createStyles(),
      pageBreakBefore: function (
        currentNode,
        followingNodesOnPage,
        nodesOnNextPage,
        previousNodesOnPage
      ) {
        let pageBreakFlag = false;
        if (
          currentNode.text === "WORK EXPERIENCE" ||
          currentNode.text === "PROJECT EXPERIENCE" ||
          currentNode.text === "ASSOCIATIONS"
        ) {
          if (currentNode.startPosition.top > 600) {
            pageBreakFlag = true;
          }
        }
        return pageBreakFlag;
      },
    };
  }

  private createStyles() {
    const color = "#000";
    const colorDifference = "#059033";

    return {
      title: {
        color: color,
        fontSize: 13,
        bold: true,
        margin: [0, 5, 0, 10],
      },
      "title-2": {
        color: color,
        fontSize: 11,
        bold: true,
        margin: [0, 0, 0, 10],
      },
      "title-mb-3": {
        color: color,
        fontSize: 13,
        bold: true,
        margin: [0, 20, 0, 5],
      },
      "title-2-mb-3": {
        color: color,
        fontSize: 11,
        bold: true,
        margin: [0, 20, 0, 5],
      },
      subtitle: {
        color: color,
        fontSize: 11,
        bold: true,
        margin: [0, 3, 0, 5],
      },
      subtitleDifference: {
        color: colorDifference,
        fontSize: 11,
        bold: true,
        margin: [0, 3, 0, 5],
      },
      subtitleassociations: {
        color: this.isAssociationsDiff ? colorDifference : color,
        fontSize: 11,
        bold: true,
        margin: [0, 3, 0, 5],
      },
      "subtitle-mt-5": {
        color: color,
        fontSize: 11,
        bold: true,
        margin: [0, 10, 0, 10],
      },
      "subtitle-mt-5-Diff": {
        color: colorDifference,
        fontSize: 11,
        bold: true,
        margin: [0, 10, 0, 10],
      },
      "subtitle-mt-4-mb-2": {
        color: color,
        fontSize: 11,
        bold: true,
        margin: [0, 5, 0, 3],
      },
      "subtitle-2": {
        color: color,
        fontSize: 10,
        bold: true,
        margin: [0, 0, 0, 2],
      },
      "subtitle-2-mt-4-mb-2": {
        color: color,
        fontSize: 10,
        bold: true,
        margin: [0, 5, 0, 3],
      },
      "subtitle-2-mb-1": {
        color: color,
        fontSize: 10,
        bold: true,
        margin: [0, 0, 0, 2],
      },
      "subtitle-2-mb-1-Diff": {
        color: colorDifference,
        fontSize: 10,
        bold: true,
        margin: [0, 0, 0, 2],
      },
      bullet: {
        color: color,
        fontSize: 9,
        margin: [0, 0, 0, 3],
      },
      bulletDifference: {
        color: colorDifference,
        fontSize: 9,
        margin: [0, 0, 0, 3],
      },
      bulletItem: {
        color: color,
        fontSize: 9,
        margin: [0, 0, 0, 0],
      },
      bulletItemDifference: {
        color: colorDifference,
        fontSize: 9,
        margin: [0, 0, 0, 0],
      },
      bulletprofexpertise: {
        color:
          this.isProfessionalExpertiseDiff &&
            this.resume.statusType.name != ResumeStatusTypes.APPROVED
            ? colorDifference
            : color,
        fontSize: 9,
        margin: [0, 0, 0, 3],
      },
      "bullet-no-margin": {
        color: color,
        fontSize: 9,
        margin: [0, 0, 0, 0],
      },
      name: {
        color: color,
        fontSize: 16,
        bold: true,
        alignment: "right",
        margin: [0, 30, 0, 0],
      },
      pronouns: {
        color:
          this.isPronounDiff &&
            this.resume.statusType.name != ResumeStatusTypes.APPROVED
            ? colorDifference
            : color,
        fontSize: 16,
        bold: true,
        alignment: "right",
        margin: [0, 30, 0, 0],
      },
      accreditation: {
        color:
          this.isAccreditationDiff &&
            this.resume.statusType.name != ResumeStatusTypes.APPROVED
            ? colorDifference
            : color,
        fontSize: 13,
        alignment: "right",
        margin: [0, 3, 0, 0],
      },
      profile: {
        color: "#059033",
      },
      body: {
        color: color,
        fontSize: 9,
        margin: [0, 0, 0, 10],
      },
      bodyDifference: {
        color: colorDifference,
        fontSize: 9,
        margin: [0, 0, 0, 10],
      },
      "body-italic-profile": {
        color: color,
        fontSize: 9,
        italics: true,
        margin: [0, 0, 0, 5],
      },
      "body-italic-difference": {
        color: colorDifference,
        fontSize: 9,
        italics: true,
        margin: [0, 0, 0, 5],
      },
      "body-italic-size-20": {
        color: color,
        fontSize: 10,
        italics: true,
        margin: [0, 0, 0, 4],
      },
      "body-italic-size-20-Diff": {
        color: colorDifference,
        fontSize: 10,
        italics: true,
        margin: [0, 0, 0, 4],
      },
      "body-size-18": {
        color: color,
        fontSize: 9,
        margin: [0, 0, 0, 4],
      },
      "body-size-18-Diff": {
        color: colorDifference,
        fontSize: 9,
        margin: [0, 0, 0, 4],
      },
      "page-header": {
        color: color,
        fontSize: 11,
        italics: true,
        margin: [0, 20, 0, 10],
      },
      "page-footer": {
        color: color,
        fontSize: 8,
        italics: true,
      },
    };
  }

  private buildFirstHeader(wordmarkLogoBase64: string) {
    return {
      image: wordmarkLogoBase64,
      width: 125,
      alignment: "left",
      opacity: 0.5,
      margin: [75, 35, 0, 0],
    };
  }

  private buildDefaultHeader(headerContent: string) {
    return {
      margin: [0, 0, 40, 0],
      columns: [
        {
          alignment: "right",
          text: headerContent,
          opacity: 0.6,
          style: "page-header",
        },
      ],
    };
  }

  private buildFooter(currentPage: number, taglineLogoBase64: string) {
    return {
      columns: [
        {
          alignment: "left",
          text: "www.obsglobal.com",
          style: "page-footer",
          margin: [80, 35, 0, 0],
        },
        {
          alignment: "center",
          text: "Page " + currentPage,
          style: "page-footer",
          margin: [0, 35, 150, 0],
        },
        {
          alignment: "right",
          width: 120,
          image: taglineLogoBase64,
          opacity: 0.5,
          margin: [0, 35, 20, 0],
        },
      ],
    };
  }

  private buildProfileAndSkillSummary() {
    return {
      margin: [0, 15, 0, 10],
      columns: [
        {
          width: "27%",
          stack: [
            this.createHeading1Paragraph("PROFILE"),
            ...this.buildProfileSummary(),
          ],
          style: "profile",
        },
        {
          width: "auto",
          stack: [
            this.createHeading1Paragraph("SKILL SUMMARY"),
            ...this.buildSkillSummary(),
          ],
        },
      ],
      columnGap: 25,
    };
  }

  private buildProfileSummary() {
    const profileSummary = this.getProfileSummary();
    const profileSummaryOriginalResume = this.getProfileSummaryOriginalResume(
      this._originalResume ? this._originalResume : this.resume
    );
    if (profileSummary === profileSummaryOriginalResume
      || this.resume.statusType.name == ResumeStatusTypes.APPROVED) {
      return this.toMultipleLines(profileSummary).map((line) =>
        this.createBodyItalicParagraph(line)
      );
    } else {
      return this.toMultipleLines(profileSummary).map((line) =>
        this.createBodyItalicParagraphDifference(line)
      );
    }
  }

  private buildSkillSummary() {
    var skillSummaries = this.getSkillSummaries();
    var skillSummariesOrigResume = this.getSkillSummariesOriginalResume(
      this._originalResume ? this._originalResume : this.resume
    );

    return skillSummaries
      .map((skillSummary, i) => {
        let arr = [];
        if (
          skillSummariesOrigResume.find((ss) => ss.id === skillSummary.id && ss.name === skillSummary.name && ss.summary === skillSummary.summary) ||
          this.resume.statusType.name == ResumeStatusTypes.APPROVED
        ) {
          arr.push(
            i === 0
              ? {
                ...this.createSubHeadingParagraph(
                  skillSummary.name
                ),
                margin: [0, 0, 0, 5],
              }
              : this.createSubHeadingParagraph(
                skillSummary.name
              )
          );
          if (
            (skillSummariesOrigResume.find((ss) => ss.id === skillSummary.id) != null &&
              skillSummariesOrigResume.find((ss) => ss.id === skillSummary.id)
                .name === skillSummary.name) ||
            this.resume.statusType.name == ResumeStatusTypes.APPROVED
          ) {
            arr = [
              ...arr,
              ...this.toMultipleLines(
                skillSummary.summary
              ).map((line) => {
                return this.createBodyParagraph(line);
              }),
            ];
          } else {
            arr = [
              ...arr,
              ...this.toMultipleLines(
                skillSummary.summary
              ).map((line) => {
                return this.createBodyParagraphDifference(line);
              }),
            ];
          }
        } else {
          arr.push(
            i === 0
              ? {
                ...this.createSubHeadingParagraphDifference(
                  skillSummary.name
                ),
                margin: [0, 0, 0, 5],
              }
              : this.createSubHeadingParagraphDifference(
                skillSummary.name
              )
          );

          arr = [
            ...arr,
            ...this.toMultipleLines(
              skillSummary.summary
            ).map((line) => {
              return this.createBodyParagraphDifference(line);
            }),
          ];
        }

        return arr;
      })
      .reduce((prev, curr) => prev.concat(curr), []);
  }

  private buildProfessionalExpertise() {
    const uniqueExpertiseTypes = this.getUniqueExpertiseTypes();
    const resume = this._originalResume ? this._originalResume : this.resume;
    return {
      columnGap: 10,
      columns: [
        ...uniqueExpertiseTypes
          .map((expertise) => {
            const arr = [];
            arr.push({
              width: "25%",
              stack: [
                this.createSubHeadingParagraph(expertise),
                this.getSkillsByExpertiseType(expertise)
                  .map((skill) => {
                    const arr: any[] = []
                    JSON.stringify(resume.skills).includes(JSON.stringify(skill))
                      ? arr.push(this.createBulletParagraph([skill.skill.name]))
                      : arr.push(this.createBulletParagraphDifference([skill.skill.name]))
                    return arr;
                  })
              ],
            });

            return arr;
          })
          .reduce((prev, curr) => prev.concat(curr), []),
      ],
    };
  }

  private buildResumeExpertizeGroupsProfessionalExpertise() {
    const resume = this._originalResume.resumeExpertiseGroups?.length > 0 ? this._originalResume : this.resume;
    resume.resumeExpertiseGroups.sort((a, b) => a.expertiseSortOrder - b.expertiseSortOrder);
    var uniqueREGExpertiseTypes = [...new Set(this.resume.resumeExpertiseGroups
                                    .map(expertiseGroup => expertiseGroup.expertiseType.id))];

    return {
      columnGap: 10,
      columns: [
        ...uniqueREGExpertiseTypes
          .map((expertiseId) => {
            const arr = [];
            let resumeExpertiseGroups = this.resume.resumeExpertiseGroups.filter((reg) => reg.expertiseType?.id === expertiseId)
                          .sort((a, b) => a.sortOrder - b.sortOrder);
            arr.push({
              width: "25%",
              stack: [
                resume.resumeExpertiseGroups
                  .find((item) => item.expertiseTypeId === resumeExpertiseGroups[0].expertiseTypeId && 
                                  item.expertiseSortOrder === resumeExpertiseGroups[0].expertiseSortOrder)
                  ? this.createSubHeadingParagraph(resumeExpertiseGroups[0]?.expertiseType?.name)
                  : this.createSubHeadingParagraphDifference(resumeExpertiseGroups[0]?.expertiseType?.name),
                resumeExpertiseGroups
                  .filter((item) => item.expertiseTypeId === resumeExpertiseGroups[0].expertiseTypeId && item.expertiseSortOrder === resumeExpertiseGroups[0].expertiseSortOrder)
                  .map((item) => {
                    const arr: any[] = []
                    JSON.stringify(resume.resumeExpertiseGroups).includes(JSON.stringify(item))
                      ? arr.push(this.createBulletParagraph([item.skill?.skill?.name]))
                      : arr.push(this.createBulletParagraphDifference([item.skill?.skill?.name]))
                    return arr;
                  })
              ],
            });

            return arr;
          })
          .reduce((prev, curr) => prev.concat(curr), []),
      ],
    };
  }

  private buildEducationAndIndustryExperience(): any {
    return {
      columnGap: 20,
      columns: [
        {
          width: "50%",
          stack: [
            this.createHeadingMB3Paragraph("EDUCATION"),
            ...this.buildEducation(),
          ],
        },
        {
          width: "auto",
          stack: [
            this.createHeadingMB3Paragraph("INDUSTRY EXPERIENCE"),
            this.buildIndustryExperience(),
          ],
        },
      ],
    };
  }

  private buildEducation() {
    let arr = [];
    var originalResumeEducations = this.getEducationsByTypeOrigResume(
      "Degree",
      this._originalResume ? this._originalResume : this.resume
    );
    arr = [
      ...arr,
      ...this.getEducationsByType("Degree")
        .map((rEducation) => {
          let arr = [];
          let institutionCity =
            rEducation.institutionCity === null ||
              rEducation.institutionCity === ""
              ? ""
              : " - " + rEducation.institutionCity;
          let degreeName = [rEducation.education.name] + institutionCity;
          if (
            originalResumeEducations.find(
              (oe) => oe.education.id === rEducation.education.id
            ) ||
            this.resume.statusType.name == ResumeStatusTypes.APPROVED
          ) {
            arr.push(this.createBulletParagraph([degreeName]));
          } else {
            arr.push(this.createBulletParagraphDifference([degreeName]));
          }
          return arr;
        })
        .reduce((prev, curr) => prev.concat(curr), []),
    ];

    /*const relevantCourses = this.getEducationsByType("Certification/Course");
    if (relevantCourses.length > 0) {
      arr = [
        ...arr,
        this.createSubtitleMT4MB2Paragraph("Relevant Courses"),
        this.createBulletParagraphEducation(
          relevantCourses.map((education) => education.education.name)
        ),
      ];
    }*/

    var relevantCourses = this.getEducationsByType("Certification/Course");
    var originalRelevantCourses = this.getEducationsByTypeOrigResume(
      "Certification/Course",
      this._originalResume ? this._originalResume : this.resume
    );
    if (relevantCourses.length > 0) {
      arr = [
        ...arr,
        this.createSubtitleMT4MB2Paragraph("Relevant Courses/Certifications"),
        relevantCourses
          .map((rCourse) => {
            let arr = [];
            if (
              originalRelevantCourses.find(
                (oe) => oe.education.id === rCourse.education.id
              ) ||
              this.resume.statusType.name == ResumeStatusTypes.APPROVED
            ) {
              arr.push(this.createBulletParagraph([rCourse.education.name]));
            } else {
              arr.push(
                this.createBulletParagraphDifference([rCourse.education.name])
              );
            }
            return arr;
          })
          .reduce((prev, curr) => prev.concat(curr), []),
      ];
    }

    return arr;
  }

  private buildIndustryExperience() {
    let arr = [];
    var resumeIndustry = this.getIndustryExperience();
    var originalResumeIndustry = this.getIndustryExperienceOrigResume(
      this._originalResume ? this._originalResume : this.resume
    );
    arr = [
      ...arr,
      ...resumeIndustry
        .map((rIndustry) => {
          let arr = [];
          if (
            originalResumeIndustry.find((ri) => ri === rIndustry) ||
            this.resume.statusType.name == ResumeStatusTypes.APPROVED
          ) {
            arr.push(this.createBulletParagraph([rIndustry]));
          } else {
            arr.push(this.createBulletParagraphDifference([rIndustry]));
          }
          return arr;
        })
        .reduce((prev, curr) => prev.concat(curr), []),
    ];

    return arr;
  }

  private buildProjectExperience() {
    const resumeProjects = this.getProjects();
    const originalResumeProjects = this.getProjectsOriginalResume(
      this._originalResume ? this._originalResume : this.resume
    );
    return {
      stack: [
        ...resumeProjects
          .map((projectGroup, i) => {
            let arr = [];
            const isGroupedProject = projectGroup.projects.length > 1;
            if (!isGroupedProject) {
              const originalProject = this.getOriginalProject(
                originalResumeProjects,
                projectGroup.projects[0].id
              );
              this.buildSingleProject(
                projectGroup.projects[0],
                originalProject,
                arr,
                i
              );
            } else {
              this.buildGroupProject(
                projectGroup,
                originalResumeProjects,
                arr,
                i
              );
            }
            return arr;
          })
          .reduce((prev, curr) => prev.concat(curr), []),
      ],
    };
  }

  private getOriginalProject(
    originalResumeProjects: RProjectGroup[],
    projectId: number
  ): RProject {
    return originalResumeProjects
      .map((x) => x.projects)
      .reduce((prev, curr) => prev.concat(curr))
      .find((x) => x.id === projectId);
  }

  private buildSingleProject(
    project: RProject,
    originalProject: RProject,
    arr: any[],
    index: number
  ) {
    this.buildProjectJobTitle(arr, false, project, originalProject, index);
    if (!this.resume.isClientHidden) {
      this.buildProjectCompany(arr, false, project, originalProject);
    }
    this.buildProjectDateInterval(arr, false, project, originalProject);
    this.buildProjectDescription(arr, false, project, originalProject);
    this.buildProjectResponsibilities(arr, false, project, originalProject);
    arr.push(this.createSubHeadingParagraph(""));
  }

  private buildGroupProject(
    projectGroup: RProjectGroup,
    originalResumeProjects: RProjectGroup[],
    arr: any[],
    index: number
  ) {
    if (this.resume.isClientHidden) {
      projectGroup.projects.forEach((project) => {
        this.buildSingleProject(
          project,
          this.getOriginalProject(originalResumeProjects, project.id),
          arr,
          index
        );
      });
    } else {
      this.buildProjectCompany(
        arr,
        true,
        projectGroup.projects[0],
        projectGroup.projects[0]
      );
      const proj = {
        ...projectGroup.projects[0],
        startDate: projectGroup.startDate,
        endDate: projectGroup.endDate,
      };

      this.buildProjectDateInterval(arr, false, proj, proj);
      arr.push(this.createSubHeadingParagraph(""));

      projectGroup.projects.forEach((project) => {
        const originalProject = this.getOriginalProject(
          originalResumeProjects,
          project.id
        );

        this.buildProjectJobTitle(arr, true, project, originalProject, index);
        this.buildProjectDateInterval(arr, true, project, originalProject);
        this.buildProjectDescription(arr, true, project, originalProject);
        this.buildProjectResponsibilities(arr, true, project, originalProject);
        arr.push(this.createSubHeadingParagraph(""));
      });
    }
  }

  private buildProjectJobTitle(
    arr: any[],
    isGroupedProject: boolean,
    project: RProject,
    originalProject: RProject,
    index: number
  ): void {
    if (
      originalProject ||
      this.resume.statusType.name == ResumeStatusTypes.APPROVED
    ) {
      let jobTitleStr =
        project.jobTitles
          ?.map((jobTitle) => jobTitle.jobTitleObj.name)
          .join(", ") || project.jobTitleObj.name;
      let originalJobTitleStr =
        originalProject?.jobTitles
          ?.map((jobTitle) => jobTitle.jobTitleObj.name)
          .join(", ") || originalProject?.jobTitleObj.name;

      let projectName = project.projectName != null ? project.projectName + ' - ' : ''
      if (isGroupedProject) {

        if (jobTitleStr != originalJobTitleStr
          && this.resume.statusType.name != ResumeStatusTypes.APPROVED)
          arr.push(
            this.createSubHeading2MB1ParagraphDifference(
              projectName + jobTitleStr,
              true
            )
          );
        else
          arr.push(
            this.createSubHeading2MB1Paragraph(
              projectName + jobTitleStr,
              true
            )
          );
      } else {
        if (jobTitleStr != originalJobTitleStr)
          arr.push(
            this.createSubHeadingParagraphDifference(
                projectName + jobTitleStr
            )
          );
        else
          arr.push(
              this.createSubHeadingParagraph(projectName + jobTitleStr)
          );
      }
    } else {
      let projectName = project.projectName != null ? project.projectName + ' - ' : '';
      if (index > 0) {
        arr.push(
          this.createSubHeadingMT5ParagraphDifference(
            projectName +
            (
              project.jobTitles
                ?.map((jobTitle) => jobTitle.jobTitleObj.name)
                .join(",") || project.jobTitleObj.name
            ),
            isGroupedProject
          )
        );
      } else {
        arr.push(
          this.createSubHeadingParagraphDifference(
            projectName +
            (
              project.jobTitles
                ?.map((jobTitle) => jobTitle.jobTitleObj.name)
                .join(",") || project.jobTitleObj.name
            ),
            isGroupedProject
          )
        );
      }
    }
  }

  private buildProjectCompany(
    arr: any[],
    isGroupedProject: boolean,
    project: RProject,
    originalProject: RProject
  ) {
    if (!isGroupedProject) {
      if (
        (originalProject && project.company.name == originalProject.company.name) ||
        this.resume.statusType.name == ResumeStatusTypes.APPROVED
      ) {
        arr.push(
          this.createSubHeading2MB1Paragraph(
            project.company.name
          )
        );
      } else {
        arr.push(
          this.createSubHeading2MB1ParagraphDifference(
            project.company.name
          )
        );
      }
    } else {
      if (
        (originalProject && project.company.name == originalProject.company.name) ||
        this.resume.statusType.name == ResumeStatusTypes.APPROVED
      ) {
        arr.push(
          this.createSubHeadingParagraph(
            project.company.name
          )
        );
      } else {
        arr.push(
          this.createSubHeadingParagraphDifference(
            project.company.name
          )
        );
      }
    }
  }

  private buildProjectDateInterval(
    arr: any[],
    isGroupedProject: boolean,
    project: RProject,
    originalProject: RProject
  ): void {
    if (
      (originalProject && project.startDate == originalProject.startDate &&
        project.endDate == originalProject.endDate) ||
      this.resume.statusType.name == ResumeStatusTypes.APPROVED
    ) {
      arr.push(
        this.createBodyMB10Paragraph(
          this.getProjectExperienceDateInterval(
            project.startDate,
            project.endDate
          ),
          isGroupedProject
        )
      );
    } else {
      arr.push(
        this.createBodyMB10ParagraphDifference(
          this.getProjectExperienceDateInterval(
            project.startDate,
            project.endDate
          ),
          isGroupedProject
        )
      );
    }
  }

  private buildProjectDescription(
    arr: any[],
    isGroupedProject: boolean,
    project: RProject,
    originalProject: RProject
  ): void {
    if (
      (originalProject && project.projectDescription == originalProject.projectDescription) ||
      this.resume.statusType.name == ResumeStatusTypes.APPROVED
    ) {
      arr.push(
        ...this.toMultipleLines(
          project.projectDescription
        ).map((line) =>
          this.createBodyItalicSize20Paragraph(line, isGroupedProject)
        )
      );
    } else {
      arr.push(
        ...this.toMultipleLines(
          project.projectDescription
        ).map((line) =>
          this.createBodyItalicSize20ParagraphDifference(line, isGroupedProject)
        )
      );
    }
  }

  private buildProjectResponsibilities(
    arr: any[],
    isGroupedProject: boolean,
    project: RProject,
    originalProject: RProject
  ): void {
    if (project.responsibilities.length > 0) {
      arr.push(
        this.createBodySize18Paragraph(
          this.getProjectResponsibilitiesIncludedStatement(),
          isGroupedProject
        )
      );
      let arrResp = [];

      if (project.responsibilities != null) {
        project.responsibilities.forEach((projectReponsibility) => {
          if (originalProject?.responsibilities?.find((x) => x.id === projectReponsibility.id)
            || this.resume.statusType.name == ResumeStatusTypes.APPROVED) {
            let originalResponsibility = originalProject.responsibilities.find((x) => x.id === projectReponsibility.id)
            if (originalResponsibility?.responsibility === projectReponsibility.responsibility
              || this.resume.statusType.name == ResumeStatusTypes.APPROVED) {
              arrResp.push(
                this.createBulletParagraphByItem(
                  projectReponsibility.responsibility
                  , isGroupedProject
                )
              );
            } else {
              arrResp.push(
                this.createBulletParagraphDifferenceByItem(
                  projectReponsibility.responsibility
                  , isGroupedProject
                ));
            }
          }
          else {
            arrResp.push(
              this.createBulletParagraphDifferenceByItem(
                projectReponsibility.responsibility
                , isGroupedProject
              ));
          }

        });

        arr.push(arrResp);
      }
    }
  }

  private buildAssociations() {
    let arr = [];
    if (this.resume.associations.length > 0) {
      arr.push(this.createHeading1Paragraph("ASSOCIATIONS"));
      var originalAssociations = this.getOriginalResumeAssociations(
        this._originalResume ? this._originalResume : this.resume
      );
      arr = [
        ...arr,
        ...this.getAssociations()
          .map((association) => {
            let arr = [];

            if (
              originalAssociations.find(
                (a) => a.assocId === association.assocId
              ) ||
              this.resume.statusType.name == ResumeStatusTypes.APPROVED
            ) {
              arr.push(
                this.createSubHeadingParagraph(
                  association.assoc.name
                )
              );
            } else {
              arr.push(
                this.createSubHeadingParagraphDifference(
                  association.assoc.name
                )
              );
            }

            return arr;
          })
          .reduce((prev, curr) => prev.concat(curr), []),
      ];
    }

    return {
      stack: [arr],
    };
  }

  private getUrlAsync(documentDefinition: any): Promise<string> {
    return new Promise((resolve) => {
      pdfMake.createPdf(documentDefinition).getDataUrl((url: string) => {
        resolve(url);
      });
    });
  }

  private getBase64Async(documentDefinition: any): Promise<string> {
    return new Promise((resolve) => {
      pdfMake.createPdf(documentDefinition).getBase64((data: string) => {
        resolve(data);
      });
    });
  }

  private createSubHeading2Paragraph(text: string): any {
    return {
      text: text,
      style: "subtitle-2",
    };
  }

  private createBodyParagraph(text: string): any {
    return {
      text: text,
      style: "body",
    };
  }

  private createBodyParagraphDifference(text: string): any {
    return {
      text: text,
      style: "bodyDifference",
    };
  }

  private createSubHeadingParagraphDifference(
    text: string,
    isIndented?: boolean
  ): any {
    return {
      text: text,
      style: "subtitleDifference",
      margin: isIndented ? [15, 0, 0, 0] : [0, 0, 0, 0],
    };
  }

  private createSubHeadingParagraphJobTitle(text: string): any {
    return {
      text: text,
      style: "subtitlejobtitle",
    };
  }

  private createSubHeadingParagraph(text: string): any {
    return {
      text: text,
      style: "subtitle",
    };
  }

  private createBodyItalicParagraph(text: string): any {
    return {
      text: text,
      style: "body-italic-profile",
      margin: [0, 0, 0, 0],
    };
  }

  private createBodyItalicParagraphDifference(text: string): any {
    return {
      text: text,
      style: "body-italic-difference",
      margin: [0, 0, 0, 0],
    };
  }

  private createBulletParagraph(items: string[], isIndented?: boolean): any {
    return {
      style: "bullet",
      margin: isIndented ? [15, 0, 0, 0] : [0, 0, 0, 0],
      ul: [
        ...items
          .map((item) => {
            const arr = [];
            arr.push({
              text: item,
              style: "bullet",
            });
            return arr;
          })
          .reduce((prev, curr) => prev.concat(curr), []),
      ],
    };
  }

  private createBulletParagraphDifference(
    items: string[],
    isIndented?: boolean
  ): any {
    return {
      style: "bulletDifference",
      margin: isIndented ? [15, 0, 0, 0] : [0, 0, 0, 0],
      ul: [
        ...items
          .map((item) => {
            const arr = [];
            arr.push({
              text: item,
              style: "bulletDifference",
            });
            return arr;
          })
          .reduce((prev, curr) => prev.concat(curr), []),
      ],
    };
  }

  private createBulletParagraphProfExpertise(items: string[]): any {
    return {
      style: "bulletprofexpertise",
      ul: [
        ...items
          .map((item) => {
            const arr = [];
            arr.push({
              text: item,
              style: "bulletprofexpertise",
            });
            return arr;
          })
          .reduce((prev, curr) => prev.concat(curr), []),
      ],
    };
  }

  private createHeading2Paragraph(text: string): any {
    return {
      text: text,
      style: "title-2",
    };
  }

  private createHeading1Paragraph(text: string): any {
    return {
      text: text,
      style: "title",
    };
  }

  private createHeading2MB3Paragraph(text: string): any {
    return {
      text: text,
      style: "title-2-mb-3",
    };
  }
  private createHeadingMB3Paragraph(text: string): any {
    return {
      text: text,
      style: "title-mb-3",
    };
  }
  private createSubtitle2MT4MB2Paragraph(text: string): any {
    return {
      text: text,
      style: "subtitle-2-mt-4-mb-2",
    };
  }
  private createSubtitleMT4MB2Paragraph(text: string): any {
    return {
      text: text,
      style: "subtitle-mt-4-mb-2",
    };
  }

  private createSubHeadingMT5Paragraph(text: string): any {
    return {
      text: text,
      style: "subtitle-mt-5",
    };
  }

  private createSubHeadingMT5ParagraphDifference(
    text: string,
    isIndented?: boolean
  ): any {
    return {
      text: text,
      style: "subtitle-mt-5-Diff",
      margin: isIndented ? [15, 0, 0, 0] : [0, 0, 0, 0],
    };
  }

  private createSubHeading2MB1Paragraph(
    text: string,
    isIndented?: boolean
  ): any {
    return {
      text: text,
      style: "subtitle-2-mb-1",
      margin: isIndented ? [15, 0, 0, 0] : [0, 0, 0, 0],
    };
  }

  private createSubHeading2MB1ParagraphDifference(
    text: string,
    isIndented?: boolean
  ): any {
    return {
      text: text,
      style: "subtitle-2-mb-1-Diff",
      margin: isIndented ? [15, 0, 0, 0] : [0, 0, 0, 0],
    };
  }

  private createBodyMB10Paragraph(text: string, isIndented?: boolean): any {
    return {
      text: text,
      style: "body",
      margin: isIndented ? [15, 0, 0, 5] : [0, 0, 0, 5],
    };
  }

  private createBodyMB10ParagraphDifference(
    text: string,
    isIndented?: boolean
  ): any {
    return {
      text: text,
      style: "bodyDifference",
      margin: isIndented ? [15, 0, 0, 5] : [0, 0, 0, 5],
    };
  }

  private createBodyItalicSize20Paragraph(
    text: string,
    isIndented?: boolean
  ): any {
    return {
      text: text,
      style: "body-italic-size-20",
      margin: isIndented ? [15, 0, 0, 0] : [0, 0, 0, 0],
    };
  }

  private createBodyItalicSize20ParagraphDifference(
    text: string,
    isIndented?: boolean
  ): any {
    return {
      text: text,
      style: "body-italic-size-20-Diff",
      margin: isIndented ? [15, 0, 0, 0] : [0, 0, 0, 0],
    };
  }

  private createBodySize18Paragraph(text: string, isIndented?: boolean): any {
    return {
      text: text,
      style: "body-size-18",
      margin: isIndented ? [15, 0, 0, 0] : [0, 0, 0, 0],
    };
  }

  private createBodySize18ParagraphDifference(
    text: string,
    isIndented?: boolean
  ): any {
    return {
      text: text,
      style: "body-size-18-Diff",
      margin: isIndented ? [15, 0, 0, 0] : [0, 0, 0, 0],
    };
  }

  private createBulletParagraphByItem(item: string, isIndented?: boolean): any {
    return {
      style: "bulletItem",
      margin: isIndented ? [15, 0, 0, 0] : [0, 0, 0, 0],
      ul: [
        {
          text: item.trim(),
          style: "bulletItem",
        }
      ]
    };
  }

  private createBulletParagraphDifferenceByItem(
    item: string,
    isIndented?: boolean,
  ): any {

    return {
      style: "bulletItemDifference",
      margin: isIndented ? [15, 0, 0, 0] : [0, 0, 0, 0],
      ul: [
        {
          text: item.trim(),
          style: "bulletItemDifference",
        }
      ]
    };
  }


}
