<div class="component-container">
  <div class="top-margin panel panel-default">
    <div class="panel-body">
      <div class="row">
        <div class="col-md-12">
          <p class="header text-center">Invalid Status Error</p>
        </div>
      </div>
      <div class="row">&nbsp;</div>
      <div class="row">
        <div class="col-md-12 information">
          <p class="text-center">
            Resume is not in correct status or you don't have access to review
            it.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
