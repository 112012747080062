import { UserService } from "src/app/common/services/user.service";
import { AltOnlinerDefault } from "./../../common/data-models/AltOnlinerDefault";
import { SnackBarService } from "../../common/services/snackbar.service";
import { AlternateOnlinerMaintenanceService } from "../../common/services/alternateOnlinerMaintenance.service";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Input,
  Output,
  AfterViewInit,
} from "@angular/core";

import { fadeInUp400ms } from "src/@vex/animations/fade-in-up.animation";
import { stagger60ms } from "src/@vex/animations/stagger.animation";

import * as _moment from "moment";
import { MatDialog } from "@angular/material/dialog";
import { default as _rollupMoment } from "moment";
import { AuthService } from "src/app/common/services/auth.service";
import {
  ConfirmDialog,
  ConfirmationDialogComponent,
} from "src/app/shared/confirmation-dialog/confirmation-dialog.component";
import { NonOnliner } from "src/app/common/data-models/NonOnliner";
import { DatePipe } from "@angular/common";
import { UntypedFormControl } from "@angular/forms";
import { SidenavComponent } from "src/@vex/layout/sidenav/sidenav.component";
import { NavigationService } from "../../../@vex/services/navigation.service";
import { GlobalConstants } from "src/app/shared/global-constants";
import { AccountingEmployee } from "../../common/data-models/commonDataModels";
import { map, startWith } from "rxjs/operators";

@Component({
  selector: "obs-alternateOnlinerMaintenance-card",
  templateUrl: "./alternateOnlinerMaintenance-card.component.html",
  styleUrls: ["./alternateOnlinerMaintenance-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [stagger60ms, fadeInUp400ms],
})
export class AlternateOnlinerMaintenanceCardComponent
  implements OnInit, AfterViewInit
{
  @Input() nonOnliner: NonOnliner;
  @Input() nonOnlinerCommentsFieldLimit: number;
  @Input() formIsDirty: boolean;
  @Input() altOnlinerDefault: string;
  @Output() refresh: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() saveNewNonOnliner: EventEmitter<any> = new EventEmitter();
  @Output() markDirty: EventEmitter<any> = new EventEmitter();
  @Output() markClean: EventEmitter<any> = new EventEmitter();

  private userId: string;
  public initialModel: NonOnliner;
  public panelOpenState = false;
  public isDirty = false;
  isSubmitting: boolean;
  disableAnimation = true;

  firstName: UntypedFormControl;
  lastName: UntypedFormControl;
  accountingUserId: UntypedFormControl;
  comments: UntypedFormControl;
  altOnliner: AltOnlinerDefault;
  selectedAccountingUserId: AccountingEmployee;
  accountingEmployees: AccountingEmployee[];
  filteredAccountingEmployees: AccountingEmployee[];

  public constructor(
    private alternateOnlinerMaintenanceService: AlternateOnlinerMaintenanceService,
    private userService: UserService,
    private authService: AuthService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private snackBarService: SnackBarService,
    public datePipe: DatePipe,
    private sidenav: SidenavComponent,
    private navigationService: NavigationService
  ) {}

  ngAfterViewInit(): void {
    //  required to prevent the mat-expansion-panel from appearing as expanded during page load
    setTimeout(() => (this.disableAnimation = false));
  }

  ngOnInit(): void {
    this.firstName = new UntypedFormControl();
    this.lastName = new UntypedFormControl();
    this.accountingUserId = new UntypedFormControl();
    this.comments = new UntypedFormControl();

    this.initialModel = new NonOnliner("", "", "", null, "", true, new Date(), false);
    this.initialModel.firstName = this.nonOnliner.firstName;
    this.initialModel.lastName = this.nonOnliner.lastName;
    this.initialModel.accountingUserId = this.nonOnliner.accountingUserId;
    this.initialModel.comments = this.nonOnliner.comments;
    this.initialModel.isActive = this.nonOnliner.isActive;
    this.initialModel.modifiedDate = this.nonOnliner.modifiedDate;

    this.accountingEmployees = this.userService.useAccountingEmployees();
    this.updateFilteredOptions();
    this.altOnliner = new AltOnlinerDefault(
      "",
      this.nonOnliner.userId,
      new Date(),
      false
    );
    
    this.userId = this.authService.getUserId();
    if (this.nonOnliner != null && this.nonOnliner.userId === "") {
      this.panelOpenState = true;
    }
  }

  public updateFilteredOptions(): void {
        // Initialize filtered arrays with all options
      this.filteredAccountingEmployees = this.accountingEmployees;

      this.accountingUserId.valueChanges.pipe(
          startWith(''),
          map(value => this._filterAccountingUserId(value instanceof Object ? '' : value || ''))
      ).subscribe(filteredOptions => {
          this.filteredAccountingEmployees = filteredOptions;
      });
  }

  private _filterAccountingUserId(value: string): AccountingEmployee[] {
        const filterValue = value.toLowerCase();
        return this.accountingEmployees.filter(emp => emp.accountingUserId.toLowerCase().includes(filterValue));
  }

  save(firstname: any, lastname: any, comments: any): void {
    this.isSubmitting = true;
    this.disableFields();
    if (this.accountingUserId.value == "") {
          this.nonOnliner.accountingUserId = null;
    }

    if (this.nonOnliner.userId !== "") {
      this.alternateOnlinerMaintenanceService
        .updateNonOnlinerAsync(this.nonOnliner)
        .subscribe(
          (data) => {
            this.isSubmitting = false;
            this.enableFields();
            this.snackBarService.message("Non Onliner saved successfully!");
            this.saveNewNonOnliner.emit();
            this.refresh.emit();
            this.userService.getAccountingEmployees().subscribe(
                    (accountingEmployees) => {
                        this.accountingEmployees = accountingEmployees;
                    },
                    (error) => {
                        this.snackBarService.message(error);
                    },
            );      
          },
          (error) => {
            this.isSubmitting = false;
            this.enableFields();
            this.snackBarService.error(error);
          }
        );
    } else {
      this.alternateOnlinerMaintenanceService
        .createNewNonOnlinerAsync(this.nonOnliner)
        .subscribe((data) => {
          this.isSubmitting = false;
          this.enableFields();
          this.snackBarService.message("Non Onliner saved successfully!");
          this.saveNewNonOnliner.emit();
          this.refresh.emit();
        });
    }
  }

  enableFields() {
    this.firstName.enable();
    this.lastName.enable();
    this.accountingUserId.enable();
    this.comments.enable();
  }

  disableFields() {
    this.firstName.disable();
    this.lastName.disable();
    this.accountingUserId.disable();
    this.comments.disable();
  }

  markPristine() {
    this.firstName.markAsPristine();
    this.lastName.markAsPristine();
    this.accountingUserId.markAsPristine();
    this.comments.markAsPristine();
  }

  closePanel(firstname: any, lastname: any, comments: any) {
    if (this.nonOnliner.userId === "") {
      this.markClean.emit();
      this.delete.emit();
    }

     this.nonOnliner.firstName = this.initialModel.firstName;
     this.nonOnliner.lastName = this.initialModel.lastName;
     this.nonOnliner.accountingUserId = this.initialModel.accountingUserId;
     this.nonOnliner.comments = this.initialModel.comments;
     this.nonOnliner.isActive = this.initialModel.isActive;
     this.nonOnliner.modifiedDate = this.initialModel.modifiedDate;

    this.markClean.emit();
    this.markPristine();
    this.panelOpenState = false;
    this.cdr.detectChanges();
  }

  cancelButton(firstname: any, lastname: any, comments: any) {
    if (this.nonOnliner.userId === "") {
      this.markClean.emit();
      this.delete.emit();
    } else if (!this.formIsDirty) {
       this.nonOnliner.firstName = this.initialModel.firstName;
       this.nonOnliner.lastName = this.initialModel.lastName;
       this.nonOnliner.accountingUserId = this.initialModel.accountingUserId;
       this.nonOnliner.comments = this.initialModel.comments;
       this.nonOnliner.isActive = this.initialModel.isActive;
       this.nonOnliner.modifiedDate = this.initialModel.modifiedDate;

      this.markPristine();
      this.markClean.emit();
      this.panelOpenState = !this.panelOpenState;
      this.cdr.detectChanges();
    } else {
      this.panelOpenState = !this.panelOpenState;
      this.cdr.detectChanges();
    }
  }

  togglePanel(firstName: any, lastName: any) {
    if (this.formIsDirty) {
      this.showConfirmationDialog();
    } else if (!this.formIsDirty) {
      this.panelOpenState = !this.panelOpenState;
      this.firstName.setValue(this.nonOnliner.firstName);
      this.lastName.setValue(this.nonOnliner.lastName);
      this.accountingUserId.setValue(this.nonOnliner.accountingUserId != null ? this.nonOnliner.firstName + " "
          + this.nonOnliner.lastName + " - " + this.nonOnliner.accountingUserId : "");
      this.comments.setValue(this.nonOnliner.comments);

      if (this.nonOnliner.isOnliner) {
        this.disableFields();
      }

      this.cdr.detectChanges();
    }
    this.accountingEmployees = this.userService.useAccountingEmployees();
    this.updateFilteredOptions();
  }

  onFirstNameChange($event, firstName: any) {
    this.nonOnliner.firstName = $event;
    if (this.firstName.dirty) {
      this.markDirty.emit();
    }
  }

  onLastNameChange($event, lastName: any) {
    this.nonOnliner.lastName = $event;
    if (this.lastName.dirty) {
      this.markDirty.emit();
    }
  }

  onAccountingUserIdChange($event : any): void {
    const selectedOption = $event.option.value;
    this.nonOnliner.accountingUserId = selectedOption.emplId;
    this.accountingUserId.setValue(selectedOption.accountingUserId);
    if (this.accountingUserId.dirty) {
      this.markDirty.emit();
    }
  }

  isEmployeeUsed(empl: any): boolean {
    return empl.isUsed;
  }

  onCommentsChange($event, comments: any) {
    this.nonOnliner.comments = $event;
    if (this.comments.dirty) {
      this.markDirty.emit();
    }
  }

  private showConfirmationDialog() {
    const confirmDialog = new ConfirmDialog();

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "500px",
      data: confirmDialog,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === "ok") {
        this.panelOpenState = !this.panelOpenState;
        this.markClean.emit();
        this.cdr.detectChanges();
      }
      if (result === "cancel") {
      }
    });
  }

  getLastModified(dateModified: Date) {
    return dateModified
      ? "Last Modified on " +
          this.datePipe.transform(
            dateModified,
            GlobalConstants.FULL_DATE_FORMAT
          )
      : "";
  }

  public deleteNonOnliner($event): void {
    const confirmDialog = new ConfirmDialog();
    this.isSubmitting = true;

    confirmDialog.title = "Delete Non Onliner";
    confirmDialog.message =
      "Delete " +
      this.nonOnliner.firstName +
      " " +
      this.nonOnliner.lastName +
      "?";
    confirmDialog.okButtonTitle = "Yes";
    confirmDialog.cancelButtonTitle = "No";

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "500px",
      data: confirmDialog,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === "ok") {
        this.nonOnliner.isActive = false;
        this.alternateOnlinerMaintenanceService
          .updateNonOnlinerAsync(this.nonOnliner)
          .subscribe(
            (data) => {
              this.isSubmitting = false;
              this.userService.getAccountingEmployees().subscribe(
              (accountingEmployees) => {
                  this.accountingEmployees = accountingEmployees;
              },
              (error) => {
                  this.snackBarService.message(error);
              },
              );
              this.snackBarService.message(
                this.nonOnliner.firstName +
                  " " +
                  this.nonOnliner.lastName +
                  " has been deleted successfully!"
              );
              this.refresh.emit();
            },
            (error) => {
              this.isSubmitting = false;
              this.snackBarService.error(error);
            }
          );
      }
      if (result === "cancel") {
        this.isSubmitting = false;
        this.cdr.detectChanges();
      }
    });
  }

  makeDefault() {
    if (this.altOnlinerDefault) {
      this.userService.updateAltOnlinerDefault(this.altOnliner).subscribe(
        (result) => {
          this.navigationService.setAltOnlinerDefault(result);
          this.isSubmitting = false;
          this.snackBarService.message(
            "Default Alternate Onliner set successfully!"
          );
          this.refresh.emit();
        },
        (error) => {
          this.snackBarService.message(error);
        }
      );
    } else {
      this.isSubmitting = true;
      this.userService.postAltOnlinerDefault(this.altOnliner).subscribe(
        (result) => {
          this.navigationService.setAltOnlinerDefault(result);
          this.isSubmitting = false;
          this.snackBarService.message(
            "Default Alternate Onliner set successfully!"
          );
          this.refresh.emit();
        },
        (error) => {
          this.snackBarService.message(error);
        }
      );
    }
  }
}
