<div class="p-6" *ngIf="isLoading">
  <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="24px">
    <mat-spinner></mat-spinner>
  </div>
</div>
<div
  *ngIf="!isLoading && nothingFound"
  fxLayout="column"
  fxLayout.lt-sm="column"
  fxLayoutGap="16px"
  fxLayoutGap.lt-sm="0"
>
  <h4>No Onliners found</h4>
</div>
<section *ngIf="!isLoading && matchingOnlinersDisplayData.data.length > 0">
  <header>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div class="page-title">Matching Onliners ({{ numMatched }})</div>

      <div>
        <i class="fas fa-file-excel" (click)="onExportExcelClicked()"></i>
      </div>
    </div>
  </header>
  <mat-table [dataSource]="matchingOnlinersDisplayData"
             class="mat-elevation-z8 attribute-row">
      <ng-container matColumnDef="Onliner">
          <mat-header-cell *matHeaderCellDef class="onlinerHeader">Onliner</mat-header-cell>
          <mat-cell *matCellDef="let element" class="topLeftAlignCell">{{ element.lastName }}, {{ element.firstName }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="Resumes">
          <mat-header-cell *matHeaderCellDef class="onlinerHeader">Resumes</mat-header-cell>
          <mat-cell *matCellDef="let element">
              <mat-cell *ngFor="let resume of element.resumes" class="topLeftAlignCell">
                      {{ resume.spins.name }}
              </mat-cell>
          </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Status">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
          <mat-cell *matCellDef="let element" class="topLeftAlignCell">
              <mat-cell *ngFor="let resume of element.resumes">
                  <mat-cell>
                      <div [ngClass]="resume.statusType.name != 'Approved' ? 'un-approved' : ''">
                        {{ resume.statusType.name }}
                      </div>
                  </mat-cell>
              </mat-cell>
          </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ApprovalDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Approval Date</mat-header-cell>
          <mat-cell *matCellDef="let element">
              <mat-cell *ngFor="let resume of element.resumes">
                  <div *ngIf="resume.statusType.name == 'Approved'; else naBlock">
                      {{ resume.dateApproved | date: dateFormat }}
                  </div>
                  <ng-template #naBlock>
                      N/A
                  </ng-template>
              </mat-cell>
          </mat-cell>
      </ng-container>

      <ng-container matColumnDef="CM">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Career Mentor</mat-header-cell>
          <mat-cell *matCellDef="let element" class="topLeftAlignCell">
              <mat-cell *ngFor="let resume of element.resumes">
                  <mat-cell>
                      <div>{{ element.careerManager }}</div>
                  </mat-cell>
              </mat-cell>
          </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Attachment">
          <mat-header-cell *matHeaderCellDef class="centerAlignCell">Attachment</mat-header-cell>
          <mat-cell *matCellDef="let element" style="flex-direction: column">
              <mat-cell *ngFor="let resume of element.resumes">
                  <i class="fas fa-file-pdf mr-1"
                     (click)="
              onCreateDocumentClicked(resume.id, DOWNLOADABLE_DOC_TYPE.PDF);
              $event.stopPropagation()
            "></i>
                  <i class="fas fa-file-word"
                     (click)="
              onCreateDocumentClicked(resume.id, DOWNLOADABLE_DOC_TYPE.WORD);
              $event.stopPropagation()
            "></i>
              </mat-cell>
          </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ClientInfo">
          <mat-header-cell class="centerAlignCell" *matHeaderCellDef mat-sort-header>Client Info</mat-header-cell>
          <mat-cell *matCellDef="let element"
                    style="flex-direction: column"
                    class="centerAlignCell">
              <mat-cell *ngFor="let resume of element.resumes">
                  {{
          resume.isClientHidden ? "Hidden" : "Displayed"
                  }}
              </mat-cell>
          </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns" (click)="getOnlinerData(row.userId, $event)"></mat-row>
  </mat-table>
</section>
