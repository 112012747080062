<div class="p-6" *ngIf="isLoadingAll" >
    <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="24px">
      <mat-spinner></mat-spinner>
    </div>
</div>

<div @fadeInUp class="card mb-3" *ngIf="!isLoadingAll">
    <div class="px-6 py-4" [@.disabled]="disableAnimation">
        <form [style.fontSize.px]="18" novalidate>  
            <div fxLayout="row" fxLayoutAlign="space-between center" style="padding-bottom: 8px;" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="0">
                <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
                    <mat-slide-toggle
                    class="slide-toggle"
                    [disabled]="isLoading"
                    (change)="searchDataChange()"
                    [checked]="onlyApprovedResume"
                    [style.fontSize.px]="13"
                    >Search only Approved Resume data
                    </mat-slide-toggle>

                    <mat-slide-toggle
                    class="slide-toggle"
                    [disabled]="isLoading"
                    (change)="startWithChange()"
                    [checked]="startWith"
                    [style.fontSize.px]="13"
                    >Contains Exact Word(s)
                    </mat-slide-toggle>
                </div> 
            </div> 
            <mat-form-field style="width: 100%">
                <mat-label>Wildcard</mat-label>    
                    <input
                    type="text"
                    matInput
                    [formControl]="wildCardSearch"
                    matTooltip="Type any word to search"
                    #wildCardInput>
            </mat-form-field>
            <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-top: -30px;" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="0">
                <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" class="clearfix" >
                    <button
                     class="mat-raised-button mat-button-base mt-3 bg-gray-500 text-contrast-black"
                     mat-raised-button
                     type="button"
                     (click)="resetQuery()"
                    >Reset</button>

                    <button
                     class="mat-raised-button mat-button-base mt-3 bg-green-500 text-contrast-white"
                     mat-raised-button
                     type="button"
                     [disabled]="isLoading || (isDirty)"
                     (click)="searchWildCard()">
                        <span *ngIf="isLoading">
                            <i class="fa fa-spinner fa-spin" *ngIf="isLoading"></i>
                        </span>Search
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>

<div @fadeInUp class="card mb-3" *ngIf="!isLoadingAll">
    <div class="px-6 py-4" [@.disabled]="disableAnimation">
        <mat-expansion-panel
            [expanded] = "searchInputOpenState"
            class="no-pad"
            (closed)="searchInputOpenState = false"
            (opened)="searchInputOpenState = true"
        >
            <mat-expansion-panel-header
            class="p-0"
            >
            <mat-panel-title>
                <p class="cardDetail">
                <span class="font-bold"
                    >Advanced Search
                </span>
                </p>
                
            </mat-panel-title>
            </mat-expansion-panel-header>
            <form [style.fontSize.px]="18" novalidate>
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayout.lt-sm="column" fxLayoutGap="8px" fxLayoutGap.lt-sm="8px">
                    
                    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="8px" fxLayoutGap.lt-sm="8px" class ="cardClass"  fxFlex ="90%"  *ngIf = "!newQuery">
                        <mat-form-field fxFlex ="71%" >
                            <mat-label>Choose Saved Query</mat-label>
                            <input 
                                type="text"
                                matInput 
                                placeholder="Choose Saved Query"
                                aria-label="Select Saved Query" 
                                [matAutocomplete]="autoSavedQuery" 
                                [formControl]="selectSavedQuery"
                                matTooltip="Select saved query"
                                #savedQueryInput>
                            <i class="material-icons" matSuffix>keyboard_arrow_down</i>
                            <mat-autocomplete #autoSavedQuery="matAutocomplete" (optionSelected)="savedQuerySelected($event)">
                                <mat-option *ngFor="let option of filteredSavedQueries | async" [value]="option">
                                {{ option }}
                                </mat-option>
                            </mat-autocomplete>
                            
                        </mat-form-field>   
                        <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" class="newSaveButtons" fxLayoutAlign.lt-sm="center">
                            <button class="mat-raised-button mat-button-base mt-3 bg-green-500 text-contrast-white" mat-raised-button
                                type="button" (click)="onNewButtonClick()">New</button>
                            <button class="mat-raised-button mat-button-base mt-3 bg-red-500 text-contrast-white" mat-raised-button
                                type="button" [disabled]="isSubmitting || !selectSavedQuery.dirty" (click)="delete(selectSavedQuery)">
                                <span *ngIf="isSubmitting"
                                ><i class="fa fa-spinner fa-spin" aria-hidden="true"></i
                                ></span>
                                {{ "delete" | translate }}</button> 
                        </div>        
                    </div> 
                    
                    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="8px" fxLayoutGap.lt-sm="8px" class ="cardClass"  fxFlex ="90%" *ngIf = "newQuery">
                        <mat-form-field fxFlex ="71%" >
                            <mat-label>Query Name</mat-label>
                            <input 
                                type="text"
                                matInput 
                                placeholder="Enter Query Name"
                                aria-label="New Query" 
                                [formControl]="newQueryName"
                                matTooltip="Enter new query name"
                                #queryName
                                [required]="newQuery"
                            />
                            
                        </mat-form-field>   
                        <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" class="newSaveButtons" fxLayoutGap.lt-sm="8px"  fxLayoutAlign.lt-sm="center">
                            <button class="mat-raised-button mat-button-base mt-3 bg-gray-500 text-contrast-black" mat-raised-button
                                type="button" (click)="onNewButtonClick()">Cancel</button>
                            <button
                                class="mat-raised-button mat-button-base mt-3 bg-green-500 text-contrast-white"
                                mat-raised-button
                                type="button"
                                [disabled]="
                                isSubmitting ||
                                (!newQueryName.dirty &&
                                !query.dirty) ||
                                newQueryName.hasError('required') ||
                                query.hasError('required')
                                "
                                (click)="save(newQueryName, query)"
                            >
                                <span *ngIf="isSubmitting"
                                ><i class="fa fa-spinner fa-spin" aria-hidden="true"></i
                                ></span>
                                {{ "save" | translate }}
                            </button>
                        </div>        
                    </div> 
                </div>

                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayout.lt-sm="column" fxLayoutGap="8px" fxLayoutGap.lt-sm="0">
                    
                    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="8px" fxLayoutGap.lt-sm="0" class ="cardClass"  fxFlex ="90%">
                        <mat-form-field fxFlex ="55%" >
                            <mat-label>Choose Preset</mat-label>
                            <input 
                                type="text"
                                matInput 
                                placeholder="Choose Preset"
                                aria-label="Select Preset" 
                                [matAutocomplete]="autoPreset" 
                                [formControl]="presetSearch"
                                matTooltip="Select preset by name"
                                #presetInput>
                            <i class="material-icons" matSuffix>keyboard_arrow_down</i>
                            <mat-autocomplete #autoPreset="matAutocomplete" (optionSelected)="presetSelected($event)">
                                <mat-option *ngFor="let option of filteredPreset | async" [value]="option.name">
                                {{ option.name }}
                                </mat-option>
                            </mat-autocomplete>
                            
                        </mat-form-field>                   
                        <mat-form-field fxFlex = "15%">
                            <mat-label>Operator</mat-label>
                            <input
                                type="text"
                                matInput
                                placeholder="Select operator"
                                aria-label="Select operator"
                                [matAutocomplete]="autoOperator"
                                [formControl]="presetOperator"
                                matTooltip="Select operator"
                                #operatorInput>
                            <i class="material-icons" matSuffix>keyboard_arrow_down</i>
                            <mat-autocomplete #autoOperator="matAutocomplete" (optionSelected)="operatorSelected($event)">
                                <mat-option *ngFor="let option of operators" [value]="option">
                                    {{ option }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>    
                    </div>
                </div>
                
                <mat-form-field style="width: 100%">
                    <mat-label>Query</mat-label>    
                    <textarea 
                        matInput 
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="5"
                        [matAutocomplete]="queryAutoComplete"
                        [formControl]="query"
                        aria-label="Query" 
                        matTooltip="Enter your query"
                        style="overflow:hidden" 
                        maxlength="500" 
                        required
                        (click)="clickAndKeyup($event)"
                        (keyup)="clickAndKeyup($event)"
                        id="queryArea"></textarea>  
                    <mat-autocomplete #queryAutoComplete="matAutocomplete" (optionSelected)="querySelected($event)">
                        <mat-option *ngFor="let option of nameOptions | async" [value]="option">
                            {{ option }}
                        </mat-option>
                    </mat-autocomplete>      
                </mat-form-field>
                <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-top: -30px;">
                    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" class="clearfix" >
                        <button class="mat-raised-button mat-button-base mt-3 bg-gray-500 text-contrast-black" mat-raised-button
                        type="button" (click)="resetQuery()">Reset</button>
                        <button class="mat-raised-button mat-button-base mt-3 bg-green-500 text-contrast-white" mat-raised-button
                        type="button" [disabled]="isLoading || (isDirty)"  (click)="validateAndSearch($event)">
                        <span *ngIf="isLoading"><i class="fa fa-spinner fa-spin" *ngIf="isLoading"></i></span>Search</button>
                        <mat-icon [icIcon]="icInfoCircle"
                                class="mt-3"
                                (click)="onInfoClicked()"
                                size="25px">
                        </mat-icon>
                    </div>
                    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" >
                        <button class="mat-raised-button mat-button-base mt-3 bg-green-500 text-contrast-white" mat-raised-button
                            type="button"
                            [disabled]="isSubmitting || !selectSavedQuery.dirty || !query.dirty || query.hasError('required')"
                            (click)="update(selectSavedQuery,query)">
                            <span *ngIf="isSubmitting"
                                ><i class="fa fa-spinner fa-spin" aria-hidden="true"></i
                            ></span>
                            {{ "Update" | translate }}
                        </button> 
                    </div> 
                </div> 
            </form>
        </mat-expansion-panel>
    </div>
</div>
