import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import * as _moment from "moment";
import { default as _rollupMoment } from "moment";
import { BehaviorSubject } from "rxjs";
import {
  ConfirmationDialogComponent,
  ConfirmDialog,
} from "../../shared/confirmation-dialog/confirmation-dialog.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "obs-review-comments-input",
  templateUrl: "comments-input.component.html",
  styleUrls: ["comments-input.component.scss"],
})
export class ReviewCommentsInput implements OnInit {
  comment: UntypedFormControl;
  @Input() commentsFieldLimit: number;
  @Input() approve: boolean;

  @Output() onSave: EventEmitter<{
    comment: string;
    approve: boolean;
    callback: any;
  }> = new EventEmitter();

  @Output() onCancel: EventEmitter<any> = new EventEmitter<boolean>();

  isSubmitting: boolean;
  isSubmitting$: BehaviorSubject<boolean>;

  constructor(public dialog: MatDialog) {}

  get commentsDisabled() {
    let disabledFlag = false;
    if (this.commentsFieldLimit - this.comment.value?.length < 0) {
      disabledFlag = true;
    } else if (!this.approve && (this.comment.value?.length === 0)) {
      disabledFlag = true;
    } else if (this.isSubmitting) {
      disabledFlag = true;
    }
    return disabledFlag;
  }

  ngOnInit() {
    this.comment = new UntypedFormControl("");
    this.isSubmitting$ = new BehaviorSubject<boolean>(
      (this.isSubmitting = false)
    );
  }

  cancel() {
    this.effectiveCancel();
  }

  effectiveCancel() {
    this.comment.reset();
    this.onCancel.emit();
  }

  save() {
    this.isSubmitting$ = new BehaviorSubject<boolean>(
      (this.isSubmitting = true)
    );
    this.onSave.emit({
      comment: this.comment.value,
      approve: this.approve,
      callback: this.finishedProcessing,
    });
  }

  finishedProcessing = () => {
    
    this.isSubmitting$ = new BehaviorSubject<boolean>(
      (this.isSubmitting = false)
    );
  };

  showConfirmationDialog() {
    const confirmDialog = new ConfirmDialog();

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "500px",
      data: confirmDialog,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === "ok") {
        this.effectiveCancel();
      }
      if (result === "cancel") {
      }
    });
  }
}
