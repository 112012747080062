import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { BuildResumeNOComponent } from "./build-resumeNO.component";
import { BuildResumeCardComponent } from "../build-resume/build-resume-card/build-resume-card.component"; // TODO: Add NO version
import { PendingChangesGuard } from "../common/services/pending-changes-guard.service";

const BuildResumeRoutes: Routes = [
  {
    path: '',
    canDeactivate: [PendingChangesGuard],
    component: BuildResumeNOComponent,
    children: [
      { path: ':id', component: BuildResumeCardComponent, pathMatch: 'full' }
    ]
  },
  // {
  //   path: "app/build-resume",
  //   component: BuildResumeComponent,
  //   children: [
  //     { path: ":id", component: BuildResumeCardComponent, pathMatch: "full" },
  //   ],
  // },
];

@NgModule({
  imports: [RouterModule.forChild(BuildResumeRoutes)],
  exports: [RouterModule],
})
export class BuildResumeNORoutingModule {}
