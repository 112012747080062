import { ElementRef, Injectable } from '@angular/core';
import { NavigationDropdown, NavigationItem, NavigationLink, NavigationSubheading } from '../interfaces/navigation-item.interface';
import { Subject } from 'rxjs';
import { AltOnlinerDefault } from '../../app/common/data-models/AltOnlinerDefault';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  items: NavigationItem[] = [];

  private _openChangeSubject = new Subject<NavigationDropdown>();
  openChange$ = this._openChangeSubject.asObservable();
  private isSlideChecked: boolean = false;
  private altOnlinerDefault: AltOnlinerDefault = null;
  private scrollToTopButton: ElementRef = null;

  constructor() {}

  triggerOpenChange(item: NavigationDropdown) {
    this._openChangeSubject.next(item);
  }

  isLink(item: NavigationItem): item is NavigationLink {
    return item.type === 'link';
  }

  isDropdown(item: NavigationItem): item is NavigationDropdown {
    return item.type === 'dropdown';
  }

  isSubheading(item: NavigationItem): item is NavigationSubheading {
    return item.type === 'subheading';
  }

  setIsSlideChecked(val: boolean) {
    this.isSlideChecked = val;
  }

  getIsSlideChecked() {
    return this.isSlideChecked;
  }

  setAltOnlinerDefault(defaultUser) {
    this.altOnlinerDefault = { ...defaultUser };
  }

  getAltOnlinerDefault() {
    return this.altOnlinerDefault;
  }

  setScrollToTopButton(scrollToTopButton: ElementRef) {
    this.scrollToTopButton = scrollToTopButton;    
  }

  getScrollToTopButton() {
    return this.scrollToTopButton;
  }
}
